import usePersistedStorage from "hook/persisted-storage/usePersistedStorage";
import { useEffect, useState } from "react";
import { createIntegrationToken } from "api/api";
import { useNotification } from "context/notificationContext";
import { copyToClipBoard } from "utils/helpers";
import { fetchEnabledIntegrations, revokeIntegration } from "api/api";
import { useAppSelector } from "store/storeTypes";
import { PARTNER_NAME_TO_LOGO } from "./constants.ts";

// ------------ Integrations Tab -------------
const Integrations = () => {
  const [localWorkspaceValue] = usePersistedStorage("vultron_workspace_id");
  const currentUser = useAppSelector((store) => store.auth.currentUser);
  const { setToast } = useNotification();
  const [sendingIntegration, setSendingIntegration] = useState(false);
  const [partnerCards, setPartnerCards] = useState([]);

  const createStorageKey = (partnerId) => `integration_token_${partnerId}`;
  const addPartnerFields = (partners) =>
    partners.map((partner) => ({
      partner_id: partner.id,
      partner_name: partner.name,
      partner_description: partner.description,
      partner_logo: PARTNER_NAME_TO_LOGO[partner.name],
      partner_token: localStorage.getItem(createStorageKey(partner.id)),
    }));

  useEffect(() => {
    fetchEnabledIntegrations()
      .then((response) => {
        const newPartnerCards = addPartnerFields(response.data.enabled_integrations);
        setPartnerCards(newPartnerCards);
      })
      .catch((error) => {
        // TODO add toast
      });
  }, [setToast]);

  const getIntegrationToken = (partner_id) => {
    return localStorage.getItem(createStorageKey(partner_id));
  };
  const copyText = (txt, showToast = false) => {
    if (!txt) {
      setToast.error({
        msg: "There was an issue copying the key. Please try again.",
      });
    }

    copyToClipBoard(txt).then(() => {
      if (showToast)
        setToast.success({
          msg: "Copied to clipboard",
        });
    });
  };

  const clearToken = (partnerId) => {
    localStorage.removeItem(createStorageKey(partnerId));
    setPartnerCards((currentCards) =>
      currentCards.map((card) => (card.partner_id === partnerId ? { ...card, partner_token: "" } : card)),
    );
    revokeIntegration(partnerId);
    setToast.success({
      msg: "Revoked key successfully",
    });
  };

  const handleIntegrationAuth = (email, workspace_id, partner_id) => {
    if (sendingIntegration) return;
    setSendingIntegration(true);
    const data = {
      email: email,
      workspace_id: workspace_id,
      partner_id: partner_id,
    };
    createIntegrationToken(data)
      .then((res) => {
        const write_only_session_jwt = res?.data?.write_only_session_jwt;
        localStorage.setItem(createStorageKey(partner_id), write_only_session_jwt);
        setPartnerCards((currentCards) =>
          currentCards.map((card) =>
            card.partner_id === partner_id ? { ...card, partner_token: write_only_session_jwt } : card,
          ),
        );
        copyText(write_only_session_jwt);
        setToast.success({
          msg: "Key generated successfully and copied to clipboard",
        });
      })
      .catch((err) => {
        const errResp = err?.response?.data?.error_msg;
        setToast.error({
          title: "Integration Authentication Error",
          msg: errResp || "There was an issue authorizing your integration. Please refresh and try again.",
        });
      })
      .finally(() => {
        setSendingIntegration(false);
      });
  };

  return (
    <div className="py-3">
      <div className="rounded-lg mb-3 bg-white p-5">
        <h4 className="font-medium text-base mb-8 flex items-center">Integrations</h4>
        {partnerCards?.map((card, ind) => (
          <div className="w-[30%] flex-shrink border rounded-lg border-[#DBE0E5] bg-[#fff] p-6" key={ind}>
            <div className="flex h-full flex-grow flex-col gap-5">
              <div className="flex items-start justify-between gap-4">
                <div className="flex flex-grow flex-row">
                  <img src={card?.partner_logo} alt="" className="w-7 h-7 mr-2" />
                  <span className="text-[#1D2630] text-sm font-medium leading-6 line-clamp-2 mt-0.5">
                    {card?.partner_name}
                  </span>
                </div>
                {card.partner_token ? (
                  <button
                    onClick={() => {
                      const fetchedIntegrationToken = getIntegrationToken(card.partner_id);
                      copyText(fetchedIntegrationToken || "", true);
                    }}
                    className="px-2 py-1.5 bg-slate-200 text-slate-600 rounded shadow-sm text-xs flex items-center justify-center duration-100 hover:text-slate-900 hover:bg-slate-300 disabled:opacity-50 disabled:hover:text-slate-600 disabled:hover:bg-slate-200"
                  >
                    Copy Key
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      handleIntegrationAuth(currentUser?.email, localWorkspaceValue, card.partner_id);
                    }}
                    className="px-2 py-1.5 bg-slate-200 text-slate-600 rounded shadow-sm text-xs flex items-center justify-center duration-100 hover:text-slate-900 hover:bg-slate-300 disabled:opacity-50 disabled:hover:text-slate-600 disabled:hover:bg-slate-200"
                  >
                    Generate Key
                  </button>
                )}
                <button
                  onClick={() => {
                    clearToken(card.partner_id);
                  }}
                  className="px-2 py-1.5 bg-slate-200 text-slate-600 text-xs rounded shadow-sm flex items-center justify-center duration-100 hover:text-slate-900 hover:bg-slate-300 disabled:opacity-50 disabled:hover:text-slate-600 disabled:hover:bg-slate-200"
                >
                  Revoke Key
                </button>
              </div>
              <div className="text-sm">{card.partner_description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Integrations;
