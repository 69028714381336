import { Modal } from "components/organisms/modal";
import { ComponentProps, useEffect, useState } from "react";
import { Subdirectory } from "types/FileStorage";
import { useMediaOperations } from "../media/useMediaOperations";

interface Props extends Partial<ComponentProps<typeof Modal>> {
  currentDirectory?: Subdirectory | null;
}

const AddMediaFolderModal = ({ currentDirectory, ...props }: Props) => {
  const [name, setName] = useState("");
  const { createFolder, isLoading } = useMediaOperations();

  useEffect(() => {
    if (props.open) {
      setName("");
    }
  }, [props.open]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!name.length) return;

    await createFolder({
      parent_directory_id: currentDirectory?.id,
      name,
    });

    props.onOpenChange?.(false);
  };

  return (
    <Modal
      contentProps={{ onClick: (e) => e.stopPropagation() }}
      title="New Folder"
      header="New Folder"
      body={
        <form className="flex flex-col overflow-y-auto px-5" onSubmit={handleSubmit}>
          <label className="mb-2 block text-sm text-[#5B6B79]">Name</label>
          <input
            type="text"
            className="w-full rounded-md py-2 px-3 h-10 border border-gray-light outline-none"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoFocus
            required
          />
          <div className="w-full flex justify-end mt-8">
            <div className="flex gap-1">
              <button
                type="button"
                className="border border-[#DBE0E5] rounded-lg py-[9px] px-4 text-sm font-medium text-[#1D2630]"
                onClick={() => props.onOpenChange?.(false)}
                disabled={isLoading}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="border-0 bg-gray-darkest rounded-lg py-[9px] px-4 text-sm font-medium text-[#ffffff] flex items-center gap-2 disabled:opacity-50"
                disabled={isLoading || !name.length}
              >
                Create
              </button>
            </div>
          </div>
        </form>
      }
      {...props}
    />
  );
};

export default AddMediaFolderModal;
