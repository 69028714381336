import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom/dist";
import { useDispatch } from "react-redux";
import { Spinner } from "utils/icons";
import { validateField } from "utils/helpers";
import { getWorkSpaces } from "store/reducers/authReducerSlice";
import { createWorkspace, getWorkspaceNames } from "api/api";
import { CircleArrowLeft } from "lucide-react";
import { useSearchDebounce } from "hook/useDebounce";
import { useNotification } from "context/notificationContext";

const initialState = {
  name: "",
  description: "",
  user_role: "",
};

// ----------------------- Create new workspace page --------------------
const CreateWorkSpace = () => {
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const { setToast } = useNotification();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState(initialState);
  const [workspaceExist, setworkspaceExist] = useState(false);
  const { search, setSearchQuery } = useSearchDebounce();

  // Handle change input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // handle workspace in change event
  const handleWorkspaceName = (e) => {
    const val = e.target.value;
    setFormData({
      ...formData,
      name: val,
    });
    setSearchQuery(val);
  };

  // check for workspace availability
  useEffect(() => {
    if (!validateField(formData.name)) return;
    getWorkspaceNames(formData.name)
      .then((res) => {
        setworkspaceExist(res?.data?.exists);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [search]);

  // on submit
  const createNewWorkSpace = (e) => {
    e.preventDefault();
    if (loading) return;
    if (formData.name && formData.company) {
      setLoading(true);
      const synthetic_test_secret = localStorage.getItem("synthetic_test_secret");
      createWorkspace(
        {
          company_name: formData.company,
          name: formData.name,
          description: formData.description,
        },
        synthetic_test_secret ? { "X-Synthetic-Test-User-Token": synthetic_test_secret } : {},
      )
        .then((res) => {
          dispatch(getWorkSpaces());
          setToast.success({
            msg: "Workspace created",
          });
          navigate("/select-workspace");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setToast.error({
            title: "Unable to create workspace",
            msg: "We were unable to create the workspace due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
          });
          console.log("Error while creating a new workspace :", err);
        });
    }
  };

  const areAllFieldsValid = () => {
    return formData.name && formData.company && formData.description && !workspaceExist;
  };

  return (
    <div className="flex w-full min-h-[100vh]">
      <div className="top-5 left-5 font-primary-font absolute">
        <Link to={window.history.state && window.history.state.idx > 0 ? -1 : `/dashboard`} className="text-gray-400">
          <CircleArrowLeft size={29} />{" "}
        </Link>
      </div>
      <div className="py-4 m-auto max-w-[560px] w-full">
        <div className="flex flex-col items-center justify-center font-primary-font">
          <h1 className="text-4xl font-bold py-1 text-center">
            Create a team <br /> workspace{" "}
          </h1>
          <p className="text-gray-600 py-2">Fill in some details on your team and company</p>
        </div>
        <form onSubmit={createNewWorkSpace} className="flex flex-col items-center justify-center">
          <div className="flex flex-col items-start justify-start pt-6 font-primary-font w-[90%]">
            <label htmlFor="name" className="pb-2 text-[#5b6b79] font-bold items-center flex gap-1">
              Workspace Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              onChange={handleWorkspaceName}
              value={formData.name}
              placeholder="Acme Team"
              className={`border-[#CCCCCC] focus:border-sky-600 border-2 rounded-lg p-2 w-full focus:outline-none ${
                workspaceExist ? "border-red-500 focus:border-red-500" : ""
              } ${error.name ? "border-red-500 focus:border-red-500" : ""}`}
            />
            {workspaceExist ? (
              <p className="text-red-500 mt-1 mb-2 block text-[14px]">Workspace name already exists</p>
            ) : null}
          </div>
          <div className="flex flex-col items-start justify-start pt-6 font-primary-font w-[90%]">
            <label htmlFor="name" className="pb-2 text-[#5b6b79] font-bold items-center flex gap-1">
              Company Name
            </label>
            <input
              type="text"
              name="company"
              id="company"
              onChange={handleChange}
              value={formData.company}
              placeholder="Acme Inc."
              className={`${
                error?.company ? "border-red-500 focus:border-red-500" : "border-[#CCCCCC] focus:border-sky-600"
              }  border-2 rounded-lg p-2 w-full focus:outline-none`}
            />
          </div>
          <div className="flex flex-col items-start justify-start pt-6 font-primary-font w-[90%]">
            <label htmlFor="description" className="pb-2 text-[#5b6b79] font-bold items-center flex gap-1">
              Company Overview
            </label>
            <textarea
              type="text"
              name="description"
              id="description"
              onChange={handleChange}
              value={formData?.description}
              placeholder="Acme Inc. provides a secure communication system with high-level encryption for government agencies, ensuring efficient and confidential data exchange."
              className={`${
                error?.description ? "border-red-500 focus:border-red-500" : "border-[#CCCCCC] focus:border-sky-600"
              }  border-2 rounded-lg p-2 w-full focus:outline-none  min-h-[130px]`}
            />
          </div>

          <button
            type="submit"
            disabled={!areAllFieldsValid() || loading}
            title={loading ? "Creating workspace" : "Create workspace"}
            className={`bg-action text-white flex my-10 w-[90%] flex-col rounded-md p-3 items-center text-lg font-primary-font ${
              !areAllFieldsValid() || loading ? "disabled:opacity-60" : ""
            }`}
          >
            {loading ? (
              <span className="flex gap-3">
                <span>
                  <Spinner />
                </span>{" "}
                <span>Creating Workspace</span>
              </span>
            ) : (
              <span>Create Workspace</span>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateWorkSpace;
