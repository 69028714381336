/** @jsxImportSource @emotion/react */

import { ComplianceMatrixRow } from "components/copilot/CopilotSchemaTypes";
import { ExternalLink, SquareMinus } from "lucide-react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { ToImmutable } from "YJSProvider/LiveObjects";
import {
  setActiveDocument,
  setActiveDragOverId,
  setHighlightedElementId,
} from "store/reducers/extract/CurrentExtractionReducer";
import useExtractionOperations from "hook/useExtractionOperations";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "styles/markdown-styles.css";
import { highlightAndScrollToElement } from "../../../utils";
import { useSelection } from "../../../document-display/SelectionContext";
import { YJS_OPERATIONS } from "const-values/yjs";
import InsertHereLine from "components/atoms/insert-here-line";
import { useDocViewNotification } from "components/copilot/extract-v2/context/doc-view-notification-context/docViewNotificationContext";
import DropItemsContainer from "components/atoms/drop-items-container";
import compact from "lodash/compact";
import { memo } from "react";

type Props = {
  row: ToImmutable<ComplianceMatrixRow>;
  isReadOnly: boolean;
  extractionId?: string;
  sectionId: string;
  isNewlyAdded: boolean;
  idx: number;
};

const RequirementItem = ({ row, isReadOnly, extractionId, sectionId, isNewlyAdded, idx }: Props) => {
  const activeDocument = useAppSelector((store) => store.currentExtractionState.activeDocument);
  const dispatch = useAppDispatch();
  const isDraggingOver = useAppSelector(
    (store) => store.currentExtractionState.activeDragOverId === row.requirement.id,
  );
  const documents = useAppSelector((store) => store.currentExtractionState.documents);
  const { selectedBlocks, clearSelection } = useSelection();
  const { assignExtractionRequirement, bulkAssignExtractionRequirements, addAttribution } = useExtractionOperations();
  const highlightedElementId = useAppSelector((root) => root.currentExtractionState.highlightedElementId);
  const { showRequirementSuccessToast } = useDocViewNotification();

  return (
    <div
      onDragEnter={(e) => {
        e.preventDefault();
        dispatch(setActiveDragOverId(row.requirement.id));
      }}
      id={`requirement-row-${row.requirement.id}`}
      key={row.requirement.id}
      className="relative group/requirementRow border-b border-gray-light"
    >
      {activeDocument?.id !== row.document?.id ? (
        <button
          onClick={() => {
            if (isReadOnly) return;
            const doc = documents.find(({ id }) => id === row.document?.id);
            if (doc) dispatch(setActiveDocument(doc));
          }}
          className="text-xs z-[1] select-none flex items-center gap-2 ml-auto absolute p-1.5 top-2 right-2 left-2 max-w-[175px] left shadow-sharp-thin rounded transition-opacity opacity-0 bg-gray-300 text-slate-900  group-hover/requirementRow:opacity-100 hover:bg-gray-300 font-medium"
        >
          <span className="truncate">{row.document?.name}</span>
          <ExternalLink size={14} className="shrink-0" />
        </button>
      ) : (
        !isReadOnly && (
          <button
            onClick={() => {
              if (isReadOnly) return;

              if (extractionId && row?.proposal_reference?.section_id) {
                assignExtractionRequirement(extractionId, row.requirement.id, row.proposal_reference.section_id);
                addAttribution(YJS_OPERATIONS.EXTRACTION.SET_REQUIREMENT_UNASSIGN);
              }
            }}
            className="text-xs z-[1] select-none flex items-center justify-center gap-2 ml-auto absolute p-1.5 top-2 right-2 left-2 max-w-[90px] left shadow-sharp-thin rounded transition-opacity opacity-0 bg-gray-darkest text-gray-200 group-hover/requirementRow:opacity-100 hover:bg-zinc-700"
          >
            Unassign
            <SquareMinus size={14} />
          </button>
        )
      )}
      <div
        className="flex peer gap-2 p-1.5 duration-100 items-center cursor-pointer group-hover/requirementRow:bg-slate-100 overflow-x-auto"
        onClick={() => {
          if (!!selectedBlocks?.length && extractionId && !isReadOnly) {
            const selectedReqIds = selectedBlocks.map((block) => block.requirement.requirement.id);

            bulkAssignExtractionRequirements(extractionId, selectedReqIds, sectionId, idx + 1);
            clearSelection?.();
            return;
          }

          if (!row.requirement.element_id || activeDocument?.id !== row.document?.id) return;

          if (highlightedElementId === row.requirement.element_id) {
            highlightAndScrollToElement(highlightedElementId);
          } else {
            dispatch(setHighlightedElementId(row.requirement.element_id));
          }
        }}
        css={[
          !!selectedBlocks?.length && !isReadOnly && tw`group-hover/requirementRow:bg-slate-100`,
          activeDocument?.id !== row.document?.id && !isReadOnly && tw`cursor-default`,
          isNewlyAdded && tw`!bg-slate-100 duration-200`,
        ]}
      >
        <Markdown remarkPlugins={[remarkGfm]} className="text-xs text-gray-600 whitespace-pre-line w-full">
          {row.requirement?.content}
        </Markdown>
      </div>
      {!isReadOnly && (
        <InsertHereLine
          onHoverOnly
          onClick={() => {
            if (!extractionId) return;
            const selectedReqIds = selectedBlocks?.map((block) => block.requirement.requirement.id) || [];
            bulkAssignExtractionRequirements(extractionId, selectedReqIds, sectionId, idx + 1);
            showRequirementSuccessToast("assigned to Proposal Outline", selectedReqIds.length);
          }}
        />
      )}
      {isDraggingOver && (
        <DropItemsContainer
          styles={[tw`border-b-4 border-dashed`]}
          onDrop={(e) => {
            if (!extractionId) return;
            const data = e.dataTransfer.getData("application/json");
            if (!data) return;

            try {
              const reqIdMap = JSON.parse(data);
              const reqIds = compact(Object.keys(reqIdMap));

              bulkAssignExtractionRequirements(extractionId, reqIds, sectionId, idx + 1);
              clearSelection?.();
            } catch {
            } finally {
              dispatch(setActiveDragOverId(""));
            }
          }}
          dropLabel="Add selected requirements below"
        />
      )}
    </div>
  );
};

export default memo(RequirementItem);
