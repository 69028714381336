import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { isEqual } from "lodash";
import { AutopilotHealthCheck } from "types/Autopilot/HealthCheck";

type State = AutopilotHealthCheck;

const initialState: State = {
  requirement_response_tasks: [],
  requirement_document_tasks: [],
  requirement_text_tasks: [],
  template_document_tasks: [],
  template_text_tasks: [],
  create_document_view_tasks: [],
  proposal_review_tasks: [],
};

export const getAutopilotHealthCheck = createAsyncThunk<AutopilotHealthCheck, string>(
  "autopilotHealthCheck/getAutopilotHealthCheck",
  (internalContractId) => {
    return axios.get(`autopilot/${internalContractId}/task_statuses`).then((res) => {
      return res.data;
    });
  },
);

const autopilotHealthCheckReducer = createSlice({
  name: "autopilotHealthCheckReducer",
  initialState,
  reducers: {
    setAutopilotHealthCheck: (state: State, action: PayloadAction<Partial<State>>) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAutopilotHealthCheck.fulfilled, (state, action) => {
      const shouldUpdate = !isEqual(state, action.payload);

      if (shouldUpdate) return action.payload;
    });
  },
});

export const { setAutopilotHealthCheck } = autopilotHealthCheckReducer.actions;

export default autopilotHealthCheckReducer.reducer;
