import newGenerationImage from "Assets/New Generation Image.png";
import { CirclePlay, LucideArrowRight } from "lucide-react";

const GenerateInstructions = () => {
  return (
    <div className="bg-gray-lightest border border-gray-light shadow rounded-md gap-4 mt-3 flex flex-row justify-between items-center">
      <div className="gap-8 flex flex-col pl-8 pr-4 pb-4 pt-7">
        <div className="flex flex-col gap-2">
          <div className="text-gray-darkest text-xl font-medium">What are Generations?</div>
          <div className="text-gray-500 text-sm">
            Generations take you from solicitation drop to a review-ready proposal draft in hours.
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-2 items-center">
            <LucideArrowRight size={16} className="shrink-0" />
            <span className="text-sm text-gray-600">Create a compliant proposal outline from the solicitation</span>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <LucideArrowRight size={16} className="shrink-0" />
            <span className="text-sm text-gray-600">
              Generate an instant proposal draft from imported documents, capture, and outline
            </span>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <LucideArrowRight size={16} className="shrink-0" />
            <span className="text-sm text-gray-600">
              Publish the results and collaborate with your team to finalize the proposal
            </span>
          </div>
          <div className="flex flex-row gap-2 items-center my-4">
            <a
              href="https://intercom.help/vultron-ai/en/articles/9312141-projects-generate"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-row gap-2 items-center hover:bg-[#F0F0F0] px-3 py-2 -my-2 -ml-2 rounded-md text-gray-600 font-medium"
            >
              <CirclePlay size={16} />
              <span className="text-sm">Watch a quick walkthrough</span>
            </a>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center pl-0 p-5 h-full">
        <img src={newGenerationImage} className="max-w-[650px] w-full h-auto" alt="Generations" />
      </div>
    </div>
  );
};

export default GenerateInstructions;
