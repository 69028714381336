import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LiveTemplateExtractionResponse } from "components/copilot/extract-v2/doc-viewer/document-display/bulk-floating-pill-actions/hooks";

type State = {
  isLoadingLiveTemplateExtraction: boolean;
  failedToExtract: boolean;
  templateData: LiveTemplateExtractionResponse["template_data"]["volumes"];
};

const initialState: State = {
  isLoadingLiveTemplateExtraction: false,
  failedToExtract: false,
  templateData: [],
};

const liveTemplateExtractionReducer = createSlice({
  name: "liveTemplateExtractionReducer",
  initialState,
  reducers: {
    setFailedToExtract: (state, action: PayloadAction<State["failedToExtract"]>) => {
      state.failedToExtract = action.payload;
    },
    setIsLoadingLiveTemplateExtraction: (state, action: PayloadAction<State["isLoadingLiveTemplateExtraction"]>) => {
      const isLoading = action.payload;
      if (isLoading) {
        state.isLoadingLiveTemplateExtraction = isLoading;
        state.failedToExtract = false;
      } else state.isLoadingLiveTemplateExtraction = isLoading;
    },
    appendLiveTemplateExtraction: (state, action: PayloadAction<State["templateData"]>) => {
      const failedToExtract = !action.payload?.length;
      if (failedToExtract) state.failedToExtract = true;
      state.templateData = [...state.templateData, ...action.payload];
    },
    removeTemplateVolume: (state, action: PayloadAction<string>) => {
      state.templateData = state.templateData.filter(({ id }) => id !== action.payload);
    },
    clearLiveTemplateExtractionState: () => {
      return initialState;
    },
  },
});

export const {
  removeTemplateVolume,
  setFailedToExtract,
  setIsLoadingLiveTemplateExtraction,
  clearLiveTemplateExtractionState,
  appendLiveTemplateExtraction,
} = liveTemplateExtractionReducer.actions;

export default liveTemplateExtractionReducer.reducer;
