import classNames from "classnames";
import IconButton from "components/atoms/icon-button";
import React from "react";
import {
  IndentDecrease,
  IndentIncrease,
  AArrowDown,
  AArrowUp,
  LockKeyhole,
  LockKeyholeOpen,
  Minus,
  Plus,
} from "lucide-react";
import { LucideIconButton } from "./LucideIconButton";
import Tooltip from "components/atoms/tooltip";

type AllowedIcons =
  | React.ComponentProps<typeof IconButton>["name"]
  | "minus"
  | "plus"
  | "a-arrow-down"
  | "a-arrow-up"
  | "indent-decrease"
  | "indent-increase"
  | "lock-keyhole-open"
  | "lock-keyhole";
type Props = Pick<React.ComponentProps<"button">, "title" | "disabled" | "onClick"> & {
  className?: string;
  flipHorizontal?: boolean;
  icon: AllowedIcons;
  sm?: boolean;
};

const iconSize: Partial<{ [icon in AllowedIcons]: number }> = {
  Undo: 16,
  Trash: 18,
  "lock-keyhole": 18,
  "lock-keyhole-open": 18,
};
const defaultIconSize = 20;

export function ToolbarButton({ className, sm, disabled, onClick, title, icon, flipHorizontal }: Props) {
  let button;
  const sizeMultiplier = sm ? 0.8 : 1;
  const sharedProps = {
    iconProps: {
      className: `w-[${(iconSize[icon] || defaultIconSize) * sizeMultiplier}px] h-[${
        (iconSize[icon] || defaultIconSize) * sizeMultiplier
      }px]`,
    },
    className: classNames(
      className,
      "p-[5px] mx-[1px] hover:bg-gray-200 hover:text-[#52525b] rounded-md disabled:opacity-50 disabled:pointer-events-none",
      flipHorizontal && "transform -scale-x-100",
    ),
    disabled,
    onClick: (e: Parameters<NonNullable<Props["onClick"]>>[0]) => {
      if (disabled) {
        return;
      }
      return onClick?.(e);
    },
  };

  switch (icon) {
    case "indent-decrease":
      button = <LucideIconButton Component={IndentDecrease} {...sharedProps} />;
      break;
    case "indent-increase":
      button = <LucideIconButton Component={IndentIncrease} {...sharedProps} />;
      break;
    case "a-arrow-down":
      button = <LucideIconButton Component={AArrowDown} {...sharedProps} />;
      break;
    case "a-arrow-up":
      button = <LucideIconButton Component={AArrowUp} {...sharedProps} />;
      break;
    case "plus":
      button = <LucideIconButton Component={Plus} {...sharedProps} />;
      break;
    case "minus":
      button = <LucideIconButton Component={Minus} {...sharedProps} />;
      break;
    case "lock-keyhole":
      button = <LucideIconButton Component={LockKeyhole} {...sharedProps} />;
      break;
    case "lock-keyhole-open":
      button = <LucideIconButton Component={LockKeyholeOpen} {...sharedProps} />;
      break;
    default:
      button = <IconButton name={icon} {...sharedProps} />;
      break;
  }

  return title ? <Tooltip content={title}>{button}</Tooltip> : button;
}
