/** @jsxImportSource @emotion/react */

import { Sheet, Storage } from "components/copilot/CopilotSchemaImmutableTypes";
import { EMPTY_SHEET, EMPTY_SHEET_DRAG_IDENTIFIER } from "const-values/Sheets";
import { memo, useEffect, useMemo, useState } from "react";
import { Plus } from "lucide-react";
import { setActiveSheetState } from "store/reducers/copilot/requirementsReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import SheetTab from "./SheetTab";
import { DndContext, DragOverlay, closestCenter } from "@dnd-kit/core";
import { useDrag } from "./hooks";
import { restrictToHorizontalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import SortableItem from "./SortableItem";
import { SortableContext, horizontalListSortingStrategy } from "@dnd-kit/sortable";
import NewSheetModal from "./NewSheetModal";
import { useStorage } from "YJSProvider/createYJSContext";

const SheetsTabs = () => {
  const [newSheetOpen, setNewSheetOpen] = useState(false);
  const shouldShowDefault = useStorage((storage) => {
    const defaultSheetExists = (storage.sheets || []).some((sheet) => sheet.name === EMPTY_SHEET.name);
    return !defaultSheetExists && storage.compliance_matrix.some((row) => !row.requirement?.extraction_id);
  });
  const activeSheet = useAppSelector((root) => root.requirements.activeSheet);
  const { handleDragCancel, handleDragEnd, handleDragStart, sensors, activeDragId } = useDrag();

  const sheets: Sheet[] = useStorage((storage) => [
    ...(shouldShowDefault ? [EMPTY_SHEET] : []),
    ...((storage.sheets as Storage["sheets"]) || []),
  ]);

  const dispatch = useAppDispatch();

  const activeSheetExists = useMemo(
    () => !!sheets.find((sheet) => sheet.id === activeSheet?.id),
    [activeSheet?.id, sheets],
  );
  useEffect(() => {
    if (!activeSheetExists) dispatch(setActiveSheetState(sheets[0] || null));
  }, [activeSheetExists, dispatch, sheets]);

  const activeDragSheet = useMemo(
    () =>
      activeDragId === EMPTY_SHEET_DRAG_IDENTIFIER ? EMPTY_SHEET : sheets?.find((sheet) => sheet.id === activeDragId),
    [activeDragId, sheets],
  );

  return (
    <>
      <div className="flex z-[1] overflow-x-auto bg-slate-100 pb-1 pl-1 border border-t-0 rounded-b-lg border-slate-200">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
          onDragEnd={(event) => handleDragEnd(event, sheets)}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
        >
          <div className="flex">
            <SortableContext id="SHEETS" items={sheets || []} strategy={horizontalListSortingStrategy}>
              {sheets?.map((sheet, idx) => <SortableItem idx={idx} key={sheet.id} sheet={sheet} sheets={sheets} />)}
              <DragOverlay style={{ transformOrigin: "0 0 " }}>
                {activeDragSheet && <SheetTab sheet={activeDragSheet} sheets={sheets} idx={2} isDragging />}
              </DragOverlay>
            </SortableContext>
          </div>
        </DndContext>
        <button
          onClick={() => setNewSheetOpen(true)}
          className="z-[3] flex justify-center items-center min-h-[34px] min-w-[34px] text-slate-500 sticky right-0 bg-slate-100 text-sm duration-100 hover:text-slate-900"
        >
          <Plus size={14} />
        </button>
      </div>
      <NewSheetModal sheets={sheets} open={newSheetOpen} onOpenChange={setNewSheetOpen} />
    </>
  );
};

export default memo(SheetsTabs);
