import { Tab } from "components/organisms/tabs/Tabs";
import { PrefixType, TabSlug } from "./types";

export const REQUIREMENT_TABS_META: { [key in TabSlug]: Tab<TabSlug> } = {
  [TabSlug.Unassigned]: {
    name: "Unassigned",
    slug: TabSlug.Unassigned,
    empty: "You don't have any requirements",
    emptyAction: "Extract requirements to get started",
  },
  [TabSlug.Assigned]: {
    name: "Assigned",
    slug: TabSlug.Assigned,
    empty: "You don't have assigned requirements",
    emptyAction: "Assign requirements to get started",
  },
  [TabSlug.Disregarded]: {
    name: "Disregarded",
    slug: TabSlug.Disregarded,
    empty: "You don't have disregarded requirements",
    emptyAction: "Disregard requirements to get started",
  },
};

export const DRAGGABLE_PREFIX_TO_TAB: Record<PrefixType, TabSlug> = {
  "drawer:unassigned:": TabSlug.Unassigned,
  "drawer:assigned:": TabSlug.Assigned,
  "drawer:disregarded:": TabSlug.Disregarded,
};
