export const LEGACY_DOCUMENT_TYPES = [
  "past_proposal",
  "technical_whitepaper",
  "marketing_material",
  "product_documentation",
  "certification",
  "Past Proposal",
  "Technical Whitepaper",
  "Marketing Material",
  "Product Documentation",
];

export const DOCUMENT_TYPES: { [key: string]: string } = {
  proposal: "proposal",
  past_performance: "past_performance",
  whitepaper: "whitepaper",
  solutions: "solutions",
  resume: "resume",
  reports: "reports",
  capabilities: "capabilities",
  technical_documentation: "technical_documentation",
  project_management: "project_management",
  certifications: "certifications",
  past_proposal: "proposal",
  technical_whitepaper: "whitepaper",
  marketing_material: "reports",
  product_documentation: "technical_documentation",
  certification: "certifications",
};
