/** @jsxImportSource @emotion/react */
import { useMemo, useState } from "react";

///////////////// IMPORTING IMAGES AND ICONS //////////////////////
import Icon from "components/atoms/icons/Icon";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { MoveIcon } from "utils/icons";
import dots from "../../Assets/3dots.svg";
import dltLoader from "../../Assets/delete-loader.svg";
import doc from "../../Assets/generic-doc.png";
import { ExternalLink, TriangleAlert } from "lucide-react";

/////////////////// IMPORTING CUSTOM COMPONENTS AND FUNCTIONS //////////////
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { Download, Trash2 } from "lucide-react";
import tw from "twin.macro";
import { toggleDocumentViewerModal } from "store/reducers/modalsSlice";
import { useAppDispatch } from "store/storeTypes";
import { getDocumentStatus } from "./getDocumentStatus";
import { getReviewStatus } from "./getReviewStatus";
import { Checkbox } from "components/atoms/checkbox";
import { useFlags } from "hook/useFlags";
//////////////////////// START OF MAIN FUNCTION //////////////////////////
//////////////////////////////////////////////////////////////////////////

export default function Document({
  deleteDocument,
  setDraggingFolderId,
  setDraggingDocIds,
  docData,
  handleMoveModalOpen,
  onSelect,
  isSelected,
  selectedDocuments,
  onDocumentClick,
  isDocumentPanelOpen,
}) {
  const { id, name, formatted_set_date, file_extension_type, download_url, review_date } = docData;
  const [deleting, setDeleting] = useState(false);
  const dispatch = useAppDispatch();
  const flags = useFlags();
  const status = getDocumentStatus(docData, flags);
  const reviewStatus = getReviewStatus(review_date);

  ///////////////////// drag and drop function ////////////////

  const handleDragStart = (e) => {
    e.stopPropagation();
    const draggedDocs = selectedDocuments.includes(id) ? selectedDocuments : [id];
    setDraggingDocIds(draggedDocs);
    setDraggingFolderId("");

    // Create a custom drag image
    const dragImage = document.createElement("div");
    dragImage.style.position = "absolute";
    dragImage.style.top = "-1000px";
    dragImage.style.padding = "10px";
    dragImage.style.background = "white";
    dragImage.style.border = "1px solid #dbe0e5";
    dragImage.style.borderRadius = "4px";
    dragImage.style.fontSize = "14px";

    if (draggedDocs.length > 1) {
      dragImage.textContent = `${draggedDocs.length} documents`;
    } else {
      dragImage.textContent = name;
    }

    // Add the drag image to the DOM
    document.body.appendChild(dragImage);

    // Set the drag image
    e.dataTransfer.setDragImage(dragImage, 0, 0);

    // Remove the drag image after a short delay
    setTimeout(() => {
      document.body.removeChild(dragImage);
    }, 0);

    // Set drag data
    e.dataTransfer.setData("text/plain", JSON.stringify(draggedDocs));

    // Change cursor style
    e.dataTransfer.effectAllowed = "move";
  };

  const menuItems = useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex items-center gap-2">
            <Download size={14} /> Download
          </div>
        ),
        onSelect: () => {
          const a = document.createElement("a");
          a.href = download_url;
          a.download = name;
          a.click();
        },
      },
      {
        key: 2,
        label: (
          <div className="flex items-center gap-2">
            <MoveIcon />
            Move
          </div>
        ),

        onSelect: () => {
          handleMoveModalOpen(id);
        },
      },
      {
        key: 3,
        label: (
          <div className="flex items-center gap-2 text-red-500">
            {deleting ? <img src={dltLoader} alt="icon" width={14} height={14} /> : <Trash2 size={14} />}
            Delete
          </div>
        ),
        disabled: deleting,
        onSelect: () => {
          setDeleting(true);
          deleteDocument(id).finally(() => {
            setDeleting(false);
          });
        },
      },
    ],
    [deleteDocument, deleting, download_url, handleMoveModalOpen, id, name],
  );

  return (
    <div
      className={`card group relative flex flex-col w-full rounded-lg cursor-pointer overflow-hidden ${
        isSelected || isDocumentPanelOpen ? "border-2 border-gray-darkest" : "border-2 border-gray-light"
      }`}
      onDragStart={handleDragStart}
      draggable
      data-doc-id={id}
      onClick={() => onDocumentClick(docData)}
    >
      <div className="absolute top-3 left-3 z-10" onClick={(e) => e.stopPropagation()}>
        <Checkbox checked={isSelected} onCheck={(checked) => onSelect(id, checked)} variant="primaryBlack" />
      </div>
      {file_extension_type === "pdf" && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            dispatch(toggleDocumentViewerModal({ open: true, initialProps: { url: download_url } }));
          }}
          className="text-xs z-[1] select-none flex items-center gap-2 ml-auto absolute p-1.5 top-2 right-2 shadow-sharp-thin rounded transition-opacity opacity-0 bg-gray-300 text-slate-900 group-hover:opacity-100 hover:brightness-95 font-medium"
        >
          <span className="truncate">Open</span>
          <ExternalLink size={14} className="shrink-0" />
        </button>
      )}
      <figure className="relative overflow-hidden w-full h-48 bg-[#DEE1E3] p-5 flex justify-center items-center cursor-pointer">
        <img src={doc} alt="Doc Preview" loading="lazy" className="w-auto max-w-full pointer-events-none" />
        <div className="iframe-overlay absolute h-full w-full cursor-pointer bg-[transparent]"></div>
      </figure>

      <div className="border-t flex-1 bg-white p-4 z-20 -mt-12">
        <div className="flex justify-between gap-2">
          <p className="font-semibold text-xxs break-all mb-2 truncate">{name}</p>
          <div onClick={(e) => e.stopPropagation()}>
            <DropdownMenu triggerProps={{ css: tw`h-fit` }} items={menuItems}>
              <div className="cursor-pointer ml-auto min-w-[20px]">
                <img src={dots} alt="Three Dots" className="max-w-full mx-auto pointer-events-none object-contain" />
              </div>
            </DropdownMenu>
          </div>
        </div>
        <div>
          <div>
            <p className="text-xxs text-gray-mid mb-2">{formatted_set_date}</p>
          </div>
          <div className="ml-auto flex flex-row items-center justify-between">
            {status.showTooltip ? (
              <div className="flex">
                <p className={`text-xxs ${status.color} mr-1`}>{status.text}</p>
                <Tooltip
                  contentProps={{ alignOffset: -78, align: "start" }}
                  content={
                    <div className="text-gray-darkest">
                      <span className="font-normal">{status.tooltipText}</span>
                    </div>
                  }
                  placement="top"
                >
                  <Icon name="InfoCircle" className={`w-3 h-3 ${status.color} mt-0.5`} />
                </Tooltip>
              </div>
            ) : (
              <p className={`text-xxs ${status.color}`}>{status.text}</p>
            )}
            {reviewStatus.needsReview && (
              <Tooltip
                contentProps={{ alignOffset: -78, align: "start" }}
                content={
                  <div className="text-gray-darkest">
                    <span className="font-normal">{reviewStatus.tooltipText}</span>
                  </div>
                }
                placement="top"
              >
                <div className="flex flex-row items-center gap-1" style={{ color: reviewStatus.color }}>
                  <TriangleAlert size={10} />
                  <div className="text-xxs">{reviewStatus.text}</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
