import { deleteInternalContract } from "api/api";
import CSmButton from "components/custom/CSmButton";
import CustomModal from "components/CustomModal";
import { useState } from "react";
import { CloseIconBlack } from "utils/icons";
import info from "../../Assets/info-orange.svg";
import { useNotification } from "context/notificationContext";

const DeleteConfirmModal = ({ isOpen, setIsOpen, selectedProject, setSelectedProject }) => {
  const { setToast } = useNotification();

  const [loading, setLoading] = useState(false);
  const [confirmationVal, setConfirmationVal] = useState("");
  const handleClose = () => {
    setIsOpen(false);
    setSelectedProject();
  };

  const deleteProjectNow = async () => {
    if (!selectedProject) return;

    if (confirmationVal !== selectedProject.projectName)
      return setToast.error({
        title: "Confirm project name",
        msg: "Please confirm your provided input matches the project name.",
      });

    setLoading(true);
    try {
      const response = await deleteInternalContract(selectedProject.id);
      if (response) {
        setToast.success({
          msg: "Project deleted",
        });
        handleClose();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="w-full max-w-[500px] overflow-x-hidden scrollbar-none pb-3"
    >
      <div className="py-5 px-6 flex flex-col pb-2">
        <div className="flex items-center justify-between border-gray-300 w-full  pb-2">
          <h6 className="font-semibold text-[14.5px] flex items-center gap-2">
            <img src={info} alt="" /> <span className="mt-1">Are you sure you want to delete this project?</span>
          </h6>
          <span className="ml-auto block" role="button" onClick={handleClose}>
            <CloseIconBlack fill="black" width={12} height={12} />
          </span>
        </div>
        <div className="mt-2 mx-2">
          <p className="text-[13.5px]">
            This is irreversible operation. This will delete the project and all progress associated with it. Please
            type <span className="font-[600]">"{selectedProject?.projectName}" </span>
            to confirm.
          </p>
        </div>
        <div className="w-full mt-4">
          <input
            className="border-gray-200 border rounded-md px-3 h-[40px] py-[5px] w-full text-[#5b6b79] focus:border-sky-600 focus:outline-none text-[12.5px]"
            placeholder="Enter the project title to delete"
            onChange={(e) => setConfirmationVal(e.target.value)}
          />
        </div>

        <div className="w-full flex justify-end mt-8">
          <div className="flex gap-[10px]">
            <CSmButton title="Cancel" isTheme={false} type="button" onClick={handleClose} style={styles.cancelBtn} />
            <CSmButton
              title="Delete"
              isTheme={true}
              type="button"
              loading={loading}
              onClick={deleteProjectNow}
              disabled={confirmationVal !== selectedProject?.projectName}
              style={styles.submitBtn}
            />
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

const styles = {
  submitBtn: { width: "81px", display: "flex", justifyContent: "center", height: "40px", alignItems: "center" },
  cancelBtn: { width: "80px", display: "flex", justifyContent: "center", height: "40px", alignItems: "center" },
};

export default DeleteConfirmModal;
