import DropdownMenu from "components/molecules/dropdown-menu";
import { FORM_ITEM_STATUS_TO_META } from "const-values/Capture";
import { PropsWithChildren, useMemo } from "react";
import { FormItemStatus } from "types/Capture";

type Props = {
  onItemSelect: (val: FormItemStatus) => void;
  selectedStatus?: FormItemStatus;
};

const FormItemStatusDropdown = ({ children, selectedStatus, onItemSelect }: PropsWithChildren<Props>) => {
  const items = useMemo(
    () =>
      (Object.keys(FORM_ITEM_STATUS_TO_META) as (keyof typeof FORM_ITEM_STATUS_TO_META)[]).map((key) => {
        const Icon = FORM_ITEM_STATUS_TO_META[key].icon;
        return {
          key,
          label: (
            <div className="flex items-center gap-2.5">
              <Icon className="h-3.5 w-3.5" style={{ color: FORM_ITEM_STATUS_TO_META[key].color }} />
              {FORM_ITEM_STATUS_TO_META[key].label}
            </div>
          ),
          onSelect: () => onItemSelect(key),
          selected: key === (selectedStatus || FormItemStatus.NotStarted),
        };
      }),
    [onItemSelect, selectedStatus],
  );

  return <DropdownMenu items={items}>{children}</DropdownMenu>;
};

export default FormItemStatusDropdown;
