import axios from "axios";
import { useCreateAndAssignRequirement } from "components/copilot/Framework/hooks";
import { useNotification } from "context/notificationContext";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { appendDraftSections, updateDraftExtractionState } from "store/reducers/draft/DraftsExtractionReducer";
import { useAppDispatch } from "store/storeTypes";
import { TemplateSections } from "types/TemplateSection";
import { createComplianceMatrixRowRequirement } from "utils/complianceMatrix";
import { DEFAULT_TEMPLATE_SECTION_STATE, TemplateSectionState } from "./constants";
import { useFrameworkOperations } from "hook/useFrameworkOperations";
import { LiveObject } from "YJSProvider/LiveObjects";

type TemplateSectionVariables = {
  text: string;
};

export const useTemplateSection = (isOpen?: boolean) => {
  const { volumeId } = useParams();
  const { setToast } = useNotification();
  const dispatch = useAppDispatch();
  const { addNewSection } = useFrameworkOperations();
  const createAndAssignToSection = useCreateAndAssignRequirement();
  const [templateSectionState, setTemplateSectionState] =
    useState<TemplateSectionState>(DEFAULT_TEMPLATE_SECTION_STATE);

  useEffect(() => {
    if (isOpen) {
      setTemplateSectionState(DEFAULT_TEMPLATE_SECTION_STATE);
    }
  }, [isOpen]);

  const handleAssign = useCallback(
    (sections: TemplateSections["sections"], sheetId: string) => {
      if (!volumeId) return;

      sections.forEach((sec) => {
        addNewSection(volumeId, {
          id: sec.id,
          title: sec.title,
        });

        sec.requirements.forEach((req, idx) => {
          const createdRequirement = createComplianceMatrixRowRequirement({
            id: req.id,
            content: req.content,
            extraction_id: sheetId,
            section_order: idx,
          });
          createAndAssignToSection(sec.id, undefined, {
            requirement: createdRequirement,
            proposal_reference: new LiveObject({ volume_id: volumeId, section_id: sec.id }),
          });
        });

        sec.subsections?.forEach((subsection) => {
          addNewSection(volumeId, {
            id: subsection.id,
            title: subsection.title,
            parent_id: sec.id,
          });

          subsection.requirements.forEach((req, idx) => {
            const createdRequirement = createComplianceMatrixRowRequirement({
              id: req.id,
              content: req.content,
              extraction_id: sheetId,
              section_order: idx,
            });
            createAndAssignToSection(subsection.id, undefined, {
              requirement: createdRequirement,
              proposal_reference: new LiveObject({ volume_id: volumeId, section_id: subsection.id }),
            });
          });
        });
      });
    },
    [addNewSection, createAndAssignToSection, volumeId],
  );

  const extractTemplateSection = useCallback(
    async (variables: TemplateSectionVariables, sheetId: string) => {
      if (!volumeId) return;
      dispatch(
        updateDraftExtractionState({
          [volumeId]: { isLoading: true },
        }),
      );
      try {
        const { data } = await axios.post<TemplateSections>("/templates/section/extract", variables);

        setToast.success({
          msg: "New sections extracted.",
        });
        setTemplateSectionState(DEFAULT_TEMPLATE_SECTION_STATE);
        dispatch(
          appendDraftSections({
            [volumeId]: data.sections.reduce<string[]>(
              (acc, { id, subsections }) => [...acc, id, ...(subsections || []).map((subsec) => subsec.id)],
              [],
            ),
          }),
        );
        handleAssign(data.sections, sheetId);
      } catch {
        setToast.error({
          title: "Unable to extract sections.",
          msg: "We were unable to start the extraction due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        });
      } finally {
        dispatch(
          updateDraftExtractionState({
            [volumeId]: { isLoading: false },
          }),
        );
      }
    },
    [volumeId, dispatch, handleAssign, setToast],
  );

  const generateTemplateSection = useCallback(
    async (variables: TemplateSectionVariables, sheetId: string) => {
      if (!volumeId) return;
      dispatch(
        updateDraftExtractionState({
          [volumeId]: { isLoading: true },
        }),
      );
      try {
        const { data } = await axios.post<TemplateSections>("/templates/section/generate", variables);

        setToast.success({
          msg: "New sections generated.",
        });
        setTemplateSectionState(DEFAULT_TEMPLATE_SECTION_STATE);
        if (!volumeId) return;
        dispatch(
          appendDraftSections({
            [volumeId]: data.sections.reduce<string[]>(
              (acc, { id, subsections }) => [...acc, id, ...(subsections || []).map((subsec) => subsec.id)],
              [],
            ),
          }),
        );
        handleAssign(data.sections, sheetId);
      } catch {
        setToast.error({
          title: "Unable to generate sections.",
          msg: "We were unable to start the generation due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        });
      } finally {
        dispatch(
          updateDraftExtractionState({
            [volumeId]: { isLoading: false },
          }),
        );
      }
    },
    [volumeId, dispatch, handleAssign, setToast],
  );

  return { templateSectionState, setTemplateSectionState, generateTemplateSection, extractTemplateSection };
};
