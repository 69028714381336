import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import SectionRow from "./SectionRow";

type Props = {
  section: FormattedSection;
};

const SectionBatch = ({ section }: Props) => {
  const { subsections } = section;

  return (
    <div className="flex flex-col">
      <SectionRow section={section} />
      <div className="flex flex-col">
        {subsections?.map((subsection) => <SectionRow key={subsection.id} section={subsection} />)}
      </div>
    </div>
  );
};

export default SectionBatch;
