/** @jsxImportSource @emotion/react */

import { Avatar } from "components/molecules/avatar";
import { useAppSelector } from "store/storeTypes";
import IconButton from "components/atoms/icon-button";
import { Trash2 } from "lucide-react";
import copyText from "utils/copyText";
import { useNotification } from "context/notificationContext";
import { CoreBlock as LiveCoreBlock } from "utils/yjs-configs/ai-assistant/schema";
import useAIAssistantOperations from "hook/useAIAssistantOperations";
import LiveDate from "components/molecules/live-date";
import Icon from "components/atoms/icons/Icon";
import Popover from "components/atoms/popover";
import tw from "twin.macro";
import { getFileIcon } from "utils/getFileIcon";
import { ToImmutable } from "YJSProvider/LiveObjects";

const CoreBlock = ({ block }: { block: ToImmutable<LiveCoreBlock> }) => {
  const { setToast } = useNotification();
  const { currentUser } = useAppSelector((root) => root.auth);
  const activeSession = useAppSelector((root) => root.aiAssistantState.activeSession);
  const { deleteMessageBlock } = useAIAssistantOperations();

  return (
    <div className="flex flex-row gap-2.5 w-full">
      <Avatar size={26} id={currentUser?.username} name={currentUser?.username} className="text-base" />
      <div className="flex flex-col gap-3 pt-1 flex-1 min-w-0">
        <div className="text-gray-darkest text-sm whitespace-pre-wrap break-words">{block.body}</div>
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row gap-2 items-center text-slate-500">
            <IconButton
              name="Copy"
              className="w-4 h-4 duration-150 hover:text-black"
              onClick={() =>
                copyText(block.body, () =>
                  setToast.success({
                    msg: "Copied",
                  }),
                )
              }
            />
            <div className="h-4 w-px bg-slate-400" />
            <button
              onClick={() => activeSession?.id && deleteMessageBlock(activeSession?.id, block.id)}
              className="duration-150 hover:text-black"
            >
              <Trash2 size={14} className="stroke-[1.6]" />
            </button>
            {!!block.sources?.length && (
              <>
                <div className="h-4 w-px bg-slate-400" />
                <Popover
                  contentProps={{ css: tw`max-w-[600px] mx-0`, align: "start" }}
                  content={
                    <div className="flex flex-col gap-1 max-h-80 overflow-y-auto">
                      {block.sources.map((source, i) => (
                        <div
                          className="px-2 py-1.5 w-full flex items-center gap-1.5"
                          css={[i !== (block.sources?.length || 0) - 1 && tw`border-b border-gray-light`]}
                        >
                          <img className="w-4" src={getFileIcon(source.extension_type)} alt={source.name} />
                          <div title={source.name} className="truncate text-xs">
                            {source.name}
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                >
                  <div className="cursor-pointer group flex flex-row gap-1 items-center duration-150 hover:text-black">
                    <Icon name="Paperclip" className="w-4 h-4" />
                    <div
                      className="flex items-center justify-center backdrop-blur-sm bg-slate-500 text-white text-xxs rounded-full duration-150 group-hover:bg-black"
                      css={[
                        String(block.sources?.length || 0).length > 2
                          ? tw`w-4 h-4 text-[8px]`
                          : tw`w-3.5 h-3.5 text-xxs`,
                      ]}
                    >
                      {block.sources.length}
                    </div>
                  </div>
                </Popover>
              </>
            )}
          </div>
          <LiveDate date={block.updated_at} duration={30000} />
        </div>
      </div>
    </div>
  );
};

export default CoreBlock;
