import { useEffect, useState } from "react";
import axios from "axios";

export const useSearch = (url, firstReload, dataKey, infiniteScroll, setHasMore, offset) => {
  const [result, setResult] = useState([]);
  const [loadingResult, setLoadingResult] = useState(true);

  useEffect(() => {
    if (firstReload) return;
    if (!infiniteScroll || !offset) {
      setResult([]);
    }
    setLoadingResult(true);
    let cancel;
    axios({
      method: "GET",
      url: url,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        const data = dataKey ? res.data[dataKey] : res.data;
        if (infiniteScroll) {
          setResult((prev) => {
            return [...new Set([...prev, ...data])];
          });
          if (setHasMore) setHasMore(data?.length > 49);
        } else {
          setResult([...new Set([...data])]);
        }
        setLoadingResult(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;
        console.log("Error while searching data", err);
      });
    return () => cancel();
  }, [url]);

  return { result, loadingResult, setResult };
};
