/** @jsxImportSource @emotion/react */
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { Button } from "components/editor/components";
import Icon from "components/atoms/icons/Icon";
import { useMemo } from "react";
import { useAppDispatch } from "store/storeTypes";
import { toggleTemplatesModal } from "store/reducers/modalsSlice";
import { Volume } from "../CopilotSchemaImmutableTypes";
import { Storage } from "../CopilotSchemaTypes";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";
import { useMutation } from "YJSProvider/createYJSContext";
import { LiveList, LiveObject, update } from "YJSProvider/LiveObjects";

const TemplateSelector = () => {
  const dispatch = useAppDispatch();

  const clearTemplate = useMutation(({ storage }) => {
    const matrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
    const framework = storage.get("framework") as Storage["framework"] | undefined;

    if (framework) {
      update(framework, { volumes: new LiveList([] as LiveObject<Volume>[]), template: new LiveObject({}) });
    }

    matrix?.forEach((row: any) => {
      row.set(
        "proposal_reference",
        new LiveObject({
          volume_id: "",
          section_id: "",
          paragraph: "",
        }),
      );
    });
  }, []);

  const menuItems = useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex items-center gap-2">
            <Icon name="TaskSquare" className="w-4 h-4" />
            Import Template
          </div>
        ),
        onSelect: () => dispatch(toggleTemplatesModal({ open: true })),
      },

      {
        key: 2,
        label: (
          <div className="flex items-center gap-2">
            <Icon name="EraserSquare" className="w-4 h-4" />
            Clear Template
          </div>
        ),
        onSelect: () => {
          triggerConfirm({
            proceedLabel: "Clear",
            header: "Are you sure you want to clear this template?",
            body: "This is an irreversible operation and will remove all progress associated with the draft.",
          }).then((proceed) => {
            if (proceed) {
              clearTemplate();
            }
          });
        },
      },
    ],
    [clearTemplate, dispatch],
  );

  return (
    <DropdownMenu items={menuItems}>
      <Button variant="outline" size="md" className="!pr-3 gap-2 bg-white">
        Templates
        <Icon name="CarrotDown" />
      </Button>
    </DropdownMenu>
  );
};

export default TemplateSelector;
