import { useState } from "react";
import CustomModal from "../CustomModal";
import cross from "../../Assets/crosss.svg";
import cloudUpload from "Assets/cloud-upload.svg";
import pdf from "../../Assets/pdf.svg";
import union from "../../Assets/addlink.svg";
import trash from "../../Assets/trash.svg";
import { Spinner } from "../../utils/icons";
import docs from "../../Assets/doc.svg";
import { useDropzone } from "react-dropzone";
import { formatBytes, pluralizeWord } from "utils/helpers";
import axios from "axios";
import { useLocalStorage } from "hook/useLocalStorage";
import { useNotification } from "context/notificationContext";

// Max size 200 MB
const maxSize = 200000000;

// ------------------- Upload new Doc modal handler --------------------------------
const UploadDocModal = ({ showUploadModal, setShowUploadModal, setForceRefresh }) => {
  const [uploading, setUploadingLoading] = useState(false);
  const { setToast } = useNotification();
  const [files, setFiles] = useState([]);
  const { localValue } = useLocalStorage("vultron_user_token", "");
  const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
  const { localValue: use_auth0 } = useLocalStorage("vultron_user_use_auth0", "");
  const useAuth0Header = use_auth0 === true;

  // close modal
  const closeModal = () => {
    if (uploading) return;
    setShowUploadModal(false);
    setFiles([]);
  };

  // on file drop/select
  const onDrop = (acceptedFiles) => {
    if (uploading) return;
    if (acceptedFiles && acceptedFiles?.length > 0) {
      const newFiles = [];
      acceptedFiles.forEach((file) => {
        if (files.find((b) => b.name === file.name)) {
          return;
        }
        newFiles.push(file);
      });
      const prevFiles = [...newFiles, ...files];
      setFiles(prevFiles);
    }
  };

  // validation
  const dropValidator = (file) => {
    if (file?.size > maxSize) {
      setToast.error({
        title: "Document size not supported",
        msg:
          file?.name +
          `size is too large. We do not support document sizes larger than ${formatBytes(
            maxSize,
          )}. Please try again with a smaller document or contact support@vultron.ai for assistance.`,
      });
      return {
        code: "file-too-large",
        message: `Document is larger than ${formatBytes(maxSize)}`,
      };
    }

    return null;
  };

  // drop files hook
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: maxSize,
    validator: dropValidator,
    accept: {
      "text/plain": [".txt"],
      "text/pdf": [".pdf"],
      "text/doc": [".docx"],
    },
    noClick: false,
    maxFiles: 50,
    multiple: true,
  });

  // remove file
  const removeFile = (file) => {
    let prevFiles = [...files];
    prevFiles = prevFiles.filter((f) => f !== file);
    setFiles(prevFiles);
  };

  // render img icon based on type
  const imgIcon = (type) => {
    let img = pdf;
    switch (type) {
      case "application/pdf":
        img = pdf;
        break;
      case "application/doc":
      case "application/docx":
        img = docs;
        break;
      default:
        img = docs;
    }
    return img;
  };

  // On save
  const onSaveBtn = () => {
    if (uploading) return;
    const responses = [];

    const windUp = () => {
      if (responses?.length === files?.length) {
        setShowUploadModal(false);
        setFiles([]);
        setToast.success({
          msg: `Successfully uploaded ${files.length} ${pluralizeWord("document", "documents", files.length)}`,
        });
        setUploadingLoading(false);
      }
    };

    // map to all files and upload one by one
    const uploadFilesGradually = async () => {
      setUploadingLoading(true);
      Promise.all(
        files.map(async (it, i) => {
          const formData = new FormData();
          formData.append("file", it);
          axios({
            method: "POST",
            url: "/corpus/document",
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localValue}`,
              Workspace: `Workspace ${workspace_id}`,
              "X-Authorization-Auth0": JSON.stringify(useAuth0Header),
            },
          })
            .then(() => {
              responses.push(i);
              windUp();
            })
            .catch((err) => {
              responses.push(i);
              setToast.error({
                title: "Unable to upload documents",
                msg: `We were unable to upload the documents due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.`,
              });
              windUp();
              console.log("Error while uploading files from proposal assistant main tab, file num ", i, it.name, err);
            });
        }),
      ).finally(() => setForceRefresh(true));
    };

    uploadFilesGradually();
  };

  //
  return (
    <>
      <CustomModal isOpen={showUploadModal} onClose={closeModal} className="w-full max-w-[596px]">
        <div>
          <div className="px-6 pt-4 flex justify-between">
            <h3 className="font-semibold text-lg">Upload Relevant Documents</h3>
            <img src={cross} alt="" className="cursor-pointer" onClick={closeModal} />
          </div>
          <div className="py-1 px-6">
            <div
              {...getRootProps()}
              className="p-3 w-full mx-auto cursor-pointer relative border-gray-300 border-dashed border-2 m-3 justify-center flex flex-col items-center rounded-lg bg-[#fafafa] min-h-[220px]"
            >
              <input multiple {...getInputProps()} />
              {isDragActive ? (
                <div className="absolute bg-gray-300 flex justify-center items-center text-gray-700 top-0 left-0 h-full w-full z-[2] bg-dark1 bg-opacity-75">
                  {" "}
                  Drop your file here...
                </div>
              ) : null}
              <img src={cloudUpload} alt="" />
              <span className="font-semibold">Drop or Select File</span>
              <span className="text-gray-text">Drops files here or browse through your machine</span>
            </div>
            {files?.map((fileItem, i) => (
              <div key={i} className="p-3 flex justify-between items-center w-full">
                <div className="flex gap-3 px-3 flex-grow max-w-[95%]">
                  <img src={imgIcon(fileItem?.type)} alt="" />
                  <div className="flex flex-col max-w-[93%]">
                    <span
                      style={{
                        wordWrap: "break-word",
                        textOverflow: "clip",
                      }}
                      className="block text-sm font-medium break-words overflow-hidden max-w-full"
                    >
                      {fileItem?.name}
                    </span>
                    <span className="text-xs">{formatBytes(fileItem?.size)}</span>
                  </div>
                </div>
                <button className="px-2 min-w-[33px] w-[33px] border-0 b-white" onClick={() => removeFile(fileItem)}>
                  <img src={trash} alt="" />
                </button>
              </div>
            ))}
            <div className="flex mt-4 justify-end items-center gap-4 my-2">
              <button className="border-gray-300 border px-4 py-2 rounded-lg" onClick={closeModal}>
                Cancel
              </button>
              <button
                className="bg-action disabled:opacity-50 py-2 px-4 flex gap-1 items-center text-white rounded-lg min-w-[117px]"
                disabled={files?.length > 0 ? false : true}
                onClick={onSaveBtn}
              >
                {uploading ? (
                  <>
                    <Spinner width={17} height={17} /> Importing files
                  </>
                ) : (
                  <>
                    <img className="mr-1" src={union} alt="" /> Add Documents
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default UploadDocModal;
