/** @jsxImportSource @emotion/react */

import cloudUpload from "Assets/cloud-upload.svg";
import { useDropzone } from "react-dropzone";
import { formatBytes } from "utils/helpers";
import { MAX_ATTACHMENT_SIZE } from "const-values/Project";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useEffect } from "react";
import { useUploadDocuments } from "./hooks";
import { setExtractState, setSelectedDocuments } from "store/reducers/extract/ExtractReducerV2";
import { FileMimeType, getFileIcon } from "utils/getFileIcon";
import useDocumentPreview from "hook/useDocumentPreview";
import { CircleCheck, Download } from "lucide-react";
import { useFlags } from "hook/useFlags";

const UploadDocuments = () => {
  const { selectedDocuments } = useAppSelector((state) => state.extractReducerV2);

  const { activeProject } = useAppSelector((state) => state.project);
  const dispatch = useAppDispatch();
  const govDocs = activeProject?.government_source || [];
  const internalDocs = activeProject?.internal_documents || [];
  const { onDrop, dropValidator, files: userUploadedFiles } = useUploadDocuments();
  const files = [...userUploadedFiles, ...internalDocs, ...govDocs];
  const { downloadFile } = useDocumentPreview();
  const flags = useFlags();

  const allowedFileTypes = flags.enableOnsiteFeatures ? ["pdf"] : ["pdf", "docx", "xlsx", "pptx"];
  const onsiteAccept = { "text/pdf": [".pdf"] };
  const managedAccept = {
    "text/pdf": [".pdf"],
    "text/doc": [".docx"],
    "text/excel": [".xlsx"],
    "text/powerpoint": [".pptx"],
  };
  const dropDescription = flags.enableOnsiteFeatures ? "We accept pdf files" : "We accept pdf and docx files";

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: MAX_ATTACHMENT_SIZE,
    validator: dropValidator,
    accept: flags.enableOnsiteFeatures ? onsiteAccept : managedAccept,
    noClick: false,
    maxFiles: 5,
    multiple: true,
  });

  useEffect(() => {
    return () => {
      dispatch(setExtractState({ selectedDocuments: [] }));
    };
  }, [dispatch]);

  return (
    <div className="flex flex-col h-full">
      <div
        {...getRootProps()}
        className="flex-shrink-0 p-3 w-full mx-auto cursor-pointer relative border-gray-300 border-dashed border-2 mb-2 justify-center flex flex-col items-center rounded-lg bg-[#fafafa]"
      >
        <input multiple {...getInputProps()} />
        {isDragActive && (
          <div className="absolute bg-gray-light flex justify-center items-center text-gray-700 top-0 left-0 h-full w-full z-[2] bg-dark1 bg-opacity-75">
            Upload Additional Documents
          </div>
        )}
        <img src={cloudUpload} alt="" className="h-9 w-9 flex-shrink" />
        <span className="font-semibold text-base">Upload Additional Documents</span>
        <span className="text-gray-text text-sm">{dropDescription}</span>
      </div>
      {!files?.length && (
        <div className="flex text-sm text-slate-600 justify-center items-center  gap-1 flex-grow flex-shrink p-2 w-full overflow-y-auto border border-gray-light bg-gray-lightest rounded-lg">
          No documents available. Please upload documents.
        </div>
      )}
      {!!files?.length && (
        <div
          className="flex flex-col gap-1 flex-grow flex-shrink p-2 w-full overflow-y-auto min-h-[112px] max-h-[320px] border border-gray-light bg-gray-lightest rounded-lg"
          css={[!!files?.length && tw`min-h-0`]}
        >
          {!!files?.length &&
            files?.map(
              (file, i) =>
                allowedFileTypes.includes(
                  "file_type" in file ? file.file_type : FileMimeType[file.type] || file.type,
                ) && (
                  <button
                    key={i}
                    className="p-3 flex justify-between items-center w-full border border-gray-lightest bg-white shadow-sm rounded-lg duration-150 hover:border-action disabled:opacity-40 disabled:pointer-events-none"
                    onClick={() => {
                      dispatch(setSelectedDocuments(["id" in file ? file?.id : file]));
                    }}
                    css={[
                      ("id" in file ? selectedDocuments.includes(file.id) : selectedDocuments.includes(file)) &&
                        tw`border-action bg-action-lightest`,
                    ]}
                  >
                    <div className="group flex items-center gap-3 px-3 flex-grow max-w-[95%]">
                      <img
                        className="max-w-[25px]"
                        src={getFileIcon("file_type" in file ? file.file_type : file.type)}
                        alt=""
                      />
                      <div className="flex flex-col items-start max-w-[93%]">
                        <div className="flex items-center gap-2 w-full">
                          <span className="block text-sm font-medium break-words overflow-hidden max-w-full truncate">
                            {"file_name" in file ? file.file_name : file.name}
                          </span>
                          <span className="text-xs text-gray-500">
                            {"file_size_formatted" in file
                              ? `(${file.file_size_formatted})`
                              : `(${formatBytes(file.size)})`}
                          </span>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              if ("download_url" in file) downloadFile(file.download_url);
                            }}
                            className="opacity-0 group-hover:opacity-100 pb-0.5 text-action duration-150 hover:text-action-hover"
                          >
                            <Download size={16} />
                          </button>
                        </div>
                      </div>
                    </div>
                    {("id" in file ? selectedDocuments.includes(file.id) : selectedDocuments.includes(file)) && (
                      <div className="w-8 flex justify-center items-center">
                        <CircleCheck size={30} className="text-action" />
                      </div>
                    )}
                  </button>
                ),
            )}
        </div>
      )}
    </div>
  );
};

export default UploadDocuments;
