import {
  AssistantBlockType,
  RequirementCompliance,
  RequirementStatus,
  UserInstruction,
  WritingPrompt,
} from "components/copilot/CopilotSchemaImmutableTypes";
import { ComplianceMatrixRow, Requirement, WritingAssistantBlock } from "components/copilot/CopilotSchemaTypes";
import { nanoid } from "nanoid";
import { v4 } from "uuid";
import { LiveList, LiveObject } from "YJSProvider/LiveObjects";

export const createComplianceMatrixRow = (
  partialProperties?: Partial<ComplianceMatrixRow>,
): LiveObject<ComplianceMatrixRow> => {
  return new LiveObject({
    requirement: createComplianceMatrixRowRequirement(),
    proposal_reference: new LiveObject({
      volume_id: "",
      section_id: "",
    }),
    document: new LiveObject({
      id: "",
      name: "",
    }),
    compliance_status: RequirementCompliance.Empty,
    risk_status: "",
    requirement_file_id_filters: new LiveList([]),
    requirement_status: RequirementStatus.Todo,
    notes: "",
    written_content: "",
    written_html_content: "",
    selected_content_v2: new LiveList([]),
    generated_content_ideas_v2: new LiveList([]),
    assigned_user_ids: [],
    is_response_generating: false,
    is_response_in_queue: false,
    auto_response_actor: "",
    writing_prompts: new LiveList([]),
    user_instructions: new LiveList([]),
    response_sources: new LiveList([]),
    ...partialProperties,
  });
};
export const createComplianceMatrixRowRequirement = (
  partialProperties?: Partial<Requirement>,
): LiveObject<Requirement> => {
  return new LiveObject({
    id: v4(),
    content: "",
    section: "",
    header: "",
    disregarded: false,
    page_number: "",
    extraction_id: "",
    ...partialProperties,
  });
};

export const createWritingAssistantBlock = (
  type: AssistantBlockType,
  properties?: Partial<WritingAssistantBlock>,
): LiveObject<WritingAssistantBlock> => {
  return new LiveObject({
    type,
    id: nanoid(),
    updated_at: new Date().toISOString(),
    body: "",
    sources: new LiveList([]),
    ...properties,
  });
};

export const createWritingPrompt = (properties?: Partial<WritingPrompt>): LiveObject<WritingPrompt> =>
  new LiveObject({ id: nanoid(), content: "", file_id_filters: [], ...properties });

export const createUserInstruction = (content: string): LiveObject<UserInstruction> =>
  new LiveObject({ id: nanoid(), content: content });
