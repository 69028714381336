import { useStorage } from "YJSProvider/createYJSContext";
import { CommentThread, ThreadContext } from "../types";
import { useMemo } from "react";

// Each thread is related to an editor - depending on the context
// In the case of proposal docs - there is only one editor
// For drafts - the thread is related to a specific requirement in a specific section of the draft
// This hook maps threads to editors
export const useThreadEditorMapping = (
  threads: CommentThread[] = [],
  context: ThreadContext,
  internalContractId: string,
  referenceId?: string,
) => {
  const draftRequirements = useStorage((storage) => {
    if (context === ThreadContext.PROPOSAL) return [];
    // Get all requirements in the draft
    const volume = storage.framework.volumes.find((volume) => volume.id === referenceId);
    const volumeSectionIds = volume?.sections.map((section) => section.id) || [];

    // Get all of the requirements in the volumes sections
    const requirements = storage.compliance_matrix.filter(
      (row) =>
        row.proposal_reference.section_id &&
        volumeSectionIds.includes(row.proposal_reference.section_id) &&
        !row.requirement.skipped,
    );

    return requirements.map((req) => {
      return {
        threadIds: req.thread_ids,
        requirementId: req.requirement.id,
        sectionId: req.proposal_reference.section_id,
      };
    });
  });

  const threadEditorMapping = useMemo(() => {
    const mapping: Record<string, string> = {};

    if (context === ThreadContext.PROPOSAL) {
      return {};
    } else {
      draftRequirements.forEach((req) => {
        req.threadIds?.forEach((threadId) => {
          mapping[threadId] = `${req.sectionId}$${req.requirementId}`;
        });
      });
    }
    return mapping;
  }, [threads, context, internalContractId, referenceId]);

  return threadEditorMapping;
};
