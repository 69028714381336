import { useNotification } from "context/notificationContext";
import { useCallback } from "react";

export const useOperationsUtils = () => {
  const { setToast } = useNotification();

  const handleApiSuccess = useCallback(
    (message: string) => {
      setToast.success({ msg: message });
    },
    [setToast],
  );

  const handleApiError = useCallback(
    (_error: any, errorMessage: string) => {
      setToast.error({ msg: errorMessage });
    },
    [setToast],
  );
  const handleInvalidFileMsg = (errorMessage: string): void => {
    setToast.error({
      msg: errorMessage,
    });
  };

  return { handleApiSuccess, handleApiError, handleInvalidFileMsg };
};
