import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { applyPaste, expandSelectionToFullNodes } from "./utils";
import { MAX_LEVEL } from "../constants";

export const ClipboardHandler = Extension.create({
  name: "ClipboardHandler",

  addOptions() {
    return {
      maxLevel: MAX_LEVEL,
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("clipboardHandler"),
        props: {
          handleDOMEvents: {
            /**
             * Ensures full nodes are selected when cutting. Expands partial selections
             * to include entire nodes for clean cuts, without affecting regular text edits.
             */
            cut: (view, event) => {
              const [foundNodesToExpand, tr] = expandSelectionToFullNodes(view.state);

              if (foundNodesToExpand) {
                view.dispatch(tr);
                event.preventDefault(); // Prevent the default cut behavior from interfering
                document.execCommand("cut"); // Trigger the cut programmatically
                return true;
              }

              return false; // Let default cut behavior occur if no changes
            },
          },
          /**
           * Handles pasting by checking for existing xid attributes to distinguish between
           * content copied from the editor and external content. If no xid is found, external
           * text is parsed and inserted as structured nodes with required attributes.
           */
          handlePaste: (view, event, slice) => {
            const { clipboardData } = event;
            const text = clipboardData?.getData("text/plain");

            return applyPaste(view, this.options.maxLevel, text, event, slice);
          },
        },
      }),
    ];
  },
});
