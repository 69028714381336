import { useCallback } from "react";
import { useNotification } from "context/notificationContext";

export function useHandleApiError() {
  const { setToast } = useNotification();
  const setError = useCallback(
    (errorMessage: string) =>
      setToast.error({
        title: "Unable to process request",
        msg: errorMessage,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { setError };
}
