/** @jsxImportSource @emotion/react */
import driveWhite from "Assets/drive-white.svg";
import folderOpenSm from "Assets/folder-open-white.svg";
import lod from "Assets/loader.svg";
import searchIcon from "Assets/search-normal.png";
import cloud_upload from "Assets/cloud-upload.svg";
import ExtractingDocumentToast from "components/LoadingToast";
import NotFoundItems from "components/NotFoundItems";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import {
  setIsDragOverDrive,
  setMediaNavHistory,
  setSelectedFolderHeading,
  setDraggingDocIds,
  setDraggingFolderId,
} from "store/reducers/driveReducerSlice";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { Subdirectory, WorkspaceMedia } from "types/FileStorage";
import { ACCEPT_MEDIA_TYPES } from "../constants";
import AddMediaFileModal from "../modals/AddMediaFileModal";
import AddMediaFolderModal from "../modals/AddMediaFolderModal";
import MoveModal from "../modals/MoveModal";
import PreviewMediaModal from "../modals/PreviewMediaModal";
import UpdateMediaFolderModal from "../modals/UpdateMediaFolderModal";
import Folder from "./Folder";
import Media from "./Media";
import { useDropValidator, useMediaSearch } from "./hooks";
import { useNotification } from "context/notificationContext";
import { useParams } from "react-router-dom";
import { Plus } from "lucide-react";
import { SelectedDocumentsToolbar } from "../documents/SelectedDocumentsToolbar";
import { ContentLibraryPanel } from "../sidebar/ContentLibraryPanel";
import { useMediaDirectory, useMediaOperations } from "./useMediaOperations";
import { pluralizeWord } from "utils/string";
import { useDragSelect } from "components/Drive/useDragSelect";

const MediaContent = () => {
  const { isSearching, isSearchActive, searchResults, onKeywordChange, keyword, setDirectoryId } = useMediaSearch();
  const [isFolderAddModalOpen, setIsFolderAddModalOpen] = useState(false);
  const [isFileAddModalOpen, setIsFileAddModalOpen] = useState(false);
  const [loadingToast, setLoadingToast] = useState({ loading: false, files: 1 });
  const dispatch = useAppDispatch();
  const { selectedFolderHeading, isDragOverDrive, draggingDocIds, draggingFolderId } = useAppSelector(
    (root) => root.drive.media,
  );
  const [isMoveMediaFolderModalOpen, setIsMoveMediaFolderModalOpen] = useState(false);
  const [isUpdateMediaFolderModalOpen, setIsUpdateMediaFolderModalOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<Subdirectory | null>(null);
  const [isMoveMediaFileModalOpen, setIsMoveMediaFileModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<WorkspaceMedia | null>(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [previewMedia, setPreviewMedia] = useState<WorkspaceMedia>({} as WorkspaceMedia);
  const [moveMediaItem, setMoveMediaItem] = useState<WorkspaceMedia | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [selectedMediaIds, setSelectedMediaIds] = useState<string[]>([]);
  const { setToast } = useNotification();
  const { folderId } = useParams();
  const dropValidator = useDropValidator();

  const { data: directoryData, isLoading: isDirectoryLoading } = useMediaDirectory(folderId);
  const { createFile, deleteFiles, isCreatingFile, updateFile } = useMediaOperations(folderId);

  // Extract current files and folders from directory data
  const currentFiles = useMemo(
    () => (folderId ? directoryData?.files || [] : directoryData?.files || []),
    [folderId, directoryData],
  );
  const currentSubdirectories = useMemo(
    () => (folderId ? directoryData?.subdirectories || [] : directoryData?.subdirectories || []),
    [folderId, directoryData],
  );
  const currentNestedDriveMediaState = directoryData;

  useEffect(() => {
    setDirectoryId(folderId || "");
    setSelectedMediaIds([]);
  }, [folderId, setDirectoryId]);

  const handleMediaSelect = useCallback((id: string, isSelected: boolean) => {
    setSelectedMediaIds((prev) => (isSelected ? [...prev, id] : prev.filter((mediaId) => mediaId !== id)));
  }, []);

  useDragSelect({
    onSelect: handleMediaSelect,
    selectedDocuments: selectedMediaIds,
    containerRef,
  });

  const handleMoveSelected = useCallback(() => {
    setIsMoveMediaFileModalOpen(true);
  }, []);

  const handleDeleteSelected = useCallback(() => {
    if (selectedMediaIds.length === 0) return;
    deleteFiles(selectedMediaIds);
    setSelectedMediaIds([]);
  }, [selectedMediaIds, deleteFiles]);

  const handleMediaClick = useCallback((media: WorkspaceMedia) => {
    setSelectedMedia(media);
    setIsPanelOpen(true);
  }, []);

  const handleClosePanel = useCallback(() => {
    setSelectedMedia(null);
    setIsPanelOpen(false);
  }, []);

  const { getRootProps: getMainRootProps, getInputProps: getMainInputProps } = useDropzone({
    validator: dropValidator,
    maxFiles: 10,
    onDropRejected: (fileRejections: FileRejection[]) => {
      for (const fileRejection of fileRejections) {
        for (const error of fileRejection.errors) {
          if (error.code === ErrorCode.TooManyFiles) {
            setToast.error({
              title: "Upload too many images",
              msg: "You can only upload 10 images at a time",
            });
          }
        }
      }
    },
    onDrop: async (acceptedFiles: File[]) => {
      if (isFileAddModalOpen) return;

      dispatch(setSelectedFolderHeading(""));
      dispatch(setIsDragOverDrive(false));
      dispatch(setDraggingFolderId(""));
      dispatch(setDraggingDocIds([]));

      if (acceptedFiles.length) {
        try {
          await Promise.all(
            acceptedFiles.map((file) =>
              createFile({
                file,
                user_tags: [],
                parent_directory_id: currentNestedDriveMediaState?.current_directory?.id,
              }),
            ),
          );
        } catch (error) {
          console.error("Upload error:", error);
        }
      }
    },

    onDragOver: (e) => {
      e.preventDefault();
      if (isFileAddModalOpen) return;
      if (currentNestedDriveMediaState?.current_directory) {
        dispatch(setSelectedFolderHeading(currentNestedDriveMediaState?.current_directory?.id));
      } else {
        dispatch(setIsDragOverDrive(true));
        dispatch(setDraggingFolderId(""));
        dispatch(setDraggingDocIds([]));
      }
    },

    onDragLeave: (e) => {
      const relatedTarget = e.relatedTarget as any;
      if (!relatedTarget || (relatedTarget !== e.currentTarget && !e.currentTarget.contains(relatedTarget))) {
        dispatch(setIsDragOverDrive(false));
        dispatch(setSelectedFolderHeading(""));
        dispatch(setDraggingFolderId(""));
        dispatch(setDraggingDocIds([]));
      }
    },
    accept: ACCEPT_MEDIA_TYPES,
    noClick: true,
  });

  const [isInitialFetch, setIsInitialFetch] = useState(false);
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (isDirectoryLoading && firstUpdate.current) {
      setIsInitialFetch(true);
      firstUpdate.current = false;
    } else setIsInitialFetch(false);
  }, [isDirectoryLoading]);

  const filesToDisplay = isSearchActive ? searchResults : currentFiles;

  return (
    <div className={`relative flex-grow bg-white ${isPanelOpen ? "mr-[400px]" : ""}`} {...getMainRootProps()}>
      <input multiple {...getMainInputProps()} />
      {/* --------- DOTTED LINE APPEARS ON DRAG AND DROP ----------- */}
      <div
        className="absolute w-full h-full pointer-events-none z-50"
        css={[
          (isDragOverDrive || selectedFolderHeading) &&
            !isFileAddModalOpen &&
            currentSubdirectories.find((f: Subdirectory) => f.id === draggingFolderId)?.id !== selectedFolderHeading &&
            tw`border-[3px] border-dashed border-[#1E1E1E]`,
        ]}
      />

      <div className="relative" ref={containerRef}>
        {/* ---------------------------------- SECTION SEARCH -------------------------------- */}
        <section className="pt-2 pb-6 px-1">
          <div className="flex-1 relative">
            <img src={searchIcon} alt="search icon" loading="lazy" className="absolute top-2.5 left-1.5 w-5" />
            <input
              placeholder="Search keywords..."
              className="bg-white focus:outline-none block focus:ring-0 focus:ring-gray-200 text-sm text-gray-500 w-full h-10 py-3 pl-9 pr-3 rounded-md border border-gray-light"
              value={keyword}
              onChange={(e) => onKeywordChange(e.target.value)}
            />
          </div>
        </section>

        {/* ---------------------------- FOLDERS SECTION ------------------------------ */}
        {!isSearchActive && (
          <section className="folders-section min-h-[150px] pt-1 px-1">
            <div className="flex justify-between items-center mb-6">
              <h5 className="font-medium text-md">Folders</h5>
              <div
                className="flex items-center gap-1 cursor-pointer hover:bg-hover-default px-1.5 py-1 rounded-md bg-gray-100 hover:border-gray-300 border border-gray-200"
                onClick={() => {
                  setIsFolderAddModalOpen(true);
                }}
              >
                <span className="text-sm font-semibold">Folder</span>
                <button onClick={() => {}}>
                  <Plus size={14} />
                </button>
              </div>
            </div>

            {isInitialFetch && !currentSubdirectories.length ? (
              <div className="w-full flex justify-center flex-col py-4 px-2">
                <img className="mx-auto max-w-full" src={lod} alt="Loading..." />
                <div className="flex-grow" />
              </div>
            ) : currentSubdirectories.length ? (
              <div className="cards grid grid-cols-[repeat(auto-fill,_minmax(230px,_1fr))] gap-5 mb-8">
                {currentSubdirectories.map((folder: Subdirectory) => (
                  <Folder
                    key={folder.id}
                    folder={folder}
                    setSelectFolder={setSelectedFolder}
                    onClick={() => {
                      dispatch(
                        setMediaNavHistory([
                          {
                            name: folder.name,
                            id: folder.id,
                          },
                        ]),
                      );
                    }}
                    setIsMoveMediaFolderModalOpen={setIsMoveMediaFolderModalOpen}
                    setIsUpdateMediaFolderModalOpen={setIsUpdateMediaFolderModalOpen}
                    setSelectedMediaIds={setSelectedMediaIds}
                  />
                ))}
              </div>
            ) : (
              <div className="pb-10">
                <h5 className="text-base text-center font-semibold text-[#1D2630]">No Folders</h5>
                <p className="text-[#353535] text-center pt-[5px]">
                  Create a folder by clicking on the “Folder +” button.
                </p>
              </div>
            )}

            <AddMediaFolderModal
              open={isFolderAddModalOpen}
              onOpenChange={setIsFolderAddModalOpen}
              currentDirectory={currentNestedDriveMediaState?.current_directory}
            />

            {selectedFolder && (
              <UpdateMediaFolderModal
                open={isUpdateMediaFolderModalOpen}
                onOpenChange={setIsUpdateMediaFolderModalOpen}
                folder={selectedFolder}
              />
            )}

            {selectedFolder && (
              <MoveModal
                open={isMoveMediaFolderModalOpen}
                onOpenChange={setIsMoveMediaFolderModalOpen}
                moveType={"directory"}
                currentDirectory={currentNestedDriveMediaState?.current_directory}
                selectedItems={selectedFolder}
                setSelectedFolder={setSelectedFolder}
              />
            )}
          </section>
        )}

        {/* -------------------------- SECTION MEDIA -------------------------------- */}
        <section className="document-section px-1 relative mb-9">
          <div className="flex items-center justify-between mb-3">
            <div className="flex flex-col gap-3">
              <h5 className="font-medium text-md mb-4">Graphics</h5>
              {/* {!!filesToDisplay.length && !isSearching && (
                <div className="flex items-center">
                  <Checkbox
                    variant="primaryBlack"
                    checked={filesToDisplay.length > 0 && selectedMediaIds.length === filesToDisplay.length}
                    onCheck={(checked) =>
                      setSelectedMediaIds(checked ? filesToDisplay.map((file: WorkspaceMedia) => file.id) : [])
                    }
                  />
                  <span className="ml-2 text-sm text-gray-mid">Select All</span>
                </div>
              )} */}
            </div>
            {!isSearchActive && (
              <div
                onClick={() => setIsFileAddModalOpen(true)}
                className="flex items-center gap-1 rounded-md cursor-pointer px-1.5 py-1 bg-gray-100 hover:border-gray-300 border border-gray-200"
              >
                <span className="text-sm font-semibold">Graphic</span>
                <span className="relative block cursor-pointer">
                  <Plus size={14} />
                </span>
              </div>
            )}
          </div>
          {isInitialFetch || isSearching ? (
            <div className="w-full flex justify-center flex-col py-14 px-2">
              <img className="mx-auto max-w-full" src={lod} alt="Loading..." />
              <div className="flex-grow" />
            </div>
          ) : (
            <>
              {!!filesToDisplay.length && (
                <div className="cards grid grid-cols-[repeat(auto-fill,_minmax(230px,_1fr))] gap-5">
                  {filesToDisplay.map((file: WorkspaceMedia) => (
                    <Media
                      key={file.id}
                      media={file}
                      setSelectedMedia={setSelectedMedia}
                      setIsMoveMediaFileModalOpen={setIsMoveMediaFileModalOpen}
                      setIsPreviweModalOpen={setIsPreviewModalOpen}
                      onSelect={handleMediaSelect}
                      isSelected={selectedMediaIds.includes(file.id)}
                      selectedMediaIds={selectedMediaIds}
                      onMediaClick={handleMediaClick}
                      isMediaPanelOpen={selectedMedia?.id === file.id}
                      setPreviewMedia={setPreviewMedia}
                      setMoveMediaItem={setMoveMediaItem}
                    />
                  ))}
                </div>
              )}
              {selectedMedia && (
                <ContentLibraryPanel
                  doc={selectedMedia}
                  isOpen={isPanelOpen}
                  onClose={handleClosePanel}
                  onUpdate={updateFile}
                  isMediaFile={true}
                />
              )}
              {isSearchActive && !filesToDisplay.length && (
                <NotFoundItems
                  title="No graphics found"
                  subTitle="No matching results. Try another search."
                  className={"w-full flex mt-24 justify-center items-center bg-inherit"}
                />
              )}
              {!isSearchActive && !filesToDisplay.length && (
                <>
                  <div
                    onClick={() => setIsFileAddModalOpen(true)}
                    className="p-3 w-full mx-auto cursor-pointer relative border-gray-300 border-dashed border-2 m-3 justify-center flex flex-col items-center rounded-lg bg-white min-h-[260px]"
                  >
                    <img className="w-9 h-9 mb-2" src={cloud_upload} alt="" />
                    <span className="font-semibold pointer-events-none">Drag and drop files here</span>
                    <span className="text-gray-text pointer-events-none pt-[4px]">We accept jpeg and png files</span>
                  </div>
                  <div className="mb-5" />
                </>
              )}
            </>
          )}

          <AddMediaFileModal
            currentNestedDriveMediaState={currentNestedDriveMediaState}
            open={isFileAddModalOpen}
            onOpenChange={setIsFileAddModalOpen}
          />
          {isMoveMediaFileModalOpen && (
            <MoveModal
              open={isMoveMediaFileModalOpen}
              onOpenChange={(open) => {
                setIsMoveMediaFileModalOpen(open);
                if (!open) {
                  setMoveMediaItem(null);
                }
              }}
              moveType="file"
              currentDirectory={currentNestedDriveMediaState?.current_directory}
              selectedItems={moveMediaItem ? moveMediaItem : selectedMediaIds}
              setSelectedMediaIds={setSelectedMediaIds}
            />
          )}

          {previewMedia && (
            <PreviewMediaModal
              open={isPreviewModalOpen}
              onOpenChange={(open) => {
                setIsPreviewModalOpen(open);
                if (!open) {
                  setPreviewMedia({} as WorkspaceMedia);
                }
              }}
              selectedMedia={previewMedia}
            />
          )}
        </section>
      </div>

      {/* ------------- Drag and Drop Toast -------------------- */}
      {(isDragOverDrive || selectedFolderHeading) && !isFileAddModalOpen && (
        <div className="fixed bottom-24 w-full pointer-events-none z-50">
          {/* Don't show toast when trying to move folder to itself */}
          {!(
            draggingFolderId &&
            selectedFolderHeading &&
            currentSubdirectories.find((folder: Subdirectory) => folder.id === draggingFolderId)?.id ===
              selectedFolderHeading
          ) && (
            <div className="fixed left-1/2 transform -translate-x-1/2 bg-gray-darkest py-3.5 px-16 rounded-full text-center">
              <span className="text-lg opacity-90 text-[#ffffff] block mb-1.5">
                {(() => {
                  if (draggingFolderId) {
                    return "Move folder to";
                  } else if (draggingDocIds.length) {
                    return `Move ${pluralizeWord(draggingDocIds.length, "graphic")} to`;
                  } else {
                    return `Drop ${pluralizeWord(draggingDocIds.length, "graphic")} to upload them to`;
                  }
                })()}
              </span>

              <span className="text-lg font-semibold text-[#ffffff] flex justify-center items-center gap-2">
                <img
                  src={selectedFolderHeading ? folderOpenSm : driveWhite}
                  alt="icon"
                  loading="lazy"
                  className="w-[18px] h-[18px] bg-[#fffffff]"
                />
                {currentSubdirectories.find((folder: Subdirectory) => folder.id === selectedFolderHeading)?.name ||
                  currentNestedDriveMediaState?.current_directory?.name ||
                  "Content Library"}
              </span>
            </div>
          )}
        </div>
      )}

      {isCreatingFile && (
        <ExtractingDocumentToast
          loadingText={`Uploading ${loadingToast?.files > 1 ? "Graphics" : "Graphic"}`}
          handleClose={() => setLoadingToast({ loading: isCreatingFile, files: 1 })}
        />
      )}
      {selectedMediaIds.length > 0 && !selectedFolderHeading && (
        <SelectedDocumentsToolbar
          selectedCount={selectedMediaIds.length}
          onClearSelection={() => setSelectedMediaIds([])}
          onMove={handleMoveSelected}
          onDelete={handleDeleteSelected}
        />
      )}
    </div>
  );
};

export default MediaContent;
