/** @jsxImportSource @emotion/react */

import { ComponentProps, useEffect, useMemo, useState } from "react";
import { Volume, Section } from "components/copilot/CopilotSchemaTypes";
import { useParams } from "react-router-dom";
import tw, { theme } from "twin.macro";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import * as Accordion from "@radix-ui/react-accordion";
import { ChevronRight, Plus, X } from "lucide-react";
import Highlighter from "react-highlight-words";
import { Button } from "components/editor/components";
import { Checkbox } from "components/atoms/checkbox";
import CreateSectionAndAssign from "./CreateSectionAndAssign";
import { MenuItem } from "components/molecules/dropdown-menu/DropdownMenu";
import Chip from "components/atoms/chip";
import { ToImmutable } from "YJSProvider/LiveObjects";
import { Modal } from "../modal";
import * as Dialog from "@radix-ui/react-dialog";
import Tooltip from "components/atoms/tooltip";

export enum TypeToMove {
  Requirement = 1,
  Section,
}

interface Props extends Pick<ComponentProps<typeof Modal>, "open" | "onOpenChange" | "portalProps"> {
  volumes: ToImmutable<Volume>[];
  type: TypeToMove;
  onCreateOutline: (newSectionConfig: NewSectionConfig | null) => void;
  onMove: (selectedSectionOrVolume: ToImmutable<Section> | ToImmutable<Volume>) => void;
  disabledState?: Partial<{ subsections?: boolean; sections: boolean; volumes: boolean }>;
  canCreateDraft?: boolean;
}

export type NewSectionConfig = {
  assignToSection: boolean;
  existingDraft: boolean;
  section?: { title: string };
  draft?: { id?: string; title: string };
};

const MoveRequirementOrSectionModal = ({
  onCreateOutline,
  onMove,
  type,
  volumes,
  disabledState,
  canCreateDraft = true,
  ...props
}: Props) => {
  const [modalRef, setModalRef] = useState<HTMLDivElement | null>(null);
  const { sectionId } = useParams();
  const [query, setQuery] = useState("");
  const [values, setValues] = useState<string[]>([]);
  const [selectedSectionOrVolume, setSelectedSectionOrVolume] = useState<
    ToImmutable<Section> | ToImmutable<Volume> | null
  >(null);
  const [newSectionConfig, setNewSectionConfig] = useState<NewSectionConfig | null>(null);
  const isTypeSection = type === TypeToMove.Section;
  const isSectionsDisabled = isTypeSection && !!disabledState?.sections;

  useEffect(() => {
    if (props.open) {
      setQuery("");
      setSelectedSectionOrVolume(null);
      setNewSectionConfig(null);
    }
  }, [props.open]);

  const filteredVolumes = useMemo(
    () =>
      volumes.reduce<ToImmutable<Volume>[]>((acc, vol) => {
        if (vol.title.toLowerCase().includes(query.toLowerCase())) return [...acc, vol];

        const matchedSections = vol.sections.filter((sec) => sec.title.toLowerCase().includes(query.toLowerCase()));
        if (matchedSections.length) return [...acc, { ...vol, sections: matchedSections }];

        return acc;
      }, []),
    [query, volumes],
  );

  useEffect(() => {
    if (query) setValues(filteredVolumes.map(({ id }) => id));
    if (!query) setValues([]);
  }, [filteredVolumes, query]);

  const formattedVolumes = useMemo(() => {
    return filteredVolumes.map((vol) => ({
      ...vol,
      sections:
        vol?.sections?.reduce<FormattedSection[]>((acc, section) => {
          if (!section.parent_id) {
            const subsections = vol?.sections?.filter(
              ({ parent_id, title }) => !!title.trim() && parent_id === section.id,
            );

            if (!subsections.length && !section.title.trim()) return acc;
            return [...acc, { ...section, subsections }];
          } else if (!!query && section.parent_id) {
            const parentSectionExists = vol?.sections.find((sec) => sec.id === section.parent_id);
            if (!parentSectionExists) return [...acc, { ...section, subsections: [] }];
          }

          return acc;
        }, []) || [],
    }));
  }, [filteredVolumes, query]);

  const canCreate = isTypeSection
    ? !!newSectionConfig?.draft?.title?.trim()
    : (!!newSectionConfig?.section?.title?.trim() && !newSectionConfig?.draft) ||
      (!!newSectionConfig?.draft?.title?.trim() && !!newSectionConfig?.section?.title?.trim());

  const draftSelectionItems: MenuItem<string>[] = useMemo(
    () =>
      volumes.map((vol) => ({
        key: vol.id,
        label: vol.title,
        selected: newSectionConfig?.draft?.id === vol.id,
        onSelect: () =>
          setNewSectionConfig((prev) => ({ ...prev, draft: { id: vol.id, title: vol.title } }) as NewSectionConfig),
      })),
    [newSectionConfig?.draft?.id, volumes],
  );

  return (
    <Modal
      title="Move requirement or section"
      contentProps={{
        css: tw`p-0 overflow-hidden !w-[700px] !min-w-[auto] !h-[581px] !min-h-[auto] !max-h-[80vh] !max-w-[80vw]`,
      }}
      bodyProps={tw`m-0`}
      body={
        <div ref={setModalRef} className="relative flex flex-col gap-2.5 pt-4 flex-1 h-full">
          <div className="mx-4 flex flex-row items-center gap-4 justify-between">
            <div className="flex flex-row items-center gap-1 min-w-0">
              <button
                onClick={() => setNewSectionConfig(null)}
                className="text-xs truncate cursor-default rounded bg-slate-200 text-slate-600 font-medium w-fit px-2 py-1"
                css={[newSectionConfig && tw`shadow-sharp-thin duration-100 cursor-pointer hover:bg-slate-300`]}
              >
                Move to {selectedSectionOrVolume?.title || "Section"}
              </button>
              {newSectionConfig && (
                <div className="text-xs flex items-center gap-1 text-slate-500">
                  <ChevronRight size={14} />
                  New {!newSectionConfig.existingDraft ? `Draft${isSectionsDisabled ? "" : " and Section"}` : "Section"}
                </div>
              )}
            </div>
            <Dialog.Close>
              <X size={18} />
            </Dialog.Close>
          </div>
          {newSectionConfig && canCreateDraft ? (
            <CreateSectionAndAssign
              draftSelectionItems={draftSelectionItems}
              newSectionConfig={newSectionConfig}
              setNewSectionConfig={setNewSectionConfig}
              isSectionsDisabled={isSectionsDisabled}
              type={type}
            />
          ) : (
            <div className="flex flex-col flex-1 h-[calc(100%-30px)]">
              <input
                autoFocus
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search draft or section to move to..."
                className="outline-none border-b border-gray-light text-slate-700 px-5 py-2.5"
              />
              <div
                className="flex flex-col flex-1 overflow-auto h-full select-none"
                css={[!canCreateDraft && tw`pb-8`]}
              >
                {!formattedVolumes.length && (
                  <div className="text-slate-400 text-sm flex-1 items-center justify-center flex">Nothing found</div>
                )}
                {!!formattedVolumes.length && (
                  <Accordion.Root type="multiple" value={values} onValueChange={setValues}>
                    {formattedVolumes.map((volume) => (
                      <Accordion.Item key={volume.id} value={volume.id}>
                        <Accordion.Header>
                          <div
                            className="flex items-center gap-2 w-full text-slate-700 border-b border-slate-100 py-3 px-4 duration-100 hover:bg-slate-50"
                            css={[
                              isTypeSection && tw`cursor-pointer`,
                              selectedSectionOrVolume?.id === volume.id && tw`!bg-slate-300`,
                            ]}
                            onClick={() => {
                              if (isTypeSection)
                                setSelectedSectionOrVolume((prev) => (prev?.id === volume.id ? null : volume));
                            }}
                          >
                            <Accordion.Trigger
                              title={volume.title}
                              onClick={(e) => e.stopPropagation()}
                              className="rounded group bg-slate-300 duration-100 hover:brightness-90"
                            >
                              <ChevronRight
                                size={14}
                                className="flex-shrink-0 transition-transform duration-300 group-data-[state=open]:rotate-90"
                              />
                            </Accordion.Trigger>{" "}
                            <Highlighter
                              highlightClassName="font-medium text-action bg-action-light"
                              className="truncate font-medium text-sm"
                              searchWords={[query]}
                              autoEscape
                              textToHighlight={volume.title}
                            />
                          </div>
                        </Accordion.Header>
                        <Accordion.Content className="collapsibleContent bg-slate-100 border-b border-slate-200">
                          <div className="flex flex-col gap-1 py-3">
                            <label className="text-slate-400 pl-10 text-xs font-medium">Sections</label>
                            {volume.sections.map((sec) =>
                              sec.subsections?.length ? (
                                <Accordion.Item key={sec.id} value={sec.id}>
                                  <Accordion.Header>
                                    <Tooltip
                                      contentProps={{ align: "start" }}
                                      portalProps={{ container: modalRef }}
                                      content="Moving a section that contains subsections to a section is not allowed."
                                      disabled={!isSectionsDisabled}
                                    >
                                      <div
                                        className="flex cursor-pointer items-center gap-1.5 w-full text-slate-600 px-4 pl-5 text-sm duration-100"
                                        css={[
                                          !isSectionsDisabled && tw`hover:bg-slate-200`,
                                          selectedSectionOrVolume?.id === sec.id && tw`!bg-slate-300`,
                                          isSectionsDisabled && tw`opacity-40 cursor-not-allowed`,
                                        ]}
                                        onClick={() => {
                                          if (isSectionsDisabled) return;
                                          setSelectedSectionOrVolume((prev) => (prev?.id === sec.id ? null : sec));
                                        }}
                                      >
                                        <Accordion.Trigger
                                          title={sec.title}
                                          className="rounded pointer-events-auto group bg-slate-300 duration-100 hover:brightness-90"
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <ChevronRight
                                            size={14}
                                            className="flex-shrink-0 transition-transform duration-300 group-data-[state=open]:rotate-90"
                                          />
                                        </Accordion.Trigger>
                                        <Highlighter
                                          highlightClassName="font-medium text-action bg-action-light"
                                          className="truncate py-2"
                                          searchWords={[query]}
                                          autoEscape
                                          textToHighlight={sec.title}
                                        />
                                        {sec.id === sectionId && (
                                          <Chip
                                            className="!ml-0.5"
                                            colors={{
                                              primaryColor: theme`colors.action.DEFAULT`,
                                              secondaryColor: theme`colors.action.light`,
                                            }}
                                          >
                                            Current
                                          </Chip>
                                        )}
                                      </div>
                                    </Tooltip>
                                  </Accordion.Header>
                                  <Accordion.Content className="collapsibleContent">
                                    {sec.subsections?.map((subsec) => (
                                      <Tooltip
                                        contentProps={{ align: "start" }}
                                        portalProps={{ container: modalRef }}
                                        key={subsec.id}
                                        content="Moving a subsection or a section that contains subsections to another subsection is not allowed."
                                        disabled={!isSectionsDisabled && !disabledState?.subsections}
                                      >
                                        <div
                                          className="flex pr-4 cursor-pointer gap-2 items-center duration-100"
                                          onClick={() => {
                                            if (isSectionsDisabled || disabledState?.subsections) return;
                                            setSelectedSectionOrVolume((prev) =>
                                              prev?.id === subsec.id ? null : subsec,
                                            );
                                          }}
                                          css={[
                                            !isSectionsDisabled &&
                                              !disabledState?.subsections &&
                                              tw`hover:bg-slate-200`,
                                            selectedSectionOrVolume?.id === subsec.id && tw`!bg-slate-300`,
                                            (isSectionsDisabled || disabledState?.subsections) &&
                                              tw`cursor-not-allowed opacity-40`,
                                          ]}
                                        >
                                          <Highlighter
                                            highlightClassName="font-medium text-action bg-action-light"
                                            searchWords={[query]}
                                            autoEscape
                                            textToHighlight={subsec.title}
                                            className="truncate text-sm text-slate-600 pl-14 py-2"
                                          />
                                          {subsec.id === sectionId && (
                                            <Chip
                                              colors={{
                                                primaryColor: theme`colors.action.DEFAULT`,
                                                secondaryColor: theme`colors.action.light`,
                                              }}
                                            >
                                              Current
                                            </Chip>
                                          )}
                                        </div>
                                      </Tooltip>
                                    ))}
                                  </Accordion.Content>
                                </Accordion.Item>
                              ) : (
                                <Tooltip
                                  contentProps={{ align: "start" }}
                                  portalProps={{ container: modalRef }}
                                  key={sec.id}
                                  content="Moving a section that contains subsections to a section is not allowed."
                                  disabled={!isSectionsDisabled}
                                >
                                  <div
                                    className="flex cursor-pointer pr-4 gap-2 items-center duration-100"
                                    onClick={() => {
                                      if (isSectionsDisabled) return;
                                      setSelectedSectionOrVolume((prev) => (prev?.id === sec.id ? null : sec));
                                    }}
                                    css={[
                                      !isSectionsDisabled && tw`hover:bg-slate-200`,
                                      selectedSectionOrVolume?.id === sec.id && tw`!bg-slate-300`,
                                      isSectionsDisabled && tw`cursor-not-allowed opacity-40`,
                                    ]}
                                  >
                                    <Highlighter
                                      highlightClassName="font-medium text-action bg-action-light"
                                      searchWords={[query]}
                                      autoEscape
                                      textToHighlight={sec.title}
                                      className="truncate text-sm text-slate-600 pl-10 py-2"
                                      key={sec.id}
                                    />
                                    {sec.id === sectionId && (
                                      <Chip
                                        colors={{
                                          primaryColor: theme`colors.action.DEFAULT`,
                                          secondaryColor: theme`colors.action.light`,
                                        }}
                                      >
                                        Current
                                      </Chip>
                                    )}
                                  </div>
                                </Tooltip>
                              ),
                            )}
                          </div>
                          {canCreateDraft && (
                            <div className="flex flex-col gap-1 pt-0.5 pb-1">
                              <label className="text-slate-400 text-xs pl-10 font-medium">New Section</label>
                              <button
                                className="flex text-xs py-2 items-center pl-10 gap-1 font-medium text-action duration-100 hover:text-action-hover hover:bg-slate-200"
                                onClick={() =>
                                  setNewSectionConfig({
                                    assignToSection: true,
                                    existingDraft: true,
                                    draft: {
                                      id: volume.id,
                                      title: volume.title,
                                    },
                                    section: { title: "" },
                                  })
                                }
                              >
                                <Plus size={14} /> Create Section
                              </button>
                            </div>
                          )}
                        </Accordion.Content>
                      </Accordion.Item>
                    ))}
                  </Accordion.Root>
                )}
                {canCreateDraft && (
                  <div className="flex flex-col gap-1 py-3">
                    <label className="text-slate-400 px-5 text-xs font-medium">New Draft</label>
                    <button
                      className="flex text-xs items-center px-5 py-2 gap-1 font-medium text-action duration-100 hover:text-action-hover hover:bg-slate-100"
                      onClick={() =>
                        setNewSectionConfig({
                          assignToSection: true,
                          existingDraft: false,
                          draft: { title: "" },
                          section: { title: "" },
                        })
                      }
                    >
                      <Plus size={14} /> Create new draft
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
          {newSectionConfig ? (
            <div className="absolute bottom-4 right-4 flex items-center gap-3">
              <div className="flex items-center gap-2 pl-2 text-sm text-slate-600">
                <Checkbox
                  checked={newSectionConfig.assignToSection}
                  onCheck={(checked) =>
                    setNewSectionConfig((prev) => ({ ...prev, assignToSection: checked }) as NewSectionConfig)
                  }
                />
                {isTypeSection
                  ? `Move to new ${newSectionConfig.section?.title ? "section" : "draft"}`
                  : "Assign to section"}
              </div>
              <Button
                disabled={!canCreate}
                onClick={() => {
                  if (canCreate) onCreateOutline(newSectionConfig);
                  setNewSectionConfig(null);
                }}
                className="shadow-md"
                variant="primary"
                size="md"
              >
                Create
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => {
                if (selectedSectionOrVolume) onMove(selectedSectionOrVolume);
              }}
              className="absolute shadow-md shadow-slate-400 bottom-4 right-4 pointer-events-none z-[0] transition-opacity duration-50 disabled:shadow-sm disabled:shadow-gray-400"
              css={[selectedSectionOrVolume && tw`pointer-events-auto z-auto`]}
              variant="primary"
              size="md"
              disabled={!selectedSectionOrVolume}
            >
              Move
            </Button>
          )}
        </div>
      }
      hideClose
      {...props}
    />
  );
};

export default MoveRequirementOrSectionModal;
