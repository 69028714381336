/** @jsxImportSource @emotion/react */

import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import ComplianceMatrix from "./ComplianceMatrix/ComplianceMatrix";
import Proposal from "./Proposal";
import { useUpdateMyPresence, useStorage, useStatus } from "YJSProvider/createYJSContext";
import { RoomProvider } from "utils/yjs-configs/proposal-document/yjs.config";
import { ScreenSpinner } from "utils/icons";
import { getProposalDocuments } from "api/api";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import ProposalTable from "./ProposalTable";
import { Loading } from "components/yjs-editor/components/Loading";
import { useSelector } from "react-redux";
import { CopilotPresencePage } from "types/Presence";
import { useBannerState, usePollAutopilotStatus, useSyncResponses } from "./hooks";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import ProjectContent from "pages/project-details/ProjectContent";
import AskAi from "components/askAi/AskAi";
import WritingAssistantDrawer from "./writing-assistant-drawer";
import { useFlags } from "hook/useFlags";
import ExtractContainer from "./extract-v2";
import CopilotModals from "./copilot-modals";
import CopilotBanners from "./copilot-banners";
import Draft from "./Framework/draft";
import useSheetOperations from "hook/useSheetOperations";
import { toggleAssistant } from "store/reducers/copilot/copilotDrawerReducer";
import Documents from "./ProposalPlan/Documents";
import Capture from "pages/capture";

// --------------- Copilot tab ---------------
const Copilot = ({
  setContractDetails,
  setInternalContractDetails,
  setForceRefreshContract,
  internalContractDetails,
  contractDetails,
  internalContractId,
  isProjectLoading,
}) => {
  const { currentUser } = useSelector((store) => store.auth);
  const [searchParams] = useSearchParams();
  const { volumeId } = useParams();
  const tabSlug = searchParams.get("tab")?.toLocaleLowerCase() || CopilotPresencePage.Project;
  const id = searchParams.get("id")?.toLocaleLowerCase();
  const docId = useMemo(() => searchParams.get("docId")?.toLocaleLowerCase(), [searchParams]);
  const navigate = useNavigate();
  const commentsOpen = useAppSelector((state) => state.copilotDrawer.commentsOpen);
  const setDocId = useCallback(
    (docId) => {
      if (docId) {
        navigate(`/dashboard/contracts/details?tab=${CopilotPresencePage.Proposal}&id=${id}&docId=${docId}`);
      } else {
        navigate(`/dashboard/contracts/details?tab=${tabSlug}&id=${id}`);
      }
    },
    [id, navigate, tabSlug],
  );
  const [totalProposals, setTotalProposals] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(true);
  const proposalRoomId = `document/${id}/${docId}`;
  const { fullscreen } = useAppSelector((root) => root.copilot);
  const { state } = useLocation();
  useSyncResponses();
  const { validateDefaultSheet } = useSheetOperations();
  usePollAutopilotStatus();
  useBannerState();
  const flags = useFlags();
  const dispatch = useAppDispatch();

  useEffect(() => {
    validateDefaultSheet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!forceRefresh) return;
    getProposalDocuments(id)
      .then((resp) => {
        setTotalProposals(resp.data || []);
      })
      .catch(() => {});
    setForceRefresh(false);
  }, [id, forceRefresh]);

  useEffect(() => {
    if (state?.docId) setDocId(state.docId);
  }, [setDocId, state?.docId]);

  useEffect(() => {
    if (flags.comments && commentsOpen && !(docId || volumeId)) {
      dispatch(toggleAssistant(true));
    }
  }, [commentsOpen, dispatch, docId, flags.comments, volumeId]);

  // TODO: Implement top level state management for all attributes
  // const patchRequirements = usePatchRequirementsId();
  // const complianceMatrixStorageState = useStorage((root) => root.compliance_matrix);

  const frameworkStorageState = useStorage((root) => root.framework);
  const yjsStatus = useStatus();

  // Tabs data
  const tabs = useMemo(
    () => [
      {
        slug: CopilotPresencePage.Project,
        component: (
          <ProjectContent
            isLoading={isProjectLoading}
            searchPage={false}
            // Represents public contract reference information
            id={contractDetails?.id}
            contractDetails={contractDetails}
            setContractDetails={setContractDetails}
            // Represents internal contract information
            internalContractId={internalContractId}
            internalContractDetails={internalContractDetails}
            setForceRefresh={setForceRefreshContract}
            setInternalContractDetails={setInternalContractDetails}
          />
        ),
      },
      {
        slug: CopilotPresencePage.Documents,
        component: (
          <Documents internalContractDetails={internalContractDetails} internalContractId={internalContractId} />
        ),
      },
      {
        slug: CopilotPresencePage.Capture,
        component: <Capture />,
      },
      {
        slug: CopilotPresencePage.Extract,
        component: <ExtractContainer />,
      },
      {
        slug: CopilotPresencePage.ComplianceMatrix,
        component: <ComplianceMatrix frameworkState={frameworkStorageState} />,
      },
      {
        slug: CopilotPresencePage.Template,
        component: <Draft />,
      },
      {
        slug: CopilotPresencePage.Proposal,
        component: docId ? (
          <RoomProvider
            id={docId}
            authParams={["document", id, docId]}
            initialPresence={{
              isTyping: false,
              name: currentUser.username,
              proposalRoomId: docId,
              activePage: CopilotPresencePage.Proposal,
            }}
            fallback={<Loading />}
          >
            <Proposal
              fullscreen={fullscreen}
              setForceRefresh={setForceRefresh}
              setDocId={setDocId}
              proposalDocId={docId}
            />
          </RoomProvider>
        ) : (
          <ProposalTable
            proposals={totalProposals}
            setProposals={setTotalProposals}
            internalContractId={internalContractId}
            setDocId={setDocId}
            setForceRefresh={setForceRefresh}
          />
        ),
      },
    ],
    [
      contractDetails,
      currentUser.username,
      docId,
      frameworkStorageState,
      fullscreen,
      id,
      internalContractDetails,
      internalContractId,
      isProjectLoading,
      setContractDetails,
      setDocId,
      setForceRefreshContract,
      setInternalContractDetails,
      totalProposals,
    ],
  );

  const activeTab = useMemo(() => tabs?.find((v) => v?.slug === tabSlug) || tabs[0], [tabSlug, tabs]);

  const updateMyPresence = useUpdateMyPresence();
  useEffect(() => {
    updateMyPresence({
      name: currentUser.username,
      activePage: activeTab.slug,
      proposalRoomId,
    });
  }, [activeTab.slug, currentUser.id, currentUser.username, docId, proposalRoomId, updateMyPresence]);

  const isAskAi = tabSlug === CopilotPresencePage.AskAi;
  return (
    <>
      {!fullscreen && <WritingAssistantDrawer fullscreen={fullscreen} />}
      {isAskAi ? (
        <AskAi internalContractId={internalContractId} />
      ) : (
        <>
          <CopilotModals />
          <CopilotBanners />
          <div className="relative w-full h-full bg-white text-gray-darkest border-[#d1d5db] border-r flex flex-col flex-1 [overflow-y:unset] overflow-x-hidden">
            <div className="flex flex-col flex-1 overflow-x-hidden overflow-y-auto">{activeTab?.component}</div>
            {(yjsStatus !== "connected" || frameworkStorageState === null) && (
              <div className="p-7 top-0 left-0 right-0 bottom-0 absolute bg-[#5f55550e] z-[999999999] backdrop-blur-[2px] my-auto flex items-center justify-center">
                <ScreenSpinner />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default memo(Copilot);
