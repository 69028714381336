import { Modal } from "components/organisms/modal";
import { ComponentProps, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { Subdirectory, WorkspaceMedia } from "types/FileStorage";
import { FolderIconBlue, FolderIconYellow, Spinner } from "utils/icons";
import { useMediaOperations } from "../media/useMediaOperations";

interface Props extends Partial<ComponentProps<typeof Modal>> {
  moveType: "file" | "directory";
  selectedItems: string[] | Subdirectory | WorkspaceMedia | null;
  currentDirectory?: Subdirectory | null;
  setSelectedFile?: (file: WorkspaceMedia | null) => void;
  setSelectedFolder?: (folder: Subdirectory | null) => void;
  setSelectedMediaIds?: (ids: string[]) => void;
}

const MoveModal = ({
  moveType,
  selectedItems,
  currentDirectory,
  setSelectedFile,
  setSelectedFolder,
  setSelectedMediaIds,
  ...props
}: Props) => {
  const [selectedDestination, setSelectedDestination] = useState<string | null | undefined>(undefined); // null is the root content drive directory, undefined is no selection
  const [selectedItemName, setSelectedItemName] = useState<string>("");
  const { moveFiles, moveFolder, isLoading } = useMediaOperations(currentDirectory?.id);
  const allDirectories = useAppSelector((root) => root.drive.media.allDirectories);

  const availableFolderIds = useMemo(() => {
    // Add main folder option only if we're in a nested folder
    const ids = currentDirectory ? [""] : [];

    if (moveType === "directory") {
      const movingId = !Array.isArray(selectedItems) ? (selectedItems as Subdirectory).id : "";

      // Get all children folders recursively
      const getChildrenFolders = (parentId: string): string[] => {
        const children = allDirectories.filter((f) => f.parent_directory === parentId);
        return children.reduce(
          (acc: string[], child) => [...acc, child.id, ...getChildrenFolders(child.id)],
          [] as string[],
        );
      };

      const movingFolder = allDirectories.find((f) => f.id === movingId);
      const folderChildrens = getChildrenFolders(movingId);

      const folderIds = allDirectories
        .filter(
          (folder) =>
            folder.id !== "" &&
            folder.id !== movingId && // Not itself
            !folderChildrens.includes(folder.id) && // Not a child
            folder.id !== movingFolder?.parent_directory, // Not current parent
        )
        .map((folder) => folder.id);

      ids.push(...folderIds);
    } else if (moveType === "file") {
      const folderIds = allDirectories
        .filter((folder) => folder.id !== "" && folder.id !== currentDirectory?.id)
        .map((folder) => folder.id);
      ids.push(...folderIds);
    }
    return ids;
  }, [moveType, selectedItems, allDirectories, currentDirectory]);

  const hasNoFolders = !currentDirectory && availableFolderIds.length === 0;

  useEffect(() => {
    if (selectedItems) {
      if (Array.isArray(selectedItems)) {
        setSelectedItemName(`${selectedItems.length} items`);
      } else {
        setSelectedItemName(
          moveType === "directory"
            ? (selectedItems as Subdirectory).name || ""
            : (selectedItems as WorkspaceMedia).file_name || "",
        );
      }
    }
  }, [selectedItems, moveType]);

  const handleMove = async () => {
    if (isLoading || !selectedItems || selectedDestination === undefined) return;
    try {
      if (moveType === "directory" && !Array.isArray(selectedItems)) {
        await moveFolder({
          id: selectedItems.id,
          parent_directory_id: selectedDestination || undefined,
        });
      } else {
        const fileIds = Array.isArray(selectedItems) ? selectedItems : [(selectedItems as WorkspaceMedia).id];
        await moveFiles({
          fileIds,
          parent_directory_id: selectedDestination || undefined,
          source_directory_id: currentDirectory?.id,
          target_directory_id: selectedDestination || null,
        });
        if (setSelectedMediaIds) setSelectedMediaIds([]);
      }
      props.onOpenChange?.(false);
      setSelectedDestination(null);
    } catch (error) {
      console.error("Move error:", error);
    }
  };

  const handleCloseMoveModal = () => {
    props.onOpenChange?.(false);
    setSelectedDestination(null);
    if (setSelectedFile) setSelectedFile(null);
    if (setSelectedFolder) setSelectedFolder(null);
  };

  const modalTitle = Array.isArray(selectedItems) ? `Move ${selectedItemName}` : `Move "${selectedItemName}"`;

  return (
    <Modal
      contentProps={{ css: tw`w-[595px]`, onClick: (e) => e.stopPropagation() }}
      title={modalTitle}
      header={modalTitle}
      body={
        <div className="flex flex-col overflow-y-auto px-5">
          <div className="my-6 max-h-[350px] flex flex-col border rounded overflow-auto">
            {hasNoFolders ? (
              <div className="p-8 text-center">
                <h5 className="text-base font-semibold text-[#1D2630]">No Folders</h5>
                <p className="text-[#353535] pt-[5px]">No folders have been created yet.</p>
              </div>
            ) : (
              availableFolderIds.map((id, i) => {
                const folder = allDirectories.find((f) => f.id === id);
                return (
                  <div
                    role="button"
                    className={`${
                      availableFolderIds?.length === i + 1 ? "" : "border-b"
                    } flex gap-3 p-4 cursor-pointer select-none ${
                      selectedDestination === id ? " bg-[#f7f7f7]" : "hover:bg-[#f8f8f8]"
                    }`}
                    key={id}
                    onClick={() => {
                      setSelectedDestination((prev) => (prev === id ? undefined : id));
                    }}
                  >
                    <div className="min-w-[38px] flex items-center justify-center">
                      {id ? <FolderIconYellow /> : <FolderIconBlue />}
                    </div>
                    <p className="font-[500] flex-1">{id ? folder?.name : "Main Library"}</p>
                  </div>
                );
              })
            )}
          </div>
        </div>
      }
      footer={
        <div className="flex justify-end gap-4">
          <button
            className="border-[1px] border-[#DBE0E5] rounded-lg py-[9px] px-4 text-sm font-medium text-[#1D2630]"
            disabled={isLoading}
            onClick={() => !isLoading && handleCloseMoveModal()}
          >
            Cancel
          </button>
          <button
            className="border-0 disabled:opacity-50 bg-gray-darkest rounded-lg py-[9px] px-4 text-sm font-medium text-[#ffffff] flex items-center gap-2"
            onClick={handleMove}
            disabled={isLoading || selectedDestination === undefined || hasNoFolders}
          >
            {isLoading ? (
              <>
                <Spinner width="18" height="18" />
                Moving
              </>
            ) : (
              "Move"
            )}
          </button>
        </div>
      }
      {...props}
    />
  );
};

export default MoveModal;
