/** @jsxImportSource @emotion/react */

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import arrowLeft from "Assets/arrow-left.svg";
import { Section, Volume } from "components/copilot/CopilotSchemaImmutableTypes";
import { useCallback, useMemo, useRef } from "react";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import { useDrag, usePreview, useSectionNavigation } from "./hooks";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import SectionNavRow from "./SectionNavRow";
import SortableItem from "./SortableItem";
import { verticalSortableListCollisionDetection } from "./utils";
import { Button } from "components/editor/components";
import { setSectionState } from "store/reducers/draft/sectionReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useFrameworkOperations } from "hook/useFrameworkOperations";
import { Download, FileText, Plus } from "lucide-react";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { PREVIEW_LAUNCH_DATE, WHITELIST_PROJECT_IDS, WHITELIST_WORKSPACE_IDS } from "const-values/Preview";
import { CopilotPresencePage } from "types/Presence";
import { usePublishVolume } from "hook/draft/usePublishVolume-old";
import { updateDraftExtractionState } from "store/reducers/draft/DraftsExtractionReducer";
import SectionNavRowLoading from "./SectionNavRowLoading";
import { setCheckedState } from "store/reducers/copilot/requirementsReducer";
import { LiveList, LiveObject, ToImmutable } from "YJSProvider/LiveObjects";
import usePersistedStorage from "hook/persisted-storage/usePersistedStorage";
import { useFlags } from "hook/useFlags";
import { SingleDraftOutline } from "components/copilot/extract-v2/doc-viewer/document-sidebar/template-manager/outline-editor/SingleDraftOutline";
import classNames from "classnames";

type Props = {
  volume?: Volume;
};

export type FormattedSection = ToImmutable<Section> & { subsections?: ToImmutable<LiveList<LiveObject<Section>>> };

const DraftVolumeSidebar = ({ volume }: Props) => {
  const textOutlineEditor = false;
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [searchParams] = useSearchParams();
  const [workspace_id] = usePersistedStorage<string>("vultron_workspace_id");
  const flags = useFlags();
  const { title, sections, id = "" } = volume || {};
  useSectionNavigation(volume);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { addNewSection } = useFrameworkOperations();
  const { triggerPreview } = usePreview(volume);
  const { handleDragCancel, handleDragEnd, handleDragStart, sensors, activeDragId } = useDrag();
  const activeProject = useAppSelector((state) => state.project.activeProject);
  const { publishVolume, isPublishing } = usePublishVolume();
  const draftExtractionState = useAppSelector((store) => store.draftsExtractionState.drafts[id]);
  const { isLoading } = draftExtractionState || {};
  const sortedSections = useMemo(() => {
    return (
      sections?.reduce<FormattedSection[]>((acc, section) => {
        if (!section.parent_id) {
          const subsections = sections.filter(({ parent_id }) => parent_id === section.id);
          return [...acc, { ...section, subsections }];
        }

        return acc;
      }, []) || []
    );
  }, [sections]);

  const activeSection = useMemo(
    () => sections?.find((section) => section.id === activeDragId) || {},
    [activeDragId, sections],
  );

  const handlePublish = useCallback(async () => {
    if (id && !isPublishing) {
      const docId = await publishVolume({ volume_id: id });
      if (!docId) return;
      searchParams.set("tab", CopilotPresencePage.Proposal);
      navigate(`/dashboard/contracts/details?${searchParams.toString()}`, { state: { docId } });
    }
  }, [id, isPublishing, navigate, publishVolume, searchParams]);

  const shouldShowPreview = useMemo(
    () =>
      WHITELIST_WORKSPACE_IDS.includes(workspace_id || "") ||
      WHITELIST_PROJECT_IDS.includes(activeProject?.internal_contract.id || "") ||
      new Date(activeProject?.internal_contract?.created_at || PREVIEW_LAUNCH_DATE).getTime() >=
        new Date(PREVIEW_LAUNCH_DATE).getTime(),
    [activeProject?.internal_contract?.created_at, activeProject?.internal_contract.id, workspace_id],
  );

  return (
    <div className="flex flex-col justify-between h-full">
      <DndContext
        sensors={sensors}
        collisionDetection={verticalSortableListCollisionDetection}
        onDragEnd={(event) => handleDragEnd(event, id)}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
      >
        <div
          className={classNames("flex flex-col pt-5 max-h-full h-full overflow-hidden", !textOutlineEditor && "gap-4")}
        >
          <div className="flex items-start justify-between gap-2 pl-[18px] pr-3">
            <div className="flex gap-2 min-w-0">
              <Link
                to={`/dashboard/contracts/details?${searchParams.toString()}`}
                className="block w-5 h-5 min-h-[20px] min-w-[20px]"
              >
                <img src={arrowLeft} alt="" />
              </Link>
              <div className="text-base text-gray-darkest font-semibold line-clamp-2 break-words">{title}</div>
            </div>
            <Tooltip content="Add Section" delayDuration={700}>
              <button
                onClick={() => {
                  if (id) {
                    const newSection = addNewSection(id);
                    if (!sections?.length) {
                      navigate(`sections/${newSection.id}/requirements?${searchParams.toString()}`);
                    }
                    scrollRef.current?.scrollTo(0, scrollRef.current?.scrollHeight + 20);
                    dispatch(
                      setSectionState({
                        sectionEditable: newSection.id,
                      }),
                    );
                  }
                }}
                className="rounded-md -mt-1 p-1.5 bg-slate-200 shadow-sharp-thin text-slate-700 duration-150 hover:bg-slate-300 hover:text-slate-900"
              >
                <Plus size={14} />
              </button>
            </Tooltip>
          </div>
          {textOutlineEditor ? (
            <SingleDraftOutline volumeId={id} isReadOnly={false} className="flex-grow overflow-y-auto" />
          ) : (
            <div className="overflow-y-auto">
              <div ref={scrollRef} className="flex flex-col pl-2 pr-3 pb-2 gap-1 shrink-0 overflow-y-auto">
                <SortableContext id="TEMPLATE_SECTIONS" items={sections || []} strategy={verticalListSortingStrategy}>
                  {sortedSections?.map((section) => (
                    <SortableItem key={section.id} id={section.id} section={section} />
                  ))}
                  <DragOverlay style={{ transformOrigin: "0 0 " }}>
                    {!!activeDragId && <SectionNavRow section={activeSection} isDragging />}
                  </DragOverlay>
                </SortableContext>
                {isLoading && <SectionNavRowLoading />}
                {!flags.disableGenerateSections && (
                  <button
                    onClick={() => {
                      dispatch(updateDraftExtractionState({ [id]: { id, isOpen: true } }));
                      dispatch(setCheckedState({}));
                    }}
                    className="bg-slate-200 justify-center text-slate-700 font-medium shadow-sharp-thin flex items-center rounded-md pr-3 pl-2 py-1.5 text-sm duration-100 hover:bg-slate-300 hover:text-slate-900"
                  >
                    Generate Sections
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </DndContext>
      <div className="p-3 flex justify-center">
        {shouldShowPreview ? (
          <Button
            onClick={() => triggerPreview()}
            size="md"
            variant="secondary"
            className="flex w-full max-w-[400px] items-center !border !border-solid !border-slate-300 hover:!border-action !bg-action-light !text-action"
          >
            <FileText size={18} className="mr-1" />
            Preview
          </Button>
        ) : (
          <Button
            onClick={handlePublish}
            loading={isPublishing}
            disabled={isPublishing}
            size="md"
            variant="secondary"
            className="flex w-full max-w-[400px] items-center !border !border-solid !border-slate-300 hover:!border-action !bg-action-light !text-action"
          >
            {!isPublishing && <Download size={14} className="mr-1" />}
            {isPublishing ? "Publishing" : "Publish"}
            {isPublishing && <span className="loading-ellipsis" />}
          </Button>
        )}
      </div>
    </div>
  );
};

export default DraftVolumeSidebar;
