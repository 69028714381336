import { useDispatch } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { EventStreamContentType, fetchEventSource } from "@microsoft/fetch-event-source";
import { useLocalStorage } from "hook/useLocalStorage";
import { ComplianceMatrixRow, Framework, Storage } from "./CopilotSchemaTypes";
import {
  Storage as ImmutableStorage,
  WritingPrompt,
  ResponseSource,
  RequirementStatus,
} from "./CopilotSchemaImmutableTypes";
import * as Sentry from "@sentry/react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { clearQueue, dequeue } from "store/reducers/requirementsSmartResponseReducer";
import { RESPONSE_TOLERANCE_TO_RELEVANCE_SCORE, RelevanceScore, ResponseTolerance } from "types/Requirement";
import { useNotification } from "context/notificationContext";
import axios, { AxiosResponse } from "axios";
import { DroppableId } from "./Framework/types";
import { useUpdateTemplate } from "./Framework/hooks";
import { addRequirementToSection } from "./Framework/utils";
import { move } from "utils/array";
import { DropResult } from "react-beautiful-dnd";
import { DRAG_TYPES } from "./constants";
import {
  GENERATED_CONTENT_DROPPABLE,
  SELECTED_CONTENT_DROPPABLE,
} from "./Framework/section-content-ideation/constants";
import {
  moveGenratedContentToSelected,
  moveSelectedContentToGenerated,
  reorderSectionIdeationGeneratedContent,
  reorderSectionIdeationSelectedContent,
} from "store/reducers/copilot/SectionIdeationReducer";
import usePageVisibility from "hook/usePageVisibility";
import { getAutopilotHealthCheck } from "store/reducers/copilot/AutopilotHealthReducer";
import { setBannerState } from "store/reducers/copilot/copilotBannerReducer";
import { DELIMITER, HEARTBEAT } from "const-values/Stream";
import { setResponseErrorRequirementIds } from "store/reducers/draft/sectionReducer";
import { useTrackUserMetric } from "utils/metrics";
import { getWordCount } from "utils/getWordCount";
import { useMutation, useStorage } from "YJSProvider/createYJSContext";
import { isEqual } from "lodash";
import { filter, find, findIndex, LiveList, LiveObject, moveItem, update } from "YJSProvider/LiveObjects";
import { getAtlasRequirements } from "store/reducers/projectReducer";
import { StreamEvent, StreamEventType, StreamStopMsgData } from "types/Streaming/streamConfig";
import { apiUrl } from "config/vultronConfig";

export type RequestVariables = {
  project_id: string;
  requirement: string;
  response_tolerance: ResponseTolerance;
  writing_prompts: Pick<WritingPrompt, "content" | "file_id_filters">[];
  user_instructions: string[];
  requirement_file_id_filters: string[];
  decompose_requirement: boolean;
  speed_optimized: boolean;
  red_team: boolean;
  requirement_id: string;
};

export const useSyncResponses = () => {
  const winThemes = useStorage(
    (root) => (root.win_themes as ImmutableStorage["win_themes"])?.filter(({ content }) => !!content),
    isEqual,
  );
  const { generateResponseQueue, autoResponseActive } = useAppSelector((root) => root.requirementsSmartResponse);
  const { currentUser } = useAppSelector((root) => root.auth);
  const dispatch = useDispatch();
  const { localValue } = useLocalStorage("vultron_user_token", "");
  const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
  const { localValue: use_auth0 } = useLocalStorage("vultron_user_use_auth0", "");
  const useAuth0Header = use_auth0 === true;
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id")?.toLocaleLowerCase();
  const generateResponseQueueRef = useRef(generateResponseQueue);
  generateResponseQueueRef.current = generateResponseQueue;
  const controller = useRef(new AbortController());
  const trackUserEvent = useTrackUserMetric();

  const { setToast } = useNotification();

  const [activelyGenerating, setActivelyGenerating] = useState<Set<string>>(new Set());

  const setIsGenerating = useMutation(({ storage }, reqId, isGenerating) => {
    if (!isGenerating) {
      setActivelyGenerating((prev) => {
        const updated = new Set(prev);
        updated.delete(reqId);
        return updated;
      });
    }

    const matrix = storage.get("compliance_matrix") as Storage["compliance_matrix"] | undefined;
    if (!matrix) return;

    const foundRow = find(matrix, (row) => row.get("requirement")?.get("id") === reqId);

    foundRow?.set("is_response_generating", isGenerating);
  }, []);

  const removeFromQueue = useMutation(
    ({ storage }, reqId) => {
      const matrix = storage.get("compliance_matrix") as Storage["compliance_matrix"] | undefined;

      if (matrix) {
        const foundRow = find(matrix, (row) => row.get("requirement")?.get("id") === reqId);
        if (foundRow) {
          update(foundRow, {
            is_response_in_queue: false,
            is_response_generating: false,
            auto_response_actor: "",
          });
        }
      }
      dispatch(dequeue(reqId));
      currentQueueReqId.current = "";
    },
    [dispatch, dequeue],
  );

  const updateRequirementResponseToAtlas = useCallback(
    async (reqId: string, body: { content: string; relevance_score: RelevanceScore }) => {
      try {
        axios.put(`/autopilot/${projectId}/requirements/${reqId}/response`, body);
      } catch {}
    },
    [projectId],
  );

  const createRequirementResponseToAtlas = useCallback(
    async (reqId: string, body: { content: string; relevance_score: RelevanceScore }) => {
      try {
        await axios.post(`/autopilot/${projectId}/requirements/${reqId}/response`, body);
        if (projectId) dispatch(getAtlasRequirements(projectId));
      } catch (error) {}
    },
    [dispatch, projectId],
  );

  const initResponseStream = useMutation(
    ({ storage }, body: RequestVariables, reqId: string, hasAtlasResponse: boolean) => {
      controller.current = new AbortController();
      const matrix = storage.get("compliance_matrix") as Storage["compliance_matrix"] | undefined;
      const foundRow = matrix && find(matrix, (row) => row.get("requirement")?.get("id") === reqId);

      if (!foundRow) return;

      update(foundRow, { written_content: "", written_html_content: "" });

      foundRow.set("response_sources", new LiveList([]));
      const isStatusTodo = (foundRow?.get("requirement_status") || RequirementStatus.Todo) === RequirementStatus.Todo;

      fetchEventSource(`${apiUrl}/requirements/generate/sources/response/stream`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Workspace: `Workspace ${workspace_id}`,
          Authorization: `Bearer ${localValue}`,
          "X-Authorization-Auth0": JSON.stringify(useAuth0Header),
          Accept: "application/json",
        },
        body: JSON.stringify(body),
        signal: controller.current?.signal,
        openWhenHidden: true,
        onmessage(msg) {
          if (msg.event === "FatalError") {
            removeFromQueue(reqId);
          }

          if (msg.event === StreamEvent.StreamRestart) {
            update(foundRow, {
              written_content: msg.data || "",
              written_html_content: "",
              response_sources: new LiveList([]),
            });
            return;
          }

          if (msg.event === StreamEvent.StreamStop) {
            try {
              const parsedData = JSON.parse(msg.data) as StreamStopMsgData;
              if (parsedData.type === StreamEventType.NoSources)
                dispatch(setResponseErrorRequirementIds({ add: true, data: [reqId] }));
              if (parsedData.type === StreamEventType.Repetition) {
                console.log("clearing");

                update(foundRow, {
                  written_content: "",
                  written_html_content: "",
                  response_sources: new LiveList([]),
                });
              }

              if (parsedData.reason) {
                setToast.error({
                  msg: parsedData.reason,
                });
              }
            } catch {}

            setTimeout(() => removeFromQueue(reqId), 500);

            return;
          }

          if (msg.data?.length) {
            if (msg.data === "*") return;
            try {
              const parsed: Record<"sources", ResponseSource[]> = JSON.parse(msg.data);
              if (typeof parsed !== "object" || !parsed.sources) throw new Error("error");
              const liveSources = parsed.sources?.map((source) => new LiveObject(source));
              foundRow.set("response_sources", new LiveList(liveSources));
            } catch {
              if (msg.data === HEARTBEAT) {
                return;
              } else if (msg.data !== DELIMITER) {
                foundRow.set("written_content", `${foundRow.get("written_content")}${msg.data}`);
                foundRow.set("written_html_content", "");

                if (isStatusTodo) foundRow.set("requirement_status", RequirementStatus.InProgress);
                dispatch(
                  setResponseErrorRequirementIds({
                    add: false,
                    data: [reqId],
                  }),
                );
              }
            }
          } else if (typeof msg.data === "string") {
            foundRow.set("written_content", `${foundRow.get("written_content")}\n`);
            foundRow.set("written_html_content", "");
          }
        },
        async onopen(response) {
          if (response.status === 204) {
            dispatch(setResponseErrorRequirementIds({ add: true, data: [reqId] }));
          } else if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
            update(foundRow, { written_content: "", written_html_content: "" });
            foundRow.set("response_sources", new LiveList([]));
            return; // everything's good
          } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
            setToast.error({
              title: "Unable to generate content",
              msg: "We were unable to generate content due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
            });
            removeFromQueue(reqId);
            Sentry.captureException(new Error("Requirement response failed"), {
              extra: { response },
            });
            // client-side errors are usually non-retriable:
            // throw new FatalError();
          } else {
            // throw new RetriableError();
          }
        },
        onclose() {
          const response = foundRow.get("written_content") || "";
          trackUserEvent("Drafts: Requirement Response Generated", {
            requirement_id: String(reqId),
            word_count: getWordCount(response),
            sensitivity: String(body.response_tolerance),
            number_sources: foundRow.get("response_sources")?.length || 0,
            file_ids:
              (foundRow.get("response_sources") as ComplianceMatrixRow["response_sources"] | undefined)?.map((source) =>
                source.get("file_id"),
              ) || [],
            unique_files_count: (
              (foundRow.get("response_sources") as ComplianceMatrixRow["response_sources"] | undefined)?.map((source) =>
                source.get("file_id"),
              ) || []
            ).length,
          });

          setTimeout(() => removeFromQueue(reqId), 500);

          const hasSources = !!foundRow.get("response_sources")?.length;
          const defaultRelevance = RESPONSE_TOLERANCE_TO_RELEVANCE_SCORE[body.response_tolerance];
          const relevanceScore = hasSources ? defaultRelevance : RelevanceScore.NoSources;
          if (hasAtlasResponse) {
            updateRequirementResponseToAtlas(reqId, {
              content: response,
              relevance_score: relevanceScore,
            });
          } else if (response) {
            createRequirementResponseToAtlas(reqId, {
              content: response,
              relevance_score: relevanceScore,
            });
          }
          // if the server closes the connection unexpectedly, retry:
          // throw new RetriableError();
        },
        onerror(err) {
          setToast.error({
            title: "Unable to generate response",
            msg: "We were unable to generate a response due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
          });
          removeFromQueue(reqId);
          if (err instanceof Error) {
            Sentry.captureException(err);
            throw err; // rethrow to stop the operation
          } else {
            // do nothing to automatically retry. You can also
            // return a specific retry interval here.
          }
        },
      });
    },
    [localValue, removeFromQueue, setToast, workspace_id],
  );
  const currentQueueReqId = useRef("");

  const isNewQueueReq = currentQueueReqId.current !== generateResponseQueue[0]?.requirement?.id;

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!autoResponseActive) {
      controller.current?.abort();
      abortLocalAutoResponse();
      dispatch(clearQueue());
    }
    dispatch(setBannerState({ smartResponse: { open: autoResponseActive, forceClose: false } }));
  }, [autoResponseActive, dispatch]);

  const abortLocalAutoResponse = useMutation(
    ({ storage }) => {
      const matrix = storage.get("compliance_matrix") as Storage["compliance_matrix"] | undefined;

      if (!matrix) return;

      const queuedRowsByCurrentUser = filter(matrix, (row) => row.get("auto_response_actor") === currentUser?.id);

      if (!queuedRowsByCurrentUser?.length) return;

      queuedRowsByCurrentUser.forEach((row) => {
        update(row, { is_response_in_queue: false, is_response_generating: false, auto_response_actor: "" });
      });
      currentQueueReqId.current = "";

      setToast.success({
        msg: "Response generations stopped",
      });
    },
    [currentUser, dispatch],
  );

  useEffect(() => {
    const row = generateResponseQueue[0];
    if (!!projectId && isNewQueueReq && !!row && !row.is_response_generating) {
      currentQueueReqId.current = row.requirement?.id;
      setIsGenerating(row.requirement?.id, true);
      setActivelyGenerating((prev) => new Set(prev.add(row.requirement?.id)));
      initResponseStream(
        {
          speed_optimized: row.speed_optimized,
          decompose_requirement: row.decompose_requirement,
          red_team: row.red_team,
          requirement_id: row.requirement.id,
          project_id: projectId,
          requirement: row.requirement.content || row.requirement.summarized_content || "",
          response_tolerance: row.response_tolerance,
          requirement_file_id_filters: row.writing_prompts?.length ? [] : row.requirement_file_id_filters || [],
          writing_prompts: (row.writing_prompts || [])
            .filter((prompt) => {
              const firstEmptyPrompt = row.writing_prompts?.find(
                ({ content, file_id_filters }) => !content.trim() && !!file_id_filters.length,
              );
              const numBlankPrompts = row.writing_prompts?.filter(
                (prompt) => !prompt.content.trim() && !!prompt.file_id_filters.length,
              ).length;
              return (
                !!prompt.content.trim() ||
                (numBlankPrompts === 1 && row.writing_prompts?.length === 1 && firstEmptyPrompt?.id === prompt.id)
              );
            })
            .map(({ id, ...rest }) => rest),
          user_instructions: (row.user_instructions || [])
            .filter((prompt) => !!prompt.content.trim())
            .map(({ content }) => content),
        },
        row.requirement.id,
        row.hasAtlasResponse,
      );
    }
  }, [
    activelyGenerating,
    initResponseStream,
    projectId,
    generateResponseQueue,
    setIsGenerating,
    dispatch,
    isNewQueueReq,
    removeFromQueue,
    winThemes,
  ]);
};

type ReviseVariables = {
  project_id: string;
  requirement?: string;
  previous_response: string;
  user_feedback: string;
  win_themes: string[];
};

export const useAIRevise = (onSuccess: (text: string) => void) => {
  const { setToast } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [revisedText, setRevisedText] = useState("");

  const reviseText = (data: ReviseVariables) => {
    setIsLoading(true);
    axios
      .post<any, AxiosResponse<{ response: string }, any>, ReviseVariables>(
        `requirements/generate/feedback/response`,
        data,
      )
      .then(({ data }) => {
        if (!data.response) setRevisedText(data.response);
        onSuccess(data.response);
      })
      .catch(() => {
        setToast.error({
          title: "Unable to revise content",
          msg: "We were unable to revise the content due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    reviseText,
    revisedText,
  };
};

export const useDrag = () => {
  const { activeTab: activeRequirementsDrawerTab } = useAppSelector((root) => root.requirementsDrawer);
  const updateTemplate = useUpdateTemplate();
  const dispatch = useAppDispatch();

  const frameworkDragEnd = useMutation(
    ({ storage }, results: DropResult) => {
      const { source, destination, type, draggableId } = results;
      if (!destination) return;

      const volumeList = (storage.get("framework") as Storage["framework"]).get("volumes") as LiveList<
        LiveObject<Framework>
      >;
      const complianceMatrixRows = storage.get("compliance_matrix") as Storage["compliance_matrix"] | undefined;

      if (type === "TEMPLATE_VOLUMES") {
        const sourceIndex = source.index;
        const destinationIndex = destination?.index;
        moveItem(volumeList, sourceIndex, destinationIndex);
      } else if (type === "TEMPLATE_REQUIREMENTS") {
        const sourceDroppableId = source?.droppableId; // source section id or drawer
        const destinationDroppableId = destination?.droppableId; // destination section id or drawer
        const isReordingWithinSection =
          destinationDroppableId !== DroppableId.RequirementDrawer && destinationDroppableId === sourceDroppableId;

        const activeDraggableTabPrefix = draggableId.match(
          /drawer:unassigned:|drawer:assigned:|drawer:disregarded:/,
        )?.[0];
        const activeDraggableRequirementId = activeDraggableTabPrefix
          ? draggableId.replace(activeDraggableTabPrefix, "")
          : draggableId; // requirement id
        if (!complianceMatrixRows) return;
        const complianceMatrixActiveDraggableRowIndex = findIndex(
          complianceMatrixRows,
          (row) => row?.get("requirement")?.get("id") === activeDraggableRequirementId,
        );
        const activeDraggableComplianceMatrixRow = complianceMatrixRows.get(complianceMatrixActiveDraggableRowIndex);
        if (!activeDraggableComplianceMatrixRow) return;

        if (isReordingWithinSection) {
          const rowsInSection = filter(
            complianceMatrixRows,
            (row) => row?.get("proposal_reference").get("section_id") === destinationDroppableId,
          );
          const doesNotHaveFullOrdering = rowsInSection.some(
            (row) => typeof row.get("requirement")?.get("section_order") !== "number",
          );
          if (doesNotHaveFullOrdering) {
            rowsInSection.forEach((row, idx) => {
              const requirement = row?.get("requirement");
              requirement?.set("section_order", idx);
            });
          }
          rowsInSection.sort(
            (a, b) =>
              (a.get("requirement")?.get("section_order") || 0) - (b.get("requirement")?.get("section_order") || 0),
          );
          const sortedRows = move([...rowsInSection], source?.index, destination?.index);
          sortedRows.forEach((row, idx) => {
            row.get("requirement")?.set("section_order", idx);
          });
          return;
        }

        // prevent dragging req to section if it already exists
        const requirementExistsInSection =
          activeDraggableComplianceMatrixRow.get("requirement")?.get("id") === activeDraggableRequirementId &&
          activeDraggableComplianceMatrixRow.get("proposal_reference")?.get("section_id") === destinationDroppableId;
        if (requirementExistsInSection) return;
        // dragging req to section from outside
        addRequirementToSection({
          complianceMatrix: complianceMatrixRows,
          activeRow: activeDraggableComplianceMatrixRow,
          volumeList,
          destinationSectionId: destinationDroppableId,
        });
      } else if (type === "TEMPLATE_SECTIONS") {
        const targetSectionId = draggableId;
        const volumeSourceId = source?.droppableId;
        const volumeDestinationId = destination?.droppableId;
        const volumeSourceIndex = findIndex(volumeList, (b) => b.get("id") === volumeSourceId);
        const volumeDestinationIndex = findIndex(volumeList, (b) => b.get("id") === volumeDestinationId);
        const newSubItems = volumeList.get(volumeSourceIndex)?.get("sections");
        if (!newSubItems) return;
        const newDestinationSubItems =
          volumeSourceId !== volumeDestinationId
            ? volumeList.get(volumeDestinationIndex)?.get("sections")
            : newSubItems;
        const destinationItemToReplace = newDestinationSubItems?.get(
          destination.index > source.index && volumeDestinationId === volumeSourceId
            ? destination.index + 1
            : destination.index,
        );
        // Remove item from source list
        const originalItem = newSubItems?.get(source.index);
        if (!originalItem) return;
        const deletedItem = new LiveObject({
          id: originalItem.get("id"),
          title: originalItem.get("title"),
          locked: originalItem.get("locked"),
          ...(!!destination.index && {
            parent_id:
              volumeDestinationId === volumeSourceId &&
              destination.index > source.index &&
              newDestinationSubItems?.get(destination.index - 1)?.get("parent_id") ===
                newDestinationSubItems?.get(source.index)?.get("parent_id")
                ? originalItem.get("parent_id")
                : destinationItemToReplace?.get("parent_id"),
          }),
          proposal: originalItem.get("proposal"),
        });
        // Update compliance matrix
        complianceMatrixRows?.forEach((row) => {
          if (row?.get("proposal_reference")?.get("section_id") === targetSectionId) {
            const proposalRef = row.get("proposal_reference");

            update(proposalRef, {
              volume_id: volumeDestinationId,
              subsection_id: deletedItem.get("parent_id") ? deletedItem.get("id") : "",
            });
          }
        });
        newSubItems?.delete(source.index);
        // Add item to destination list
        newDestinationSubItems?.insert(destination?.index, [deletedItem]);
      }
      updateTemplate({ isDirty: true });
    },
    [activeRequirementsDrawerTab],
  );

  const sectionIdeationDragEnd = useCallback(
    (results: DropResult) => {
      const { source, destination } = results;

      if (!destination || !source) return;

      if (
        source?.droppableId === GENERATED_CONTENT_DROPPABLE &&
        destination?.droppableId === GENERATED_CONTENT_DROPPABLE
      ) {
        dispatch(reorderSectionIdeationGeneratedContent({ from: source.index, to: destination.index }));
      }
      if (
        source?.droppableId === SELECTED_CONTENT_DROPPABLE &&
        destination?.droppableId === SELECTED_CONTENT_DROPPABLE
      ) {
        dispatch(reorderSectionIdeationSelectedContent({ from: source.index, to: destination.index }));
      }
      if (
        source?.droppableId === GENERATED_CONTENT_DROPPABLE &&
        destination?.droppableId === SELECTED_CONTENT_DROPPABLE
      ) {
        dispatch(moveGenratedContentToSelected({ from: source.index, to: destination.index }));
      }
      if (
        source?.droppableId === SELECTED_CONTENT_DROPPABLE &&
        destination?.droppableId === GENERATED_CONTENT_DROPPABLE
      ) {
        dispatch(moveSelectedContentToGenerated({ from: source.index, to: destination.index }));
      }
    },
    [dispatch],
  );

  const dragEnd = useCallback(
    (results: DropResult) => {
      const { source, destination, type } = results;
      if (!destination) return;
      if (source?.index === destination?.index && source?.droppableId === destination?.droppableId) return;

      if (DRAG_TYPES.sectionContentIdeation.includes(type)) {
        sectionIdeationDragEnd(results);
      } else if (DRAG_TYPES.framework.includes(type)) {
        frameworkDragEnd(results);
      }
    },
    [frameworkDragEnd, sectionIdeationDragEnd],
  );

  return dragEnd;
};

export const usePollAutopilotStatus = () => {
  const isPageVisible = usePageVisibility();
  const timerIdRef = useRef<NodeJS.Timeout>();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id")?.toLocaleLowerCase();
  const dispatch = useAppDispatch();
  const [isPollingEnabled, setIsPollingEnabled] = useState(true);

  useEffect(() => {
    if (!projectId) return;
    const pollingCallback = async () => {
      dispatch(getAutopilotHealthCheck(projectId));
    };

    const startPolling = () => {
      pollingCallback();
      // Polling every 3 seconds
      timerIdRef.current = setInterval(pollingCallback, 3000);
    };

    const stopPolling = () => {
      clearInterval(timerIdRef.current);
    };

    if (isPageVisible && isPollingEnabled) {
      startPolling();
    } else {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [dispatch, isPageVisible, isPollingEnabled, projectId]);
};

export const useBannerState = () => {
  const { requirement_document_tasks, requirement_text_tasks, template_document_tasks, template_text_tasks } =
    useAppSelector((state) => state.autopilotHealthCheck);
  const { requirementExtraction, templateExtraction } = useAppSelector((state) => state.bannerState);
  const dispatch = useAppDispatch();

  const requirementExtractionsInProgress = !![...requirement_document_tasks, ...requirement_text_tasks].length;

  const templateExtractionsInProgress = !![...template_document_tasks, ...template_text_tasks].length;

  useEffect(() => {
    if (requirementExtractionsInProgress) {
      if (requirementExtraction.forceClose) return;
      dispatch(
        setBannerState({
          requirementExtraction: {
            open: requirementExtractionsInProgress,
            forceClose: false,
          },
        }),
      );
    } else {
      dispatch(
        setBannerState({
          requirementExtraction: {
            open: false,
            forceClose: false,
          },
        }),
      );
    }
  }, [dispatch, requirementExtraction.forceClose, requirementExtractionsInProgress]);

  useEffect(() => {
    if (templateExtractionsInProgress) {
      if (templateExtraction.forceClose) return;
      dispatch(
        setBannerState({
          templateExtraction: {
            open: templateExtractionsInProgress,
            forceClose: false,
          },
        }),
      );
    } else {
      dispatch(
        setBannerState({
          templateExtraction: {
            open: false,
            forceClose: false,
          },
        }),
      );
    }
  }, [dispatch, templateExtraction.forceClose, templateExtractionsInProgress]);
};
