import { Button } from "components/editor/components";
import { Modal } from "components/organisms/modal";
import { ComponentProps } from "react";

interface Props extends Pick<ComponentProps<typeof Modal>, "open" | "onOpenChange"> {}

const HelpModal = (props: Props) => {
  return (
    <Modal
      hideClose
      title="How to use"
      header="How to use"
      contentProps={{ css: { maxWidth: "575px" } }}
      {...props}
      body={
        <div className="flex flex-col gap-3 min-w-[150px] m-1 min-h-0">
          <div className="flex flex-col gap-2 overflow-y-auto px-5">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-3 flex-row">
                <div className="bg-white">
                  <div className="border-2 bg-[rgba(14,120,8,.2)] border-[rgb(14,120,8)] w-4 h-4" />
                </div>
                <span className="text-base font-semibold">Proposal Outline & Compliance Matrix</span>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-sm text-gray-500">
                  The text block is tracked as a requirement in both the proposal outline and the compliance matrix.
                </div>
                <div className="text-sm text-gray-500">
                  Selected results will be added to the <strong>requirements and drafts tab</strong> once complete.
                </div>
                <div>
                  <span className="font-semibold text-sm">How to Assign</span>
                  <div className="text-gray-500 text-sm">
                    Click on the text block or highlight to select it. Navigate and hover over the target location in
                    the far-right panel, then click it to insert the item into the table.
                    <div className="italic mt-1">
                      Tip: Shift + click allows you to select multiple text blocks at a time.
                    </div>
                  </div>
                </div>
                <div>
                  <span className="font-semibold text-sm">When to use</span>
                  <div className="text-gray-500 text-sm">
                    Ideal for requirements that demand a direct response within the proposal.
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-2" />
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-3 flex-row">
                <div className="bg-white">
                  <div className="border-2 border-action bg-action/20 w-4 h-4" />
                </div>
                <span className="text-base font-semibold">Compliance Matrix Only</span>
              </div>
              <div className="flex flex-col gap-2 mb-2">
                <div className="text-sm text-gray-500">
                  The text block is tracked solely as a requirement in the compliance matrix and will be imported
                  directly into the requirements tab for management and export.
                </div>
                <div className="text-sm text-gray-500">
                  Selected results will be added to the <strong>requirements tab</strong> only once complete.
                </div>
                <div>
                  <span className="font-semibold text-sm">How to use</span>
                  <div className="text-gray-500 text-sm">
                    Click on the text block or highlight to select it. In the bulk action bar at the bottom of the
                    screen, click the “Compliance Matrix” button.{" "}
                  </div>
                </div>
                <div>
                  <span className="font-semibold text-sm">When to use</span>
                  <div className="text-gray-500 text-sm">
                    Ideal for requirements that need tracking but do not require a direct response within the proposal.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-5 w-full">
            <Button onClick={() => props.onOpenChange?.(false)} variant="primaryBlack" size="md" className="w-full">
              Understood
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default HelpModal;
