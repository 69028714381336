import { Spinner } from "utils/icons";

const CSmButton = ({ title, onClick, type, isTheme, className, loading, disabled, style }) => {
  const buttonClass = isTheme
    ? "bg-action disabled:opacity-50 py-2 px-4 flex gap-1 items-center text-white rounded-lg border-none"
    : "border-gray-300 border px-4 py-2 rounded-lg";

  return (
    <button
      className={`${buttonClass} ${className}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
      style={disabled ? styles.disabledStyles : { ...style }}
    >
      {loading ? <Spinner width="18" height="18" /> : title}
    </button>
  );
};

const styles = {
  disabledStyles: {
    background: "#EFEFEF",
    color: "#DBDCDE",
  },
};

export default CSmButton;
