/** @jsxImportSource @emotion/react */

import { EditorContent, useEditor } from "@tiptap/react";
import { getCommentEditorExtensions } from "./CommentExtensions";
import { Button } from "components/yjs-editor/primitives/Button";
import { useEffect, useMemo, useRef, useState } from "react";
import { SendHorizontal, X } from "lucide-react";
import classNames from "classnames";
import tw from "twin.macro";

interface CommentEditorProps {
  readonly: boolean;
  placeholder?: string;
  content?: string;
  onAddComment?: (comment: string) => boolean | void;
  showCancel?: boolean;
  onCancel?: () => void;
  autofocus?: boolean;
}
export const CommentEditor = ({
  readonly,
  onAddComment,
  content,
  showCancel,
  onCancel,
  autofocus,
  placeholder = "Write a comment...",
}: CommentEditorProps) => {
  const commentResponseWrapperRef = useRef<HTMLDivElement | null>(null);
  const [showFullComment, setShowFullComment] = useState(false);

  const showVisibilityControls =
    readonly &&
    commentResponseWrapperRef?.current?.offsetHeight &&
    commentResponseWrapperRef?.current?.offsetHeight > 200;

  const extensions = useMemo(() => getCommentEditorExtensions(placeholder), [placeholder]);
  const editor = useEditor({
    extensions,
    autofocus: !!autofocus,
    editable: !readonly,
    editorProps: {
      attributes: {
        class: "focus:outline-none",
      },
      handleKeyDown: (view: any, event: KeyboardEvent) => {
        if (event.key === "Enter" && !event.shiftKey) {
          const editorContent = view.state.doc.textContent.trim();
          if (!editorContent) {
            event.preventDefault();
            return true;
          }
          if (onAddComment) {
            event.preventDefault();
            const clear = onAddComment(editorContent);
            if (clear) {
              view.dispatch(view.state.tr.delete(0, view.state.doc.content.size));
            }
            return true;
          }
        }
      },
    },
    content,
  });

  useEffect(() => {
    if (!editor) return;
    if (autofocus) editor.commands.focus();
  }, [editor]);

  useEffect(() => {
    if (!editor) return;
    editor.setEditable(!readonly);
  }, [editor, readonly]);

  useEffect(() => {
    if (!editor) return;
    editor.commands.setContent(content || "");
  }, [content]);

  if (!editor) return null;

  return (
    <div className="flex flex-row gap-2 items-center justify-between w-full">
      <div className="flex-1 min-w-0" ref={commentResponseWrapperRef}>
        <EditorContent
          editor={editor}
          className="w-full break-words text-sm"
          readOnly={readonly}
          css={[!showFullComment && showVisibilityControls && tw`line-clamp-10`]}
        />
        {showVisibilityControls && (
          <button
            onClick={(event) => {
              event.preventDefault();
              setShowFullComment((prev) => !prev);
            }}
            className="text-xs mt-2 flex self-start text-slate-500 duration-100 hover:text-slate-900"
          >
            {showFullComment ? "Show less..." : "Show more..."}
          </button>
        )}
      </div>
      <div className="flex flex-row gap-1.5 flex-shrink-0 self-end">
        {!readonly && showCancel && (
          <Button
            variant="secondary"
            onClick={() => {
              editor.commands.setContent(content || "");
              onCancel?.();
            }}
            className="rounded h-7 w-7 flex items-center justify-center"
          >
            <X size={16} />
          </Button>
        )}
        {!readonly && (
          <Button
            variant="primary"
            onClick={async () => {
              if (editor.isEmpty || editor.getText().trim() === "") {
                editor.commands.setContent("");
                editor.commands.focus();
                return;
              }
              if (!onAddComment) return;
              const editorContent = editor.getText().trim();
              if (editorContent) {
                const clear = onAddComment(editor.getHTML());
                if (clear) editor.commands.setContent("");
              }
            }}
            className={classNames(
              "rounded h-7 w-7 flex items-center justify-center",
              !showCancel && !editor.isFocused && "opacity-0",
              editor.isEmpty && "bg-gray-300 hover:opacity-100",
            )}
          >
            <SendHorizontal size={16} />
          </Button>
        )}
      </div>
    </div>
  );
};
