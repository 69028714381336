import { signIn } from "api/api";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loading from "./Loading";
import { validateEmail } from "utils/helpers";
import { useNotification } from "context/notificationContext";

// -------------------- Login screen ----------------------
const LoginPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [emailVal, setEmailVal] = useState(searchParams.get("email") || "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { setToast } = useNotification();

  // Handle change input
  const handleChange = (e) => {
    setEmailVal(e.target.value);
    setError(false);
  };

  // Call logged in method API
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(emailVal) || error) {
      setError(!validateEmail(e.target.value));
      return;
    }
    setLoading(true);
    setError(false);

    const synthetic_test_secret = localStorage.getItem("synthetic_test_secret");
    const headers = synthetic_test_secret ? { "X-Synthetic-Test-User-Token": synthetic_test_secret } : {};
    signIn({ email: emailVal }, headers)
      .then((res) => {
        setLoading(false);
        const user_token = res?.data.user_token;
        if (user_token) {
          navigate("/auth/redirect?token=" + user_token);
        } else {
          navigate("/auth/confirm-email?email=" + emailVal);
        }
        setEmailVal("");
      })
      .catch((err) => {
        const errType = err?.response?.status;
        switch (errType) {
          case 401:
            setToast.warning({
              msg: "Email not found. Please contact sales@vultron.ai to sign up.",
            });
            break;
          default:
            setToast.error({
              title: "Authentication failed",
              msg: "We were unable to authenticate the login due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
            });
        }
        setLoading(false);
      });
  };

  //
  if (loading) return <Loading />;
  return (
    <div className="">
      <div className="flex flex-col items-center justify-center font-primary-font">
        <h1 className="text-4xl font-medium py-1">Welcome to Vultron</h1>
        <p className="text-gray-600 py-2">Sign up or login with your work email.</p>
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-start justify-start pt-6 font-primary-font w-[450px]"
      >
        <label className="text-gray-500 pb-2">Enter your work email</label>
        <input
          type="email"
          placeholder="Email"
          autoFocus
          className={`${
            error ? "border-red-500 focus:border-red-500 text-red-500" : "border-[#CCCCCC] focus:border-midnight-900"
          } border-2 peer: rounded-lg p-3 w-full focus:outline-none`}
          value={emailVal}
          onChange={handleChange}
          // onBlur={() => setError(!validateEmail(emailVal))}
        />
        {error ? <p className="text-red-500 mt-1 mb-2 block text-[14px]">Email is not valid</p> : null}
        <button
          type="submit"
          className="bg-midnight-900 hover:bg-midnight-700 text-white justify-center flex my-2 w-full flex-col rounded-lg p-3 items-center text-base font-medium min-h-[60px]"
        >
          Sign in
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
