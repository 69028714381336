export interface DocumentMargin {
  // Margins are in inches
  top: number;
  bottom: number;
  left: number;
  right: number;
}

export enum DefaultMarginOptions {
  Normal = "Normal",
  Narrow = "Narrow",
  Moderate = "Moderate",
  Wide = "Wide",
}

export const DOCUMENT_MARGIN_NODE = "documentmargin";

export const margins: Record<DefaultMarginOptions, DocumentMargin> = {
  Normal: {
    top: 1,
    bottom: 1,
    left: 1,
    right: 1,
  },
  Narrow: {
    top: 0.5,
    bottom: 0.5,
    left: 0.5,
    right: 0.5,
  },
  Moderate: {
    top: 1,
    bottom: 1,
    left: 0.75,
    right: 0.75,
  },
  Wide: {
    top: 1,
    bottom: 1,
    left: 1.25,
    right: 1.25,
  },
};
