import GenerationLottie from "Assets/lotties/generation-lottie.json";
import Lottie from "lottie-react";
import { cancelInstantDraft } from "api/api";
import { INSTANT_DRAFT_LOADING_MSGS } from "./constants";
import { setIsCanceled } from "store/reducers/extract/CurrentExtractionReducer";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";
import { useAnimateLoadingMsg } from "hook/useAnimateLoadingMsg";
import { useFlags } from "hook/useFlags";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useMemo, useState } from "react";

const InstantDraftLoading = () => {
  const [searchParams] = useSearchParams();
  const flags = useFlags();
  const dispatch = useAppDispatch();
  const [loadingStep, setLoadingStep] = useState(INSTANT_DRAFT_LOADING_MSGS[0]);
  useAnimateLoadingMsg(true, 15000, INSTANT_DRAFT_LOADING_MSGS, setLoadingStep);

  const extractionId = useAppSelector((store) => store.currentExtractionState.currentExtraction?.id);
  const isCanceled = useAppSelector((store) => store.currentExtractionState.isCanceled);
  const projectId = searchParams.get("id")?.toLocaleLowerCase();
  const requirementResponseTasks = useAppSelector((state) => state.autopilotHealthCheck.requirement_response_tasks);

  const instantDraftTask = useMemo(() => {
    return requirementResponseTasks.find((task) => {
      return task.reference_id === extractionId;
    });
  }, [extractionId, requirementResponseTasks]);

  const progressPercentage = useMemo(() => {
    if (
      typeof instantDraftTask?.completed_responses === "number" &&
      typeof instantDraftTask?.total_responses === "number"
    ) {
      return (instantDraftTask?.completed_responses / instantDraftTask?.total_responses) * 100;
    }
    return 0;
  }, [instantDraftTask]);

  const handleCancelGeneration = () => {
    triggerConfirm({
      header: "Are you sure you want to stop the instant draft generation?",
      body: "Stopping the generation will halt the instant draft process. The responses generated up to this point will be retained, but no additional responses will be generated.",
      proceedLabel: "Yes, Stop Generation",
    }).then((proceed) => {
      if (proceed && projectId && extractionId) {
        cancelInstantDraft(projectId, extractionId);
        dispatch(setIsCanceled(true));
      }
    });
  };

  return (
    <div className="h-full w-full gap-8 flex flex-col justify-center items-center px-14 pb-6">
      <div className="flex flex-col items-center">
        <Lottie className="h-[350px] w-[350px]" animationData={GenerationLottie} loop />
        <div className="text-xl font-medium">
          {isCanceled ? "Stopping Generation" : <>{loadingStep}</>}
          <span className="loading-ellipsis" />
        </div>
      </div>
      {!isCanceled && (
        <>
          <div className="bg-gray-200 relative overflow-hidden rounded h-1 w-1/3">
            <div
              className="absolute duration-700 rounded-full left-0 top-0 bottom-0 bg-gray-darkest"
              style={{ width: `${progressPercentage || 2}%` }}
            />
          </div>
          <div className="flex flex-col gap-4">
            <div className="text-gray-500 text-sm text-center">
              {typeof instantDraftTask?.minutes_time_remaining === "number" ? (
                `Generating the draft will take about ${instantDraftTask?.minutes_time_remaining || 2} minutes`
              ) : (
                <>
                  Estimating time remaining
                  <span className="loading-ellipsis" />
                </>
              )}
            </div>
            <div className="text-gray-500 text-sm text-center">
              Use this time to grab a coffee, relax, or start another task. <br />
              Your generation will continue running in the background even if you leave this page.
            </div>
          </div>
          {flags.enableOnsiteFeatures && (
            <button className="px-3 py-1.5 rounded-md bg-gray-900 text-white text-xs" onClick={handleCancelGeneration}>
              Cancel Generation
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default InstantDraftLoading;
