import { Section, ComplianceMatrixRow } from "../CopilotSchemaImmutableTypes";

export type PrefixType = "drawer:unassigned:" | "drawer:assigned:" | "drawer:disregarded:";

export enum TabSlug {
  Unassigned = "unassigned",
  Assigned = "assigned",
  Disregarded = "disregarded",
}

export enum DroppableId {
  RequirementDrawer = "REQUIREMENTS_DRAWER",
}

export interface GroupedComplianceMatrixRow extends ComplianceMatrixRow {
  proposal_reference: ComplianceMatrixRow["proposal_reference"] & {
    volumeName?: string;
    sectionName?: string;
  };
}

export type GroupedRequirement = {
  groupedSections: Record<string, Section>;
  [TabSlug.Unassigned]: GroupedComplianceMatrixRow[];
  [TabSlug.Assigned]: {
    volumes: Record<string, GroupedComplianceMatrixRow[]>;
    sections: Record<string, GroupedComplianceMatrixRow[]>;
    all: GroupedComplianceMatrixRow[];
  };
  [TabSlug.Disregarded]: GroupedComplianceMatrixRow[];
};
