import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { CopilotPresencePage } from "types/Presence";

export const useBuildDraftLinks = () => {
  const [searchParams] = useSearchParams();

  const buildSectionLink = useCallback(
    (volumeId?: string, sectionId?: string) => {
      if (!volumeId || !sectionId)
        return { path: window.location.href, link: window.location.pathname + window.location.search };

      const newSearchParams = new URLSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        tab: CopilotPresencePage.Template,
      });
      const directionPath = `/dashboard/contracts/details/volumes/${volumeId}/sections/${sectionId}/requirements?${newSearchParams.toString()}`;
      return { path: directionPath, link: `${window.location.origin}${directionPath}` };
    },
    [searchParams],
  );

  return { buildSectionLink };
};
