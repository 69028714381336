import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { uniqBy } from "lodash";
import { AIAssistantSession } from "utils/yjs-configs/ai-assistant/schema";
import { ToImmutable } from "YJSProvider/LiveObjects";

export type FileMention = {
  id: string;
  name: string;
  fileExtensionType: string;
};

export type State = {
  sessionEditable: string;
  autoscroll: boolean;
  activeSession?: ToImmutable<AIAssistantSession>;
  prompt: string;
  streamState: {
    blockId: string | null;
    isStreamingInProgress: boolean;
    streamCopy: string;
  };
  selectedFiles: FileMention[];
  enableInternet: boolean;
};

const initialState: State = {
  sessionEditable: "",
  autoscroll: true,
  activeSession: undefined,
  prompt: "",
  streamState: {
    blockId: null,
    isStreamingInProgress: false,
    streamCopy: "",
  },
  selectedFiles: [],
  enableInternet: false,
};

const aiAssistantReducer = createSlice({
  name: "aiAssistant",
  initialState,
  reducers: {
    resetAssistantState: () => {
      return initialState;
    },
    setAiAssistantState: (state: State, action: PayloadAction<Partial<State>>) => {
      return { ...state, ...action.payload };
    },
    setAssistantPrompt: (state: State, action: PayloadAction<State["prompt"]>) => {
      state.prompt = action.payload;
    },
    insertAssistantFiles: (state: State, action: PayloadAction<State["selectedFiles"]>) => {
      state.selectedFiles = uniqBy([...state.selectedFiles, ...action.payload], "id");
      state.enableInternet = false;
    },
    removeAssistantFiles: (state: State, action: PayloadAction<FileMention["id"][]>) => {
      state.selectedFiles = state.selectedFiles.filter(({ id }) => !action.payload.includes(id));
    },
    removeAllAssistantFiles: (state: State) => {
      state.selectedFiles = [];
    },
    setToggleInternet: (state: State) => {
      if (!state.enableInternet) state.selectedFiles = [];
      state.enableInternet = !state.enableInternet;
    },
    setStreamingState: (state: State, action: PayloadAction<Partial<State["streamState"]>>) => {
      const reset = !Object.keys(action.payload).length;
      if (reset) {
        state.streamState = initialState.streamState;
      } else {
        state.streamState = { ...state.streamState, ...action.payload };
      }
    },
  },
});

export const {
  setAiAssistantState,
  setStreamingState,
  removeAllAssistantFiles,
  resetAssistantState,
  setAssistantPrompt,
  insertAssistantFiles,
  removeAssistantFiles,
  setToggleInternet,
} = aiAssistantReducer.actions;

export default aiAssistantReducer.reducer;
