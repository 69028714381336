import { ResponseSpeed } from "./types";
import { LucideIcon, Wand, Zap } from "lucide-react";
import { isLaunchDarklyEnabled } from "config/vultronConfig";

export const SPEED_TO_META: Record<
  ResponseSpeed,
  { icon: LucideIcon; value: ResponseSpeed; label: string; description: string }
> = {
  [ResponseSpeed.Speed]: {
    icon: Zap,
    value: ResponseSpeed.Speed,
    label: "Speed Optimized",
    description: isLaunchDarklyEnabled
      ? "This option is optimized for generation speed and will take approximately 1 minute to complete."
      : "This option is optimized for generation speed and will take approximately 2 minutes to complete.",
  },
  [ResponseSpeed.Quality]: {
    icon: Wand,
    value: ResponseSpeed.Quality,
    label: "Detail Optimized",
    description: isLaunchDarklyEnabled
      ? "This option is optimized for detailed responses and will take 2-3 minutes to complete."
      : "This option is optimized for detailed responses and will take 3-5 minutes to complete.",
  },
};
