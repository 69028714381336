import { RelevanceScore, ResponseTolerance } from "types/Requirement";
import { FilterOption } from "./utils";
import { ReactComponent as Stars } from "Assets/stars.svg";
import { ReactComponent as Atom } from "Assets/atom.svg";
import { ReactComponent as Diamond } from "Assets/diamond.svg";
import { ReactComponent as High } from "Assets/requirements/high-relevance.svg";
import { ReactComponent as Medium } from "Assets/requirements/medium-relevance.svg";
import { ReactComponent as Low } from "Assets/requirements/low-relevance.svg";
import { ReactComponent as Adequate } from "Assets/requirements/adequate-score.svg";
import { ReactComponent as Improve } from "Assets/requirements/improve-score.svg";
import { ReactComponent as Improve2 } from "Assets/requirements/improve2-score.svg";
import { ReactComponent as EmptyScore } from "Assets/requirements/empty-score.svg";
import { EMPTY_VALUE } from "const-values/Copilot";

export enum SortKey {
  RequirementText = "requirementText",
  RequirementStatus = "requirement_status",
  ComplianceStatus = "compliance_status",
  ProposalVolume = "proposalVolume",
  ProposalSection = "proposalSection",
}

export enum Direction {
  Descending = "descending",
  Ascending = "ascending",
}

export const DEFAULT_DOC = [{ id: null, file_name: "No Document" }];

export const RESPONSE_TOLERANCE_OPTIONS = [
  {
    name: "Flexible",
    icon: Stars,
    value: ResponseTolerance.Flexible,
    text: "Vultron will always generate a response even when no relevant content was found.",
  },
  {
    name: "Moderate",
    icon: Atom,
    value: ResponseTolerance.Moderate,
    text: "Vultron will attempt to generate a response from any relevant content found.",
  },
  {
    name: "Strict",
    icon: Diamond,
    value: ResponseTolerance.Strict,
    text: "Vultron will only generate a response from directly relevant content.",
  },
];

export enum ColumnOption {
  "Assignees" = "Assignees",
  "Status" = "Status",
  "Compliance" = "Compliance",
  "ProposalVolume" = "Proposal Volume",
  "ProposalSection" = "Proposal Section",
}

export enum ResponseColumnOption {
  "Response" = "Response",
  "Sources" = "Sources",
  "Relevance" = "Relevancy",
  "Score" = "Score",
}

export const COLUMN_OPTION_TO_FILTER_KEY = {
  [ColumnOption.Assignees]: FilterOption.Assignees,
  [ColumnOption.Status]: FilterOption.Status,
  [ColumnOption.Compliance]: FilterOption.Compliance,
  [ColumnOption.ProposalVolume]: FilterOption.ProposalVolume,
  [ColumnOption.ProposalSection]: FilterOption.ProposalSection,
};

export const COLUMN_OPTION_TO_EXPORT_KEY = {
  [ColumnOption.Assignees]: "assignees",
  [ColumnOption.Status]: "status",
  [ColumnOption.Compliance]: "compliance",
  [ColumnOption.ProposalVolume]: "proposal_volume",
  [ColumnOption.ProposalSection]: "proposal_section",
  [ResponseColumnOption.Response]: "response",
  [ResponseColumnOption.Relevance]: "relevance",
  [ResponseColumnOption.Sources]: "sources",
  [ResponseColumnOption.Score]: "score",
};

export const SORT_KEY_TO_TEXT = {
  [SortKey.ComplianceStatus]: "Compliance",
  [SortKey.RequirementStatus]: "Status",
  [SortKey.ProposalSection]: "Section",
  [SortKey.ProposalVolume]: "Volume",
  [SortKey.RequirementText]: "Requirement",
};

export const RELEVANCE_TO_META = {
  [RelevanceScore.Low]: {
    icon: Low,
    label: "Low",
  },
  [RelevanceScore.Medium]: {
    icon: Medium,
    label: "Medium",
  },
  [RelevanceScore.High]: {
    icon: High,
    label: "High",
  },
};

export const SCORE_TO_META: Record<number, { icon: SVGSVGElement; label: string }> = {
  0: {
    icon: EmptyScore,
    label: EMPTY_VALUE,
  },
  1: {
    icon: Improve,
    label: "Improve",
  },
  2: {
    icon: Improve2,
    label: "Improve",
  },
  3: {
    icon: Adequate,
    label: "Adequate",
  },
};
