/** @jsxImportSource @emotion/react */

import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import { ComponentProps, ReactNode, useMemo, useState } from "react";
import { styles } from "./styles";
import tw from "twin.macro";
import classNames from "classnames";
import Icon from "components/atoms/icons/Icon";
import { Checkbox } from "components/atoms/checkbox";
import { CSSObject, Interpolation, Theme } from "@emotion/react";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { Search } from "lucide-react";
import React from "react";

export type MenuItem<T> = {
  key: number | string;
  label: ReactNode;
  onSelect?: (value?: T, e?: Event) => void;
  value?: T;
  searchValue?: string;
  selected?: boolean;
  preventAutoClose?: boolean;
  disabled?: boolean;
  style?: CSSObject;
  tooltipProps?: ComponentProps<typeof Tooltip>;
  subs?: ReactNode;
  isBordered?: boolean;
};

export type MultiselectMenuItem<T> = {
  key: number | string;
  label: ReactNode;
  value: T;
  searchValue?: string;
  checked?: boolean;
  onCheck: (checked: boolean, value: T) => void;
};

export type StaticMenuItemNode = {
  key: number | string;
  node: ReactNode;
  searchValue?: string;
};

type DropdownMenuProps<T> =
  | {
      items: (MultiselectMenuItem<T> | StaticMenuItemNode)[];
      multiselect: true;
      subs?: never;
    }
  | {
      items: (MenuItem<T> | StaticMenuItemNode)[]; // Update the type of searchValue
      multiselect?: false;
    };

export type Props<T> = {
  empty?: ReactNode;
  label?: ReactNode;
  contentProps?: RadixDropdownMenu.DropdownMenuContentProps & { css?: Interpolation<Theme> };
  triggerProps?: RadixDropdownMenu.DropdownMenuTriggerProps & { css?: Interpolation<Theme> };
  portalProps?: RadixDropdownMenu.DropdownMenuPortalProps;
  disabled?: boolean;
  searchable?: boolean;
  searchPlaceholder?: string;
} & RadixDropdownMenu.DropdownMenuProps &
  DropdownMenuProps<T>;

const DropdownMenu = <T,>({
  empty,
  children,
  label,
  items,
  contentProps,
  triggerProps,
  portalProps,
  disabled,
  multiselect,
  searchable,
  searchPlaceholder,
  ...props
}: Props<T>) => {
  const [query, setQuery] = useState("");

  const filteredItems = useMemo(() => {
    if (!searchable || !query.trim().length) return items;

    return items.filter(
      ({ searchValue }: { searchValue?: string }) =>
        !!searchValue?.trim()?.toLowerCase()?.startsWith(query?.trim()?.toLowerCase()),
    );
  }, [items, query, searchable]);

  return (
    <RadixDropdownMenu.Root
      modal={false}
      {...props}
      onOpenChange={(o) => {
        if (!o) setQuery("");
        props.onOpenChange?.(o);
      }}
    >
      <RadixDropdownMenu.Trigger
        {...triggerProps}
        disabled={disabled}
        className={classNames([styles.trigger, triggerProps?.className])}
      >
        {children}
      </RadixDropdownMenu.Trigger>
      {!disabled && (
        <RadixDropdownMenu.Portal {...portalProps}>
          <RadixDropdownMenu.Content className={styles.content} align="end" sideOffset={4} {...contentProps}>
            {label && (
              <RadixDropdownMenu.Label className="text-slate-500 text-xxs font-medium py-0.5 px-1">
                {label}
              </RadixDropdownMenu.Label>
            )}
            {searchable && (
              <div
                className="px-2 -mx-1 gap-1.5 flex flex-row items-center -mt-1 border-b border-b-gray-light"
                css={[label && tw`border-t border-t-gray-light mt-0`]}
              >
                <Search size={14} className="text-gray-400" />
                <input
                  autoFocus
                  className="flex-1 outline-none py-3 text-sm text-gray-600"
                  placeholder={searchPlaceholder || "Search..."}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            )}
            {!filteredItems.length &&
              (empty || (
                <div className="min-h-[150px] text-xs text-gray-400 w-full flex justify-center items-center">
                  Nothing found
                </div>
              ))}
            {multiselect &&
              (filteredItems as MultiselectMenuItem<T>[]).map((item: StaticMenuItemNode | MultiselectMenuItem<T>) => {
                if ("node" in item) {
                  const staticItem = item as StaticMenuItemNode;

                  return <React.Fragment key={staticItem.key}>{staticItem.node}</React.Fragment>;
                }

                return (
                  <RadixDropdownMenu.CheckboxItem
                    key={item.key}
                    className={styles.checkboxItem}
                    css={{ wordBreak: "break-word" }}
                    onSelect={(e) => {
                      e.preventDefault();
                    }}
                    checked={item.checked}
                    onCheckedChange={(checkedState) => {
                      item.onCheck(checkedState, item.value);
                    }}
                    onPointerLeave={(event) => event.preventDefault()}
                    onPointerMove={(event) => event.preventDefault()}
                  >
                    <Checkbox checked={item.checked} onCheck={() => {}} /> {item.label}
                  </RadixDropdownMenu.CheckboxItem>
                );
              })}
            {!multiselect &&
              filteredItems.map((item: StaticMenuItemNode | MenuItem<T>) => {
                if ("node" in item) {
                  const staticItem = item as StaticMenuItemNode;

                  return <React.Fragment key={staticItem.key}>{staticItem.node}</React.Fragment>;
                } else {
                  const menuItem = item as MenuItem<T>;

                  if (menuItem.subs) {
                    return menuItem.subs;
                  }

                  if (menuItem.tooltipProps) {
                    return (
                      <Tooltip key={menuItem.key} {...menuItem.tooltipProps}>
                        <RadixDropdownMenu.Item
                          className={styles.item}
                          css={[
                            { wordBreak: "break-word" },
                            menuItem.style,
                            menuItem.disabled && tw`cursor-default opacity-40 !bg-transparent`,
                          ]}
                          onSelect={(e) => {
                            if (menuItem.disabled) return;
                            if (menuItem.preventAutoClose) e.preventDefault();
                            if (menuItem.onSelect) menuItem.onSelect(menuItem.value, e);
                          }}
                          onPointerLeave={(event) => event.preventDefault()}
                          onPointerMove={(event) => event.preventDefault()}
                        >
                          {menuItem.label} {menuItem.selected && <Icon name="Check" className="w-5 h-5 min-w-[20px]" />}
                        </RadixDropdownMenu.Item>
                      </Tooltip>
                    );
                  }

                  return (
                    <RadixDropdownMenu.Item
                      key={menuItem.key}
                      className={styles.item}
                      css={[
                        { wordBreak: "break-word" },
                        menuItem.disabled && tw`pointer-events-none cursor-not-allowed opacity-40`,
                        menuItem.style,
                      ]}
                      onSelect={(e) => {
                        if (menuItem.disabled) return;
                        if (menuItem.preventAutoClose) e.preventDefault();
                        if (menuItem.onSelect) menuItem.onSelect(menuItem.value, e);
                      }}
                      onPointerLeave={(event) => event.preventDefault()}
                      onPointerMove={(event) => event.preventDefault()}
                    >
                      {menuItem.label} {menuItem.selected && <Icon name="Check" className="w-5 h-5 min-w-[20px]" />}
                    </RadixDropdownMenu.Item>
                  );
                }
              })}
          </RadixDropdownMenu.Content>
        </RadixDropdownMenu.Portal>
      )}
    </RadixDropdownMenu.Root>
  );
};

DropdownMenu.Sub = RadixDropdownMenu.Sub;
DropdownMenu.SubTrigger = RadixDropdownMenu.SubTrigger;
DropdownMenu.Portal = RadixDropdownMenu.Portal;
DropdownMenu.SubContent = RadixDropdownMenu.SubContent;
DropdownMenu.Item = RadixDropdownMenu.Item;

export default DropdownMenu;
