import {
  RequirementCompliance,
  RequirementStatus,
  Framework,
  Volume,
  ComplianceMatrixRow,
} from "../CopilotSchemaImmutableTypes";

export const getFrameworkVolumeSectionId = (volumeId: string, sectionName: string, frameworkState?: Framework) => {
  const volumeIndex = frameworkState?.volumes?.findIndex((v) => v.id === volumeId);
  if (volumeIndex === -1 || volumeIndex === undefined) return "";

  const sectionIndex = frameworkState?.volumes[volumeIndex]?.sections?.findIndex((s) => s.title === sectionName);
  if (sectionIndex === -1 || sectionIndex === undefined) return "";

  return frameworkState?.volumes[volumeIndex]?.sections[sectionIndex]?.id;
};

export const getVolumeId = (volumeName: string, frameworkState?: Framework) => {
  const volumeIndex = frameworkState?.volumes?.findIndex((v) => v.title === volumeName);
  if (volumeIndex === -1 || volumeIndex === undefined) return "";

  return frameworkState?.volumes[volumeIndex]?.id;
};

export enum FilterOption {
  Assignees = "assignees",
  Status = "requirementStatusType",
  Compliance = "complianceType",
  Document = "documentName",
  RequirementsOnly = "requirementsOnly",
  ProposalVolume = "proposalVolume",
  ProposalSection = "proposalSection",
}

export type ActiveFilter = Partial<{
  [FilterOption.Assignees]: string;
  [FilterOption.Document]: string;
  [FilterOption.RequirementsOnly]?: boolean;
  [FilterOption.Compliance]: string;
  [FilterOption.Status]: string;
  [FilterOption.ProposalVolume]: string;
  [FilterOption.ProposalSection]: string;
}>;

export const getAllSections = (volumes?: Framework["volumes"], volumeId?: string, include_subsections?: boolean) =>
  volumes?.reduce((acc: Volume["sections"], vol: Volume) => {
    const sections = include_subsections ? vol.sections : vol.sections.filter((sec) => !sec.parent_id);
    return volumeId ? [...acc, ...(volumeId === vol.id ? sections : [])] : [...acc, ...sections];
  }, []);

const getRowVolume = (row: ComplianceMatrixRow, frameworkState?: Framework) =>
  frameworkState?.volumes?.find((v) => v.id === row?.proposal_reference?.volume_id);

export const filterOut = ({
  row,
  searchFilters,
  frameworkState,
  requirementsOnly,
}: {
  row: ComplianceMatrixRow;
  searchFilters?: ActiveFilter;
  frameworkState?: Framework;
  requirementsOnly: boolean;
}) => {
  const filterByAssignee = !searchFilters?.assignees || row?.assigned_user_ids?.includes(searchFilters?.assignees);
  const filterByDocument = !searchFilters?.documentName || row?.document?.name === searchFilters?.documentName;
  const filterByRequirementsOnly = !requirementsOnly || !!row.requirement.skipped === !requirementsOnly;
  const filterByCompliance =
    !searchFilters?.complianceType ||
    (row?.compliance_status || RequirementCompliance.Empty) === searchFilters?.complianceType;
  const filterByRequirementStatus =
    !searchFilters?.requirementStatusType ||
    (row?.requirement_status || RequirementStatus.Todo) === searchFilters?.requirementStatusType;
  const filterByVolume =
    !searchFilters?.proposalVolume || searchFilters?.proposalVolume === getRowVolume(row, frameworkState)?.title;
  const filterBySection =
    !searchFilters?.proposalSection ||
    row.proposal_reference?.section_id ===
      getRowVolume(row, frameworkState)?.sections?.find((section) => section.title === searchFilters?.proposalSection)
        ?.id;

  return (
    filterByAssignee &&
    filterByDocument &&
    filterByCompliance &&
    filterByRequirementsOnly &&
    filterByVolume &&
    filterBySection &&
    filterByRequirementStatus
  );
};
