import { createRestoreThreadPlugin } from "./RestoreThreadPlugin";
import { findMarksInRange, getHTMLBetween } from "./utils";
import { v4 } from "uuid";
import { YJSProvider } from "YJSProvider/YJSProvider";
import { CommentsPlugin, commentMetaKey } from "./CommentsDraftPlugin";
import { Editor, Extension } from "@tiptap/react";
import { CommentsPluginHighlight, commentPluginHighlightKey } from "./CommentsPluginHighlight";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    CommentsMark: {
      setDraft: () => ReturnType;
      resolveCommentMark: (id: string, resolved: boolean) => ReturnType;
      removeCommentMark: (id: string) => ReturnType;
      setCommentMark: (options?: { id?: string; resolved?: boolean }) => ReturnType;
      setActiveComment: (id?: string) => ReturnType;
      setHiddenComments: (id?: string[]) => ReturnType;
    };
  }
}

export const CommentsExtension = Extension.create<{
  provider: YJSProvider;
  removeThread: (threadID: string) => void;
  restoreThread: (threadID: string) => void;
}>({
  name: "CommentsMark",

  addOptions() {
    return {
      provider: undefined as unknown as YJSProvider,
      internalContractId: undefined,
      volumeId: undefined,
      removeThread: () => {},
      restoreThread: () => {},
    };
  },
  addCommands() {
    return {
      setHiddenComments:
        (ids) =>
        ({ editor }) => {
          editor.storage.hiddenComments = ids;
          return true;
        },
      setDraft:
        () =>
        ({ chain, dispatch, state }) => {
          const { selection } = state;
          if (!selection) return false;
          if (dispatch) {
            return chain()
              .blur()
              .command(({ tr, dispatch }) => {
                if (!dispatch) return true;
                return dispatch(
                  tr.setMeta(commentMetaKey, {
                    draftActive: true,
                    draftContent: getHTMLBetween(state.doc, {
                      from: selection.from,
                      to: selection.to,
                    }),
                  }),
                );
              })
              .run();
          }
          return true;
        },
      setCommentMark:
        (options) =>
        ({ chain, state: { selection } }) => {
          const { id = v4(), resolved = false } = options || {};
          if (!selection || selection.empty) return false;
          return chain().setMark("comment", { id, resolved }).setActiveComment(id).setMeta("addToHistory", false).run();
        },
      resolveCommentMark:
        (id, resolved) =>
        ({ state, tr, dispatch }) => {
          if (!dispatch) return true;

          const markType = state.schema.marks.comment;
          if (!markType) return false;

          let hasChanges = false;

          state.doc.descendants((node, pos) => {
            const marks = node.marks.filter((m) => m.type === markType && m.attrs.id === id);

            if (marks.length > 0) {
              const from = pos;
              const to = pos + node.nodeSize;

              marks.forEach((mark) => {
                tr.removeMark(from, to, mark);
                const newMark = markType.create({ ...mark.attrs, resolved });
                tr.addMark(from, to, newMark);
              });

              hasChanges = true;
            }
          });

          if (hasChanges) {
            tr.setMeta("addToHistory", false);
            tr.setMeta("commentResolveStateChange", true);
            dispatch(tr);
          }

          return hasChanges;
        },

      removeCommentMark:
        (id) =>
        ({ state, tr, dispatch }) => {
          if (!dispatch) return true;

          const markResults = findMarksInRange(
            state.doc,
            0,
            state.doc.nodeSize - 2,
            (m) => m.type.name === "comment" && m.attrs.id === id,
          );

          if (markResults.length === 0) return false;

          // Sort markResults by position, from end to start
          markResults.sort((a, b) => b.pos + b.node.nodeSize - (a.pos + a.node.nodeSize));

          markResults.forEach(({ pos, node, mark }) => {
            const from = pos;
            const to = pos + node.nodeSize;

            // Find all comment marks in this range
            const marksInRange = node.marks.filter((m) => m.type.name === "comment");

            // Remove the specific mark
            const newMarks = marksInRange.filter((m) => m.attrs.id !== id);

            // If there are other comment marks, preserve them
            if (newMarks.length > 0) {
              tr = tr.removeMark(from, to, mark.type).addMark(from, to, newMarks[0].type.create(newMarks[0].attrs));

              // Add back any additional marks
              for (let i = 1; i < newMarks.length; i++) {
                tr = tr.addMark(from, to, newMarks[i].type.create(newMarks[i].attrs));
              }
            } else {
              // If no other comment marks, just remove the mark
              tr = tr.removeMark(from, to, mark.type);
            }
          });

          return dispatch(tr.setMeta("addToHistory", false).setMeta("userAction", true));
        },

      setActiveComment:
        (id) =>
        ({ tr, dispatch, state }) => {
          if (!dispatch) return true; // If dispatch is false we are in a can() in that case return true
          if (id) {
            const markResults = findMarksInRange(
              state.doc,
              0,
              state.doc.nodeSize - 2,
              (m) => m.type.name === "comment" && m.attrs.id === id,
            );
            if (markResults.length > 0) {
              let start = Infinity;
              let end = -Infinity;
              markResults.forEach(({ pos, node }) => {
                start = Math.min(start, pos);
                end = Math.max(end, pos + node.nodeSize);
              });

              tr = tr
                .setMeta(commentPluginHighlightKey, {
                  activeCommentId: id,
                  activeCommentPosition: { from: start, to: end },
                })
                // If the mark isn't found, deactivate the active node
                .setMeta("addToHistory", false);
              return dispatch(tr);
            } else {
              tr = tr
                .setMeta(commentPluginHighlightKey, { activeCommentId: null, activeCommentPosition: null })
                .setMeta("addToHistory", false);
              return dispatch(tr);
            }
          } else {
            tr = tr
              .setMeta(commentPluginHighlightKey, { activeCommentId: null, activeCommentPosition: null })
              .setMeta("addToHistory", false);
            return dispatch(tr);
          }
        },
    };
  },
  addProseMirrorPlugins() {
    return [
      CommentsPlugin,
      CommentsPluginHighlight(this.editor as Editor),
      createRestoreThreadPlugin({ removeThread: this.options.removeThread, restoreThread: this.options.restoreThread }),
    ];
  },
});
