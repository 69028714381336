export const MAX_FILE_SIZE = 20000000;

export const AVAILABLE_KEYWORDS = [
  "shall",
  "will",
  "must",
  "should",
  "submit",
  "perform",
  "may",
  "can",
  "require",
  "compliant",
  "compliance",
  "comply",
  "specification",
  "criteria",
  "qualification",
  "deliverable",
  "mandatory",
  "license",
  "licensing",
  "certification",
  "certified",
  "assist",
  "acknowledge",
  "comprise",
  "contain",
  "describe",
  "designate",
  "ensure",
  "explain",
  "establish",
  "identify",
  "include",
  "necessary",
  "?",
];

export const FAR_KEYWORDS = [
  "FAR",
  "FARS",
  "1.000",
  "1.101",
  "1.102-1",
  "1.102-2",
  "1.102-3",
  "1.102-4",
  "1.102-5",
  "1.102",
  "1.103",
  "1.104",
  "1.105-1",
  "1.105-2",
  "1.105-3",
  "1.105",
  "1.106",
  "1.107",
  "1.108",
  "1.109",
  "1.110",
  "1.201-1",
  "1.201-2",
  "1.201",
  "1.202",
  "1.301",
  "1.302",
  "1.303",
  "1.304",
  "1.400",
  "1.401",
  "1.402",
  "1.403",
  "1.404",
  "1.405",
  "1.501-1",
  "1.501-2",
  "1.501-3",
  "1.501",
  "1.502",
  "1.503",
  "1.601",
  "1.602-1",
  "1.602-2",
  "1.602-3",
  "1.602",
  "1.603-1",
  "1.603-2",
  "1.603-3",
  "1.603-4",
  "1.603",
  "1.604",
  "1.700",
  "1.701",
  "1.702",
  "1.703",
  "1.704",
  "1.705",
  "1.706",
  "1.707",
  "10.000",
  "10.001",
  "10.002",
  "10.003",
  "11.000",
  "11.001",
  "11.002",
  "11.101",
  "11.102",
  "11.103",
  "11.104",
  "11.105",
  "11.106",
  "11.107",
  "11.201",
  "11.202",
  "11.203",
  "11.204",
  "11.301",
  "11.302",
  "11.303",
  "11.304",
  "11.401",
  "11.402",
  "11.403",
  "11.404",
  "11.500",
  "11.501",
  "11.502",
  "11.503",
  "11.600",
  "11.601",
  "11.602",
  "11.603",
  "11.604",
  "11.701",
  "11.702",
  "11.703",
  "11.801",
  "12.000",
  "12.001",
  "12.101",
  "12.102",
  "12.103",
  "12.201",
  "12.202",
  "12.203",
  "12.204",
  "12.205",
  "12.206",
  "12.207",
  "12.208",
  "12.209",
  "12.210",
  "12.211",
  "12.212",
  "12.213",
  "12.214",
  "12.215",
  "12.216",
  "12.300",
  "12.301",
  "12.302",
  "12.303",
  "12.401",
  "12.402",
  "12.403",
  "12.404",
  "12.500",
  "12.501",
  "12.502",
  "12.503",
  "12.504",
  "12.505",
  "12.601",
  "12.602",
  "12.603",
  "13.000",
  "13.001",
  "13.002",
  "13.003",
  "13.004",
  "13.005",
  "13.006",
  "13.101",
  "13.102",
  "13.103",
  "13.104",
  "13.105",
  "13.106-1",
  "13.106-2",
  "13.106-3",
  "13.106",
  "13.201",
  "13.202",
  "13.203",
  "13.301",
  "13.302-1",
  "13.302-2",
  "13.302-3",
  "13.302-4",
  "13.302-5",
  "13.302",
  "13.303-1",
  "13.303-2",
  "13.303-3",
  "13.303-4",
  "13.303-5",
  "13.303-6",
  "13.303-7",
  "13.303-8",
  "13.303",
  "13.304",
  "13.305-1",
  "13.305-2",
  "13.305-3",
  "13.305-4",
  "13.305",
  "13.306",
  "13.307",
  "13.401",
  "13.402",
  "13.403",
  "13.404",
  "13.500",
  "13.501",
  "14.000",
  "14.101",
  "14.102",
  "14.103-1",
  "14.103-2",
  "14.103",
  "14.104",
  "14.105",
  "14.201-1",
  "14.201-2",
  "14.201-3",
  "14.201-4",
  "14.201-5",
  "14.201-6",
  "14.201-7",
  "14.201-8",
  "14.201-9",
  "14.201",
  "14.202-1",
  "14.202-2",
  "14.202-3",
  "14.202-4",
  "14.202-5",
  "14.202-6",
  "14.202-7",
  "14.202-8",
  "14.202",
  "14.203-1",
  "14.203-2",
  "14.203-3",
  "14.203",
  "14.204",
  "14.205",
  "14.206",
  "14.207",
  "14.208",
  "14.209",
  "14.210",
  "14.211",
  "14.212",
  "14.301",
  "14.302",
  "14.303",
  "14.304",
  "14.400",
  "14.401",
  "14.402-1",
  "14.402-2",
  "14.402-3",
  "14.402",
  "14.403",
  "14.404-1",
  "14.404-2",
  "14.404-3",
  "14.404-4",
  "14.404-5",
  "14.404",
  "14.405",
  "14.406",
  "14.407-1",
  "14.407-2",
  "14.407-3",
  "14.407-4",
  "14.407",
  "14.408-1",
  "14.408-2",
  "14.408-3",
  "14.408-4",
  "14.408-5",
  "14.408-6",
  "14.408-7",
  "14.408-8",
  "14.408",
  "14.409-1",
  "14.409-2",
  "14.409",
  "14.501",
  "14.502",
  "14.503-1",
  "14.503-2",
  "14.503",
  "15.000",
  "15.001",
  "15.002",
  "15.100",
  "15.101-1",
  "15.101-2",
  "15.101-3",
  "15.101",
  "15.102",
  "15.200",
  "15.201",
  "15.202",
  "15.203",
  "15.204-1",
  "15.204-2",
  "15.204-3",
  "15.204-4",
  "15.204-5",
  "15.204",
  "15.205",
  "15.206",
  "15.207",
  "15.208",
  "15.209",
  "15.210",
  "15.300",
  "15.301",
  "15.302",
  "15.303",
  "15.304",
  "15.305",
  "15.306",
  "15.307",
  "15.308",
  "15.400",
  "15.401",
  "15.402",
  "15.403-1",
  "15.403-2",
  "15.403-3",
  "15.403-4",
  "15.403-5",
  "15.403",
  "15.404-1",
  "15.404-2",
  "15.404-3",
  "15.404-4",
  "15.404",
  "15.405",
  "15.406-1",
  "15.406-2",
  "15.406-3",
  "15.406",
  "15.407-1",
  "15.407-2",
  "15.407-3",
  "15.407-4",
  "15.407-5",
  "15.407",
  "15.408",
  "15.501",
  "15.502",
  "15.503",
  "15.504",
  "15.505",
  "15.506",
  "15.507",
  "15.508",
  "15.509",
  "15.600",
  "15.601",
  "15.602",
  "15.603",
  "15.604",
  "15.605",
  "15.606-1",
  "15.606-2",
  "15.606",
  "15.607",
  "15.608",
  "15.609",
  "16.000",
  "16.001",
  "16.101",
  "16.102",
  "16.103",
  "16.104",
  "16.105",
  "16.201",
  "16.202-1",
  "16.202-2",
  "16.202",
  "16.203-1",
  "16.203-2",
  "16.203-3",
  "16.203-4",
  "16.203",
  "16.204",
  "16.205-1",
  "16.205-2",
  "16.205-3",
  "16.205-4",
  "16.205",
  "16.206-1",
  "16.206-2",
  "16.206-3",
  "16.206-4",
  "16.206",
  "16.207-1",
  "16.207-2",
  "16.207-3",
  "16.207",
  "16.301-1",
  "16.301-2",
  "16.301-3",
  "16.301",
  "16.302",
  "16.303",
  "16.304",
  "16.305",
  "16.306",
  "16.307",
  "16.401",
  "16.402-1",
  "16.402-2",
  "16.402-3",
  "16.402-4",
  "16.402",
  "16.403-1",
  "16.403-2",
  "16.403",
  "16.404",
  "16.405-1",
  "16.405-2",
  "16.405",
  "16.406",
  "16.500",
  "16.501-1",
  "16.501-2",
  "16.501",
  "16.502",
  "16.503",
  "16.504",
  "16.505",
  "16.506",
  "16.600",
  "16.601",
  "16.602",
  "16.603-1",
  "16.603-2",
  "16.603-3",
  "16.603-4",
  "16.603",
  "16.701",
  "16.702",
  "16.703",
  "17.000",
  "17.101",
  "17.102",
  "17.103",
  "17.104",
  "17.105-1",
  "17.105-2",
  "17.105",
  "17.106-1",
  "17.106-2",
  "17.106-3",
  "17.106",
  "17.107",
  "17.108",
  "17.109",
  "17.200",
  "17.201",
  "17.202",
  "17.203",
  "17.204",
  "17.205",
  "17.206",
  "17.207",
  "17.208",
  "17.401",
  "17.402",
  "17.403",
  "17.500",
  "17.501",
  "17.502-1",
  "17.502-2",
  "17.502",
  "17.503",
  "17.504",
  "17.600",
  "17.601",
  "17.602",
  "17.603",
  "17.604",
  "17.605",
  "17.700",
  "17.701",
  "17.702",
  "17.703",
  "18.000",
  "18.001",
  "18.101",
  "18.102",
  "18.103",
  "18.104",
  "18.105",
  "18.106",
  "18.107",
  "18.108",
  "18.109",
  "18.110",
  "18.111",
  "18.112",
  "18.113",
  "18.114",
  "18.115",
  "18.116",
  "18.117",
  "18.118",
  "18.119",
  "18.120",
  "18.121",
  "18.122",
  "18.123",
  "18.124",
  "18.125",
  "18.126",
  "18.127",
  "18.201",
  "18.202",
  "18.203",
  "18.204",
  "18.205",
  "19.000",
  "19.001",
  "19.101",
  "19.102",
  "19.103",
  "19.1301",
  "19.1302",
  "19.1303",
  "19.1304",
  "19.1305",
  "19.1306",
  "19.1307",
  "19.1308",
  "19.1309",
  "19.1401",
  "19.1402",
  "19.1403",
  "19.1404",
  "19.1405",
  "19.1406",
  "19.1407",
  "19.1408",
  "19.1500",
  "19.1501",
  "19.1502",
  "19.1503",
  "19.1504",
  "19.1505",
  "19.1506",
  "19.1507",
  "19.1508",
  "19.201",
  "19.202-1",
  "19.202-2",
  "19.202-3",
  "19.202-4",
  "19.202-5",
  "19.202-6",
  "19.202",
  "19.203",
  "19.301-1",
  "19.301-2",
  "19.301-3",
  "19.301",
  "19.302",
  "19.303",
  "19.304",
  "19.305",
  "19.306",
  "19.307",
  "19.308",
  "19.309",
  "19.401",
  "19.402",
  "19.403",
  "19.501",
  "19.502-1",
  "19.502-10",
  "19.502-11",
  "19.502-2",
  "19.502-3",
  "19.502-4",
  "19.502-5",
  "19.502-6",
  "19.502-7",
  "19.502-8",
  "19.502-9",
  "19.502",
  "19.503",
  "19.504",
  "19.505",
  "19.506",
  "19.507",
  "19.601",
  "19.602-1",
  "19.602-2",
  "19.602-3",
  "19.602-4",
  "19.602",
  "19.701",
  "19.702",
  "19.703",
  "19.704",
  "19.705-1",
  "19.705-2",
  "19.705-3",
  "19.705-4",
  "19.705-5",
  "19.705-6",
  "19.705-7",
  "19.705",
  "19.706",
  "19.707",
  "19.708",
  "19.800",
  "19.801",
  "19.802",
  "19.803",
  "19.804-1",
  "19.804-2",
  "19.804-3",
  "19.804-4",
  "19.804-5",
  "19.804-6",
  "19.804",
  "19.805-1",
  "19.805-2",
  "19.805",
  "19.806",
  "19.807",
  "19.808-1",
  "19.808-2",
  "19.808",
  "19.809-1",
  "19.809-2",
  "19.809",
  "19.810",
  "19.811-1",
  "19.811-2",
  "19.811-3",
  "19.811",
  "19.812",
  "19.813",
  "19.814",
  "19.815",
  "19.816",
  "2.000",
  "2.101",
  "2.201",
  "22.000",
  "22.001",
  "22.1000",
  "22.1001",
  "22.1002-1",
  "22.1002-2",
  "22.1002-3",
  "22.1002-4",
  "22.1002-5",
  "22.1002-6",
  "22.1002",
  "22.1003-1",
  "22.1003-2",
  "22.1003-3",
  "22.1003-4",
  "22.1003-5",
  "22.1003-6",
  "22.1003-7",
  "22.1003",
  "22.1004",
  "22.1005",
  "22.1006",
  "22.1007",
  "22.1008-1",
  "22.1008-2",
  "22.1008",
  "22.1009-1",
  "22.1009-2",
  "22.1009-3",
  "22.1009-4",
  "22.1009",
  "22.101-1",
  "22.101-2",
  "22.101-3",
  "22.101-4",
  "22.101",
  "22.1010",
  "22.1011",
  "22.1012-1",
  "22.1012-2",
  "22.1012",
  "22.1013",
  "22.1014",
  "22.1015",
  "22.1016",
  "22.1017",
  "22.1018",
  "22.1019",
  "22.102-1",
  "22.102-2",
  "22.102",
  "22.1020",
  "22.1021",
  "22.1022",
  "22.1023",
  "22.1024",
  "22.1025",
  "22.1026",
  "22.103-1",
  "22.103-2",
  "22.103-3",
  "22.103-4",
  "22.103-5",
  "22.103",
  "22.1101",
  "22.1102",
  "22.1103",
  "22.1300",
  "22.1301",
  "22.1302",
  "22.1303",
  "22.1304",
  "22.1305",
  "22.1306",
  "22.1307",
  "22.1308",
  "22.1309",
  "22.1310",
  "22.1400",
  "22.1401",
  "22.1402",
  "22.1403",
  "22.1404",
  "22.1405",
  "22.1406",
  "22.1407",
  "22.1408",
  "22.1500",
  "22.1501",
  "22.1502",
  "22.1503",
  "22.1504",
  "22.1505",
  "22.1600",
  "22.1601",
  "22.1602",
  "22.1603",
  "22.1604",
  "22.1605",
  "22.1700",
  "22.1701",
  "22.1702",
  "22.1703",
  "22.1704",
  "22.1705",
  "22.1800",
  "22.1801",
  "22.1802",
  "22.1803",
  "22.1900",
  "22.1901",
  "22.1902",
  "22.1903",
  "22.1904",
  "22.1905",
  "22.1906",
  "22.201",
  "22.202",
  "22.2100",
  "22.2101",
  "22.2102",
  "22.2103",
  "22.2104",
  "22.2105",
  "22.2106",
  "22.2107",
  "22.2108",
  "22.2109",
  "22.2110",
  "22.300",
  "22.301",
  "22.302",
  "22.303",
  "22.304",
  "22.305",
  "22.400",
  "22.401",
  "22.402",
  "22.403-1",
  "22.403-2",
  "22.403-3",
  "22.403-4",
  "22.403-5",
  "22.403-6",
  "22.403",
  "22.404-1",
  "22.404-10",
  "22.404-11",
  "22.404-12",
  "22.404-2",
  "22.404-3",
  "22.404-4",
  "22.404-5",
  "22.404-6",
  "22.404-7",
  "22.404-8",
  "22.404-9",
  "22.404",
  "22.405",
  "22.406-1",
  "22.406-10",
  "22.406-11",
  "22.406-12",
  "22.406-13",
  "22.406-2",
  "22.406-3",
  "22.406-4",
  "22.406-5",
  "22.406-6",
  "22.406-7",
  "22.406-8",
  "22.406-9",
  "22.406",
  "22.407",
  "22.501",
  "22.502",
  "22.503",
  "22.504",
  "22.505",
  "22.601",
  "22.602",
  "22.603",
  "22.604-1",
  "22.604-2",
  "22.604",
  "22.605",
  "22.606",
  "22.607",
  "22.608",
  "22.609",
  "22.610",
  "22.800",
  "22.801",
  "22.802",
  "22.803",
  "22.804-1",
  "22.804-2",
  "22.804",
  "22.805",
  "22.806",
  "22.807",
  "22.808",
  "22.809",
  "22.810",
  "22.901",
  "22.902",
  "23.000",
  "23.001",
  "23.002",
  "23.1000",
  "23.1001",
  "23.1002",
  "23.1003",
  "23.1004",
  "23.1005",
  "23.101",
  "23.102",
  "23.103",
  "23.104",
  "23.105",
  "23.1101",
  "23.1102",
  "23.1103",
  "23.1104",
  "23.1105",
  "23.200",
  "23.201",
  "23.202",
  "23.203",
  "23.204",
  "23.205",
  "23.206",
  "23.300",
  "23.301",
  "23.302",
  "23.303",
  "23.400",
  "23.401",
  "23.402",
  "23.403",
  "23.404",
  "23.405",
  "23.406",
  "23.500",
  "23.501",
  "23.502",
  "23.503",
  "23.504",
  "23.505",
  "23.506",
  "23.601",
  "23.602",
  "23.700",
  "23.701",
  "23.702",
  "23.703",
  "23.704",
  "23.705",
  "23.800",
  "23.801",
  "23.802",
  "23.803",
  "23.804",
  "23.900",
  "23.901",
  "23.902",
  "23.903",
  "24.000",
  "24.101",
  "24.102",
  "24.103",
  "24.104",
  "24.201",
  "24.202",
  "24.203",
  "24.301",
  "24.302",
  "25.000",
  "25.001",
  "25.002",
  "25.003",
  "25.100",
  "25.1001",
  "25.1002",
  "25.1003",
  "25.101",
  "25.102",
  "25.103",
  "25.104",
  "25.105",
  "25.106",
  "25.1101",
  "25.1102",
  "25.1103",
  "25.200",
  "25.201",
  "25.202",
  "25.203",
  "25.204",
  "25.205",
  "25.206",
  "25.301-1",
  "25.301-2",
  "25.301-3",
  "25.301-4",
  "25.301",
  "25.302-1",
  "25.302-2",
  "25.302-3",
  "25.302-4",
  "25.302-5",
  "25.302-6",
  "25.302",
  "25.400",
  "25.401",
  "25.402",
  "25.403",
  "25.404",
  "25.405",
  "25.406",
  "25.407",
  "25.408",
  "25.501",
  "25.502",
  "25.503",
  "25.504-1",
  "25.504-2",
  "25.504-3",
  "25.504-4",
  "25.504",
  "25.600",
  "25.601",
  "25.602-1",
  "25.602-2",
  "25.602",
  "25.603",
  "25.604",
  "25.605",
  "25.606",
  "25.607",
  "25.700",
  "25.701",
  "25.702-1",
  "25.702-2",
  "25.702-3",
  "25.702-4",
  "25.702",
  "25.703-1",
  "25.703-2",
  "25.703-3",
  "25.703-4",
  "25.703",
  "25.801",
  "25.802",
  "25.900",
  "25.901",
  "25.902",
  "25.903",
  "26.100",
  "26.101",
  "26.102",
  "26.103",
  "26.104",
  "26.200",
  "26.201",
  "26.202-1",
  "26.202-2",
  "26.202",
  "26.203",
  "26.204",
  "26.205",
  "26.206",
  "26.300",
  "26.301",
  "26.302",
  "26.303",
  "26.304",
  "26.400",
  "26.401",
  "26.402",
  "26.403",
  "26.404",
  "27.000",
  "27.001",
  "27.101",
  "27.102",
  "27.200",
  "27.201-1",
  "27.201-2",
  "27.201",
  "27.202-1",
  "27.202-2",
  "27.202-3",
  "27.202-4",
  "27.202-5",
  "27.202",
  "27.203-1",
  "27.203-2",
  "27.203",
  "27.204-1",
  "27.204-2",
  "27.204",
  "27.300",
  "27.301",
  "27.302",
  "27.303",
  "27.304-1",
  "27.304-2",
  "27.304-3",
  "27.304-4",
  "27.304",
  "27.305-1",
  "27.305-2",
  "27.305-3",
  "27.305-4",
  "27.305",
  "27.306",
  "27.400",
  "27.401",
  "27.402",
  "27.403",
  "27.404-1",
  "27.404-2",
  "27.404-3",
  "27.404-4",
  "27.404-5",
  "27.404-6",
  "27.404",
  "27.405-1",
  "27.405-2",
  "27.405-3",
  "27.405-4",
  "27.405",
  "27.406-1",
  "27.406-2",
  "27.406-3",
  "27.406",
  "27.407",
  "27.408",
  "27.409",
  "27.501",
  "28.000",
  "28.001",
  "28.100",
  "28.101-1",
  "28.101-2",
  "28.101-3",
  "28.101-4",
  "28.101",
  "28.102-1",
  "28.102-2",
  "28.102-3",
  "28.102",
  "28.103-1",
  "28.103-2",
  "28.103-3",
  "28.103-4",
  "28.103",
  "28.104",
  "28.105-1",
  "28.105-2",
  "28.105",
  "28.106-1",
  "28.106-2",
  "28.106-3",
  "28.106-4",
  "28.106-5",
  "28.106-6",
  "28.106-7",
  "28.106-8",
  "28.106",
  "28.200",
  "28.201",
  "28.202",
  "28.203-1",
  "28.203-2",
  "28.203-3",
  "28.203-4",
  "28.203-5",
  "28.203",
  "28.204-1",
  "28.204-2",
  "28.204-3",
  "28.204-4",
  "28.204",
  "28.301",
  "28.302",
  "28.303",
  "28.304",
  "28.305",
  "28.306",
  "28.307-1",
  "28.307-2",
  "28.307",
  "28.308",
  "28.309",
  "28.310",
  "28.311-1",
  "28.311-2",
  "28.311",
  "28.312",
  "28.313",
  "29.000",
  "29.001",
  "29.101",
  "29.201",
  "29.202",
  "29.203",
  "29.204",
  "29.300",
  "29.301",
  "29.302",
  "29.303",
  "29.304",
  "29.305",
  "29.401-1",
  "29.401-2",
  "29.401-3",
  "29.401-4",
  "29.401",
  "29.402-1",
  "29.402-2",
  "29.402-3",
  "29.402-4",
  "29.402",
  "3.000",
  "3.1000",
  "3.1001",
  "3.1002",
  "3.1003",
  "3.1004",
  "3.101-1",
  "3.101-2",
  "3.101-3",
  "3.101",
  "3.102",
  "3.103-1",
  "3.103-2",
  "3.103-3",
  "3.103",
  "3.104-1",
  "3.104-2",
  "3.104-3",
  "3.104-4",
  "3.104-5",
  "3.104-6",
  "3.104-7",
  "3.104-8",
  "3.104-9",
  "3.104",
  "3.1100",
  "3.1101",
  "3.1102",
  "3.1103",
  "3.1104",
  "3.1105",
  "3.1106",
  "3.201",
  "3.202",
  "3.203",
  "3.204",
  "3.301",
  "3.302",
  "3.303",
  "3.400",
  "3.401",
  "3.402",
  "3.403",
  "3.404",
  "3.405",
  "3.406",
  "3.501-1",
  "3.501-2",
  "3.501",
  "3.502-1",
  "3.502-2",
  "3.502-3",
  "3.502",
  "3.503-1",
  "3.503-2",
  "3.503",
  "3.601",
  "3.602",
  "3.603",
  "3.700",
  "3.701",
  "3.702",
  "3.703",
  "3.704",
  "3.705",
  "3.800",
  "3.801",
  "3.802",
  "3.803",
  "3.804",
  "3.805",
  "3.806",
  "3.807",
  "3.808",
  "3.900",
  "3.901",
  "3.902",
  "3.903",
  "3.904-1",
  "3.904-2",
  "3.904",
  "3.905-1",
  "3.905-2",
  "3.905",
  "3.906",
  "3.907-1",
  "3.907-2",
  "3.907-3",
  "3.907-4",
  "3.907-5",
  "3.907-6",
  "3.907-7",
  "3.907",
  "3.908",
  "3.909-1",
  "3.909-2",
  "3.909-3",
  "3.909",
  "30.000",
  "30.001",
  "30.101",
  "30.102",
  "30.201-1",
  "30.201-2",
  "30.201-3",
  "30.201-4",
  "30.201-5",
  "30.201-6",
  "30.201-7",
  "30.201",
  "30.202-1",
  "30.202-2",
  "30.202-3",
  "30.202-4",
  "30.202-5",
  "30.202-6",
  "30.202-7",
  "30.202-8",
  "30.202",
  "30.601",
  "30.602",
  "30.603-1",
  "30.603-2",
  "30.603",
  "30.604",
  "30.605",
  "30.606",
  "30.607",
  "31.000",
  "31.001",
  "31.002",
  "31.100",
  "31.101",
  "31.102",
  "31.103",
  "31.104",
  "31.105",
  "31.106",
  "31.107",
  "31.108",
  "31.109",
  "31.110",
  "31.201-1",
  "31.201-2",
  "31.201-3",
  "31.201-4",
  "31.201-5",
  "31.201-6",
  "31.201-7",
  "31.201",
  "31.202",
  "31.203",
  "31.204",
  "31.205-1",
  "31.205-10",
  "31.205-11",
  "31.205-12",
  "31.205-13",
  "31.205-14",
  "31.205-15",
  "31.205-16",
  "31.205-17",
  "31.205-18",
  "31.205-19",
  "31.205-2",
  "31.205-20",
  "31.205-21",
  "31.205-22",
  "31.205-23",
  "31.205-24",
  "31.205-25",
  "31.205-26",
  "31.205-27",
  "31.205-28",
  "31.205-29",
  "31.205-3",
  "31.205-30",
  "31.205-31",
  "31.205-32",
  "31.205-33",
  "31.205-34",
  "31.205-35",
  "31.205-36",
  "31.205-37",
  "31.205-38",
  "31.205-39",
  "31.205-4",
  "31.205-40",
  "31.205-41",
  "31.205-42",
  "31.205-43",
  "31.205-44",
  "31.205-45",
  "31.205-46",
  "31.205-47",
  "31.205-48",
  "31.205-49",
  "31.205-5",
  "31.205-50",
  "31.205-51",
  "31.205-52",
  "31.205-6",
  "31.205-7",
  "31.205-8",
  "31.205-9",
  "31.205",
  "31.301",
  "31.302",
  "31.303",
  "31.601",
  "31.602",
  "31.603",
  "31.701",
  "31.702",
  "31.703",
  "32.000",
  "32.001",
  "32.002",
  "32.003",
  "32.004",
  "32.005",
  "32.006-1",
  "32.006-2",
  "32.006-3",
  "32.006-4",
  "32.006-5",
  "32.006",
  "32.007",
  "32.008",
  "32.009-1",
  "32.009-2",
  "32.009",
  "32.100",
  "32.1000",
  "32.1001",
  "32.1002",
  "32.1003",
  "32.1004",
  "32.1005",
  "32.1006",
  "32.1007",
  "32.1008",
  "32.1009",
  "32.101",
  "32.1010",
  "32.102",
  "32.103",
  "32.104",
  "32.105",
  "32.106",
  "32.107",
  "32.108",
  "32.109",
  "32.110",
  "32.1100",
  "32.1101",
  "32.1102",
  "32.1103",
  "32.1104",
  "32.1105",
  "32.1106",
  "32.1107",
  "32.1108",
  "32.1109",
  "32.111",
  "32.1110",
  "32.112-1",
  "32.112-2",
  "32.112",
  "32.113",
  "32.114",
  "32.200",
  "32.201",
  "32.202-1",
  "32.202-2",
  "32.202-3",
  "32.202-4",
  "32.202",
  "32.203",
  "32.204",
  "32.205",
  "32.206",
  "32.207",
  "32.300",
  "32.301",
  "32.302",
  "32.303",
  "32.304-1",
  "32.304-2",
  "32.304-3",
  "32.304-4",
  "32.304-5",
  "32.304-6",
  "32.304-7",
  "32.304-8",
  "32.304",
  "32.305",
  "32.306",
  "32.400",
  "32.401",
  "32.402",
  "32.403",
  "32.404",
  "32.405",
  "32.406",
  "32.407",
  "32.408",
  "32.409-1",
  "32.409-2",
  "32.409-3",
  "32.409",
  "32.410",
  "32.411",
  "32.412",
  "32.500",
  "32.501-1",
  "32.501-2",
  "32.501-3",
  "32.501-4",
  "32.501-5",
  "32.501",
  "32.502-1",
  "32.502-2",
  "32.502-3",
  "32.502-4",
  "32.502",
  "32.503-1",
  "32.503-10",
  "32.503-11",
  "32.503-12",
  "32.503-13",
  "32.503-14",
  "32.503-15",
  "32.503-16",
  "32.503-2",
  "32.503-3",
  "32.503-4",
  "32.503-5",
  "32.503-6",
  "32.503-7",
  "32.503-8",
  "32.503-9",
  "32.503",
  "32.504",
  "32.600",
  "32.601",
  "32.602",
  "32.603",
  "32.604",
  "32.605",
  "32.606",
  "32.607-1",
  "32.607-2",
  "32.607",
  "32.608-1",
  "32.608-2",
  "32.608",
  "32.609",
  "32.610",
  "32.611",
  "32.700",
  "32.701",
  "32.702",
  "32.703-1",
  "32.703-2",
  "32.703-3",
  "32.703",
  "32.704",
  "32.705",
  "32.706-1",
  "32.706-2",
  "32.706-3",
  "32.706",
  "32.800",
  "32.801",
  "32.802",
  "32.803",
  "32.804",
  "32.805",
  "32.806",
  "32.900",
  "32.901",
  "32.902",
  "32.903",
  "32.904",
  "32.905",
  "32.906",
  "32.907",
  "32.908",
  "32.909",
  "33.000",
  "33.001",
  "33.101",
  "33.102",
  "33.103",
  "33.104",
  "33.105",
  "33.106",
  "33.201",
  "33.202",
  "33.203",
  "33.204",
  "33.205",
  "33.206",
  "33.207",
  "33.208",
  "33.209",
  "33.210",
  "33.211",
  "33.212",
  "33.213",
  "33.214",
  "33.215",
  "34.000",
  "34.001",
  "34.002",
  "34.003",
  "34.004",
  "34.005-1",
  "34.005-2",
  "34.005-3",
  "34.005-4",
  "34.005-5",
  "34.005-6",
  "34.005",
  "34.100",
  "34.101",
  "34.102",
  "34.103",
  "34.104",
  "34.201",
  "34.202",
  "34.203",
  "35.000",
  "35.001",
  "35.002",
  "35.003",
  "35.004",
  "35.005",
  "35.006",
  "35.007",
  "35.008",
  "35.009",
  "35.010",
  "35.011",
  "35.012",
  "35.013",
  "35.014",
  "35.015",
  "35.016",
  "35.017-1",
  "35.017-2",
  "35.017-3",
  "35.017-4",
  "35.017-5",
  "35.017-6",
  "35.017-7",
  "35.017",
  "36.000",
  "36.001",
  "36.101",
  "36.102",
  "36.103",
  "36.104",
  "36.201",
  "36.202",
  "36.203",
  "36.204",
  "36.205",
  "36.206",
  "36.207",
  "36.208",
  "36.209",
  "36.210",
  "36.211",
  "36.212",
  "36.213-1",
  "36.213-2",
  "36.213-3",
  "36.213-4",
  "36.213",
  "36.214",
  "36.215",
  "36.300",
  "36.301",
  "36.302",
  "36.303-1",
  "36.303-2",
  "36.303",
  "36.500",
  "36.501",
  "36.502",
  "36.503",
  "36.504",
  "36.505",
  "36.506",
  "36.507",
  "36.508",
  "36.509",
  "36.510",
  "36.511",
  "36.512",
  "36.513",
  "36.514",
  "36.515",
  "36.516",
  "36.517",
  "36.518",
  "36.519",
  "36.520",
  "36.521",
  "36.522",
  "36.523",
  "36.600",
  "36.601-1",
  "36.601-2",
  "36.601-3",
  "36.601-4",
  "36.601",
  "36.602-1",
  "36.602-2",
  "36.602-3",
  "36.602-4",
  "36.602-5",
  "36.602",
  "36.603",
  "36.604",
  "36.605",
  "36.606",
  "36.607",
  "36.608",
  "36.609-1",
  "36.609-2",
  "36.609-3",
  "36.609-4",
  "36.609",
  "36.700",
  "36.701",
  "36.702",
  "37.000",
  "37.101",
  "37.102",
  "37.103",
  "37.104",
  "37.105",
  "37.106",
  "37.107",
  "37.108",
  "37.109",
  "37.110",
  "37.111",
  "37.112",
  "37.113-1",
  "37.113-2",
  "37.113",
  "37.114",
  "37.115-1",
  "37.115-2",
  "37.115-3",
  "37.115",
  "37.200",
  "37.201",
  "37.202",
  "37.203",
  "37.204",
  "37.205",
  "37.300",
  "37.301",
  "37.302",
  "37.303",
  "37.304",
  "37.400",
  "37.401",
  "37.402",
  "37.403",
  "37.500",
  "37.501",
  "37.502",
  "37.503",
  "37.504",
  "37.600",
  "37.601",
  "37.602",
  "37.603",
  "37.604",
  "38.000",
  "38.101",
  "38.201",
  "39.000",
  "39.001",
  "39.002",
  "39.101",
  "39.102",
  "39.103",
  "39.104",
  "39.105",
  "39.106",
  "39.201",
  "39.202",
  "39.203",
  "39.204",
  "39.205",
  "4.000",
  "4.001",
  "4.1000",
  "4.1001",
  "4.1002",
  "4.1003",
  "4.1004",
  "4.1005-1",
  "4.1005-2",
  "4.1005",
  "4.1006",
  "4.1007",
  "4.1008",
  "4.101",
  "4.102",
  "4.103",
  "4.1100",
  "4.1101",
  "4.1102",
  "4.1103",
  "4.1104",
  "4.1105",
  "4.1200",
  "4.1201",
  "4.1202",
  "4.1300",
  "4.1301",
  "4.1302",
  "4.1303",
  "4.1400",
  "4.1401",
  "4.1402",
  "4.1403",
  "4.1500",
  "4.1501",
  "4.1502",
  "4.1600",
  "4.1601",
  "4.1602",
  "4.1603",
  "4.1700",
  "4.1701",
  "4.1702",
  "4.1703",
  "4.1704",
  "4.1705",
  "4.1800",
  "4.1801",
  "4.1802",
  "4.1803",
  "4.1804",
  "4.1901",
  "4.1902",
  "4.1903",
  "4.2001",
  "4.2002",
  "4.2003",
  "4.2004",
  "4.201",
  "4.202",
  "4.203",
  "4.2100",
  "4.2101",
  "4.2102",
  "4.2103",
  "4.2104",
  "4.2105",
  "4.2201",
  "4.2202",
  "4.2203",
  "4.2300",
  "4.2301",
  "4.2302",
  "4.2303",
  "4.2304",
  "4.2305",
  "4.2306",
  "4.300",
  "4.301",
  "4.302",
  "4.303",
  "4.401",
  "4.402",
  "4.403",
  "4.404",
  "4.500",
  "4.501",
  "4.502",
  "4.600",
  "4.601",
  "4.602",
  "4.603",
  "4.604",
  "4.605",
  "4.606",
  "4.607",
  "4.700",
  "4.701",
  "4.702",
  "4.703",
  "4.704",
  "4.705-1",
  "4.705-2",
  "4.705-3",
  "4.705",
  "4.800",
  "4.801",
  "4.802",
  "4.803",
  "4.804-1",
  "4.804-2",
  "4.804-3",
  "4.804-4",
  "4.804-5",
  "4.804",
  "4.805",
  "4.900",
  "4.901",
  "4.902",
  "4.903",
  "4.904",
  "4.905",
  "41.100",
  "41.101",
  "41.102",
  "41.103",
  "41.201",
  "41.202",
  "41.203",
  "41.204",
  "41.205",
  "41.206",
  "41.301",
  "41.401",
  "41.402",
  "41.501",
  "41.601",
  "41.701",
  "41.702",
  "42.000",
  "42.001",
  "42.002",
  "42.003",
  "42.101",
  "42.102",
  "42.103",
  "42.1101",
  "42.1102",
  "42.1103",
  "42.1104",
  "42.1105",
  "42.1106",
  "42.1107",
  "42.1200",
  "42.1201",
  "42.1202",
  "42.1203",
  "42.1204",
  "42.1205",
  "42.1301",
  "42.1302",
  "42.1303",
  "42.1304",
  "42.1305",
  "42.1500",
  "42.1501",
  "42.1502",
  "42.1503",
  "42.1504",
  "42.1601",
  "42.1701",
  "42.201",
  "42.202",
  "42.203",
  "42.301",
  "42.302",
  "42.401",
  "42.402",
  "42.403",
  "42.500",
  "42.501",
  "42.502",
  "42.503-1",
  "42.503-2",
  "42.503-3",
  "42.503",
  "42.504",
  "42.505",
  "42.601",
  "42.602",
  "42.603",
  "42.700",
  "42.701",
  "42.702",
  "42.703-1",
  "42.703-2",
  "42.703",
  "42.704",
  "42.705-1",
  "42.705-2",
  "42.705-3",
  "42.705-4",
  "42.705-5",
  "42.705",
  "42.706",
  "42.707",
  "42.708",
  "42.709-1",
  "42.709-2",
  "42.709-3",
  "42.709-4",
  "42.709-5",
  "42.709-6",
  "42.709-7",
  "42.709",
  "42.800",
  "42.801",
  "42.802",
  "42.803",
  "42.900",
  "42.901",
  "42.902",
  "42.903",
  "43.000",
  "43.101",
  "43.102",
  "43.103",
  "43.104",
  "43.105",
  "43.106",
  "43.107",
  "43.201",
  "43.202",
  "43.203",
  "43.204",
  "43.205",
  "43.301",
  "44.000",
  "44.101",
  "44.201-1",
  "44.201-2",
  "44.201",
  "44.202-1",
  "44.202-2",
  "44.202",
  "44.203",
  "44.204",
  "44.301",
  "44.302",
  "44.303",
  "44.304",
  "44.305-1",
  "44.305-2",
  "44.305-3",
  "44.305",
  "44.306",
  "44.307",
  "44.400",
  "44.401",
  "44.402",
  "44.403",
  "45.000",
  "45.101",
  "45.102",
  "45.103",
  "45.104",
  "45.105",
  "45.106",
  "45.107",
  "45.201",
  "45.202",
  "45.301",
  "45.302",
  "45.303",
  "45.401",
  "45.402",
  "45.501",
  "45.502",
  "45.503",
  "45.600",
  "45.601",
  "45.602-1",
  "45.602-2",
  "45.602-3",
  "45.602-4",
  "45.602",
  "45.603",
  "45.604-1",
  "45.604-2",
  "45.604-3",
  "45.604-4",
  "45.604",
  "45.605",
  "45.606",
  "46.000",
  "46.101",
  "46.102",
  "46.103",
  "46.104",
  "46.105",
  "46.201",
  "46.202-1",
  "46.202-2",
  "46.202-3",
  "46.202-4",
  "46.202",
  "46.203",
  "46.301",
  "46.302",
  "46.303",
  "46.304",
  "46.305",
  "46.306",
  "46.307",
  "46.308",
  "46.309",
  "46.310",
  "46.311",
  "46.312",
  "46.313",
  "46.314",
  "46.315",
  "46.316",
  "46.317",
  "46.401",
  "46.402",
  "46.403",
  "46.404",
  "46.405",
  "46.406",
  "46.407",
  "46.408",
  "46.501",
  "46.502",
  "46.503",
  "46.504",
  "46.505",
  "46.601",
  "46.701",
  "46.702",
  "46.703",
  "46.704",
  "46.705",
  "46.706",
  "46.707",
  "46.708",
  "46.709",
  "46.710",
  "46.800",
  "46.801",
  "46.802",
  "46.803",
  "46.804",
  "46.805",
  "47.000",
  "47.001",
  "47.002",
  "47.101",
  "47.102",
  "47.103-1",
  "47.103-2",
  "47.103",
  "47.104-1",
  "47.104-2",
  "47.104-3",
  "47.104-4",
  "47.104-5",
  "47.104",
  "47.105",
  "47.200",
  "47.201",
  "47.202",
  "47.203",
  "47.204",
  "47.205",
  "47.206",
  "47.207-1",
  "47.207-10",
  "47.207-11",
  "47.207-2",
  "47.207-3",
  "47.207-4",
  "47.207-5",
  "47.207-6",
  "47.207-7",
  "47.207-8",
  "47.207-9",
  "47.207",
  "47.208-1",
  "47.208-2",
  "47.208",
  "47.300",
  "47.301-1",
  "47.301-2",
  "47.301-3",
  "47.301",
  "47.302",
  "47.303-1",
  "47.303-10",
  "47.303-11",
  "47.303-12",
  "47.303-13",
  "47.303-14",
  "47.303-15",
  "47.303-16",
  "47.303-17",
  "47.303-2",
  "47.303-3",
  "47.303-4",
  "47.303-5",
  "47.303-6",
  "47.303-7",
  "47.303-8",
  "47.303-9",
  "47.303",
  "47.304-1",
  "47.304-2",
  "47.304-3",
  "47.304-4",
  "47.304-5",
  "47.304",
  "47.305-1",
  "47.305-10",
  "47.305-11",
  "47.305-12",
  "47.305-13",
  "47.305-14",
  "47.305-15",
  "47.305-16",
  "47.305-17",
  "47.305-2",
  "47.305-3",
  "47.305-4",
  "47.305-5",
  "47.305-6",
  "47.305-7",
  "47.305-8",
  "47.305-9",
  "47.305",
  "47.306-1",
  "47.306-2",
  "47.306-3",
  "47.306",
  "47.401",
  "47.402",
  "47.403-1",
  "47.403-2",
  "47.403-3",
  "47.403",
  "47.404",
  "47.405",
  "47.500",
  "47.501",
  "47.502",
  "47.503",
  "47.504",
  "47.505",
  "47.506",
  "47.507",
  "48.000",
  "48.001",
  "48.101",
  "48.102",
  "48.103",
  "48.104-1",
  "48.104-2",
  "48.104-3",
  "48.104-4",
  "48.104",
  "48.105",
  "48.201",
  "48.202",
  "49.000",
  "49.001",
  "49.002",
  "49.100",
  "49.101",
  "49.102",
  "49.103",
  "49.104",
  "49.105-1",
  "49.105-2",
  "49.105-3",
  "49.105-4",
  "49.105",
  "49.106",
  "49.107",
  "49.108-1",
  "49.108-2",
  "49.108-3",
  "49.108-4",
  "49.108-5",
  "49.108-6",
  "49.108-7",
  "49.108-8",
  "49.108",
  "49.109-1",
  "49.109-2",
  "49.109-3",
  "49.109-4",
  "49.109-5",
  "49.109-6",
  "49.109-7",
  "49.109",
  "49.110",
  "49.111",
  "49.112-1",
  "49.112-2",
  "49.112",
  "49.113",
  "49.114",
  "49.115",
  "49.201",
  "49.202",
  "49.203",
  "49.204",
  "49.205",
  "49.206-1",
  "49.206-2",
  "49.206-3",
  "49.206",
  "49.207",
  "49.208",
  "49.301",
  "49.302",
  "49.303-1",
  "49.303-2",
  "49.303-3",
  "49.303-4",
  "49.303-5",
  "49.303",
  "49.304-1",
  "49.304-2",
  "49.304-3",
  "49.304",
  "49.305-1",
  "49.305-2",
  "49.305",
  "49.401",
  "49.402-1",
  "49.402-2",
  "49.402-3",
  "49.402-4",
  "49.402-5",
  "49.402-6",
  "49.402-7",
  "49.402-8",
  "49.402",
  "49.403",
  "49.404",
  "49.405",
  "49.406",
  "49.501",
  "49.502",
  "49.503",
  "49.504",
  "49.505",
  "49.601-1",
  "49.601-2",
  "49.601",
  "49.602-1",
  "49.602-2",
  "49.602-3",
  "49.602-4",
  "49.602-5",
  "49.602",
  "49.603-1",
  "49.603-2",
  "49.603-3",
  "49.603-4",
  "49.603-5",
  "49.603-6",
  "49.603-7",
  "49.603-8",
  "49.603-9",
  "49.603",
  "49.604",
  "49.605",
  "49.606",
  "49.607",
  "5.000",
  "5.001",
  "5.002",
  "5.003",
  "5.101",
  "5.102",
  "5.201",
  "5.202",
  "5.203",
  "5.204",
  "5.205",
  "5.206",
  "5.207",
  "5.301",
  "5.302",
  "5.303",
  "5.401",
  "5.402",
  "5.403",
  "5.404-1",
  "5.404-2",
  "5.404",
  "5.405",
  "5.406",
  "5.501",
  "5.502",
  "5.503",
  "5.504",
  "5.601",
  "5.701",
  "5.702",
  "5.703",
  "5.704",
  "5.705",
  "50.000",
  "50.100",
  "50.101-1",
  "50.101-2",
  "50.101-3",
  "50.101",
  "50.102-1",
  "50.102-2",
  "50.102-3",
  "50.102",
  "50.103-1",
  "50.103-2",
  "50.103-3",
  "50.103-4",
  "50.103-5",
  "50.103-6",
  "50.103-7",
  "50.103",
  "50.104-1",
  "50.104-2",
  "50.104-3",
  "50.104-4",
  "50.104",
  "50.200",
  "50.201",
  "50.202",
  "50.203",
  "50.204",
  "50.205-1",
  "50.205-2",
  "50.205-3",
  "50.205-4",
  "50.205",
  "50.206",
  "51.000",
  "51.100",
  "51.101",
  "51.102",
  "51.103",
  "51.104",
  "51.105",
  "51.106",
  "51.107",
  "51.200",
  "51.201",
  "51.202",
  "51.203",
  "51.204",
  "51.205",
  "52.000",
  "52.100",
  "52.101",
  "52.102",
  "52.103",
  "52.104",
  "52.105",
  "52.106",
  "52.107",
  "52.200",
  "52.201-1",
  "52.201",
  "52.202-1",
  "52.202",
  "52.203-1",
  "52.203-10",
  "52.203-11",
  "52.203-12",
  "52.203-13",
  "52.203-14",
  "52.203-15",
  "52.203-16",
  "52.203-17",
  "52.203-18",
  "52.203-19",
  "52.203-2",
  "52.203-3",
  "52.203-4",
  "52.203-5",
  "52.203-6",
  "52.203-7",
  "52.203-8",
  "52.203-9",
  "52.203",
  "52.204-1",
  "52.204-10",
  "52.204-11",
  "52.204-12",
  "52.204-13",
  "52.204-14",
  "52.204-15",
  "52.204-16",
  "52.204-17",
  "52.204-18",
  "52.204-19",
  "52.204-2",
  "52.204-20",
  "52.204-21",
  "52.204-22",
  "52.204-23",
  "52.204-24",
  "52.204-25",
  "52.204-26",
  "52.204-27",
  "52.204-28",
  "52.204-29",
  "52.204-3",
  "52.204-30",
  "52.204-4",
  "52.204-5",
  "52.204-6",
  "52.204-7",
  "52.204-8",
  "52.204-9",
  "52.204",
  "52.205",
  "52.206",
  "52.207-1",
  "52.207-2",
  "52.207-3",
  "52.207-4",
  "52.207-5",
  "52.207-6",
  "52.207",
  "52.208-1",
  "52.208-2",
  "52.208-3",
  "52.208-4",
  "52.208-5",
  "52.208-6",
  "52.208-7",
  "52.208-8",
  "52.208-9",
  "52.208",
  "52.209-1",
  "52.209-10",
  "52.209-11",
  "52.209-12",
  "52.209-13",
  "52.209-14",
  "52.209-2",
  "52.209-3",
  "52.209-4",
  "52.209-5",
  "52.209-6",
  "52.209-7",
  "52.209-8",
  "52.209-9",
  "52.209",
  "52.210-1",
  "52.210",
  "52.211-1",
  "52.211-10",
  "52.211-11",
  "52.211-12",
  "52.211-13",
  "52.211-14",
  "52.211-15",
  "52.211-16",
  "52.211-17",
  "52.211-18",
  "52.211-2",
  "52.211-3",
  "52.211-4",
  "52.211-5",
  "52.211-6",
  "52.211-7",
  "52.211-8",
  "52.211-9",
  "52.211",
  "52.212-1",
  "52.212-2",
  "52.212-3",
  "52.212-4",
  "52.212-5",
  "52.212",
  "52.213-1",
  "52.213-2",
  "52.213-3",
  "52.213-4",
  "52.213",
  "52.214-1",
  "52.214-10",
  "52.214-11",
  "52.214-12",
  "52.214-13",
  "52.214-14",
  "52.214-15",
  "52.214-16",
  "52.214-17",
  "52.214-18",
  "52.214-19",
  "52.214-2",
  "52.214-20",
  "52.214-21",
  "52.214-22",
  "52.214-23",
  "52.214-24",
  "52.214-25",
  "52.214-26",
  "52.214-27",
  "52.214-28",
  "52.214-29",
  "52.214-3",
  "52.214-30",
  "52.214-31",
  "52.214-32",
  "52.214-33",
  "52.214-34",
  "52.214-35",
  "52.214-4",
  "52.214-5",
  "52.214-6",
  "52.214-7",
  "52.214-8",
  "52.214-9",
  "52.214",
  "52.215-1",
  "52.215-10",
  "52.215-11",
  "52.215-12",
  "52.215-13",
  "52.215-14",
  "52.215-15",
  "52.215-16",
  "52.215-17",
  "52.215-18",
  "52.215-19",
  "52.215-2",
  "52.215-20",
  "52.215-21",
  "52.215-22",
  "52.215-23",
  "52.215-3",
  "52.215-4",
  "52.215-5",
  "52.215-6",
  "52.215-7",
  "52.215-8",
  "52.215-9",
  "52.215",
  "52.216-1",
  "52.216-10",
  "52.216-11",
  "52.216-12",
  "52.216-13",
  "52.216-14",
  "52.216-15",
  "52.216-16",
  "52.216-17",
  "52.216-18",
  "52.216-19",
  "52.216-2",
  "52.216-20",
  "52.216-21",
  "52.216-22",
  "52.216-23",
  "52.216-24",
  "52.216-25",
  "52.216-26",
  "52.216-27",
  "52.216-28",
  "52.216-29",
  "52.216-3",
  "52.216-30",
  "52.216-31",
  "52.216-32",
  "52.216-4",
  "52.216-5",
  "52.216-6",
  "52.216-7",
  "52.216-8",
  "52.216-9",
  "52.216",
  "52.217-1",
  "52.217-2",
  "52.217-3",
  "52.217-4",
  "52.217-5",
  "52.217-6",
  "52.217-7",
  "52.217-8",
  "52.217-9",
  "52.217",
  "52.218",
  "52.219-1",
  "52.219-10",
  "52.219-11",
  "52.219-12",
  "52.219-13",
  "52.219-14",
  "52.219-15",
  "52.219-16",
  "52.219-17",
  "52.219-18",
  "52.219-19",
  "52.219-2",
  "52.219-20",
  "52.219-21",
  "52.219-22",
  "52.219-23",
  "52.219-24",
  "52.219-25",
  "52.219-26",
  "52.219-27",
  "52.219-28",
  "52.219-29",
  "52.219-3",
  "52.219-30",
  "52.219-31",
  "52.219-32",
  "52.219-33",
  "52.219-4",
  "52.219-5",
  "52.219-6",
  "52.219-7",
  "52.219-8",
  "52.219-9",
  "52.219",
  "52.220",
  "52.221",
  "52.222-1",
  "52.222-10",
  "52.222-11",
  "52.222-12",
  "52.222-13",
  "52.222-14",
  "52.222-15",
  "52.222-16",
  "52.222-17",
  "52.222-18",
  "52.222-19",
  "52.222-2",
  "52.222-20",
  "52.222-21",
  "52.222-22",
  "52.222-23",
  "52.222-24",
  "52.222-25",
  "52.222-26",
  "52.222-27",
  "52.222-28",
  "52.222-29",
  "52.222-3",
  "52.222-30",
  "52.222-31",
  "52.222-32",
  "52.222-33",
  "52.222-34",
  "52.222-35",
  "52.222-36",
  "52.222-37",
  "52.222-38",
  "52.222-39",
  "52.222-4",
  "52.222-40",
  "52.222-41",
  "52.222-42",
  "52.222-43",
  "52.222-44",
  "52.222-45",
  "52.222-46",
  "52.222-47",
  "52.222-48",
  "52.222-49",
  "52.222-5",
  "52.222-50",
  "52.222-51",
  "52.222-52",
  "52.222-53",
  "52.222-54",
  "52.222-55",
  "52.222-56",
  "52.222-57",
  "52.222-58",
  "52.222-59",
  "52.222-6",
  "52.222-60",
  "52.222-61",
  "52.222-62",
  "52.222-7",
  "52.222-8",
  "52.222-9",
  "52.222",
  "52.223-1",
  "52.223-10",
  "52.223-11",
  "52.223-12",
  "52.223-13",
  "52.223-14",
  "52.223-15",
  "52.223-16",
  "52.223-17",
  "52.223-18",
  "52.223-19",
  "52.223-2",
  "52.223-20",
  "52.223-21",
  "52.223-22",
  "52.223-3",
  "52.223-4",
  "52.223-5",
  "52.223-6",
  "52.223-7",
  "52.223-8",
  "52.223-9",
  "52.223",
  "52.224-1",
  "52.224-2",
  "52.224-3",
  "52.224",
  "52.225-1",
  "52.225-10",
  "52.225-11",
  "52.225-12",
  "52.225-13",
  "52.225-14",
  "52.225-15",
  "52.225-16",
  "52.225-17",
  "52.225-18",
  "52.225-19",
  "52.225-2",
  "52.225-20",
  "52.225-21",
  "52.225-22",
  "52.225-23",
  "52.225-24",
  "52.225-25",
  "52.225-26",
  "52.225-3",
  "52.225-4",
  "52.225-5",
  "52.225-6",
  "52.225-7",
  "52.225-8",
  "52.225-9",
  "52.225",
  "52.226-1",
  "52.226-2",
  "52.226-3",
  "52.226-4",
  "52.226-5",
  "52.226-6",
  "52.226",
  "52.227-1",
  "52.227-10",
  "52.227-11",
  "52.227-12",
  "52.227-13",
  "52.227-14",
  "52.227-15",
  "52.227-16",
  "52.227-17",
  "52.227-18",
  "52.227-19",
  "52.227-2",
  "52.227-20",
  "52.227-21",
  "52.227-22",
  "52.227-23",
  "52.227-3",
  "52.227-4",
  "52.227-5",
  "52.227-6",
  "52.227-7",
  "52.227-8",
  "52.227-9",
  "52.227",
  "52.228-1",
  "52.228-10",
  "52.228-11",
  "52.228-12",
  "52.228-13",
  "52.228-14",
  "52.228-15",
  "52.228-16",
  "52.228-17",
  "52.228-2",
  "52.228-3",
  "52.228-4",
  "52.228-5",
  "52.228-6",
  "52.228-7",
  "52.228-8",
  "52.228-9",
  "52.228",
  "52.229-1",
  "52.229-10",
  "52.229-11",
  "52.229-12",
  "52.229-13",
  "52.229-14",
  "52.229-2",
  "52.229-3",
  "52.229-4",
  "52.229-5",
  "52.229-6",
  "52.229-7",
  "52.229-8",
  "52.229-9",
  "52.229",
  "52.230-1",
  "52.230-2",
  "52.230-3",
  "52.230-4",
  "52.230-5",
  "52.230-6",
  "52.230-7",
  "52.230",
  "52.231",
  "52.232-1",
  "52.232-10",
  "52.232-11",
  "52.232-12",
  "52.232-13",
  "52.232-14",
  "52.232-15",
  "52.232-16",
  "52.232-17",
  "52.232-18",
  "52.232-19",
  "52.232-2",
  "52.232-20",
  "52.232-21",
  "52.232-22",
  "52.232-23",
  "52.232-24",
  "52.232-25",
  "52.232-26",
  "52.232-27",
  "52.232-28",
  "52.232-29",
  "52.232-3",
  "52.232-30",
  "52.232-31",
  "52.232-32",
  "52.232-33",
  "52.232-34",
  "52.232-35",
  "52.232-36",
  "52.232-37",
  "52.232-38",
  "52.232-39",
  "52.232-4",
  "52.232-40",
  "52.232-5",
  "52.232-6",
  "52.232-7",
  "52.232-8",
  "52.232-9",
  "52.232",
  "52.233-1",
  "52.233-2",
  "52.233-3",
  "52.233-4",
  "52.233",
  "52.234-1",
  "52.234-2",
  "52.234-3",
  "52.234-4",
  "52.234",
  "52.235",
  "52.236-1",
  "52.236-10",
  "52.236-11",
  "52.236-12",
  "52.236-13",
  "52.236-14",
  "52.236-15",
  "52.236-16",
  "52.236-17",
  "52.236-18",
  "52.236-19",
  "52.236-2",
  "52.236-20",
  "52.236-21",
  "52.236-22",
  "52.236-23",
  "52.236-24",
  "52.236-25",
  "52.236-26",
  "52.236-27",
  "52.236-28",
  "52.236-3",
  "52.236-4",
  "52.236-5",
  "52.236-6",
  "52.236-7",
  "52.236-8",
  "52.236-9",
  "52.236",
  "52.237-1",
  "52.237-10",
  "52.237-2",
  "52.237-3",
  "52.237-4",
  "52.237-5",
  "52.237-6",
  "52.237-7",
  "52.237-8",
  "52.237-9",
  "52.237",
  "52.238",
  "52.239-1",
  "52.239",
  "52.240",
  "52.241-1",
  "52.241-10",
  "52.241-11",
  "52.241-12",
  "52.241-13",
  "52.241-2",
  "52.241-3",
  "52.241-4",
  "52.241-5",
  "52.241-6",
  "52.241-7",
  "52.241-8",
  "52.241-9",
  "52.241",
  "52.242-1",
  "52.242-10",
  "52.242-11",
  "52.242-12",
  "52.242-13",
  "52.242-14",
  "52.242-15",
  "52.242-16",
  "52.242-17",
  "52.242-2",
  "52.242-3",
  "52.242-4",
  "52.242-5",
  "52.242-6",
  "52.242-7",
  "52.242-8",
  "52.242-9",
  "52.242",
  "52.243-1",
  "52.243-2",
  "52.243-3",
  "52.243-4",
  "52.243-5",
  "52.243-6",
  "52.243-7",
  "52.243",
  "52.244-1",
  "52.244-2",
  "52.244-3",
  "52.244-4",
  "52.244-5",
  "52.244-6",
  "52.244",
  "52.245-1",
  "52.245-2",
  "52.245-3",
  "52.245-4",
  "52.245-5",
  "52.245-6",
  "52.245-7",
  "52.245-8",
  "52.245-9",
  "52.245",
  "52.246-1",
  "52.246-10",
  "52.246-11",
  "52.246-12",
  "52.246-13",
  "52.246-14",
  "52.246-15",
  "52.246-16",
  "52.246-17",
  "52.246-18",
  "52.246-19",
  "52.246-2",
  "52.246-20",
  "52.246-21",
  "52.246-22",
  "52.246-23",
  "52.246-24",
  "52.246-25",
  "52.246-26",
  "52.246-3",
  "52.246-4",
  "52.246-5",
  "52.246-6",
  "52.246-7",
  "52.246-8",
  "52.246-9",
  "52.246",
  "52.247-1",
  "52.247-10",
  "52.247-11",
  "52.247-12",
  "52.247-13",
  "52.247-14",
  "52.247-15",
  "52.247-16",
  "52.247-17",
  "52.247-18",
  "52.247-19",
  "52.247-2",
  "52.247-20",
  "52.247-21",
  "52.247-22",
  "52.247-23",
  "52.247-24",
  "52.247-25",
  "52.247-26",
  "52.247-27",
  "52.247-28",
  "52.247-29",
  "52.247-3",
  "52.247-30",
  "52.247-31",
  "52.247-32",
  "52.247-33",
  "52.247-34",
  "52.247-35",
  "52.247-36",
  "52.247-37",
  "52.247-38",
  "52.247-39",
  "52.247-4",
  "52.247-40",
  "52.247-41",
  "52.247-42",
  "52.247-43",
  "52.247-44",
  "52.247-45",
  "52.247-46",
  "52.247-47",
  "52.247-48",
  "52.247-49",
  "52.247-5",
  "52.247-50",
  "52.247-51",
  "52.247-52",
  "52.247-53",
  "52.247-54",
  "52.247-55",
  "52.247-56",
  "52.247-57",
  "52.247-58",
  "52.247-59",
  "52.247-6",
  "52.247-60",
  "52.247-61",
  "52.247-62",
  "52.247-63",
  "52.247-64",
  "52.247-65",
  "52.247-66",
  "52.247-67",
  "52.247-68",
  "52.247-7",
  "52.247-8",
  "52.247-9",
  "52.247",
  "52.248-1",
  "52.248-2",
  "52.248-3",
  "52.248",
  "52.249-1",
  "52.249-10",
  "52.249-11",
  "52.249-12",
  "52.249-13",
  "52.249-14",
  "52.249-2",
  "52.249-3",
  "52.249-4",
  "52.249-5",
  "52.249-6",
  "52.249-7",
  "52.249-8",
  "52.249-9",
  "52.249",
  "52.250-1",
  "52.250-2",
  "52.250-3",
  "52.250-4",
  "52.250-5",
  "52.250",
  "52.251-1",
  "52.251-2",
  "52.251",
  "52.252-1",
  "52.252-2",
  "52.252-3",
  "52.252-4",
  "52.252-5",
  "52.252-6",
  "52.252",
  "52.253-1",
  "52.253",
  "52.300",
  "52.301-2",
  "52.301",
  "53.000",
  "53.001",
  "53.100",
  "53.101",
  "53.102",
  "53.103",
  "53.104",
  "53.105",
  "53.106",
  "53.107",
  "53.108",
  "53.109",
  "53.110",
  "53.111",
  "53.200",
  "53.201-1",
  "53.201",
  "53.202",
  "53.203",
  "53.204-1",
  "53.204-2",
  "53.204",
  "53.205-1",
  "53.205",
  "53.206",
  "53.207",
  "53.208",
  "53.209-1",
  "53.209",
  "53.210",
  "53.211",
  "53.212",
  "53.213",
  "53.214",
  "53.215-1",
  "53.215",
  "53.216-1",
  "53.216",
  "53.217",
  "53.218",
  "53.219",
  "53.220",
  "53.221",
  "53.222",
  "53.223",
  "53.224",
  "53.225",
  "53.226",
  "53.227",
  "53.228",
  "53.229",
  "53.230",
  "53.231",
  "53.232",
  "53.233",
  "53.234",
  "53.235",
  "53.236-1",
  "53.236-2",
  "53.236",
  "53.237",
  "53.238",
  "53.239",
  "53.240",
  "53.241",
  "53.242-1",
  "53.242",
  "53.243",
  "53.244",
  "53.245",
  "53.246",
  "53.247",
  "53.248",
  "53.249",
  "53.250",
  "53.251",
  "53.300",
  "6.000",
  "6.001",
  "6.002",
  "6.003",
  "6.100",
  "6.101",
  "6.102",
  "6.200",
  "6.201",
  "6.202",
  "6.203",
  "6.204",
  "6.205",
  "6.206",
  "6.207",
  "6.208",
  "6.300",
  "6.301",
  "6.302-1",
  "6.302-2",
  "6.302-3",
  "6.302-4",
  "6.302-5",
  "6.302-6",
  "6.302-7",
  "6.302",
  "6.303-1",
  "6.303-2",
  "6.303",
  "6.304",
  "6.305",
  "6.401",
  "6.501",
  "6.502",
  "7.000",
  "7.101",
  "7.102",
  "7.103",
  "7.104",
  "7.105",
  "7.106",
  "7.107-1",
  "7.107-2",
  "7.107-3",
  "7.107-4",
  "7.107-5",
  "7.107-6",
  "7.107",
  "7.108",
  "7.200",
  "7.201",
  "7.202",
  "7.203",
  "7.204",
  "7.300",
  "7.301",
  "7.302",
  "7.303",
  "7.304",
  "7.305",
  "7.400",
  "7.401",
  "7.402",
  "7.403",
  "7.404",
  "7.500",
  "7.501",
  "7.502",
  "7.503",
  "8.000",
  "8.001",
  "8.002",
  "8.003",
  "8.004",
  "8.005",
  "8.101",
  "8.102",
  "8.103",
  "8.104",
  "8.1100",
  "8.1101",
  "8.1102",
  "8.1103",
  "8.1104",
  "8.401",
  "8.402",
  "8.403",
  "8.404",
  "8.405-1",
  "8.405-2",
  "8.405-3",
  "8.405-4",
  "8.405-5",
  "8.405-6",
  "8.405-7",
  "8.405",
  "8.406-1",
  "8.406-2",
  "8.406-3",
  "8.406-4",
  "8.406-5",
  "8.406-6",
  "8.406-7",
  "8.406",
  "8.601",
  "8.602",
  "8.603",
  "8.604",
  "8.605",
  "8.606",
  "8.607",
  "8.608",
  "8.700",
  "8.701",
  "8.702",
  "8.703",
  "8.704",
  "8.705-1",
  "8.705-2",
  "8.705-3",
  "8.705-4",
  "8.705",
  "8.706",
  "8.707",
  "8.708",
  "8.709",
  "8.710",
  "8.711",
  "8.712",
  "8.713",
  "8.714",
  "8.715",
  "8.716",
  "8.800",
  "8.801",
  "8.802",
  "9.000",
  "9.100",
  "9.101",
  "9.102",
  "9.103",
  "9.104-1",
  "9.104-2",
  "9.104-3",
  "9.104-4",
  "9.104-5",
  "9.104-6",
  "9.104-7",
  "9.104",
  "9.105-1",
  "9.105-2",
  "9.105-3",
  "9.105",
  "9.106-1",
  "9.106-2",
  "9.106-3",
  "9.106-4",
  "9.106",
  "9.107",
  "9.108-1",
  "9.108-2",
  "9.108-3",
  "9.108-4",
  "9.108-5",
  "9.108",
  "9.109-1",
  "9.109-2",
  "9.109-3",
  "9.109-4",
  "9.109-5",
  "9.109",
  "9.110-1",
  "9.110-2",
  "9.110-3",
  "9.110-4",
  "9.110-5",
  "9.110",
  "9.200",
  "9.201",
  "9.202",
  "9.203",
  "9.204",
  "9.205",
  "9.206-1",
  "9.206-2",
  "9.206-3",
  "9.206",
  "9.207",
  "9.301",
  "9.302",
  "9.303",
  "9.304",
  "9.305",
  "9.306",
  "9.307",
  "9.308-1",
  "9.308-2",
  "9.308",
  "9.400",
  "9.401",
  "9.402",
  "9.403",
  "9.404",
  "9.405-1",
  "9.405-2",
  "9.405",
  "9.406-1",
  "9.406-2",
  "9.406-3",
  "9.406-4",
  "9.406-5",
  "9.406",
  "9.407-1",
  "9.407-2",
  "9.407-3",
  "9.407-4",
  "9.407-5",
  "9.407",
  "9.408",
  "9.409",
  "9.500",
  "9.501",
  "9.502",
  "9.503",
  "9.504",
  "9.505-1",
  "9.505-2",
  "9.505-3",
  "9.505-4",
  "9.505",
  "9.506",
  "9.507-1",
  "9.507-2",
  "9.507",
  "9.508",
  "9.601",
  "9.602",
  "9.603",
  "9.604",
  "9.701",
  "9.702",
  "9.703",
  "FAR 1",
  "FAR 2",
  "FAR 3",
  "FAR 4",
  "FAR 5",
  "FAR 6",
  "FAR 7",
  "FAR 8",
  "Subchapter A",
  "Subchapter B",
  "Subchapter C",
  "Subchapter D",
  "Subchapter E",
  "Subchapter F",
  "Subchapter G",
  "Subchapter H",
  "Subpart 1.1",
  "Subpart 1.2",
  "Subpart 1.3",
  "Subpart 1.4",
  "Subpart 1.5",
  "Subpart 1.6",
  "Subpart 1.7",
  "Subpart 11.1",
  "Subpart 11.2",
  "Subpart 11.3",
  "Subpart 11.4",
  "Subpart 11.5",
  "Subpart 11.6",
  "Subpart 11.7",
  "Subpart 11.8",
  "Subpart 12.1",
  "Subpart 12.2",
  "Subpart 12.3",
  "Subpart 12.4",
  "Subpart 12.5",
  "Subpart 12.6",
  "Subpart 13.1",
  "Subpart 13.2",
  "Subpart 13.3",
  "Subpart 13.4",
  "Subpart 13.5",
  "Subpart 14.1",
  "Subpart 14.2",
  "Subpart 14.3",
  "Subpart 14.4",
  "Subpart 14.5",
  "Subpart 15.1",
  "Subpart 15.2",
  "Subpart 15.3",
  "Subpart 15.4",
  "Subpart 15.5",
  "Subpart 15.6",
  "Subpart 16.1",
  "Subpart 16.2",
  "Subpart 16.3",
  "Subpart 16.4",
  "Subpart 16.5",
  "Subpart 16.6",
  "Subpart 16.7",
  "Subpart 17.1",
  "Subpart 17.2",
  "Subpart 17.3",
  "Subpart 17.4",
  "Subpart 17.5",
  "Subpart 17.6",
  "Subpart 17.7",
  "Subpart 18.1",
  "Subpart 18.2",
  "Subpart 19.1",
  "Subpart 19.10",
  "Subpart 19.11",
  "Subpart 19.12",
  "Subpart 19.13",
  "Subpart 19.14",
  "Subpart 19.15",
  "Subpart 19.2",
  "Subpart 19.3",
  "Subpart 19.4",
  "Subpart 19.5",
  "Subpart 19.6",
  "Subpart 19.7",
  "Subpart 19.8",
  "Subpart 19.9",
  "Subpart 2.1",
  "Subpart 2.2",
  "Subpart 22.1",
  "Subpart 22.10",
  "Subpart 22.11",
  "Subpart 22.12",
  "Subpart 22.13",
  "Subpart 22.14",
  "Subpart 22.15",
  "Subpart 22.16",
  "Subpart 22.17",
  "Subpart 22.18",
  "Subpart 22.19",
  "Subpart 22.2",
  "Subpart 22.20",
  "Subpart 22.21",
  "Subpart 22.3",
  "Subpart 22.4",
  "Subpart 22.5",
  "Subpart 22.6",
  "Subpart 22.7",
  "Subpart 22.8",
  "Subpart 22.9",
  "Subpart 23.1",
  "Subpart 23.10",
  "Subpart 23.11",
  "Subpart 23.2",
  "Subpart 23.3",
  "Subpart 23.4",
  "Subpart 23.5",
  "Subpart 23.6",
  "Subpart 23.7",
  "Subpart 23.8",
  "Subpart 23.9",
  "Subpart 24.1",
  "Subpart 24.2",
  "Subpart 24.3",
  "Subpart 25.1",
  "Subpart 25.10",
  "Subpart 25.11",
  "Subpart 25.2",
  "Subpart 25.3",
  "Subpart 25.4",
  "Subpart 25.5",
  "Subpart 25.6",
  "Subpart 25.7",
  "Subpart 25.8",
  "Subpart 25.9",
  "Subpart 26.1",
  "Subpart 26.2",
  "Subpart 26.3",
  "Subpart 26.4",
  "Subpart 27.1",
  "Subpart 27.2",
  "Subpart 27.3",
  "Subpart 27.4",
  "Subpart 27.5",
  "Subpart 28.1",
  "Subpart 28.2",
  "Subpart 28.3",
  "Subpart 29.1",
  "Subpart 29.2",
  "Subpart 29.3",
  "Subpart 29.4",
  "Subpart 3.1",
  "Subpart 3.10",
  "Subpart 3.11",
  "Subpart 3.2",
  "Subpart 3.3",
  "Subpart 3.4",
  "Subpart 3.5",
  "Subpart 3.6",
  "Subpart 3.7",
  "Subpart 3.8",
  "Subpart 3.9",
  "Subpart 30.1",
  "Subpart 30.2",
  "Subpart 30.3",
  "Subpart 30.4",
  "Subpart 30.5",
  "Subpart 30.6",
  "Subpart 31.1",
  "Subpart 31.2",
  "Subpart 31.3",
  "Subpart 31.4",
  "Subpart 31.5",
  "Subpart 31.6",
  "Subpart 31.7",
  "Subpart 32.1",
  "Subpart 32.10",
  "Subpart 32.11",
  "Subpart 32.2",
  "Subpart 32.3",
  "Subpart 32.4",
  "Subpart 32.5",
  "Subpart 32.6",
  "Subpart 32.7",
  "Subpart 32.8",
  "Subpart 32.9",
  "Subpart 33.1",
  "Subpart 33.2",
  "Subpart 34.1",
  "Subpart 34.2",
  "Subpart 36.1",
  "Subpart 36.2",
  "Subpart 36.3",
  "Subpart 36.4",
  "Subpart 36.5",
  "Subpart 36.6",
  "Subpart 36.7",
  "Subpart 37.1",
  "Subpart 37.2",
  "Subpart 37.3",
  "Subpart 37.4",
  "Subpart 37.5",
  "Subpart 37.6",
  "Subpart 38.1",
  "Subpart 38.2",
  "Subpart 39.1",
  "Subpart 39.2",
  "Subpart 4.1",
  "Subpart 4.10",
  "Subpart 4.11",
  "Subpart 4.12",
  "Subpart 4.13",
  "Subpart 4.14",
  "Subpart 4.15",
  "Subpart 4.16",
  "Subpart 4.17",
  "Subpart 4.18",
  "Subpart 4.19",
  "Subpart 4.2",
  "Subpart 4.20",
  "Subpart 4.21",
  "Subpart 4.22",
  "Subpart 4.23",
  "Subpart 4.3",
  "Subpart 4.4",
  "Subpart 4.5",
  "Subpart 4.6",
  "Subpart 4.7",
  "Subpart 4.8",
  "Subpart 4.9",
  "Subpart 41.1",
  "Subpart 41.2",
  "Subpart 41.3",
  "Subpart 41.4",
  "Subpart 41.5",
  "Subpart 41.6",
  "Subpart 41.7",
  "Subpart 42.1",
  "Subpart 42.10",
  "Subpart 42.11",
  "Subpart 42.12",
  "Subpart 42.13",
  "Subpart 42.14",
  "Subpart 42.15",
  "Subpart 42.16",
  "Subpart 42.17",
  "Subpart 42.2",
  "Subpart 42.3",
  "Subpart 42.4",
  "Subpart 42.5",
  "Subpart 42.6",
  "Subpart 42.7",
  "Subpart 42.8",
  "Subpart 42.9",
  "Subpart 43.1",
  "Subpart 43.2",
  "Subpart 43.3",
  "Subpart 44.1",
  "Subpart 44.2",
  "Subpart 44.3",
  "Subpart 44.4",
  "Subpart 45.1",
  "Subpart 45.2",
  "Subpart 45.3",
  "Subpart 45.4",
  "Subpart 45.5",
  "Subpart 45.6",
  "Subpart 46.1",
  "Subpart 46.2",
  "Subpart 46.3",
  "Subpart 46.4",
  "Subpart 46.5",
  "Subpart 46.6",
  "Subpart 46.7",
  "Subpart 46.8",
  "Subpart 47.1",
  "Subpart 47.2",
  "Subpart 47.3",
  "Subpart 47.4",
  "Subpart 47.5",
  "Subpart 48.1",
  "Subpart 48.2",
  "Subpart 49.1",
  "Subpart 49.2",
  "Subpart 49.3",
  "Subpart 49.4",
  "Subpart 49.5",
  "Subpart 49.6",
  "Subpart 5.1",
  "Subpart 5.2",
  "Subpart 5.3",
  "Subpart 5.4",
  "Subpart 5.5",
  "Subpart 5.6",
  "Subpart 5.7",
  "Subpart 50.1",
  "Subpart 50.2",
  "Subpart 51.1",
  "Subpart 51.2",
  "Subpart 52.1",
  "Subpart 52.2",
  "Subpart 52.3",
  "Subpart 53.1",
  "Subpart 53.2",
  "Subpart 53.3",
  "Subpart 6.1",
  "Subpart 6.2",
  "Subpart 6.3",
  "Subpart 6.4",
  "Subpart 6.5",
  "Subpart 7.1",
  "Subpart 7.2",
  "Subpart 7.3",
  "Subpart 7.4",
  "Subpart 7.5",
  "Subpart 8.1",
  "Subpart 8.10",
  "Subpart 8.11",
  "Subpart 8.2",
  "Subpart 8.3",
  "Subpart 8.4",
  "Subpart 8.5",
  "Subpart 8.6",
  "Subpart 8.7",
  "Subpart 8.8",
  "Subpart 8.9",
  "Subpart 9.1",
  "Subpart 9.2",
  "Subpart 9.3",
  "Subpart 9.4",
  "Subpart 9.5",
  "Subpart 9.6",
  "Subpart 9.7",
];

export const DFARS_KEYWORDS = [
  "DFAR",
  "DFARS",
  "201.101",
  "201.104",
  "201.105-3",
  "201.105",
  "201.106",
  "201.107",
  "201.109",
  "201.170",
  "201.201-1",
  "201.201-70",
  "201.201",
  "201.301",
  "201.303",
  "201.304",
  "201.402",
  "201.403",
  "201.404",
  "201.602-2",
  "201.602-70",
  "201.602",
  "201.603-2",
  "201.603-3",
  "201.603",
  "201.670",
  "202.101",
  "203.070",
  "203.1003",
  "203.1004",
  "203.104-1",
  "203.104-4",
  "203.104",
  "203.170",
  "203.171-1",
  "203.171-2",
  "203.171-3",
  "203.171-4",
  "203.171",
  "203.502-2",
  "203.570-1",
  "203.570-2",
  "203.570-3",
  "203.570",
  "203.703",
  "203.806",
  "203.900",
  "203.901",
  "203.903",
  "203.904",
  "203.905",
  "203.906",
  "203.909-3",
  "203.909",
  "203.970",
  "204.101",
  "204.1103",
  "204.1202",
  "204.1601",
  "204.1603",
  "204.1670",
  "204.1671",
  "204.1700",
  "204.1701",
  "204.1703",
  "204.1705",
  "204.1870",
  "204.201",
  "204.203",
  "204.2100",
  "204.2101",
  "204.2102",
  "204.2103",
  "204.2104",
  "204.2105",
  "204.270-1",
  "204.270-2",
  "204.270",
  "204.402",
  "204.403",
  "204.404-70",
  "204.404",
  "204.470-1",
  "204.470-2",
  "204.470-3",
  "204.470",
  "204.602",
  "204.604",
  "204.606",
  "204.7001",
  "204.7100",
  "204.7101",
  "204.7102",
  "204.7103-1",
  "204.7103-2",
  "204.7103",
  "204.7104-1",
  "204.7104-2",
  "204.7104",
  "204.7105",
  "204.7106",
  "204.7107",
  "204.7108",
  "204.7109",
  "204.7200",
  "204.7201",
  "204.7202",
  "204.7203",
  "204.7300",
  "204.7301",
  "204.7302",
  "204.7303",
  "204.7304",
  "204.7400",
  "204.7401",
  "204.7402",
  "204.7403",
  "204.7500",
  "204.7501",
  "204.7502",
  "204.7503",
  "204.7600",
  "204.7601",
  "204.7602",
  "204.7603",
  "204.7604",
  "204.802",
  "204.804-70",
  "204.804",
  "204.805",
  "204.902",
  "205.203",
  "205.205-70",
  "205.205-71",
  "205.205",
  "205.207",
  "205.301",
  "205.303",
  "205.470",
  "205.502",
  "206.000",
  "206.001",
  "206.001 70",
  "206.102-70",
  "206.102",
  "206.202",
  "206.302-1",
  "206.302-2",
  "206.302-3-70",
  "206.302-3",
  "206.302-4",
  "206.302-5",
  "206.302-7",
  "206.302",
  "206.303-1",
  "206.303-2",
  "206.303-70",
  "206.303",
  "206.304",
  "206.305",
  "207.102",
  "207.103",
  "207.104",
  "207.105",
  "207.106",
  "207.108",
  "207.170",
  "207.171-1",
  "207.171-2",
  "207.171-3",
  "207.171-4",
  "207.171",
  "207.172",
  "207.302",
  "207.401",
  "207.470",
  "207.471",
  "207.500",
  "207.503",
  "207.7001",
  "207.7002",
  "207.7003",
  "208.002",
  "208.404",
  "208.405-6",
  "208.405",
  "208.406-1",
  "208.406",
  "208.602-70",
  "208.602",
  "208.606",
  "208.7000",
  "208.7001",
  "208.7002-1",
  "208.7002-2",
  "208.7002",
  "208.7003-1",
  "208.7003-2",
  "208.7003",
  "208.7004",
  "208.7005",
  "208.7006",
  "208.705",
  "208.7100",
  "208.7101",
  "208.7102",
  "208.7301",
  "208.7302",
  "208.7303",
  "208.7304",
  "208.7305",
  "208.7400",
  "208.7401",
  "208.7402",
  "208.7403",
  "209.101",
  "209.104-1",
  "209.104-4",
  "209.104-70",
  "209.104",
  "209.105-1",
  "209.105-2-70",
  "209.105-2",
  "209.105",
  "209.106",
  "209.170-1",
  "209.170-2",
  "209.170-3",
  "209.170-4",
  "209.170",
  "209.202",
  "209.270-1",
  "209.270-2",
  "209.270-3",
  "209.270-4",
  "209.270-5",
  "209.270",
  "209.402",
  "209.403",
  "209.405-2",
  "209.405",
  "209.406-1",
  "209.406-2",
  "209.406-3",
  "209.406",
  "209.407-3",
  "209.407",
  "209.409",
  "209.470",
  "209.471",
  "209.505-4",
  "209.505",
  "209.570-1",
  "209.570-2",
  "209.570-3",
  "209.570-4",
  "209.570",
  "209.571-0",
  "209.571-1",
  "209.571-2",
  "209.571-3",
  "209.571-4",
  "209.571-5",
  "209.571-6",
  "209.571-7",
  "209.571-8",
  "209.571",
  "210.001",
  "210.002",
  "211.002",
  "211.104",
  "211.106",
  "211.107",
  "211.170",
  "211.201",
  "211.204",
  "211.270",
  "211.271",
  "211.272",
  "211.273-1",
  "211.273-2",
  "211.273-3",
  "211.273-4",
  "211.273",
  "211.274-1",
  "211.274-2",
  "211.274-3",
  "211.274-4",
  "211.274-5",
  "211.274",
  "211.275",
  "211.500",
  "211.503",
  "211.602",
  "211.7001",
  "212.001",
  "212.102",
  "212.203",
  "212.205",
  "212.207",
  "212.209",
  "212.211",
  "212.212",
  "212.270",
  "212.271",
  "212.272",
  "212.301",
  "212.302",
  "212.370",
  "212.371",
  "212.503",
  "212.504",
  "212.505",
  "212.602",
  "212.7000",
  "212.7001",
  "212.7002",
  "212.7003",
  "212.7004",
  "212.7005",
  "213.101",
  "213.104",
  "213.106-1-70",
  "213.106-1",
  "213.106-2-70",
  "213.106-2",
  "213.106",
  "213.201",
  "213.270",
  "213.301",
  "213.302-3",
  "213.302-5",
  "213.302",
  "213.303-5",
  "213.303",
  "213.305-3",
  "213.305",
  "213.306",
  "213.307",
  "213.402",
  "213.500-70",
  "213.500",
  "213.501",
  "213.7001",
  "213.7002",
  "214.201-5",
  "214.201-6",
  "214.201",
  "214.202-5",
  "214.202",
  "214.209",
  "214.404-1",
  "214.404",
  "214.407-3",
  "214.407",
  "214.408-1",
  "214.408",
  "214.503-1",
  "214.503",
  "215.101-2-70",
  "215.101-2",
  "215.101-70",
  "215.101",
  "215.203-70",
  "215.203",
  "215.209",
  "215.270",
  "215.300",
  "215.303",
  "215.304",
  "215.305",
  "215.306",
  "215.370-1",
  "215.370-2",
  "215.370-3",
  "215.370",
  "215.371-1",
  "215.371-2",
  "215.371-3",
  "215.371-4",
  "215.371-5",
  "215.371-6",
  "215.371",
  "215.401",
  "215.402",
  "215.403-1",
  "215.403-3",
  "215.403-5",
  "215.403",
  "215.404-1",
  "215.404-2",
  "215.404-3",
  "215.404-4",
  "215.404-70",
  "215.404-71-1",
  "215.404-71-2",
  "215.404-71-3",
  "215.404-71-4",
  "215.404-71-5",
  "215.404-71",
  "215.404-72",
  "215.404-73",
  "215.404-74",
  "215.404-75",
  "215.404",
  "215.406-1",
  "215.406-2",
  "215.406-3",
  "215.406",
  "215.407-1",
  "215.407-2",
  "215.407-3",
  "215.407-4",
  "215.407-5-70",
  "215.407-5",
  "215.407",
  "215.408",
  "215.470",
  "215.503",
  "215.506-70",
  "215.506",
  "215.570",
  "215.602",
  "215.604",
  "216.102",
  "216.104-70",
  "216.104",
  "216.203-4-70",
  "216.203-4",
  "216.203",
  "216.301-3",
  "216.301",
  "216.306",
  "216.307",
  "216.401-71",
  "216.401",
  "216.402-2",
  "216.402",
  "216.403-1",
  "216.403-2",
  "216.403",
  "216.405-1",
  "216.405-2-70",
  "216.405-2-71",
  "216.405-2",
  "216.405",
  "216.406",
  "216.470",
  "216.501-2-70",
  "216.501",
  "216.504",
  "216.505-70",
  "216.505",
  "216.506-70",
  "216.506",
  "216.601",
  "216.603-2",
  "216.603-3",
  "216.603-4",
  "216.603",
  "216.703",
  "217.103",
  "217.170",
  "217.171",
  "217.172",
  "217.173",
  "217.174",
  "217.202",
  "217.204",
  "217.207",
  "217.208-70",
  "217.208",
  "217.500",
  "217.502-1",
  "217.502",
  "217.503",
  "217.600",
  "217.700",
  "217.7000",
  "217.7001",
  "217.7002",
  "217.7003",
  "217.7004",
  "217.7005",
  "217.701",
  "217.7100",
  "217.7101",
  "217.7102",
  "217.7103-1",
  "217.7103-2",
  "217.7103-3",
  "217.7103-4",
  "217.7103-5",
  "217.7103-6",
  "217.7103",
  "217.7104",
  "217.7300",
  "217.7301",
  "217.7302",
  "217.7303",
  "217.7400",
  "217.7401",
  "217.7402",
  "217.7403",
  "217.7404-1",
  "217.7404-2",
  "217.7404-3",
  "217.7404-4",
  "217.7404-5",
  "217.7404-6",
  "217.7404",
  "217.7405",
  "217.7406",
  "217.7500",
  "217.7501",
  "217.7502",
  "217.7503",
  "217.7504",
  "217.7505",
  "217.7506",
  "217.7601",
  "217.770",
  "217.7701",
  "217.7702",
  "217.7801",
  "218.170",
  "218.201",
  "218.202",
  "218.203",
  "218.204",
  "218.270",
  "218.271",
  "219.1307",
  "219.201",
  "219.202-1",
  "219.202",
  "219.270-1",
  "219.270-2",
  "219.270-3",
  "219.270",
  "219.301-2",
  "219.301-3",
  "219.301",
  "219.309",
  "219.401",
  "219.402",
  "219.502-1",
  "219.502-2",
  "219.502-8",
  "219.502",
  "219.602",
  "219.702-70",
  "219.702",
  "219.703",
  "219.704",
  "219.705-4",
  "219.705-6",
  "219.705",
  "219.706",
  "219.708",
  "219.7100",
  "219.7101",
  "219.7102",
  "219.7103-1",
  "219.7103-2",
  "219.7103",
  "219.7104",
  "219.7105",
  "219.7106",
  "219.800",
  "219.803",
  "219.804-1",
  "219.804",
  "219.805-1",
  "219.805-2",
  "219.805",
  "219.806",
  "219.808-1",
  "219.808",
  "219.811-3",
  "219.811",
  "222.001",
  "222.1003-1",
  "222.1003",
  "222.1008-1",
  "222.1008",
  "222.101-1",
  "222.101-3-70",
  "222.101-3",
  "222.101-4",
  "222.101-70",
  "222.101",
  "222.102-1",
  "222.102",
  "222.103-4",
  "222.103",
  "222.1305",
  "222.1308",
  "222.1310",
  "222.1403",
  "222.1406",
  "222.1703",
  "222.1704",
  "222.1770",
  "222.302",
  "222.402-70",
  "222.402",
  "222.403-470",
  "222.403",
  "222.404-2",
  "222.404",
  "222.406-1",
  "222.406-10",
  "222.406-13",
  "222.406-6",
  "222.406-8",
  "222.406-9",
  "222.406",
  "222.604-2",
  "222.604",
  "222.7000",
  "222.7001",
  "222.7002",
  "222.7003",
  "222.7004",
  "222.7201",
  "222.7300",
  "222.7301",
  "222.7302",
  "222.7400",
  "222.7401",
  "222.7402",
  "222.7403",
  "222.7404",
  "222.7405",
  "222.806",
  "222.807",
  "223.302",
  "223.303",
  "223.370-1",
  "223.370-2",
  "223.370-3",
  "223.370-4",
  "223.370-5",
  "223.370",
  "223.405",
  "223.570-1",
  "223.570-2",
  "223.570",
  "223.7101",
  "223.7102",
  "223.7103",
  "223.7104",
  "223.7105",
  "223.7106",
  "223.7200",
  "223.7201",
  "223.7202",
  "223.7203",
  "223.7300",
  "223.7301",
  "223.7302",
  "223.7303",
  "223.7304",
  "223.7305",
  "223.7306",
  "223.7400",
  "223.7401",
  "223.7402",
  "223.7403",
  "223.7404",
  "223.802",
  "224.103",
  "224.203",
  "225.001",
  "225.003",
  "225.070",
  "225.101",
  "225.103",
  "225.106",
  "225.1070",
  "225.1100",
  "225.1101",
  "225.1103",
  "225.170",
  "225.202",
  "225.206",
  "225.301-1",
  "225.301-4",
  "225.301",
  "225.302-6",
  "225.302",
  "225.370",
  "225.371-1",
  "225.371-2",
  "225.371-3",
  "225.371-4",
  "225.371-5",
  "225.371",
  "225.372-1",
  "225.372-2",
  "225.372",
  "225.373",
  "225.374",
  "225.401-70",
  "225.401-71",
  "225.401",
  "225.402",
  "225.403",
  "225.408",
  "225.502",
  "225.503",
  "225.504",
  "225.7000",
  "225.7001",
  "225.7002-1",
  "225.7002-2",
  "225.7002-3",
  "225.7002",
  "225.7003-1",
  "225.7003-2",
  "225.7003-3",
  "225.7003-4",
  "225.7003-5",
  "225.7003",
  "225.7004-1",
  "225.7004-2",
  "225.7004-3",
  "225.7004-4",
  "225.7004",
  "225.7005-1",
  "225.7005-2",
  "225.7005-3",
  "225.7005",
  "225.7006-1",
  "225.7006-2",
  "225.7006-3",
  "225.7006-4",
  "225.7006",
  "225.7007-1",
  "225.7007-2",
  "225.7007-3",
  "225.7007",
  "225.7008",
  "225.7009-1",
  "225.7009-2",
  "225.7009-3",
  "225.7009-4",
  "225.7009-5",
  "225.7009",
  "225.701-70",
  "225.701",
  "225.7010-1",
  "225.7010-2",
  "225.7010-3",
  "225.7010-4",
  "225.7010-5",
  "225.7010",
  "225.7011-1",
  "225.7011-2",
  "225.7011-3",
  "225.7011",
  "225.7012-1",
  "225.7012-2",
  "225.7012-3",
  "225.7012",
  "225.7013-0",
  "225.7013-1",
  "225.7013-2",
  "225.7013",
  "225.7014",
  "225.7015",
  "225.7017-1",
  "225.7017-2",
  "225.7017-3",
  "225.7017-4",
  "225.7017",
  "225.7018-1",
  "225.7018-2",
  "225.7018-3",
  "225.7018-4",
  "225.7018-5",
  "225.7018",
  "225.7019-1",
  "225.7019-2",
  "225.7019-3",
  "225.7019-4",
  "225.7019",
  "225.7020-1",
  "225.7020-2",
  "225.7020-3",
  "225.7020-4",
  "225.7020-5",
  "225.7020",
  "225.7021-1",
  "225.7021-2",
  "225.7021-3",
  "225.7021-4",
  "225.7021",
  "225.7022-1",
  "225.7022-2",
  "225.7022-3",
  "225.7022-4",
  "225.7022-5",
  "225.7022",
  "225.7023-1",
  "225.7023-2",
  "225.7023-3",
  "225.7023-4",
  "225.7023",
  "225.7100",
  "225.7101",
  "225.7102-1",
  "225.7102-2",
  "225.7102-3",
  "225.7102-4",
  "225.7102",
  "225.7201",
  "225.7202",
  "225.7203",
  "225.7204",
  "225.7300",
  "225.7301-1",
  "225.7301-2",
  "225.7301",
  "225.7302",
  "225.7303-1",
  "225.7303-2",
  "225.7303-3",
  "225.7303-4",
  "225.7303-5",
  "225.7303",
  "225.7304",
  "225.7305",
  "225.7306",
  "225.7307",
  "225.7500",
  "225.7501",
  "225.7502",
  "225.7503",
  "225.7601",
  "225.7602",
  "225.7603",
  "225.7604",
  "225.7605",
  "225.770-1",
  "225.770-2",
  "225.770-3",
  "225.770-4",
  "225.770-5",
  "225.770",
  "225.7700",
  "225.7701",
  "225.7702-1",
  "225.7702-2",
  "225.7702",
  "225.7703-1",
  "225.7703-2",
  "225.7703-3",
  "225.7703-4",
  "225.7703",
  "225.7704-1",
  "225.7704-2",
  "225.7704-3",
  "225.7704",
  "225.7705-1",
  "225.7705-2",
  "225.7705-3",
  "225.7705",
  "225.771-0",
  "225.771-1",
  "225.771-2",
  "225.771-3",
  "225.771-4",
  "225.771-5",
  "225.771",
  "225.772-0",
  "225.772-1",
  "225.772-2",
  "225.772-3",
  "225.772-4",
  "225.772-5",
  "225.772",
  "225.7798",
  "225.7799",
  "225.7801",
  "225.7900",
  "225.7901-1",
  "225.7901-2",
  "225.7901-3",
  "225.7901-4",
  "225.7901",
  "225.7902-1",
  "225.7902-2",
  "225.7902-3",
  "225.7902-4",
  "225.7902-5",
  "225.7902",
  "225.802-70",
  "225.802-71",
  "225.802",
  "225.870-1",
  "225.870-2",
  "225.870-3",
  "225.870-4",
  "225.870-5",
  "225.870-6",
  "225.870-7",
  "225.870-8",
  "225.870",
  "225.871-1",
  "225.871-2",
  "225.871-3",
  "225.871-4",
  "225.871-5",
  "225.871-6",
  "225.871-7",
  "225.871",
  "225.872-1",
  "225.872-2",
  "225.872-3",
  "225.872-4",
  "225.872-5",
  "225.872-6",
  "225.872-7",
  "225.872-8",
  "225.872",
  "225.873-1",
  "225.873-2",
  "225.873",
  "225.900-70",
  "225.900",
  "225.901",
  "225.902",
  "225.903",
  "226.103",
  "226.104",
  "226.7100",
  "226.7101",
  "226.7102",
  "226.7103",
  "226.7104",
  "226.7200",
  "226.7201",
  "226.7202",
  "226.7203",
  "227.303",
  "227.304-1",
  "227.304",
  "227.400",
  "227.670",
  "227.671",
  "227.672",
  "227.673",
  "227.674",
  "227.675-1",
  "227.675-2",
  "227.675",
  "227.676",
  "227.7000",
  "227.7001",
  "227.7002",
  "227.7003",
  "227.7004",
  "227.7005",
  "227.7006",
  "227.7007",
  "227.7008",
  "227.7009-1",
  "227.7009-2",
  "227.7009-3",
  "227.7009-4",
  "227.7009",
  "227.7010",
  "227.7011",
  "227.7012",
  "227.7013",
  "227.7100",
  "227.7101",
  "227.7102-1",
  "227.7102-2",
  "227.7102-3",
  "227.7102-4",
  "227.7102",
  "227.7103-1",
  "227.7103-10",
  "227.7103-11",
  "227.7103-12",
  "227.7103-13",
  "227.7103-14",
  "227.7103-15",
  "227.7103-16",
  "227.7103-17",
  "227.7103-2",
  "227.7103-3",
  "227.7103-4",
  "227.7103-5",
  "227.7103-6",
  "227.7103-7",
  "227.7103-8",
  "227.7103-9",
  "227.7103",
  "227.7104",
  "227.7105-1",
  "227.7105-2",
  "227.7105-3",
  "227.7105",
  "227.7106",
  "227.7107-1",
  "227.7107-2",
  "227.7107-3",
  "227.7107",
  "227.7108",
  "227.7200",
  "227.7201",
  "227.7202-1",
  "227.7202-2",
  "227.7202-3",
  "227.7202-4",
  "227.7202",
  "227.7203-1",
  "227.7203-10",
  "227.7203-11",
  "227.7203-12",
  "227.7203-13",
  "227.7203-14",
  "227.7203-15",
  "227.7203-16",
  "227.7203-17",
  "227.7203-2",
  "227.7203-3",
  "227.7203-4",
  "227.7203-5",
  "227.7203-6",
  "227.7203-8",
  "227.7203-9",
  "227.7203",
  "227.7204",
  "227.7205",
  "227.7206",
  "227.7207",
  "228.102-1",
  "228.102-70",
  "228.102",
  "228.105",
  "228.106-7",
  "228.106",
  "228.304",
  "228.305",
  "228.307-1",
  "228.307",
  "228.311-1",
  "228.311",
  "228.370-1",
  "228.370-2",
  "228.370-3",
  "228.370",
  "228.371",
  "229.101",
  "229.170-1",
  "229.170-2",
  "229.170-3",
  "229.170-4",
  "229.170",
  "229.204",
  "229.402-70",
  "229.402",
  "230.201-5",
  "230.201",
  "231.100-70",
  "231.100",
  "231.205-1",
  "231.205-18",
  "231.205-19",
  "231.205-22",
  "231.205-6",
  "231.205-70",
  "231.205-71",
  "231.205",
  "231.303",
  "231.603",
  "231.703",
  "232.001",
  "232.006-5",
  "232.006",
  "232.007",
  "232.009-1",
  "232.009",
  "232.070",
  "232.071",
  "232.072-1",
  "232.072-2",
  "232.072-3",
  "232.072",
  "232.1001",
  "232.1003-70",
  "232.1003",
  "232.1004",
  "232.1005-70",
  "232.1005",
  "232.102-70",
  "232.102",
  "232.104",
  "232.1108-70",
  "232.1108",
  "232.1110",
  "232.202-4",
  "232.202",
  "232.206",
  "232.302",
  "232.404",
  "232.409-1",
  "232.409",
  "232.410",
  "232.412-70",
  "232.412",
  "232.470",
  "232.501-1",
  "232.501-2",
  "232.501-3",
  "232.501",
  "232.502-4-70",
  "232.502-4",
  "232.502",
  "232.503-15",
  "232.503-6",
  "232.503",
  "232.602",
  "232.603",
  "232.604",
  "232.610",
  "232.611",
  "232.670",
  "232.671",
  "232.7000",
  "232.7001",
  "232.7002",
  "232.7003",
  "232.7004",
  "232.702",
  "232.703-1",
  "232.703-3",
  "232.703-70",
  "232.703",
  "232.704-70",
  "232.704",
  "232.706-70",
  "232.706",
  "232.7100",
  "232.7101",
  "232.7102",
  "232.803",
  "232.805",
  "232.806",
  "232.901",
  "232.903",
  "232.904",
  "232.905",
  "232.906",
  "232.908",
  "233.102",
  "233.104",
  "233.170",
  "233.171",
  "233.204-70",
  "233.204",
  "233.210",
  "233.215-70",
  "233.215",
  "234.001",
  "234.003",
  "234.004",
  "234.005-1",
  "234.005-2",
  "234.005",
  "234.201",
  "234.203",
  "234.7000",
  "234.7001",
  "234.7002",
  "234.7100",
  "234.7101",
  "235.001",
  "235.006-70",
  "235.006-71",
  "235.006",
  "235.008",
  "235.010",
  "235.015-70",
  "235.015",
  "235.016",
  "235.017-1",
  "235.017",
  "235.070-1",
  "235.070-2",
  "235.070-3",
  "235.070",
  "235.071",
  "235.072",
  "236.102",
  "236.203",
  "236.204",
  "236.206",
  "236.211",
  "236.213",
  "236.215",
  "236.270",
  "236.271",
  "236.272",
  "236.273",
  "236.274",
  "236.275",
  "236.303-1",
  "236.303",
  "236.570",
  "236.601",
  "236.602-1",
  "236.602-70",
  "236.602",
  "236.604",
  "236.606-70",
  "236.606",
  "236.609-70",
  "236.609",
  "236.701",
  "237.101",
  "237.102-70",
  "237.102-71",
  "237.102-72",
  "237.102-73",
  "237.102-74",
  "237.102-75",
  "237.102-76",
  "237.102-77",
  "237.102-78",
  "237.102-79",
  "237.102",
  "237.104",
  "237.106",
  "237.109",
  "237.170-1",
  "237.170-2",
  "237.170",
  "237.171-1",
  "237.171-2",
  "237.171-3",
  "237.171-4",
  "237.171",
  "237.172",
  "237.173-1",
  "237.173-2",
  "237.173-3",
  "237.173-4",
  "237.173-5",
  "237.173",
  "237.174",
  "237.175",
  "237.270",
  "237.503",
  "237.7000",
  "237.7001",
  "237.7002",
  "237.7003",
  "237.7100",
  "237.7101",
  "237.7200",
  "237.7201",
  "237.7202",
  "237.7203",
  "237.7204",
  "237.7300",
  "237.7301",
  "237.7302",
  "237.7303",
  "237.7400",
  "237.7401",
  "237.7402",
  "237.7501",
  "237.7502",
  "237.7600",
  "237.7601",
  "237.7602",
  "237.7603",
  "237.7700",
  "237.7701",
  "237.7702",
  "237.7800",
  "237.7801",
  "237.7802",
  "237.7803",
  "237.7804",
  "239.001",
  "239.101",
  "239.7001",
  "239.7100",
  "239.7101",
  "239.7102-1",
  "239.7102-2",
  "239.7102-3",
  "239.7102",
  "239.7103",
  "239.7201",
  "239.7300",
  "239.7301",
  "239.7302",
  "239.7303",
  "239.7304",
  "239.7305",
  "239.7306",
  "239.7400",
  "239.7401",
  "239.7402",
  "239.7403",
  "239.7404",
  "239.7405",
  "239.7406",
  "239.7407",
  "239.7408-1",
  "239.7408-2",
  "239.7408",
  "239.7409",
  "239.7410",
  "239.7411",
  "239.7600",
  "239.7601",
  "239.7602-1",
  "239.7602-2",
  "239.7602",
  "239.7603",
  "239.7604",
  "241.101",
  "241.102",
  "241.103",
  "241.201",
  "241.202",
  "241.205",
  "241.501-70",
  "241.501",
  "242.002",
  "242.1104",
  "242.1105",
  "242.1106",
  "242.1107",
  "242.1203",
  "242.1204",
  "242.1502",
  "242.200-70",
  "242.200",
  "242.202",
  "242.301",
  "242.302",
  "242.503-2",
  "242.503",
  "242.602",
  "242.7000",
  "242.7001",
  "242.705-1",
  "242.705-2",
  "242.705",
  "242.708",
  "242.7100",
  "242.7200",
  "242.7201",
  "242.7202",
  "242.7203",
  "242.7204",
  "242.7301",
  "242.7302",
  "242.7303",
  "242.7400",
  "242.7401",
  "242.7501",
  "242.7502",
  "242.7503",
  "242.770",
  "242.771-1",
  "242.771-2",
  "242.771-3",
  "242.771",
  "242.803",
  "243.107-70",
  "243.107",
  "243.170",
  "243.171",
  "243.172",
  "243.204-70-1",
  "243.204-70-2",
  "243.204-70-3",
  "243.204-70-4",
  "243.204-70-5",
  "243.204-70-6",
  "243.204-70",
  "243.204-71",
  "243.204",
  "243.204 70 7",
  "243.205-70",
  "243.205-71",
  "243.205-72",
  "243.205",
  "244.101",
  "244.201-1",
  "244.201",
  "244.202-2",
  "244.202",
  "244.301",
  "244.302",
  "244.303",
  "244.305-70",
  "244.305-71",
  "244.305",
  "244.402",
  "244.403",
  "245.101",
  "245.102",
  "245.103-70",
  "245.103-71",
  "245.103-72",
  "245.103-73",
  "245.103-74",
  "245.103",
  "245.104",
  "245.105",
  "245.107",
  "245.201-70",
  "245.201",
  "245.302",
  "245.402-70",
  "245.402-71",
  "245.402",
  "245.570",
  "245.602-1",
  "245.602-3",
  "245.602-70",
  "245.602",
  "245.604-1",
  "245.604",
  "246.101",
  "246.102",
  "246.103",
  "246.202-4",
  "246.202",
  "246.270-1",
  "246.270-2",
  "246.270-3",
  "246.270-4",
  "246.270",
  "246.370",
  "246.401",
  "246.402",
  "246.404",
  "246.406",
  "246.407",
  "246.408-70",
  "246.408-71",
  "246.408",
  "246.470-1",
  "246.470-2",
  "246.470",
  "246.471",
  "246.472",
  "246.504",
  "246.601",
  "246.702-70",
  "246.702",
  "246.704",
  "246.705",
  "246.706",
  "246.708",
  "246.710-70",
  "246.710",
  "246.870-0",
  "246.870-1",
  "246.870-2",
  "246.870-3",
  "246.870",
  "247.001",
  "247.101",
  "247.200",
  "247.206",
  "247.207",
  "247.270-1",
  "247.270-2",
  "247.270-3",
  "247.270-4",
  "247.270",
  "247.271-1",
  "247.271-2",
  "247.271-3",
  "247.271",
  "247.301-70",
  "247.301-71",
  "247.301",
  "247.305-10",
  "247.305",
  "247.370",
  "247.371",
  "247.372",
  "247.570",
  "247.571",
  "247.572",
  "247.573",
  "247.574",
  "249.105-1",
  "249.105-2",
  "249.105",
  "249.109-7",
  "249.109-70",
  "249.109",
  "249.110",
  "249.501-70",
  "249.501",
  "249.7000",
  "249.7001",
  "249.7002",
  "249.7003",
  "249.7004",
  "250.100",
  "250.101-2-70",
  "250.101-2",
  "250.101-3",
  "250.101",
  "250.102-1-70",
  "250.102-1",
  "250.102-2",
  "250.102",
  "250.103-3",
  "250.103-5",
  "250.103-6",
  "250.103",
  "250.104-3-70",
  "250.104-3",
  "250.104",
  "251.101",
  "251.102",
  "251.107",
  "251.202",
  "251.205",
  "252.101",
  "252.103",
  "252.201-7000",
  "252.201",
  "252.203-7000",
  "252.203-7001",
  "252.203-7002",
  "252.203-7003",
  "252.203-7004",
  "252.203-7005",
  "252.203",
  "252.204-7000",
  "252.204-7001",
  "252.204-7002",
  "252.204-7003",
  "252.204-7004",
  "252.204-7005",
  "252.204-7006",
  "252.204-7007",
  "252.204-7008",
  "252.204-7009",
  "252.204-7010",
  "252.204-7011",
  "252.204-7012",
  "252.204-7013",
  "252.204-7014",
  "252.204-7015",
  "252.204-7016",
  "252.204-7017",
  "252.204-7018",
  "252.204-7019",
  "252.204-7020",
  "252.204-7021",
  "252.204-7022",
  "252.204-7023",
  "252.204-7024",
  "252.204",
  "252.205-7000",
  "252.205",
  "252.206-7000",
  "252.206",
  "252.208-7000",
  "252.208",
  "252.209-7000",
  "252.209-7001",
  "252.209-7002",
  "252.209-7003",
  "252.209-7004",
  "252.209-7005",
  "252.209-7006",
  "252.209-7007",
  "252.209-7008",
  "252.209-7009",
  "252.209-7010",
  "252.209-7011",
  "252.209-7998",
  "252.209-7999",
  "252.209",
  "252.211-7000",
  "252.211-7001",
  "252.211-7002",
  "252.211-7003",
  "252.211-7004",
  "252.211-7005",
  "252.211-7006",
  "252.211-7007",
  "252.211-7008",
  "252.211",
  "252.212-7000",
  "252.212-7001",
  "252.212-7002",
  "252.212",
  "252.213-7000",
  "252.213",
  "252.215-7000",
  "252.215-7001",
  "252.215-7002",
  "252.215-7003",
  "252.215-7004",
  "252.215-7005",
  "252.215-7006",
  "252.215-7007",
  "252.215-7008",
  "252.215-7009",
  "252.215-7010",
  "252.215-7011",
  "252.215-7012",
  "252.215-7013",
  "252.215-7014",
  "252.215-7015",
  "252.215-7016",
  "252.215",
  "252.216-7000",
  "252.216-7001",
  "252.216-7002",
  "252.216-7003",
  "252.216-7004",
  "252.216-7005",
  "252.216-7006",
  "252.216-7007",
  "252.216-7008",
  "252.216-7009",
  "252.216-7010",
  "252.216",
  "252.217-7000",
  "252.217-7001",
  "252.217-7002",
  "252.217-7003",
  "252.217-7004",
  "252.217-7005",
  "252.217-7006",
  "252.217-7007",
  "252.217-7008",
  "252.217-7009",
  "252.217-7010",
  "252.217-7011",
  "252.217-7012",
  "252.217-7013",
  "252.217-7014",
  "252.217-7015",
  "252.217-7016",
  "252.217-7017",
  "252.217-7018",
  "252.217-7019",
  "252.217-7020",
  "252.217-7021",
  "252.217-7022",
  "252.217-7023",
  "252.217-7024",
  "252.217-7025",
  "252.217-7026",
  "252.217-7027",
  "252.217-7028",
  "252.217",
  "252.219-7000",
  "252.219-7001",
  "252.219-7002",
  "252.219-7003",
  "252.219-7004",
  "252.219-7005",
  "252.219-7006",
  "252.219-7007",
  "252.219-7008",
  "252.219-7009",
  "252.219-7010",
  "252.219-7011",
  "252.219-7012",
  "252.219",
  "252.222-7000",
  "252.222-7001",
  "252.222-7002",
  "252.222-7003",
  "252.222-7004",
  "252.222-7005",
  "252.222-7006",
  "252.222",
  "252.223-7000",
  "252.223-7001",
  "252.223-7002",
  "252.223-7003",
  "252.223-7004",
  "252.223-7005",
  "252.223-7006",
  "252.223-7007",
  "252.223-7008",
  "252.223-7009",
  "252.223",
  "252.225-7000",
  "252.225-7001",
  "252.225-7002",
  "252.225-7003",
  "252.225-7004",
  "252.225-7005",
  "252.225-7006",
  "252.225-7007",
  "252.225-7008",
  "252.225-7009",
  "252.225-7010",
  "252.225-7011",
  "252.225-7012",
  "252.225-7013",
  "252.225-7014",
  "252.225-7015",
  "252.225-7016",
  "252.225-7017",
  "252.225-7018",
  "252.225-7019",
  "252.225-7020",
  "252.225-7021",
  "252.225-7022",
  "252.225-7023",
  "252.225-7024",
  "252.225-7025",
  "252.225-7026",
  "252.225-7027",
  "252.225-7028",
  "252.225-7029",
  "252.225-7030",
  "252.225-7031",
  "252.225-7032",
  "252.225-7033",
  "252.225-7034",
  "252.225-7035",
  "252.225-7036",
  "252.225-7037",
  "252.225-7038",
  "252.225-7039",
  "252.225-7040",
  "252.225-7041",
  "252.225-7042",
  "252.225-7043",
  "252.225-7044",
  "252.225-7045",
  "252.225-7046",
  "252.225-7047",
  "252.225-7048",
  "252.225-7049",
  "252.225-7050",
  "252.225-7051",
  "252.225-7052",
  "252.225-7053",
  "252.225-7054",
  "252.225-7055",
  "252.225-7056",
  "252.225-7057",
  "252.225-7058",
  "252.225-7059",
  "252.225-7060",
  "252.225-7061",
  "252.225-7062",
  "252.225",
  "252.226-7001",
  "252.226-7002",
  "252.226",
  "252.227-7000",
  "252.227-7001",
  "252.227-7002",
  "252.227-7003",
  "252.227-7004",
  "252.227-7005",
  "252.227-7006",
  "252.227-7007",
  "252.227-7008",
  "252.227-7009",
  "252.227-7010",
  "252.227-7011",
  "252.227-7012",
  "252.227-7013",
  "252.227-7014",
  "252.227-7015",
  "252.227-7016",
  "252.227-7017",
  "252.227-7018",
  "252.227-7019",
  "252.227-7020",
  "252.227-7021",
  "252.227-7022",
  "252.227-7023",
  "252.227-7024",
  "252.227-7025",
  "252.227-7026",
  "252.227-7027",
  "252.227-7028",
  "252.227-7029",
  "252.227-7030",
  "252.227-7031",
  "252.227-7032",
  "252.227-7033",
  "252.227-7034",
  "252.227-7035",
  "252.227-7036",
  "252.227-7037",
  "252.227-7038",
  "252.227-7039",
  "252.227",
  "252.228-7000",
  "252.228-7001",
  "252.228-7002",
  "252.228-7003",
  "252.228-7004",
  "252.228-7005",
  "252.228-7006",
  "252.228-7007",
  "252.228",
  "252.229-7000",
  "252.229-7001",
  "252.229-7002",
  "252.229-7003",
  "252.229-7004",
  "252.229-7005",
  "252.229-7006",
  "252.229-7007",
  "252.229-7008",
  "252.229-7009",
  "252.229-7010",
  "252.229-7011",
  "252.229-7012",
  "252.229-7013",
  "252.229-7014",
  "252.229",
  "252.231-7000",
  "252.231",
  "252.232-7000",
  "252.232-7001",
  "252.232-7002",
  "252.232-7003",
  "252.232-7004",
  "252.232-7005",
  "252.232-7006",
  "252.232-7007",
  "252.232-7008",
  "252.232-7009",
  "252.232-7010",
  "252.232-7011",
  "252.232-7012",
  "252.232-7013",
  "252.232-7014",
  "252.232-7015",
  "252.232-7016",
  "252.232-7017",
  "252.232-7018",
  "252.232",
  "252.233-7000",
  "252.233-7001",
  "252.233",
  "252.234-7001",
  "252.234-7002",
  "252.234-7004",
  "252.234",
  "252.234 7003",
  "252.235-7000",
  "252.235-7001",
  "252.235-7002",
  "252.235-7003",
  "252.235-7004",
  "252.235-7005",
  "252.235-7006",
  "252.235-7007",
  "252.235-7008",
  "252.235-7009",
  "252.235-7010",
  "252.235-7011",
  "252.235",
  "252.236-7000",
  "252.236-7001",
  "252.236-7002",
  "252.236-7003",
  "252.236-7004",
  "252.236-7005",
  "252.236-7006",
  "252.236-7007",
  "252.236-7008",
  "252.236-7009",
  "252.236-7010",
  "252.236-7011",
  "252.236-7012",
  "252.236-7013",
  "252.236",
  "252.237-7000",
  "252.237-7001",
  "252.237-7002",
  "252.237-7003",
  "252.237-7004",
  "252.237-7005",
  "252.237-7006",
  "252.237-7007",
  "252.237-7008",
  "252.237-7009",
  "252.237-7010",
  "252.237-7011",
  "252.237-7012",
  "252.237-7013",
  "252.237-7014",
  "252.237-7015",
  "252.237-7016",
  "252.237-7017",
  "252.237-7018",
  "252.237-7019",
  "252.237-7020",
  "252.237-7021",
  "252.237-7022",
  "252.237-7023",
  "252.237-7024",
  "252.237-7025",
  "252.237-7026",
  "252.237-7027",
  "252.237",
  "252.239-7000",
  "252.239-7001",
  "252.239-7002",
  "252.239-7003",
  "252.239-7004",
  "252.239-7005",
  "252.239-7006",
  "252.239-7007",
  "252.239-7008",
  "252.239-7009",
  "252.239-7010",
  "252.239-7011",
  "252.239-7012",
  "252.239-7013",
  "252.239-7014",
  "252.239-7015",
  "252.239-7016",
  "252.239-7017",
  "252.239-7018",
  "252.239",
  "252.241-7000",
  "252.241-7001",
  "252.241",
  "252.242-7000",
  "252.242-7001",
  "252.242-7002",
  "252.242-7003",
  "252.242-7004",
  "252.242-7005",
  "252.242-7006",
  "252.242",
  "252.243-7000",
  "252.243-7001",
  "252.243-7002",
  "252.243",
  "252.244-7000",
  "252.244-7001",
  "252.244",
  "252.245-7002",
  "252.245-7003",
  "252.245-7004",
  "252.245-7005",
  "252.245",
  "252.245 7000",
  "252.245 7001",
  "252.246-7000",
  "252.246-7001",
  "252.246-7002",
  "252.246-7003",
  "252.246-7004",
  "252.246-7005",
  "252.246-7006",
  "252.246-7007",
  "252.246-7008",
  "252.246",
  "252.247-7000",
  "252.247-7001",
  "252.247-7002",
  "252.247-7003",
  "252.247-7004",
  "252.247-7005",
  "252.247-7006",
  "252.247-7007",
  "252.247-7008",
  "252.247-7009",
  "252.247-7010",
  "252.247-7011",
  "252.247-7012",
  "252.247-7013",
  "252.247-7014",
  "252.247-7015",
  "252.247-7016",
  "252.247-7017",
  "252.247-7018",
  "252.247-7019",
  "252.247-7020",
  "252.247-7021",
  "252.247-7022",
  "252.247-7023",
  "252.247-7024",
  "252.247-7025",
  "252.247-7026",
  "252.247-7027",
  "252.247-7028",
  "252.247",
  "252.249-7000",
  "252.249-7001",
  "252.249-7002",
  "252.249",
  "252.251-7000",
  "252.251-7001",
  "252.251",
  "253.204",
  "253.209-1",
  "253.209",
  "253.213-70",
  "253.213",
  "253.215-70",
  "253.215",
  "253.303",
  "DFAR A",
  "DFAR B",
  "DFAR C",
  "DFAR D",
  "DFAR E",
  "DFAR F",
  "DFAR G",
  "DFAR H",
  "DFAR I",
  "PART 201",
  "PART 202",
  "PART 203",
  "PART 204",
  "PART 205",
  "PART 206",
  "PART 207",
  "PART 208",
  "PART 209",
  "PART 210",
  "PART 211",
  "PART 212",
  "PART 213",
  "PART 214",
  "PART 215",
  "PART 216",
  "PART 217",
  "PART 218",
  "PART 219",
  "PART 220",
  "PART 221",
  "PART 222",
  "PART 223",
  "PART 224",
  "PART 225",
  "PART 226",
  "PART 227",
  "PART 228",
  "PART 229",
  "PART 230",
  "PART 231",
  "PART 232",
  "PART 233",
  "PART 234",
  "PART 235",
  "PART 236",
  "PART 237",
  "PART 238",
  "PART 239",
  "PART 240",
  "PART 241",
  "PART 242",
  "PART 243",
  "PART 244",
  "PART 245",
  "PART 246",
  "PART 247",
  "PART 248",
  "PART 249",
  "PART 250",
  "PART 251",
  "PART 252",
  "PART 253",
  "204.4",
  "204.71",
  "204.7303-3",
  "204.7303-4",
  "208.70",
  "208.7004-3",
  "210.070",
  "217.1",
  "219.8",
  "219.804-2",
  "225.78",
  "234.70",
  "245.102-70",
  "245.6",
  "253.208-1",
  "253.208-2",
  "253.219-70",
  "201.1",
  "201.2",
  "201.3",
  "201.4",
  "201.6",
  "202.1",
  "203.1",
  "203.10",
  "203.2",
  "203.3",
  "203.4",
  "203.5",
  "203.7",
  "203.70",
  "203.8",
  "203.9",
  "204.1",
  "204.11",
  "204.12",
  "204.16",
  "204.17",
  "204.18",
  "204.2",
  "204.21",
  "204.6",
  "204.70",
  "204.72",
  "204.73",
  "204.74",
  "204.75",
  "204.76",
  "204.8",
  "204.9",
  "205.2",
  "205.3",
  "205.4",
  "205.5",
  "206.1",
  "206.2",
  "206.3",
  "207.1",
  "207.3",
  "207.4",
  "207.5",
  "207.70",
  "208.4",
  "208.6",
  "208.7",
  "208.71",
  "208.72",
  "208.73",
  "208.74",
  "209.1",
  "209.2",
  "209.3",
  "209.4",
  "209.5",
  "211.1",
  "211.2",
  "211.5",
  "211.6",
  "211.70",
  "212.1",
  "212.2",
  "212.3",
  "212.5",
  "212.6",
  "212.70",
  "212.71",
  "213.0",
  "213.1",
  "213.2",
  "213.3",
  "213.4",
  "213.5",
  "213.70",
  "214.2",
  "214.4",
  "214.5",
  "215.1",
  "215.2",
  "215.3",
  "215.4",
  "215.5",
  "215.6",
  "216.1",
  "216.2",
  "216.3",
  "216.4",
  "216.5",
  "216.6",
  "216.7",
  "217.2",
  "217.4",
  "217.5",
  "217.6",
  "217.7",
  "217.70",
  "217.71",
  "217.72",
  "217.73",
  "217.74",
  "217.75",
  "217.76",
  "217.77",
  "217.78",
  "218.1",
  "218.2",
  "219.0",
  "219.10",
  "219.11",
  "219.12",
  "219.13",
  "219.2",
  "219.3",
  "219.4",
  "219.5",
  "219.6",
  "219.7",
  "219.70",
  "219.71",
  "219.72",
  "222.1",
  "222.10",
  "222.13",
  "222.14",
  "222.17",
  "222.3",
  "222.4",
  "222.6",
  "222.70",
  "222.71",
  "222.72",
  "222.73",
  "222.74",
  "222.8",
  "223.1",
  "223.2",
  "223.3",
  "223.4",
  "223.5",
  "223.7",
  "223.70",
  "223.71",
  "223.72",
  "223.73",
  "223.74",
  "223.8",
  "224.1",
  "224.2",
  "225.1",
  "225.10",
  "225.11",
  "225.2",
  "225.3",
  "225.4",
  "225.5",
  "225.6",
  "225.7",
  "225.70",
  "225.71",
  "225.72",
  "225.73",
  "225.74",
  "225.75",
  "225.76",
  "225.77",
  "225.79",
  "225.8",
  "225.9",
  "226.1",
  "226.3",
  "226.70",
  "226.71",
  "226.72",
  "227.3",
  "227.4",
  "227.6",
  "227.70",
  "227.71",
  "227.72",
  "228.1",
  "228.3",
  "229.1",
  "229.2",
  "229.4",
  "229.70",
  "230.2",
  "230.70",
  "230.71",
  "231.1",
  "231.2",
  "231.3",
  "231.6",
  "231.7",
  "232.1",
  "232.10",
  "232.11",
  "232.2",
  "232.3",
  "232.4",
  "232.5",
  "232.6",
  "232.7",
  "232.70",
  "232.71",
  "232.72",
  "232.8",
  "232.9",
  "233.1",
  "233.2",
  "234.2",
  "234.71",
  "235.70",
  "236.1",
  "236.2",
  "236.3",
  "236.4",
  "236.5",
  "236.6",
  "236.7",
  "237.1",
  "237.2",
  "237.5",
  "237.6",
  "237.70",
  "237.71",
  "237.72",
  "237.73",
  "237.74",
  "237.75",
  "237.76",
  "237.77",
  "237.78",
  "239.1",
  "239.70",
  "239.71",
  "239.72",
  "239.73",
  "239.74",
  "239.75",
  "239.76",
  "241.1",
  "241.2",
  "241.5",
  "242.1",
  "242.11",
  "242.12",
  "242.14",
  "242.15",
  "242.2",
  "242.3",
  "242.4",
  "242.5",
  "242.6",
  "242.7",
  "242.70",
  "242.71",
  "242.72",
  "242.73",
  "242.74",
  "242.75",
  "242.8",
  "243.1",
  "243.2",
  "244.1",
  "244.2",
  "244.3",
  "244.4",
  "245.1",
  "245.2",
  "245.3",
  "245.4",
  "245.5",
  "245.70",
  "246.1",
  "246.2",
  "246.3",
  "246.4",
  "246.5",
  "246.6",
  "246.7",
  "246.8",
  "247.1",
  "247.2",
  "247.3",
  "247.5",
  "248.2",
  "249.1",
  "249.5",
  "249.70",
  "250.0",
  "250.1",
  "250.2",
  "250.3",
  "250.4",
  "251.1",
  "251.2",
  "252.1",
  "252.2",
  "253.2",
  "253.3",
  "Subchapter A",
  "Subchapter B",
  "Subchapter C",
  "Subchapter D",
  "Subchapter E",
  "Subchapter F",
  "Subchapter G",
  "Subchapter H",
  "Subchapter I",
];
