/** @jsxImportSource @emotion/react */

import { CopilotPresencePage } from "types/Presence";
import { ReactComponent as PinNavigation } from "Assets/svgs/pin-navigation.svg";
import { ReactComponent as UnpinNavigation } from "Assets/svgs/unpin-navigation.svg";
import { ReactComponent as VultronLogo } from "Assets/svgs/vultron-logo.svg";
import { ReactComponent as VultronLogoNoTitle } from "Assets/svgs/vultron-logo-no-title.svg";
import { setActiveSheetState } from "store/reducers/copilot/requirementsReducer";
import { useAppDispatch } from "store/storeTypes";
import { useState } from "react";
import { useStorage } from "YJSProvider/createYJSContext";
import { guideLinkSubItems, sideNavigationTabs } from "./constants";
import { LiveObject, ToImmutable } from "YJSProvider/LiveObjects";
import { NavLink, useSearchParams } from "react-router-dom";
import { Sheet, Storage } from "components/copilot/CopilotSchemaImmutableTypes";
import { useDocumentSubItems, useExtractionSubItems, useSheetSubItems, useVolumeSubItems } from "./hooks";

import SideNavigationNavigationItem from "./side-navigation-navigation-tem/SideNavigationNavigationItem";
import Tooltip from "components/atoms/tooltip/Tooltip";
import tw from "twin.macro";
import { useFlags } from "hook/useFlags";

interface SideNavigationProps {
  isPinned: boolean | undefined;
  setIsPinned: (isPinned: boolean) => void;
}

const SideNavigation = ({ isPinned, setIsPinned }: SideNavigationProps) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id")?.toLocaleLowerCase();
  const [currentlyExpandedTab, setCurrentlyExpandedTab] = useState("");
  const dispatch = useAppDispatch();
  const flags = useFlags();
  const [isHovering, setIsHovering] = useState(false);
  const navigationIsExpanded = isHovering || isPinned;

  const extractions = useStorage<NonNullable<ToImmutable<LiveObject<Storage>>["extractions"]>>(
    (root) => (root as ToImmutable<LiveObject<Storage>>).extractions || [],
  );

  const volumes = useStorage<NonNullable<ToImmutable<LiveObject<Storage>>["framework"]>>(
    (root) => (root as ToImmutable<LiveObject<Storage>>).framework || [],
  );

  const sheets: Sheet[] = useStorage((storage) => [...((storage.sheets as Storage["sheets"]) || [])]);
  const handleSheetSelection = (sheet?: Sheet) => {
    if (sheet) dispatch(setActiveSheetState(sheet));
  };

  const documentSubItems = useDocumentSubItems(id);
  const extractionsSubItems = useExtractionSubItems(extractions, id);
  const sheetSubItems = useSheetSubItems(sheets, id);
  const volumesSubItems = useVolumeSubItems(volumes, id);

  return (
    <div
      className="absolute h-full bg-white w-[92px] border border-gray-300 flex flex-col justify-between z-[11] ease-in-out duration-300"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      css={[navigationIsExpanded ? tw`w-[230px]` : tw`w-[92px]`]}
    >
      <div className="overflow-x-hidden overflow-y-auto">
        <NavLink className="sticky top-0" to={`/dashboard/contracts`}>
          <div
            className="flex flex-col justify-center px-4 h-[72px] bg-white"
            css={[!navigationIsExpanded && tw`items-center justify-center`]}
          >
            {navigationIsExpanded ? (
              <VultronLogo className="w-[114px] h-[30px]" />
            ) : (
              <VultronLogoNoTitle className="w-[30px] h-[30px]" />
            )}
          </div>
        </NavLink>
        {/* PROJECT SECTION */}
        <div
          className="py-3 px-3 text-[#757575] flex flex-col gap-1]"
          css={[!navigationIsExpanded && tw`items-center justify-center`]}
        >
          <div className="text-xxs font-medium px-2 mb-2" css={[!navigationIsExpanded && tw`px-0`]}>
            PROJECT
          </div>
          <SideNavigationNavigationItem
            tab={sideNavigationTabs.Dashboard}
            id={id}
            navigationIsExpanded={navigationIsExpanded}
            currentlyExpandedTab={currentlyExpandedTab}
            setCurrentlyExpandedTab={setCurrentlyExpandedTab}
          />
          <SideNavigationNavigationItem
            tab={sideNavigationTabs.Documents}
            id={id}
            navigationIsExpanded={navigationIsExpanded}
            subItems={documentSubItems}
            currentlyExpandedTab={currentlyExpandedTab}
            setCurrentlyExpandedTab={setCurrentlyExpandedTab}
            to={`/dashboard/contracts/details/documents/project-specific?tab=${CopilotPresencePage.Documents}&id=${id}`}
          />
          {flags.capture && (
            <SideNavigationNavigationItem
              tab={sideNavigationTabs.Capture}
              id={id}
              navigationIsExpanded={navigationIsExpanded}
              currentlyExpandedTab={currentlyExpandedTab}
              setCurrentlyExpandedTab={setCurrentlyExpandedTab}
              to={`/dashboard/contracts/details/capture?tab=${CopilotPresencePage.Capture}&id=${id}`}
            />
          )}
          <SideNavigationNavigationItem
            tab={sideNavigationTabs.Generate}
            id={id}
            navigationIsExpanded={navigationIsExpanded}
            subItems={extractionsSubItems}
            currentlyExpandedTab={currentlyExpandedTab}
            setCurrentlyExpandedTab={setCurrentlyExpandedTab}
          />
          <SideNavigationNavigationItem
            tab={sideNavigationTabs.Requirements}
            id={id}
            navigationIsExpanded={navigationIsExpanded}
            subItems={sheetSubItems}
            currentlyExpandedTab={currentlyExpandedTab}
            setCurrentlyExpandedTab={setCurrentlyExpandedTab}
            handleSubItemClick={handleSheetSelection}
          />
          <SideNavigationNavigationItem
            tab={sideNavigationTabs.Draft}
            id={id}
            navigationIsExpanded={navigationIsExpanded}
            subItems={volumesSubItems}
            currentlyExpandedTab={currentlyExpandedTab}
            setCurrentlyExpandedTab={setCurrentlyExpandedTab}
          />
          <SideNavigationNavigationItem
            tab={sideNavigationTabs.Proposals}
            id={id}
            navigationIsExpanded={navigationIsExpanded}
            currentlyExpandedTab={currentlyExpandedTab}
            setCurrentlyExpandedTab={setCurrentlyExpandedTab}
          />
        </div>
        {/* LEARN SECTION */}
        <div
          className="px-3 text-[#757575] flex flex-col"
          css={[!navigationIsExpanded && tw`items-center justify-center`]}
        >
          <div className="text-xxs font-medium px-2 mb-2" css={[!navigationIsExpanded && tw`px-0`]}>
            LEARN
          </div>
          <SideNavigationNavigationItem
            openLinksInNewTab
            tab={sideNavigationTabs.SupportCenter}
            id={id}
            navigationIsExpanded={navigationIsExpanded}
            currentlyExpandedTab={currentlyExpandedTab}
            setCurrentlyExpandedTab={setCurrentlyExpandedTab}
            to="https://intercom.help/vultron-ai/en/"
          />
          <SideNavigationNavigationItem
            openLinksInNewTab
            tab={sideNavigationTabs.Guides}
            id={id}
            navigationIsExpanded={navigationIsExpanded}
            subItems={guideLinkSubItems}
            currentlyExpandedTab={currentlyExpandedTab}
            setCurrentlyExpandedTab={setCurrentlyExpandedTab}
            to="https://intercom.help/vultron-ai/en/"
          />
        </div>
      </div>
      {/* NAVIGATION PIN */}
      {navigationIsExpanded && (
        <div className="p-6 self-end">
          <Tooltip content={isPinned ? "Unpin navigation" : "Pin navigation open"}>
            <button
              onClick={() => setIsPinned(!isPinned)}
              className="h-8 w-8 rounded-md bg-[#F0F0F0] items-center justify-center flex"
            >
              {isPinned ? <UnpinNavigation /> : <PinNavigation />}
            </button>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default SideNavigation;
