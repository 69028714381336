import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { useCallback, useMemo, useState } from "react";
import { useAppSelector } from "store/storeTypes";
import useExtractionOperations from "hook/useExtractionOperations";
import { InstantDraftStatus } from "components/copilot/CopilotSchemaTypes";
import { GenerationStatus, ResponseTolerance } from "types/Requirement";
import useGetRequirements from "hook/Requirements/useGetRequirements";
import { YJS_OPERATIONS } from "const-values/yjs";

type Params = {
  analysis_id: string;
  requirements: { id: string; content: string; volume_id: string | null; section_id: string | null }[];
  decompose_requirements: boolean;
  apply_no_source_feedback: boolean;
  apply_source_feedback: boolean;
  page_counts?: { id: string; count: number }[] | null;
  response_tolerance: ResponseTolerance;
};

type InstantDraftResponse = {
  requirements: {
    response: { id: string; content: string | null; generation_status: GenerationStatus };
    id: string;
    volume_id: string;
    section_id: string;
  }[];
};

export const useGenerateInstantDraft = () => {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const projectId = searchParams.get("id");
  const { updateInstantDraftConfig, addAttribution } = useExtractionOperations();
  const extractionMatrix = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.compliance_matrix || [],
  );
  const extractionId = useAppSelector((store) => store.currentExtractionState.currentExtraction?.id);
  const { refetch } = useGetRequirements(
    { analysis_id: extractionId || "" },
    { enabled: false, refetchOnWindowFocus: false },
  );
  const instantDraftConfigSections = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.sections,
  );
  const instantDraftConfigStrictness = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.strictness,
  );
  const extractionVolumes = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.framework?.volumes,
  );

  const requirementsToSubmit = useMemo(
    () =>
      extractionMatrix.filter(
        ({ requirement, proposal_reference }) =>
          !requirement.soft_deleted &&
          !!requirement.content?.trim() &&
          !!proposal_reference.section_id &&
          !requirement.skipped &&
          instantDraftConfigSections?.includes(proposal_reference.section_id),
      ),
    [extractionMatrix, instantDraftConfigSections],
  );

  const canSubmit = !!requirementsToSubmit.length && !isLoading;

  const generateInstantDraft = useCallback(async () => {
    if (!extractionId || !canSubmit) return;
    setIsLoading(true);
    try {
      const requirements = requirementsToSubmit.map(({ requirement, proposal_reference }) => ({
        id: requirement.id,
        content: requirement.content?.trim(),
        volume_id: proposal_reference.volume_id || null,
        section_id: proposal_reference.section_id || null,
      }));

      const pageCounts = extractionVolumes
        ?.flatMap(({ sections }) => sections)
        .filter(({ draft_page_count }) => !!draft_page_count)
        .map(({ id, draft_page_count }) => ({
          id,
          count: draft_page_count,
        })) as Params["page_counts"];

      const params: Params = {
        analysis_id: extractionId,
        decompose_requirements: true,
        requirements,
        apply_no_source_feedback: false,
        apply_source_feedback: true,
        page_counts: pageCounts,
        response_tolerance: instantDraftConfigStrictness || ResponseTolerance.Moderate,
      };
      addAttribution(YJS_OPERATIONS.EXTRACTION.TRIGGER_INSTANT_DRAFT);

      await axios.post<InstantDraftResponse>(`/autopilot/${projectId}/requirements/responses/generate`, params);
      await refetch();
      updateInstantDraftConfig(extractionId, {
        status: InstantDraftStatus.Pending,
        submittedRequirements: requirements.map(({ id }) => id),
      });
    } catch {
    } finally {
      setIsLoading(false);
    }
  }, [
    canSubmit,
    extractionId,
    extractionVolumes,
    instantDraftConfigStrictness,
    projectId,
    refetch,
    requirementsToSubmit,
    updateInstantDraftConfig,
    addAttribution,
  ]);

  return { generateInstantDraft, canSubmit, isLoading };
};
