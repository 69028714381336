/** @jsxImportSource @emotion/react */

import classNames from "classnames";
import { useSelection } from "components/copilot/extract-v2/doc-viewer/document-display/SelectionContext";
import { pluralizeWord } from "utils/string";

type Props = {
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onHoverOnly?: boolean;
  insertLabel?: string;
};

export default function InsertHereLine({ onClick, onHoverOnly, insertLabel = "requirement" }: Props) {
  const { selectedBlocks, clearSelection } = useSelection();

  if (!selectedBlocks?.length) {
    return <></>;
  }

  const parent =
    "z-[4] justify-self-center w-fit peer/middle flex pt-1.5 justify-center absolute left-0 right-0 top-full";
  const parentHoverClass =
    "peer-hover:opacity-100 hover:opacity-100 opacity-0 pointer-events-none peer-hover:pointer-events-auto hover:pointer-events-auto hover:z-[4] peer-hover:z-[4]";
  const bar = "z-[3] flex justify-center absolute left-0 right-0 top-full border-t-4 border-dashed border-gray-darkest";
  const barHoverClass =
    "peer-hover:opacity-100 peer-hover/middle:opacity-100 opacity-0 pointer-events-none peer-hover:pointer-events-auto peer-hover/middle:pointer-events-auto";

  return (
    <>
      <div className={classNames(parent, onHoverOnly && parentHoverClass)}>
        <button
          onClick={(e) => {
            onClick(e);
            clearSelection?.();
          }}
          className="bg-gray-darkest text-white text-xs rounded-md px-4 py-2 shadow-sharp-thin hover:bg-zinc-700"
        >
          Click to add selected {pluralizeWord(selectedBlocks.length, insertLabel)} here
        </button>
      </div>
      <button
        onClick={(e) => {
          onClick(e);
          clearSelection?.();
        }}
        className={classNames(bar, onHoverOnly && barHoverClass)}
      />
    </>
  );
}
