/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import styles from "./Selection.module.css";

export default function Selection({
  name,
  color,
  isFirstRow,
  containerProps,
  nameProps,
  colorSet,
}: {
  name?: string;
  color?: string;
  isFirstRow?: boolean;
  containerProps?: any;
  nameProps?: any;
  colorSet?: Record<string, string>;
}) {
  return (
    <div className={styles.selection}>
      <div
        className={styles.selection_border}
        style={{
          borderColor: colorSet?.bg,
        }}
        {...containerProps}
      />
      <div
        className={styles.selection_name}
        style={{ background: colorSet?.bg, color: "#fff" }}
        css={[isFirstRow && tw`top-[-14px]`]}
        {...nameProps}
      >
        {name}
      </div>
    </div>
  );
}
