import { useMutation } from "YJSProvider/createYJSContext";
import { Extraction, Framework, Section, Storage, Volume } from "components/copilot/CopilotSchemaTypes";
import { LiveList, LiveObject } from "YJSProvider/LiveObjects";
import { getExtraction } from "./getExtraction";
import type { Level } from "@tiptap/extension-heading";
import {
  OnOutlineEditorChange,
  OutlineContent,
} from "components/copilot/extract-v2/doc-viewer/document-sidebar/template-manager/outline-editor/types";
import {
  MIN_LEVEL,
  UPDATE_VERSION_KEY,
} from "components/copilot/extract-v2/doc-viewer/document-sidebar/template-manager/outline-editor/constants";
import { createNode } from "components/copilot/extract-v2/doc-viewer/document-sidebar/template-manager/outline-editor/hooks/utils";
import { ComplianceMatrixRow } from "components/copilot/CopilotSchemaImmutableTypes";
import { YMap } from "yjs/dist/src/internals";
import { useAppSelector } from "store/storeTypes";
import { useCallback } from "react";

type TreeNode = {
  id: string;
  title: string;
  level: Level;
  children: TreeNode[];
};

function getText(item: OutlineContent) {
  return item?.content?.[0]?.text || "";
}

function getId(item: OutlineContent): OutlineContent["attrs"]["xid"] {
  if (item?.attrs?.xid) {
    return item.attrs.xid;
  }

  const newId = createNode(MIN_LEVEL).attrs.xid;
  const attrs: Partial<OutlineContent["attrs"]> = item?.attrs || {};

  console.warn(`Created new id (${newId}) for node ${attrs.level} - ${getText(item)}`);
  return newId;
}

function buildTreeFromOutline(updates: OutlineContent[]): TreeNode[] {
  const rootNodes: TreeNode[] = [];
  const nodeStack: TreeNode[] = [];

  for (const item of updates) {
    const level = item.attrs.level || 1;
    const node: TreeNode = {
      id: getId(item),
      title: getText(item),
      level: level,
      children: [],
    };

    while (nodeStack.length > 0 && nodeStack[nodeStack.length - 1].level >= level) {
      nodeStack.pop();
    }

    if (nodeStack.length === 0) {
      rootNodes.push(node);
    } else {
      const parent = nodeStack[nodeStack.length - 1];
      parent.children.push(node);
    }

    nodeStack.push(node);
  }

  return rootNodes;
}

function updateVolumesWithFormattedVolumes(framework: LiveObject<Framework>, updates: OutlineContent[]): number {
  const tree = buildTreeFromOutline(updates);

  const existingElements = new Map<string, LiveObject<Volume | Section>>();
  const existingVolumes: LiveList<LiveObject<Volume>> = framework.get("volumes") || new LiveList();

  // Build existing Volume and Section maps
  for (const volume of existingVolumes.toArray()) {
    existingElements.set(volume.get("id"), volume);
    const sections: LiveList<LiveObject<Section>> = volume.get("sections") || new LiveList();
    for (const section of sections.toArray()) {
      existingElements.set(section.get("id"), section);
    }
  }

  const updateCountObj = { count: 0 };
  const volumesToKeep: LiveObject<Volume>[] = [];

  for (const volumeNode of tree) {
    const volume = new LiveObject<Volume>({
      ...existingElements.get(volumeNode.id)?.toJSON(),
      id: volumeNode.id,
      title: volumeNode.title,
      sections: new LiveList<LiveObject<Section>>(),
    });
    updateCountObj.count++;

    volumesToKeep.push(volume);

    const sectionsToKeep: LiveObject<Section>[] = [];
    processSections(volumeNode.children, existingElements, undefined, sectionsToKeep, updateCountObj);

    volume.set("sections", new LiveList(sectionsToKeep));
  }

  framework.set("volumes", new LiveList(volumesToKeep));

  return updateCountObj.count;
}

function processSections(
  sectionNodes: TreeNode[],
  existingSectionMap: Map<string, LiveObject<Section | Volume>>,
  parentId: string | undefined,
  sectionsToKeep: LiveObject<Section>[],
  updateCountObj: { count: number },
) {
  for (const sectionNode of sectionNodes) {
    const section = new LiveObject<Section>({
      ...existingSectionMap.get(sectionNode.id)?.toJSON(),
      id: sectionNode.id,
      title: sectionNode.title,
      parent_id: parentId,
    });
    updateCountObj.count++;
    sectionsToKeep.push(section);

    processSections(sectionNode.children, existingSectionMap, sectionNode.id, sectionsToKeep, updateCountObj);
  }
}

function updateSectionsWithFormattedSections(volume: LiveObject<Volume>, updates: OutlineContent[]): number {
  const tree = buildTreeFromOutline(updates);

  const existingElements = new Map<string, LiveObject<Section>>();
  const existingSections: LiveList<LiveObject<Section>> =
    volume.get("sections") || new LiveList<LiveObject<Section>>([]);

  // Build existing Section map
  for (const section of existingSections?.toArray() || []) {
    existingElements.set(section.get("id"), section);
  }

  const updateCountObj = { count: 0 };
  const sectionsToKeep: LiveObject<Section>[] = [];

  processSections(tree, existingElements, undefined, sectionsToKeep, updateCountObj);

  volume.set("sections", new LiveList(sectionsToKeep));

  return updateCountObj.count;
}

function updateComplianceMatrix(
  matrix: Storage["compliance_matrix"] | undefined,
  updates: ComplianceMatrixRow[] | undefined,
) {
  if (!matrix?.length || !updates?.length) {
    return;
  }

  updates.forEach(({ requirement, proposal_reference }) => {
    const req = matrix.toArray().find((row) => row.get("requirement")?.get("id") === requirement.id);
    if (!req) {
      console.warn(
        `Requirement id ${requirement.id} (with content: ${requirement.content.substring(
          0,
          50,
        )}...) not found in compliance matrix`,
      );
      return;
    }
    let proposalReference = req.get("proposal_reference");
    if (!proposalReference) {
      proposalReference = new LiveObject({
        volume_id: undefined,
        section_id: undefined,
        subsection_id: undefined,
      });
      req.set("proposal_reference", proposalReference);
    }

    proposalReference.set("volume_id", proposal_reference.volume_id);
    proposalReference.set("section_id", proposal_reference.section_id);
  });
}

export function useBulkVolumesUpdate(extractionId: string): OnOutlineEditorChange {
  const getDraftMatrix = useGetMatrix();
  return useMutation(
    ({ storage }, contentUpdates, complianceMatrixUpdates, version) => {
      const extraction = getExtraction(storage, extractionId);
      if (!extraction) {
        throw new Error(`Unable to apply updates, extraction for ${extractionId} not found`);
      }
      const framework: LiveObject<Framework> | undefined = extraction.get("framework");
      if (!framework) {
        throw new Error(`Framework not found for extraction ${extractionId}`);
      }

      updateVolumesWithFormattedVolumes(framework, contentUpdates);

      const matrix = getDraftMatrix(storage);
      updateComplianceMatrix(matrix, complianceMatrixUpdates);

      framework.set(UPDATE_VERSION_KEY, version);
    },
    [extractionId],
  );
}

function useGetMatrix() {
  const activeExtraction = useAppSelector((root) => root.currentExtractionState.currentExtraction?.id);

  return useCallback(
    (storage: YMap<any>): LiveList<LiveObject<ComplianceMatrixRow>> | undefined =>
      storage
        .get("extractions")
        ?.toArray()
        ?.find((e: YMap<Extraction>) => e?.get("id") === activeExtraction)
        ?.get("compliance_matrix"),
    [activeExtraction],
  );
}

export function useBulkSectionUpdate(volumeId?: string): OnOutlineEditorChange {
  return useMutation(
    ({ storage }, contentUpdates, complianceMatrixUpdates, version) => {
      const framework: LiveObject<Framework> | undefined = storage.get("framework");
      if (!framework) {
        throw new Error(`Framework not found on storage`);
      }
      const existingVolumes: LiveList<LiveObject<Volume>> | undefined = framework.get("volumes");
      const volume = existingVolumes?.toArray().find((vol) => vol.get("id") === volumeId);

      if (!volume) {
        throw new Error(`Volume with id ${volumeId} not found`);
      }

      updateSectionsWithFormattedSections(volume, contentUpdates);

      const matrix = storage.get("framework").get("extractions");
      updateComplianceMatrix(matrix, complianceMatrixUpdates);

      framework.set(UPDATE_VERSION_KEY, version);
    },
    [volumeId],
  );
}
