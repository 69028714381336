import { useEffect, useState } from "react";
import logo from "Assets/svgs/vultron-logo.svg";
import { useNavigate } from "react-router-dom/dist";
import { Spinner } from "../../utils/icons";
import { useSelector, useDispatch } from "react-redux";
import { resetAuth, setMyWorkSpaces } from "store/reducers/authReducerSlice";
import Avatar from "components/Avatar";
import { getSortByTransactionDate, pluralizeWord } from "utils/helpers";
import { useLocalStorage } from "hook/useLocalStorage";
import axios from "axios";
import Loading from "./Loading";
import useLogout from "hook/useLogout";
import { isJWTExpired } from "utils/timerUtils";

// ------------------ Select workspaces to continue --------------------
const SelectWorkSpacePage = () => {
  const { localValue, setLocalVal } = useLocalStorage("vultron_workspace_id", "");
  const dispatch = useDispatch();
  const { myWorkSpaces, isLoadingWorkSpaces } = useSelector((store) => store.auth);
  const navigate = useNavigate("");
  const [redirecting, setRedirecting] = useState(false);
  const logout = useLogout();

  const { localValue: userToken } = useLocalStorage("vultron_user_token", "");

  useEffect(() => {
    if (isJWTExpired(userToken)) {
      logout();
    }
  }, [userToken, isJWTExpired, logout]);

  //
  const createWorkSpace = () => {
    navigate("/create-workspace");
  };

  // If a user wants to login with different email then we will clear the old session
  const tryANewAccount = () => {
    logout();
    dispatch(
      resetAuth({
        isAuthenticated: false,
        isLoading: false,
      }),
    );
    dispatch(
      setMyWorkSpaces({
        myWorkSpaces: {},
        isLoadingWorkSpaces: false,
      }),
    );
  };

  // select workspace to loggedIn
  const selectWorkSpace = (id) => {
    setRedirecting(true);
    if (id?.length > 0 && id === localValue) {
      navigate("/dashboard");
      return;
    }
    setLocalVal(id);
    axios.defaults.headers.common["Workspace"] = `Workspace ${id}`;
    window.location.replace("/dashboard");
  };

  if (redirecting) {
    return (
      <div className="flex justify-center items-center w-full min-h-[100vh]">
        <Loading />
      </div>
    );
  }
  //
  return (
    <div className="flex w-full min-h-[100vh]">
      {/* logo */}
      <div className="absolute top-6 left-6 z-[2]">
        <img width="150px" height="139px" src={logo} alt="logo" />
      </div>
      {/* ------------------------- */}
      <div className="flex items-center flex-col justify-center  gap-[40px] w-full h-screen pt-32 pb-8 3xl:py-0">
        <div className="flex-none flex flex-col items-center gap-[15px]">
          <h1 className="text-4xl font-medium py-1 font-primary-font line-h leading-[46px]">Welcome to Vultron!</h1>
          <span className="font-primary-font text-gray-text font-thin-inter">Select the workspace to navigate to</span>
        </div>
        <div className="flex flex-col shrink border border-gray-300 rounded-lg w-[600px] overflow-y-hidden">
          <div className="flex items-start justify-start p-3 border-b border-gray-300 font-primary-font">
            <span>
              Workspaces for <b> {myWorkSpaces?.email} </b>
            </span>
          </div>
          <div className="overflow-y-auto">
            {myWorkSpaces?.workspaces?.length > 0 ? (
              Object.values(myWorkSpaces?.workspaces)
                ?.sort((a, b) => getSortByTransactionDate(a, b, "created_at"))
                .map((workSpace, i) => (
                  <div
                    key={i}
                    onClick={() => selectWorkSpace(workSpace?.id)}
                    className={`flex item-start justify-start p-5 cursor-pointer ${
                      myWorkSpaces?.workspaces?.length === i + 1 ? "" : "border-b"
                    } border-gray-300 font-primary-font hover:bg-[#F0F0F0] group/item`}
                  >
                    <div className="flex flex-col gap-3 flex-grow pr-1">
                      <h2 className="font-semibold">{workSpace?.name}</h2>
                      <div className="flex flex-wrap gap-3">
                        <span className="flex items-center -space-x-2">
                          {[...new Array(workSpace?.number_of_users || 0)].map((v, ind) => (
                            <Avatar
                              key={ind}
                              id={workSpace?.users[ind]}
                              alt={workSpace?.users[ind]}
                              width={26}
                              height={26}
                            />
                          ))}
                        </span>
                        <span className="text-gray-text">
                          {workSpace?.number_of_users} {pluralizeWord("member", "members", workSpace?.number_of_users)}
                        </span>
                      </div>
                    </div>
                    <div className={`invisible flex items-center justify-center ml-auto pr-3 group-hover/item:visible`}>
                      {/* {workSpace?.id?.length > 0 && workSpace?.id === localValue ? */}
                    </div>
                  </div>
                ))
            ) : isLoadingWorkSpaces ? (
              <div className="flex item-start justify-center p-5 border-b border-gray-300">
                <Spinner classes="!text-black" width={35} height={35} />
              </div>
            ) : (
              <div className="flex item-start justify-start p-5 border-b border-gray-300">
                <span className="font-[500] text-base">There are no workspaces currently tied to this email</span>
              </div>
            )}
          </div>
        </div>
        {/* ------------------------------ */}
        <div className="flex-none rounded-lg h-auto w-[600px] flex pl-5 py-5 gap-16 bg-gray-100 items-center">
          <p className="font-primary-font text-black font-medium">Create a new workspace to get started.</p>
          <button
            title={isLoadingWorkSpaces ? "Loading" : "Create Workspace"}
            disabled={isLoadingWorkSpaces}
            onClick={createWorkSpace}
            className="p-2 bg-gray-darkest text-white font-primary-font text-xs rounded-md w-[30%] items-center flex justify-center mr-5 disabled:opacity-60"
          >
            Create Workspace
          </button>
        </div>
        {/* ------------------- */}
        <div className="flex-none">
          <p className="font-primary-font text-gray-text">
            Not seeing your workspace?
            <button
              title={isLoadingWorkSpaces ? "Loading" : "Login with different email"}
              disabled={isLoadingWorkSpaces}
              onClick={tryANewAccount}
              className="text-action font-[500] ml-2 disabled:opacity-50"
            >
              Try a different email
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SelectWorkSpacePage;
