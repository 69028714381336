import { useOutletContext } from "react-router-dom";
import Icon from "components/atoms/icons/Icon";
import SwitchBtn from "components/SwitchBtn";
import {
  getProposalPlanDocument,
  deleteProposalPlanDocument,
  createProposalPlanDocument,
  updateProposalPlanDocumentFilterState,
  getSingleDirectory,
} from "api/api";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDirectoryFileSearch } from "pages/drive/documents/hooks";
import { useNotification } from "context/notificationContext";
import { useDispatch } from "react-redux";
import { Subdirectory } from "types/FileStorage";
import { useAppSelector } from "store/storeTypes";
import { Document, Directory, DocumentType } from "../types";
import { fetchFileStorage } from "store/reducers/driveReducerSlice";
import xor from "lodash/xor";
import uniqBy from "lodash/uniqBy";
import throttle from "lodash/throttle";
import { useContentLibraryFilterItems } from "components/molecules/content-drive-popover-content/hooks";
import { Button } from "components/editor/components";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import SelectedDocumentListItem from "../SelectedDocumentListItem";
import { Search } from "lucide-react";
import { LEGACY_DOCUMENT_TYPES } from "pages/drive/documents/constants";
import { Spinner } from "utils/icons";
import ListItem from "../ListItem";
import rightArrow from "../../../../Assets/chevRight.png";
import { getProject } from "store/reducers/projectReducer";
import tw from "twin.macro";

const ContentLibrary = () => {
  const { internalContractId } = useOutletContext<{
    internalContractId: string;
  }>();
  const {
    rootFiles: allDocs,
    rootSubdirectories: allFolders,
    isLoading,
  } = useAppSelector((state) => state.drive.fileStorage);
  const internalContractDetails = useAppSelector((store) => store.project.activeProject);
  const { setToast } = useNotification();
  const [incomingDocs, setIncomingDocs] = useState<any[]>([]);
  const [incomingFolders, setIncomingFolders] = useState<Subdirectory[]>([]);
  const dispatch = useDispatch();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [currentDirectory, setCurrentDirectory] = useState<Subdirectory | null>(null);
  const [allSelectedDocuments, setAllSelectedDocuments] = useState<DocumentType[]>([]);
  const [isDocumentFilterOn, setIsDocumentFilterOn] = useState(
    internalContractDetails?.internal_contract?.use_file_scope || false,
  );

  const {
    isSearching,
    searchResults,
    isSearchActive,
    setDocumentTypes,
    setSearchQuery,
    searchQuery,
    documentTypes,
    setUploaded,
    uploaded,
    setDirectoryId,
    folderSearchResults,
    resetSearch,
  } = useDirectoryFileSearch();

  useEffect(() => {
    if (!allDocs.length && !allFolders.length) {
      dispatch(fetchFileStorage());
    }
  }, [allDocs.length, allFolders.length, dispatch]);

  useEffect(() => {
    if (allDocs) {
      setIncomingDocs(allDocs);
      setCurrentDirectory(null);
    }
    if (allFolders) {
      setIncomingFolders(allFolders);
    }
  }, [allDocs, allFolders, dispatch]);

  const isFirstRender = useRef(true); //track initial render
  useEffect(() => {
    if (isFirstRender.current && typeof internalContractDetails?.internal_contract?.use_file_scope === "boolean") {
      setIsDocumentFilterOn(internalContractDetails?.internal_contract?.use_file_scope || false);
      isFirstRender.current = false;
    }
  }, [internalContractDetails?.internal_contract?.use_file_scope]);

  useEffect(() => {
    if (!internalContractId) return;

    const getDocuments = async () => {
      setIsDataLoading(true);
      try {
        const { data } = await getProposalPlanDocument(internalContractId);
        if (data) {
          setAllSelectedDocuments(data);
        } else {
          setAllSelectedDocuments([]);
        }
      } catch (err) {
        setToast.error({
          title: "Unable to retrieve documents",
          msg: "We were unable to retrieve documents due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        });
      } finally {
        setIsDataLoading(false);
      }
    };

    getDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalContractId]);

  const handleResetSelectedDocuments = () => {
    if (allSelectedDocuments) {
      allSelectedDocuments.forEach((document) => {
        deleteDocument({ id: document.file_id });
      });
    }
  };

  const handleAction = async (
    action: (internalContractId: string, data: any) => Promise<any>,
    data: any,
    onSuccess: (files: DocumentType[]) => void,
    errorMsg: string,
  ) => {
    if (!internalContractId) return;
    try {
      const { data: files } = await action(internalContractId, data);
      onSuccess?.(files);
    } catch (err) {
      setToast.error({
        title: "Unable to process request",
        msg: errorMsg,
      });
    }
  };

  const deleteDocument = (document: Document) => {
    const data = {
      document_id: document.id,
    };
    handleAction(
      deleteProposalPlanDocument,
      data,
      (files) => setAllSelectedDocuments((prev) => prev.filter((doc) => doc.file_id !== document.id)),
      "There was an issue deleting the document. Please refresh and try again.",
    );
  };

  const addDirectory = (directory: Directory, callback?: () => void) => {
    const data = {
      is_directory: true,
      target_id: directory.id,
    };
    handleAction(
      createProposalPlanDocument,
      data,
      (files) => {
        setAllSelectedDocuments((prev) => uniqBy([...prev, ...files], "file_id"));
        callback?.();
      },
      "There was an issue creating the directory. Please refresh and try again.",
    );
  };

  const addDocument = ({ id }: any, callback?: () => void) => {
    if (allSelectedDocuments.find((doc) => doc.file_id === id)) {
      callback?.();
      return;
    }

    const data = {
      is_directory: false,
      target_id: id,
    };

    handleAction(
      createProposalPlanDocument,
      data,
      (files) => {
        setAllSelectedDocuments((prev) => uniqBy([...prev, ...files], "file_id"));
        callback?.();
      },
      "There was an issue creating the document. Please refresh and try again.",
    );
  };

  const addAllIncoming = () => {
    docs.forEach((document) => {
      addDocument(document, () => {});
    });

    folders.forEach((directory) => {
      addDirectory(directory, () => {});
    });
  };

  const onDirectorySelected = async (directoryId: string) => {
    if (isDataLoading) return;
    setIsDataLoading(true);

    try {
      const { data } = await getSingleDirectory(directoryId);
      if (isSearchActive) resetSearch();
      if (data) {
        setCurrentDirectory(data.current_directory);
        setIncomingDocs(data.files);
        setIncomingFolders(data.subdirectories);
      }
    } catch {
    } finally {
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    setDirectoryId(currentDirectory?.id || "");
  }, [currentDirectory?.id, setDirectoryId]);

  const toggleDocumentFilter = useMemo(
    () =>
      throttle(
        (checked: boolean) => {
          updateProposalPlanDocumentFilterState(internalContractId, checked)
            .catch(() => {
              setToast.error({
                title: "Unable to update document filter",
                msg: "There was an issue updating the document filter. Please refresh and try again.",
              });
            })
            .finally(() => {
              dispatch(getProject({ internalContractId, triggerDocRefresh: true }));
            });
        },
        800,
        { leading: false, trailing: true },
      ),
    [dispatch, internalContractId, setToast],
  );

  useEffect(() => {
    if (!isFirstRender.current) toggleDocumentFilter(isDocumentFilterOn);
  }, [isDocumentFilterOn, toggleDocumentFilter]);

  const { typeItems, publishDateItemOptions } = useContentLibraryFilterItems({
    publishDatesConfig: { uploaded, onSelect: (val) => setUploaded(val === uploaded ? "" : val) },
    typesConfig: {
      documentTypes,
      onCheck: (val: string | string[]) => {
        if (Array.isArray(val)) {
          setDocumentTypes((prev) => xor(prev, val));
        } else {
          setDocumentTypes((prev) => xor(prev, [val]));
        }
      },
    },
  });

  const folders = isSearchActive ? folderSearchResults : incomingFolders;

  const docs = isSearchActive ? searchResults : incomingDocs;
  const displayedDocs = docs;

  return (
    <div className="p-6 flex flex-col h-full">
      <div className="flex">
        <div className="font-semibold text-lg mb-2 pl-1">Content Library</div>
      </div>
      <div className="flex flex-wrap">
        <div className="text-gray-text text-sm pb-4 flex items-center gap-[0.2rem]">
          <span className="mr-2.5 pl-1 flex-nowrap">Use Full Content Library</span>
          <SwitchBtn value={!isDocumentFilterOn} handleChange={() => setIsDocumentFilterOn(!isDocumentFilterOn)} />
        </div>
        <span className="ml-2.5 pl-1 text-sm text-gray">
          {isDocumentFilterOn
            ? "Vultron will reference the selected documents as context for this project."
            : "Vultron will use your entire content library as context for this project."}
        </span>
      </div>
      <div
        className={`w-full flex-grow pt-2 overflow-hidden group  ${
          isDocumentFilterOn ? "" : "opacity-50 pointer-events-none overflow-y-auto"
        }`}
      >
        <div className="flex gap-4 h-full pb-6">
          <div className="overflow-hidden h-full border border-light min-h-[270px] w-[60%] rounded-md bg-white shadow flex flex-col">
            <div className="flex flex-col gap-1.5 py-4 px-6">
              <div className="flex justify-between">
                <div className="flex items-center gap-3 text-sm">
                  <button
                    className="cursor-pointer font-medium"
                    onClick={() => {
                      setIncomingDocs([...allDocs]);
                      setCurrentDirectory(null);
                      setIncomingFolders([...allFolders]);
                      if (isSearchActive) resetSearch();
                    }}
                  >
                    Content Library
                  </button>
                  {!!currentDirectory?.name && (
                    <>
                      <img src={rightArrow} alt="icon" loading="lazy" className="w-[14.5px] h-[16px]" />
                      <div className="truncate">{!isDataLoading && currentDirectory.name}</div>
                    </>
                  )}
                </div>
                <Button
                  variant="link"
                  size="sm"
                  disabled={!docs.length && !folders.length}
                  className={`text-small flex items-center gap-1 font-medium cursor-pointer ${
                    docs.length && folders.length ? "text-action" : "text-[#9FA2AA]"
                  }`}
                  onClick={addAllIncoming}
                >
                  Select All
                </Button>
              </div>
              <div className="flex items-center gap-1 flex-wrap w-full">
                <div className="px-1.5 gap-1.5 flex flex-row text-xs w-[50%] items-center border rounded border-b-gray-light">
                  <Search size={12} className="text-gray-400" />
                  <input
                    autoFocus
                    className="outline-none py-1 text-xs text-gray-600 w-full"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search..."
                  />
                </div>
                <DropdownMenu multiselect items={typeItems} triggerProps={{ css: tw` w-[22%]` }}>
                  <div className="rounded text-xs bg-white flex justify-between px-1.5 py-1 items-center gap-1 border border-gray-light text-slate-600">
                    <div className="truncate">
                      {documentTypes.length
                        ? documentTypes.filter((document) => !LEGACY_DOCUMENT_TYPES.includes(document)).join(", ")
                        : "Type"}
                    </div>
                    <Icon name="CarrotDown" className="min-w-3" />
                  </div>
                </DropdownMenu>
                <DropdownMenu items={publishDateItemOptions} triggerProps={{ css: tw` w-[22%]` }}>
                  <div className="rounded text-xs  bg-white flex justify-between px-1.5 py-1 items-center gap-1 border border-gray-light text-slate-600">
                    <div className="truncate">{uploaded || "Uploaded"}</div>
                    <Icon name="CarrotDown" className="min-w-3" />
                  </div>
                </DropdownMenu>
              </div>
            </div>
            {isLoading || isSearching ? (
              <div className="p-3 my-auto flex items-center justify-center">
                <Spinner classes="!text-black" />
              </div>
            ) : (
              (!!displayedDocs.length || !!folders.length) && (
                <div className="overflow-auto h-full bg-white w-full py-0 px-2">
                  {folders.map((item) => (
                    <div key={item.id}>
                      <ListItem
                        item={item}
                        onRowSelected={() => {
                          onDirectorySelected(item.id);
                        }}
                        type="directory"
                        addType={addDirectory}
                        allSelectedDocuments={allSelectedDocuments}
                        allNestedFiles={item.all_nested_files}
                      />
                      <hr className="border-lightest" />
                    </div>
                  ))}
                  {displayedDocs.map((item: any) => (
                    <div key={item.id}>
                      <ListItem
                        item={item}
                        type="document"
                        addType={addDocument}
                        allSelectedDocuments={allSelectedDocuments}
                      />
                      <hr className="border-lightest" />
                    </div>
                  ))}
                </div>
              )
            )}
            {!docs.length && !folders.length && !isLoading && !isSearching && (
              <div className="bg-white flex-1 w-full flex justify-center items-center flex-col py-7 px-2 gap-3">
                <span className="text-gray-text text-sm">No documents found.</span>
              </div>
            )}
          </div>
          {/* second half */}
          <div className="border w-[40%] flex flex-col h-full border-light shadow rounded-md bg-white overflow-hidden">
            <div className="flex justify-between p-4 items-center">
              <div className="text-sm font-medium">
                Selected Documents ({isDocumentFilterOn ? allSelectedDocuments.length : 0})
              </div>
              <Button
                variant="link"
                size="sm"
                disabled={!allSelectedDocuments.length}
                className="cursor-pointer !text-[#F54040] !p-0"
                onClick={handleResetSelectedDocuments}
              >
                Reset
              </Button>
            </div>
            {(!allSelectedDocuments.length || !isDocumentFilterOn) && (
              <div className=" bg-white flex-1 w-full flex justify-center items-center flex-col py-7 px-2">
                <span className="text-gray-text text-sm">No documents selected.</span>
              </div>
            )}
            {!!allSelectedDocuments.length && isDocumentFilterOn && (
              <div className="overflow-auto bg-white w-full px-2 pb-2">
                {allSelectedDocuments.map((item, index) => (
                  <div key={item.file_id}>
                    <SelectedDocumentListItem onDeleteSelectedDocument={deleteDocument} item={item} />
                    {index !== allSelectedDocuments.length - 1 && <hr className="border-lightest mx-2" />}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentLibrary;
