import {
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { findIndex, moveItem } from "YJSProvider/LiveObjects";
import { useMutation } from "YJSProvider/createYJSContext";
import { Sheet } from "components/copilot/CopilotSchemaImmutableTypes";
import { Storage } from "components/copilot/CopilotSchemaTypes";
import { EMPTY_SHEET } from "const-values/Sheets";
import useSheetOperations from "hook/useSheetOperations";
import { useCallback, useState } from "react";

export const useDrag = () => {
  const [activeDragId, setActiveDragId] = useState<UniqueIdentifier | null>(null);
  const { insertDefaultSheet } = useSheetOperations();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 6,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveDragId(event.active.id);
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveDragId(null);
  }, []);

  const handleDragEnd = useMutation(({ storage }, event: DragEndEvent, sheets: Sheet[]) => {
    const { active, over } = event;

    let activeSheet = active.id;
    let overSheet = over?.id;
    if (overSheet && activeSheet !== overSheet) {
      const liveSheets = storage.get("sheets") as Storage["sheets"];
      const emptySheetIdx = sheets.findIndex(({ id }) => id === EMPTY_SHEET.id);
      const emptySheet = sheets[emptySheetIdx];

      if (!liveSheets?.length) return;

      if (emptySheet) {
        const createdSheet = insertDefaultSheet({ name: emptySheet.name }, 0);

        if (activeSheet === EMPTY_SHEET.id) activeSheet = createdSheet.id;
        if (overSheet === EMPTY_SHEET.id) overSheet = createdSheet.id;
      }

      const sourceIndex = findIndex(liveSheets, (row) => row.get("id") === activeSheet);
      const destinationIndex = findIndex(liveSheets, (row) => row.get("id") === overSheet);

      if (sourceIndex === -1 || destinationIndex === -1) return;
      moveItem(liveSheets, sourceIndex, destinationIndex);
    }

    setActiveDragId(null);
  }, []);

  return { sensors, handleDragStart, handleDragEnd, handleDragCancel, activeDragId };
};
