/** @jsxImportSource @emotion/react */

import { ButtonHTMLAttributes, ComponentProps, forwardRef } from "react";
import Icon from "../icons/Icon";
import "twin.macro";

interface Props extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "name"> {
  name: ComponentProps<typeof Icon>["name"];
  iconProps?: Omit<ComponentProps<typeof Icon>, "name">;
}

const IconButton = forwardRef<HTMLButtonElement, Props>(({ name, iconProps, ...props }, ref) => {
  return (
    <button ref={ref} tw="flex items-center justify-center" {...props}>
      <Icon name={name} {...iconProps} />
    </button>
  );
});

export default IconButton;
