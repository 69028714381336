import { useLayoutEffect, useMemo } from "react";
import { useLocalStorage } from "hook/useLocalStorage";
import type { Editor } from "@tiptap/react";

const MAX_FONT_SIZE = 18;
const MIN_FONT_SIZE = 12;
const DEFAULT_FONT_SIZE = 14;
const FONT_INCREMENT = 1;
const KEY = "textOutline/defaultFontSize";

export function getEditorFontSize(editor: Editor) {
  const size = Number.parseInt(editor.view.dom.parentElement?.style.fontSize || "", 10) || DEFAULT_FONT_SIZE;

  return Math.max(MIN_FONT_SIZE, Math.min(MAX_FONT_SIZE, size));
}

export function useLocalFontSize(editorRef?: React.RefObject<HTMLDivElement>) {
  const { localValue: fontSize, setLocalVal: setFontSize } = useLocalStorage(KEY, DEFAULT_FONT_SIZE);
  const editorHTMLEl = editorRef?.current;

  useLayoutEffect(() => {
    if (editorHTMLEl) {
      editorHTMLEl.style.fontSize = `${fontSize}px`;
    }
  }, [editorHTMLEl, fontSize]);

  return useMemo(() => {
    const canIncrease = () => fontSize + FONT_INCREMENT <= MAX_FONT_SIZE;
    const canDecrease = () => fontSize - FONT_INCREMENT >= MIN_FONT_SIZE;
    const resetFontSize = () => setFontSize(DEFAULT_FONT_SIZE);

    const increaseFontSize = () => {
      if (canIncrease()) {
        setFontSize(fontSize + FONT_INCREMENT);
      }
    };
    const decreaseFontSize = () => {
      if (canDecrease()) {
        setFontSize(fontSize - FONT_INCREMENT);
      }
    };
    const setBoundedFontSize = (num: number) => {
      if (Number.isNaN(num)) {
        return;
      }
      const bounded = Math.min(MAX_FONT_SIZE, Math.max(MIN_FONT_SIZE, num));
      setFontSize(bounded);
    };

    return {
      fontSize,
      setFontSize: setBoundedFontSize,
      canIncrease,
      canDecrease,
      resetFontSize,
      increaseFontSize,
      decreaseFontSize,
    };
  }, [fontSize, setFontSize]);
}
