import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { uniq } from "lodash";

export enum DisplayOption {
  DontInclude = 1,
  Heading,
  FullRequirement,
}

export type SectionFormat = {
  id: string;
  displayOption: DisplayOption;
  headingStyle: {
    bold: boolean;
    underline: boolean;
    italic: boolean;
  };
};

export type PreviewConfig = {
  includeSourceDocuments: boolean;
  sectionFormats: SectionFormat[];
};

type State = {
  activeDraftId: null | string;
  activeSectionId: null | string;
  headingsInProgress: string[];
};

const initialState: State = {
  activeDraftId: null,
  activeSectionId: null,
  headingsInProgress: [],
};

const previewProposalReducer = createSlice({
  name: "previewProposal",
  initialState,
  reducers: {
    resetPreviewProposalState: (state: State) => {
      return { ...initialState, headingsInProgress: state.headingsInProgress };
    },
    setPreviewProposalState: (state: State, action: PayloadAction<Partial<State>>) => {
      return { ...state, ...action.payload };
    },
    setHeadingProgress: (state: State, action: PayloadAction<{ add: boolean; ids: string[] }>) => {
      const { add, ids } = action.payload;
      const existingIds = state.headingsInProgress;

      if (add) {
        state.headingsInProgress = uniq([...existingIds, ...ids]);
      } else {
        state.headingsInProgress = existingIds.filter((id) => !ids.includes(id));
      }
    },
  },
});

export const { setHeadingProgress, resetPreviewProposalState, setPreviewProposalState } =
  previewProposalReducer.actions;

export default previewProposalReducer.reducer;
