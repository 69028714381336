import CoreBlock from "components/molecules/ai-assistant-blocks/core-block";
import VultronBlock from "components/molecules/ai-assistant-blocks/vultron-block";
import { AIAssistantBlock, AssistantBlockType } from "utils/yjs-configs/ai-assistant/schema";
import { ToImmutable } from "YJSProvider/LiveObjects";

export const renderBlock = (block: ToImmutable<AIAssistantBlock>) => {
  switch (block.type) {
    case AssistantBlockType.CoreBlock:
      return <CoreBlock key={block.id} block={block} />;
    case AssistantBlockType.VultronBlock:
      return <VultronBlock key={block.id} block={block} />;

    default:
      return <div />;
  }
};
