import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTemplates } from "api/api";
import { Template } from "types/Templates";

type State = {
  isFetchingTemplates: boolean;
  templates: Template[];
};

const initialState: State = {
  isFetchingTemplates: false,
  templates: [],
};

export const fetchTemplates = createAsyncThunk<Template[], void>("templates/fetchTemplates", async () => {
  try {
    const response = await getTemplates();
    return response.data || [];
  } catch {}
});

const templatesReducer = createSlice({
  name: "templates",
  initialState,
  reducers: {
    setTemplates: (state: State, action: PayloadAction<Template[]>) => {
      state.templates = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTemplates.pending, (state) => {
      state.isFetchingTemplates = true;
    });
    builder.addCase(fetchTemplates.fulfilled, (state, { payload }) => {
      state.templates = payload;
      state.isFetchingTemplates = false;
    });
    builder.addCase(fetchTemplates.rejected, (state) => {
      state.isFetchingTemplates = false;
    });
  },
});

export const { setTemplates } = templatesReducer.actions;

export default templatesReducer.reducer;
