import keyBy from "lodash/keyBy";
import axios, { CancelToken, CancelTokenSource } from "axios";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DocumentReferenceRoot, GroupedBlock, MergedRequirement } from "components/copilot/extract-v2/doc-viewer/types";
import { Extraction, ExtractionErrorReason, Section } from "components/copilot/CopilotSchemaTypes";
import { LiveObject, ToImmutable } from "YJSProvider/LiveObjects";
import { RootState } from "store/storeTypes";
import { Requirement } from "types/Requirement";
import {
  ActiveFilter,
  DEFAULT_FILTERS,
} from "components/copilot/extract-v2/doc-viewer/document-sidebar/requirements-filter/constants";
import compact from "lodash/compact";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

export type Document = { id: string; file_name: string; secure_preview_url: string };
export type GeneratedDraft = {
  id: string;
  sections: {
    id: string;
    parent_id?: string | null;
    requirements?: [] | null;
    subsections: { id: string; title: string; requirements: null }[];
    title: string;
  }[];
  title: string;
};
export type ExtractionTemplate = {
  context_template?: boolean;
  template_type: "evaluation_criteria" | "statement_of_work" | "generated" | "identified" | "extracted";
  generated: boolean;
  description: string;
  document_id: string;
  template_data: {
    id: string;
    volumes: GeneratedDraft[];
  };
};

export type OrderKey = {
  end_order_key: number;
  requirement_id: string;
  start_order_key: number;
};

export const getCoordinates = createAsyncThunk<
  DocumentReferenceRoot,
  { fileId: string; cancelToken: CancelToken },
  { state: RootState }
>(
  "currentExtractionReducer/getCoordinates",
  async ({ fileId, cancelToken }) => {
    return await axios
      .get<DocumentReferenceRoot>(`/document/${fileId}/elements/`, { cancelToken })
      .then((resp) => resp.data);
  },
  {
    condition: ({ fileId }, { getState }) => {
      const { currentExtractionState } = getState();
      const { coordinateCache } = currentExtractionState;
      const isLoadingCurrentFileCoordinateCache = coordinateCache[fileId]?.isLoading;

      if (isLoadingCurrentFileCoordinateCache) return false;
    },
  },
);

export const getExtractionDocuments = createAsyncThunk<
  Document[],
  ToImmutable<LiveObject<Extraction>>["file_ids"],
  { state: RootState }
>(
  "currentExtractionReducer/getDocument",
  async (fileIds) => {
    return await axios
      .get(`/autopilot/preview/documents`, { params: { document_ids: fileIds } })
      .then((resp) => resp.data);
  },
  {
    condition: (_, { getState }) => {
      const { currentExtractionState } = getState();
      const { isLoadingTemplate } = currentExtractionState;

      if (isLoadingTemplate) return false;
    },
  },
);

export const getFilteredRequirements = createAsyncThunk<
  Requirement[],
  {
    projectId: string;
    params?: {
      source?: string[];
      section_header?: string[];
      keyword?: string[];
    };
  },
  { state: RootState }
>(
  "currentExtractionReducer/getFilteredRequirements",
  async ({ projectId, params }, { getState }) => {
    const { currentExtractionState } = getState();
    const { requirementFilters } = currentExtractionState;
    const keywords = compact(requirementFilters.query.toLowerCase().trim().split(/,\s*/));
    const currentFilterParams = {
      source: requirementFilters.sections,
      ...(!!keywords.length && { keyword: keywords }),
    };

    const { data } = await axios.get<Requirement[]>(`/autopilot/${projectId}/requirements`, {
      params: { ...(params || currentFilterParams), analysis_id: currentExtractionState.currentExtraction?.id },
    });
    return data;
  },
  {
    condition: (_, { getState }) => {
      const { currentExtractionState } = getState();
      const { isLoadingFilteredRequirements, requirementFilters } = currentExtractionState;
      const hasActiveFilters = Object.values(requirementFilters).some((filterVal) => !!filterVal.length);

      if (isLoadingFilteredRequirements || !hasActiveFilters) return false;
    },
  },
);

export const getAtlasRequirements = createAsyncThunk<
  Requirement[],
  {
    projectId: string;
    params?: {
      source?: string[];
      section_header?: string[];
      keyword?: string[];
    };
  },
  { state: RootState }
>("currentExtractionReducer/getAtlasRequirements", async ({ projectId, params }, { getState }) => {
  const { currentExtractionState } = getState();

  const { data } = await axios.get<Requirement[]>(`/autopilot/${projectId}/requirements`, {
    params: { ...params, analysis_id: currentExtractionState.currentExtraction?.id },
  });
  return data;
});

export const getOrderKeys = createAsyncThunk<OrderKey[], { projectId: string }, { state: RootState }>(
  "currentExtractionReducer/getOrderKeys",
  async ({ projectId }) => {
    const { data } = await axios.get<OrderKey[]>(`/autopilot/${projectId}/requirements/order`);
    return data;
  },
);

export const getTemplate = createAsyncThunk<
  {
    analysis_id: string;
    templates: ExtractionTemplate[];
    requirements: Requirement[];
    error_reason?: ExtractionErrorReason;
  },
  { extractionId: string; includeContextTemplates: boolean }
>("currentExtractionReducer/getTemplate", async ({ extractionId, includeContextTemplates }) => {
  return await axios
    .get<{
      analysis_id: string;
      templates: ExtractionTemplate[];
      requirements: Requirement[];
    }>(`/autopilot/analyze/document/${extractionId}`)
    .then((resp) =>
      includeContextTemplates
        ? resp.data
        : { ...resp.data, templates: resp.data?.templates.filter((template) => !template.context_template) },
    );
});

export const getTemplateRequirements = createAsyncThunk<Requirement[], string>(
  "currentExtractionReducer/getTemplateRequirements",
  async (extractionId) => {
    const { data } = await axios.get<{
      analysis_id: string;
      templates: ExtractionTemplate[];
      requirements: Requirement[];
    }>(`/autopilot/analyze/document/${extractionId}`);
    return data.requirements;
  },
);

export const getRequirementGroups = createAsyncThunk<
  { data: MergedRequirement[]; isInitialFetch: boolean },
  { projectId: string; isInitialFetch?: boolean },
  { state: RootState }
>("currentExtractionReducer/getRequirementGroups", async ({ projectId, isInitialFetch = false }) => {
  return await axios
    .get(`/autopilot/${projectId}/requirements/groups`)
    .then((resp) => ({ data: resp.data, isInitialFetch }));
});

type State = {
  currentExtraction?: ToImmutable<LiveObject<Extraction>>;
  activeDocument?: Document;
  documents: Document[];
  templates: ExtractionTemplate[];
  coordinates: DocumentReferenceRoot["elements"];
  orderKeys: Record<OrderKey["requirement_id"], OrderKey>;
  isExtractingDocument: boolean;
  isLoadingDocuments: boolean;
  isLoadingOrderKeys: boolean;
  isLoadingTemplate: boolean;
  isLoadingRequirementGroups: boolean;
  isLoadingFilteredRequirements: boolean;
  viewSummary: boolean;
  editableTemplateRowState: {
    id: string;
    localValue: string;
  };
  timeRemaining: number;
  highlightedElementId: string;
  requirementsConfig: {
    currentRequirementIdAdded: string;
  };
  templateRequirements: Requirement[];
  atlasRequirements: Requirement[];
  sectionToMove: ToImmutable<Section> | null;
  mergedRequirements: MergedRequirement[];
  requirementFilters: ActiveFilter;
  contextBankOpen: boolean;
  askAiOpen: boolean;
  shouldTriggerJump: boolean;
  groupedFilteredRequirementsByDocument: Record<string, Requirement[]>;
  filteredRequirements: Requirement[];
  coordinateCache: {
    [key: string]: {
      isLoading: boolean;
      coordinates: DocumentReferenceRoot["elements"];
    };
  };
  coordinateCancelTokens: CancelTokenSource[];
  groupedBlocks: {
    allFilteredBlocks: GroupedBlock[];
    pageGroups: Record<number, GroupedBlock[]>;
  };
  isCanceled: boolean;
  activeDragOverId: string;
};

const initialState: State = {
  currentExtraction: undefined,
  documents: [],
  templates: [],
  coordinates: [],
  orderKeys: {},
  isExtractingDocument: false,
  isLoadingDocuments: false,
  isLoadingOrderKeys: false,
  isLoadingTemplate: false,
  isLoadingRequirementGroups: false,
  isLoadingFilteredRequirements: false,
  viewSummary: false,
  editableTemplateRowState: {
    id: "",
    localValue: "",
  },
  timeRemaining: 0,
  highlightedElementId: "",
  requirementsConfig: {
    currentRequirementIdAdded: "",
  },
  templateRequirements: [],
  atlasRequirements: [],
  sectionToMove: null,
  mergedRequirements: [],
  requirementFilters: DEFAULT_FILTERS,
  contextBankOpen: true,
  askAiOpen: true,
  groupedFilteredRequirementsByDocument: {},
  filteredRequirements: [],
  shouldTriggerJump: false,
  coordinateCache: {},
  coordinateCancelTokens: [],
  groupedBlocks: {
    allFilteredBlocks: [],
    pageGroups: {},
  },
  isCanceled: false,
  activeDragOverId: "",
};

const currentExtractionReducer = createSlice({
  name: "currentExtractionReducer",
  initialState,
  reducers: {
    setViewSummary: (state: State, action: PayloadAction<State["viewSummary"]>) => {
      state.viewSummary = action.payload;
    },
    setRequirementsConfig: (state: State, action: PayloadAction<Partial<State["requirementsConfig"]>>) => {
      state.requirementsConfig = { ...state.requirementsConfig, ...action.payload };
    },
    setCurrentExtraction: (state: State, action: PayloadAction<State["currentExtraction"]>) => {
      state.currentExtraction = action.payload;
      const isCoordinateCacheEmpty = isEmpty(state.coordinateCache);
      if (isCoordinateCacheEmpty) {
        const cache = action.payload?.file_ids.reduce<State["coordinateCache"]>((acc, fileId) => {
          acc[fileId] = {
            isLoading: false,
            coordinates: [],
          };
          return acc;
        }, {});
        state.coordinateCache = cache || {};
      }
    },
    setGroupedBlocks: (state: State, action: PayloadAction<State["groupedBlocks"]>) => {
      state.groupedBlocks = action.payload;
    },
    setActiveDocument: (state: State, action: PayloadAction<State["activeDocument"]>) => {
      if (state.activeDocument?.id !== action.payload?.id) {
        state.activeDocument = action.payload;
        state.coordinates = [];
      }
    },
    setEditableTemplateRowState: (state: State, action: PayloadAction<Partial<State["editableTemplateRowState"]>>) => {
      state.editableTemplateRowState = { ...state.editableTemplateRowState, ...action.payload };
    },
    setIsCanceled: (state: State, action: PayloadAction<State["isCanceled"]>) => {
      state.isCanceled = action.payload;
    },
    setIsExtractingDocument: (state: State, action: PayloadAction<State["isExtractingDocument"]>) => {
      state.isExtractingDocument = action.payload;
    },
    setHighlightedElementId: (state: State, action: PayloadAction<State["highlightedElementId"]>) => {
      state.highlightedElementId = action.payload;
    },
    setFilteredRequirements: (state: State, action: PayloadAction<Requirement[]>) => {
      const requirements = action.payload;
      const groupedByDocument = groupBy(requirements, "document_id");

      state.groupedFilteredRequirementsByDocument = groupedByDocument;
      state.filteredRequirements = requirements;
    },
    setSectionToMove: (state: State, action: PayloadAction<State["sectionToMove"]>) => {
      state.sectionToMove = action.payload;
    },
    setTimeRemaining: (state: State, action: PayloadAction<State["timeRemaining"]>) => {
      state.timeRemaining = action.payload;
    },
    setRequirementFilters: (state: State, action: PayloadAction<State["requirementFilters"]>) => {
      state.requirementFilters = action.payload;
    },
    setContextBankOpen: (state: State, action: PayloadAction<State["contextBankOpen"]>) => {
      state.contextBankOpen = action.payload;
    },
    setShouldTriggerJump: (state: State, action: PayloadAction<State["shouldTriggerJump"]>) => {
      state.shouldTriggerJump = action.payload;
    },
    toggleAskAiOpen: (state: State, action: PayloadAction<State["askAiOpen"] | undefined>) => {
      state.askAiOpen = action.payload || !state.askAiOpen;
    },
    appendCoordinateCancelTokens: (state: State, action: PayloadAction<CancelTokenSource>) => {
      state.coordinateCancelTokens.push(action.payload);
    },
    setActiveDragOverId: (state: State, action: PayloadAction<State["activeDragOverId"]>) => {
      state.activeDragOverId = action.payload;
    },
    clearExtractState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCoordinates.pending, (state, action) => {
        const { fileId } = action.meta.arg;

        const currentCache = state.coordinateCache[fileId];

        if (currentCache) {
          state.coordinateCache[fileId].isLoading = true;
        } else {
          state.coordinateCache[fileId] = {
            isLoading: true,
            coordinates: [],
          };
        }
      })
      .addCase(getCoordinates.fulfilled, (state, action) => {
        const { fileId } = action.meta.arg;

        const coordinates = action.payload.elements || [];
        const existingCoordinates = state.coordinateCache[fileId]?.coordinates;
        const isSameCoordinates = isEqual(coordinates, existingCoordinates);

        if (state.coordinateCache[fileId] && isSameCoordinates) {
          state.coordinateCache[fileId].isLoading = false;
        } else {
          state.coordinateCache[fileId] = {
            isLoading: false,
            coordinates,
          };
        }
      })
      .addCase(getCoordinates.rejected, (state, action) => {
        const { fileId } = action.meta.arg;
        const hasCache = state.coordinateCache[fileId];
        if (hasCache) state.coordinateCache[fileId].isLoading = false;
      })
      .addCase(getOrderKeys.rejected, (state) => {
        state.isLoadingOrderKeys = false;
      })
      .addCase(getOrderKeys.pending, (state) => {
        state.isLoadingOrderKeys = true;
      })
      .addCase(getOrderKeys.fulfilled, (state, action) => {
        state.orderKeys = keyBy(action.payload, "requirement_id");
        state.isLoadingOrderKeys = false;
      })
      .addCase(getExtractionDocuments.pending, (state) => {
        state.isLoadingDocuments = true;
      })
      .addCase(getExtractionDocuments.fulfilled, (state, action) => {
        state.activeDocument = action.payload[0];
        state.documents = action.payload;
        state.isLoadingDocuments = false;
      })
      .addCase(getExtractionDocuments.rejected, (state) => {
        state.isLoadingDocuments = false;
      })
      .addCase(getTemplate.pending, (state) => {
        state.isLoadingTemplate = true;
      })
      .addCase(getTemplate.fulfilled, (state, action) => {
        state.templates = action.payload.templates;
        state.templateRequirements = action.payload.requirements;
        state.isLoadingTemplate = false;
      })
      .addCase(getTemplate.rejected, (state) => {
        state.isLoadingTemplate = false;
      })
      .addCase(getTemplateRequirements.fulfilled, (state, action) => {
        state.templateRequirements = action.payload;
      })
      .addCase(getRequirementGroups.pending, (state, action) => {
        const { isInitialFetch } = action.meta.arg;

        if (isInitialFetch) {
          state.isLoadingRequirementGroups = true;
        }
      })
      .addCase(getRequirementGroups.fulfilled, (state, action) => {
        const { data: mergedRequirements } = action.payload;
        state.mergedRequirements = mergedRequirements;
        state.isLoadingRequirementGroups = false;
      })
      .addCase(getRequirementGroups.rejected, (state) => {
        state.isLoadingRequirementGroups = false;
      })
      .addCase(getFilteredRequirements.pending, (state) => {
        state.isLoadingFilteredRequirements = true;
      })
      .addCase(getFilteredRequirements.fulfilled, (state, action) => {
        const requirements = action.payload.filter((req) => !req.group_id);
        const groupedByDocument = groupBy(requirements, "document_id");
        state.groupedFilteredRequirementsByDocument = groupedByDocument;
        state.isLoadingFilteredRequirements = false;
      })
      .addCase(getFilteredRequirements.rejected, (state) => {
        state.isLoadingFilteredRequirements = false;
      })
      .addCase(getAtlasRequirements.fulfilled, (state, action) => {
        state.atlasRequirements = action.payload;
      });
  },
});

export const {
  setGroupedBlocks,
  setShouldTriggerJump,
  clearExtractState,
  setActiveDocument,
  setEditableTemplateRowState,
  setViewSummary,
  setFilteredRequirements,
  setHighlightedElementId,
  setIsExtractingDocument,
  setCurrentExtraction,
  setRequirementsConfig,
  setSectionToMove,
  setTimeRemaining,
  setRequirementFilters,
  setContextBankOpen,
  toggleAskAiOpen,
  appendCoordinateCancelTokens,
  setIsCanceled,
  setActiveDragOverId,
} = currentExtractionReducer.actions;

export default currentExtractionReducer.reducer;
