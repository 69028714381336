import CDropdown from "components/custom/CDropdown";
import CFWButton from "components/custom/CFWButton";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import progressIcon from "../../../Assets/ProgressIcon.svg";
import sort from "../../../Assets/sort.svg";
import MemberProgress from "./MemberProgress";
import MembersModal from "./MembersModal";
import { useAppDispatch } from "store/storeTypes";
import { useSelector } from "react-redux";
import { useStorage } from "YJSProvider/createYJSContext";
import { isEqual } from "lodash";

const Progress = ({ internalContractId }) => {
  const { workspaceMembers } = useSelector((store) => store.auth);

  const complianceMatrixState = useStorage((storage) => storage.compliance_matrix, isEqual);

  const unskippedRequirements = useMemo(
    () => complianceMatrixState?.filter((requirement) => !requirement?.requirement.skipped),
    [complianceMatrixState],
  );

  const totalRequirements = useMemo(() => unskippedRequirements?.length, [unskippedRequirements]);

  const completedRequirementsRows = useMemo(
    () => unskippedRequirements?.filter((requirement) => requirement?.requirement_status == "Completed"),
    [unskippedRequirements],
  );

  const completedRequirements = completedRequirementsRows.length;

  const assignedRequirementRows = useMemo(
    () => unskippedRequirements?.filter((requirement) => requirement?.assigned_user_ids?.length > 0),
    [unskippedRequirements],
  );

  const userIdToCompletion = useMemo(() => {
    const userIdToCompletion = {};
    assignedRequirementRows?.forEach((requirement) => {
      requirement?.assigned_user_ids?.forEach((userId) => {
        if (userIdToCompletion[userId]) {
          userIdToCompletion[userId].total_requirements += 1;
          if (requirement?.requirement_status == "Completed") {
            userIdToCompletion[userId].total_completed += 1;
          }
        } else {
          userIdToCompletion[userId] = {
            total_requirements: 1,
            total_completed: requirement?.requirement_status == "Completed" ? 1 : 0,
          };
        }
      });
    });
    for (const userId in userIdToCompletion) {
      const member = workspaceMembers.find((member) => member.id === userId);
      if (member) {
        userIdToCompletion[userId].username = member.username;
      } else {
        // if member is not found in workspaceMembers, then they are deleted or removed, so remove key
        delete userIdToCompletion[userId];
      }
    }
    return userIdToCompletion;
  }, [assignedRequirementRows]);

  const progress = {
    user_completion_progress: Object.values(userIdToCompletion),
  };

  const [openMembersModal, setOpenMembersModal] = useState(false);
  const [progressSortOpen, setProgressSortOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [sortType, setSortType] = useState();

  const closeSort = () => setProgressSortOpen(false);
  const openSort = () => setProgressSortOpen(true);

  const sortSelect = (sortType) => {
    setSortType(sortType);
    setProgressSortOpen(false);
  };

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (!internalContractId || !firstUpdate.current) return;

    firstUpdate.current = false;
  }, [dispatch, internalContractId]);

  const allProgress = useMemo(
    () => [...(progress?.user_completion_progress || [])],
    [progress?.user_completion_progress],
  );

  const usersProgress = useMemo(
    () =>
      sortType === "Least completed %"
        ? allProgress.sort(
            (a, b) =>
              (a?.total_completed / a?.total_requirements) * 100 - (b?.total_completed / b?.total_requirements) * 100,
          )
        : allProgress.sort(
            (a, b) =>
              (b?.total_completed / b?.total_requirements) * 100 - (a?.total_completed / a?.total_requirements) * 100,
          ),
    [allProgress, sortType],
  );
  const progressNumber =
    totalRequirements && totalRequirements !== 0 ? Math.round((completedRequirements / totalRequirements) * 100) : 0;

  return (
    <div className="border border-[#D8DDE2] shadow-sm p-4 rounded-lg mb-4 bg-white pb-4">
      <h1 className="text-base font-medium">Progress</h1>

      <div className="flex flex-col items-end mt-5 gap-2">
        <span className="text-sm font-medium text-[#8D98A2]">
          {progressNumber === undefined ? "-" : `${progressNumber}%`}
        </span>
        <ProgressBar progress={progressNumber || 0} />
      </div>

      <div className="w-full flex flex-col mt-7">
        <div className="w-full flex gap-2 items-center">
          <img src={progressIcon} alt="" className="w-8 h-8" />
          <h3 className="text-[15px] font-medium">Completed Requirements</h3>
          <Badge total_completed={completedRequirements} total_requirements={totalRequirements} />
        </div>
        <div className="mt-7 flex justify-between items-center">
          <h5 className="text-[14px] font-medium">Member Performance</h5>
          <div className="relative">
            <img src={sort} alt="" className="w-4 cursor-pointer" onClick={openSort} />
            {progressSortOpen && (
              <CDropdown
                className="absolute top-[110%] flex flex-col gap-[4px] shadow-lg translate-x-[-90%] rounded-[4px] max-w-[300px] w-[132px]"
                options={["Least completed %", "Most completed %"]}
                onClickaway={closeSort}
                onSelect={sortSelect}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col mt-2">
          {usersProgress?.length > 0 ? (
            usersProgress
              ?.slice(0, 5)
              .map((userProgress, index) => (
                <MemberProgress
                  name={userProgress.username}
                  totalTasks={userProgress?.total_requirements}
                  completedTasks={userProgress?.total_completed}
                  key={index}
                />
              ))
          ) : (
            <p className="text-[13px] text-[#8D98A2]">No members assigned.</p>
          )}
        </div>
        <div className="w-full">
          <CFWButton
            title="View All Members"
            className="mt-5 h-[40px] rounded-[8px]"
            onClick={() => setOpenMembersModal(true)}
          />
        </div>
      </div>
      <MembersModal isOpen={openMembersModal} setIsOpen={setOpenMembersModal} members={usersProgress} />
    </div>
  );
};

const ProgressBar = ({ progress }) => {
  return (
    <div className="w-full  h-[15px] bg-[#D8E0E8] rounded-lg overflow-hidden">
      <div
        className={` h-[100%] rounded-lg transition-all ease-in-out`}
        style={{
          backgroundImage: "linear-gradient(to right, #2A47AB, rgba(42, 70, 171, 0.5))",
          width: progress > 0 ? `${progress}%` : 0,
        }}
      ></div>
    </div>
  );
};

const Badge = ({ total_completed, total_requirements }) => {
  const percentage = (total_completed / total_requirements) * 100;

  const styles = {
    background:
      total_requirements === 0
        ? "#DAE0E4"
        : percentage < 50
          ? "#FBE5E5"
          : percentage > 50 && percentage < 75
            ? "#FCF1E0"
            : percentage > 75
              ? "#E6F5F0"
              : "lightgray",
    color:
      total_requirements === 0
        ? "#5B6B78"
        : percentage < 50
          ? "#DC2626"
          : percentage > 50 && percentage < 75
            ? "#E99C26"
            : percentage > 75
              ? "#2CA87F"
              : "gray",
  };

  return (
    <div className="px-[8px] py-[2px] rounded-xl font-medium text-[12px]" style={{ ...styles }}>
      {total_completed}/{total_requirements}
    </div>
  );
};

export default memo(Progress);
