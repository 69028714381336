import axios from "axios";
import { UserMeta, Storage } from "./schema";
import { createYJSContext } from "YJSProvider/createYJSContext";
import { RoomTypes } from "YJSProvider/types";
import { parseArrayBufferToChunks } from "utils/parseArrayBufferToChunks";

const authenticateAIAssistant = async () => {
  return await axios
    .post("/chat/ai_assistant/token")
    .then((response) => {
      if (response.status === 401) {
        // @ts-expect-error
        window.location = "/auth";
        return Promise.reject();
      }
      return response.data.token;
    })
    .catch((e) => {
      if (e.response.status === 401) {
        // @ts-expect-error
        window.location = "/auth";
      }
    });
};

export const getAIAssistantStorage = async () => {
  const roomType = RoomTypes.AIAssistant;

  const res = await axios.get<ArrayBuffer>(`/ypy/rooms/${roomType}/ydoc_updates`, {
    responseType: "arraybuffer",
  });
  return parseArrayBufferToChunks(res.data);
};

export const { RoomProvider, useMutation, useStorage, useStatus, useRoom } = createYJSContext<
  UserMeta,
  Storage,
  typeof authenticateAIAssistant
>(authenticateAIAssistant, getAIAssistantStorage);
