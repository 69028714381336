import { deleteADoc } from "api/api";
import CustomModal from "components/CustomModal";
import Icon from "components/atoms/icons/Icon";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { useNotification } from "context/notificationContext";
import useDocumentPreview from "hook/useDocumentPreview";
import { useCallback, useEffect, useState } from "react";
import { CloseIconBlack, Spinner } from "utils/icons";
import dots from "../../Assets/3dots.svg";
import commonFile from "../../Assets/defaultDocument.svg";
import doc from "../../Assets/doc.svg";
import document_text from "../../Assets/document-text.svg";
import pdf from "../../Assets/pdf.svg";
import pptx from "../../Assets/ppt.svg";
import xls from "../../Assets/xls.svg";
import zip from "../../Assets/zipNew.svg";
import UploadInternalDocument from "./modals/UploadInternalDocument";
import { Download, Trash2 } from "lucide-react";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { useFlags } from "hook/useFlags";
import { fileProcessed } from "utils/fileProcessed";

// ----------------- List of source files, docs ---------------------
const Source = ({ searchPage, contractDetails, internalContractId, internalContractDetails, setForceRefresh }) => {
  const flags = useFlags();
  const { setToast } = useNotification();
  const { downloadFile } = useDocumentPreview();
  const [allDocs, setAllDocs] = useState([]);
  const [deletingDocs, setDeletingDocs] = useState([]);

  const [pollInternalContractDetails, setPollInternalContractDetails] = useState(false);

  const [documentSummaryModal, setDocumentSummaryModal] = useState({
    open: false,
    summary: "",
  });

  const [rfpReportModal, setRfpReportModal] = useState({
    open: false,
    report: "",
  });

  useEffect(() => {
    if (!pollInternalContractDetails) return;
    const intervalId = setInterval(() => {
      setForceRefresh(true);
    }, 10000);
    return () => clearInterval(intervalId);
  }, [pollInternalContractDetails]);

  // render img icon based on type
  const imgIcon = (type) => {
    let img = commonFile;
    switch (type) {
      case "pdf":
      case "application/pdf":
        img = pdf;
        break;
      case "doc":
      case "docx":
      case "application/doc":
      case "application/docx":
        img = doc;
        break;
      case "xls":
      case "xlsx":
      case "application/xls":
      case "application/xlsx":
        img = xls;
        break;
      case "pptx":
      case "potx":
      case "ppsx":
        img = pptx;
        break;
      case "zip":
        img = zip;
        break;
      default:
        img = commonFile;
    }
    return img;
  };

  const deleteInternalDoc = useCallback(
    (id) => {
      if (deletingDocs.includes(id)) return;
      deleteADoc(id)
        .then(() => {
          setForceRefresh(true);
          setTimeout(() => setDeletingDocs((prev) => prev?.filter((b) => b !== id)), 700);
        })
        .catch((err) => {
          setToast.error({
            title: "Unable to delete document",
            msg: "We were unable to delete the document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
          });
          setDeletingDocs((prev) => prev?.filter((b) => b !== id));
        });
    },
    [deletingDocs, setForceRefresh, setToast],
  );

  useEffect(() => {
    if (internalContractDetails) {
      const government_sources = internalContractDetails?.government_source || [];
      const internal_documents = internalContractDetails?.internal_documents || [];
      const combined_sources = [...government_sources, ...internal_documents];
      combined_sources.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      setAllDocs(combined_sources);
    } else if (contractDetails) {
      setAllDocs(contractDetails?.government_source || []);
    }
  }, [contractDetails, internalContractDetails]);

  const sourceItems = useCallback(
    (source) => {
      return [
        ...(searchPage
          ? [
              {
                key: 1,
                label: (
                  <div className="flex items-center gap-2">
                    <Download size={14} />
                    Download
                  </div>
                ),
                disabled:
                  source?.ext_access?.toLowerCase() === "controlled" ? true : source?.download_url ? false : true,
                onSelect: () => {
                  if (source?.ext_access?.toLowerCase() === "controlled") return;
                  var a = document.createElement("a");
                  a.href = source?.download_url;
                  a.click();
                },
              },
            ]
          : []),
        ...(!searchPage
          ? [
              {
                key: 1,
                label: (
                  <div className="flex items-center gap-2">
                    <Download size={14} />
                    Download
                  </div>
                ),
                disabled:
                  source?.ext_access?.toLowerCase() === "controlled" ? true : source?.download_url ? false : true,
                onSelect: () => {
                  if (source?.ext_access?.toLowerCase() === "controlled") return;
                  const a = document.createElement("a");
                  a.href = source?.download_url;
                  a.click();
                },
              },
            ]
          : []),
        ...(!searchPage
          ? [
              {
                key: 2,
                label: (
                  <div className="flex items-center gap-2 text-red-500">
                    {deletingDocs?.includes(source?.id) ? <Spinner width={13} height={13} /> : <Trash2 size={14} />}
                    Delete
                  </div>
                ),
                disabled: !!deletingDocs?.includes(source?.id),
                onSelect: () => {
                  setDeletingDocs([...deletingDocs, source?.id]);
                  deleteInternalDoc(source?.id);
                },
              },
            ]
          : []),
      ];
    },
    [deleteInternalDoc, deletingDocs, searchPage],
  );

  const attachmentTitle = searchPage ? "Attachments" : "Relevant Documents";

  const fileTooLargeMessages = [
    "Apologies! File is too large to process. For support with larger files, please connect with our support team at support@vultron.ai.",
    "The document size exceeds our processing limits (approximately 500 pages and 200MB). Please contact support@vultron.ai for assistance or to request a limit increase.",
    "The document size exceeds our processing limits (approximately 2000 pages and 500MB). Please contact support@vultron.ai for assistance or to request a limit increase.",
  ];

  const pendingMessage = flags.enableOnsiteFeatures
    ? "Vultron is synthesizing and processing the document. This may take a few hours, depending on the size of the document and the number of documents uploaded."
    : "Vultron is synthesizing and processing the document. This may take up to 45 minutes, depending on the size of the document.";

  return (
    <>
      <div className="border border-[#D8DDE2] shadow-sm rounded-lg p-4 pt-1 mb-4 bg-white">
        {searchPage && (
          <div className="flex justify-between items-center mb-5 mt-3">
            <div className="text-base font-medium">
              <div className="flex items-center gap-2">{searchPage && attachmentTitle}</div>
            </div>
          </div>
        )}
        {!searchPage && (
          <div className="flex justify-between items-center mb-2">
            <div className="text-base font-medium">
              <div className="flex items-center gap-2">{searchPage && attachmentTitle}</div>
            </div>
            <UploadInternalDocument internalContractId={internalContractId} setForceRefresh={setForceRefresh} />
          </div>
        )}
        {/* government  */}
        <div className="max-h-[235px] overflow-y-auto">
          {!!allDocs?.length &&
            allDocs?.map((source, i) => (
              <div
                key={i}
                className={`group flex border border-gray-300 w-[100%] max-h-[60px] shadow-sm justify-between items-center my-2 rounded-lg bg-white hover:bg-[#f7f7f7]`}
              >
                <button
                  className={`p-3 flex justify-between items-center w-full rounded-l-lg cursor-pointer`}
                  style={{
                    maxWidth: "calc(100% - 62px)",
                    width: "100%",
                  }}
                >
                  <div className="flex items-center gap-1 flex-grow max-w-[52%]">
                    <img
                      className="max-w-[25px] w-auto object-contain"
                      src={imgIcon(source.file_type)}
                      alt="file-icon"
                    />
                    <p className="text-sm pl-2 text-gray-700 truncate max-w-full w-max text-left">
                      {source?.file_name}
                    </p>
                    {source?.ext_access && (
                      <span className="text-sm text-gray-700 ">
                        {source?.ext_access?.toLowerCase() === "controlled"
                          ? "(Controlled)"
                          : source?.ext_access?.toLowerCase() === "deleted"
                            ? "(Deleted)"
                            : null}
                      </span>
                    )}
                    <button
                      onClick={() => downloadFile(source?.download_url)}
                      className="opacity-0 group-hover:opacity-100 text-[16px] pb-0.5 text-action duration-150 hover:text-action-hover"
                    >
                      <Download size={16} />
                    </button>
                  </div>
                  <div className="ml-auto text-sm text-gray-700">{source?.file_size_formatted}</div>
                  <div className="ml-auto text-sm text-gray-700">{source?.upload_date}</div>
                  {!searchPage && (
                    <div className="ml-auto">
                      {document?.error_message ? (
                        <div className="flex">
                          {fileTooLargeMessages.includes(document?.error_message) ? (
                            <p className="text-sm text-[#7063FF] mr-1">Limit Exceeded</p>
                          ) : (
                            <p className="text-sm text-[#7063FF] mr-1">Attention Required</p>
                          )}
                          <Tooltip
                            contentProps={{ alignOffset: -78, align: "start" }}
                            content={
                              <div className="text-gray-darkest">
                                <span className="font-normal">{document?.error_message}</span>
                              </div>
                            }
                            placement="top"
                          >
                            <Icon name="InfoCircle" className="w-4 h-4 text-[#7063FF] mt-0.5" />
                          </Tooltip>
                        </div>
                      ) : fileProcessed(flags.useIndexed, source.created_at, 20, source.indexed) ? (
                        <p className="text-sm text-[#4cb592]">Processed</p>
                      ) : (
                        <div className="flex">
                          <p className="text-sm text-[#4680FF] mr-1">Pending</p>
                          <Tooltip
                            contentProps={{ alignOffset: -78, align: "start" }}
                            content={
                              <div className="text-gray-darkest">
                                <span className="font-normal">{pendingMessage}</span>
                              </div>
                            }
                            placement="top"
                          >
                            <Icon name="InfoCircle" className="w-4 h-4 text-[#4680FF] mt-0.5" />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  )}
                </button>
                <DropdownMenu modal items={sourceItems(source)}>
                  <div className="w-full flex justify-end pl-1 pr-2">
                    {deletingDocs?.includes(source?.id) ? (
                      <Spinner classes="!text-black ml-auto w-5" width={13} height={13} />
                    ) : (
                      <img src={dots} alt="menu" className="cursor-pointer select-none w-5" />
                    )}
                  </div>
                </DropdownMenu>
              </div>
            ))}
        </div>
      </div>
      <CustomModal
        isOpen={documentSummaryModal?.open}
        onClose={() => {
          setDocumentSummaryModal({
            open: false,
            summary: "",
          });
        }}
        className="w-[100%] max-w-[850px]"
      >
        <div className="flex items-center sticky top-0 bg-white px-5 pt-6 pb-4 z-10">
          <img className="pr-2" src={document_text} alt="icon" />
          <h3 className="font-semibold text-xl">Document Summary</h3>
          <span
            className="ml-auto p-1 cursor-pointer select-none"
            role="button"
            onClick={() => {
              setDocumentSummaryModal({
                open: false,
                summary: "",
              });
            }}
          >
            <CloseIconBlack />
          </span>
        </div>
        <div className="mx-4 p-4 overflow-y-auto max-h-[560px] border-gray-200 border-[1px] rounded-md">
          <pre className="whitespace-pre-wrap text-base overflow-x-hidden font-sans">
            {documentSummaryModal?.summary?.trim() ?? "No summary available for this document."}
          </pre>
        </div>
        <button
          className="py-3 px-4 m-4 rounded-md font-[500] border-action bg-action text-white active:bg-[#304aa5de]"
          style={{ width: "calc(100% - 2rem)" }}
          onClick={() => {
            setDocumentSummaryModal({
              open: false,
              summary: "",
            });
          }}
        >
          Close
        </button>
      </CustomModal>
      <CustomModal
        isOpen={rfpReportModal?.open}
        onClose={() => {
          setRfpReportModal({
            open: false,
            report: "",
          });
        }}
        className="w-[100%] max-w-[850px]"
      >
        <div className="flex items-center sticky top-0 bg-white px-5 pt-6 pb-4 z-10">
          <img className="pr-2" src={document_text} alt="icon" />
          <h3 className="font-semibold text-xl">RFP Report</h3>
          <span
            className="ml-auto p-1 cursor-pointer select-none"
            role="button"
            onClick={() => {
              setRfpReportModal({
                open: false,
                report: "",
              });
            }}
          >
            <CloseIconBlack />
          </span>
        </div>
        <div className="mx-4 p-4 overflow-y-auto max-h-[560px] border-gray-200 border-[1px] rounded-md">
          {(() => {
            try {
              const parsedReport = JSON.parse(rfpReportModal?.report);
              if (!parsedReport) throw new Error("No report");
              return Object.entries(parsedReport).map(([field, value]) => {
                let displayValue;
                if (typeof value === "string") {
                  displayValue = value;
                } else if (typeof value === "object" && value !== null && !Array.isArray(value)) {
                  displayValue = Object.entries(value)
                    .map(([field, value]) => `${field}: ${value}`)
                    .join(", ");
                }
                return (
                  <div className="pb-5">
                    <strong>{field}:</strong> {displayValue}
                  </div>
                );
              });
            } catch (e) {
              // Parsing failed. Default to no report available.
              return <div className="text-gray-600">No report available.</div>;
            }
          })()}
        </div>
        <button
          className="py-3 px-4 m-4 rounded-md font-[500] border-action bg-action text-white active:bg-[#304aa5de]"
          style={{ width: "calc(100% - 2rem)" }}
          onClick={() => {
            setRfpReportModal({
              open: false,
              report: "",
            });
          }}
        >
          Close
        </button>
      </CustomModal>
    </>
  );
};

export default Source;
