import { useSearchParams } from "react-router-dom";
import { CopilotPresencePage } from "types/Presence";

export const useBuildRequirementLink = (volumeId?: string, sectionId?: string, reqId?: string) => {
  const [searchParams] = useSearchParams();

  if (!volumeId || !sectionId || !reqId)
    return { path: window.location.href, link: window.location.pathname + window.location.search };

  const newSearchParams = new URLSearchParams({
    ...Object.fromEntries(searchParams.entries()),
    tab: CopilotPresencePage.Template,
    req: reqId,
  });
  const directionPath = `/dashboard/contracts/details/volumes/${volumeId}/sections/${sectionId}/requirements?${newSearchParams.toString()}`;
  return { path: directionPath, link: `${window.location.origin}${directionPath}` };
};
