import { Dispatch, SetStateAction, useMemo } from "react";
import { statusList } from "config/data";
import { MenuItem, MultiselectMenuItem } from "components/molecules/dropdown-menu/DropdownMenu";
import { deadlineOptions } from "components/Search/SearchPageSearchBar";

type FilterState = {
  status: string[];
  response_deadline: string[];
  publishedDate: string[];
};

export const useFilterOptions = (
  selectedFilters: FilterState,
  setSelectedFilters: Dispatch<SetStateAction<FilterState>>,
) => {
  const statusItems: MultiselectMenuItem<string>[] = useMemo(
    () =>
      statusList.map(({ label, bg }) => ({
        key: label,
        label: (
          <div>
            <span className="inline-block mr-2 w-3 h-3 rounded-full" style={{ backgroundColor: bg }} />
            {label}
          </div>
        ),
        value: label,
        onCheck: (checked, value) =>
          setSelectedFilters((prev) => ({
            ...prev,
            status: checked
              ? [value, ...(prev.status || [])]
              : prev.status?.filter((selectedStatus) => selectedStatus !== value),
          })),
        checked: !!selectedFilters?.status?.includes(label),
      })),
    [selectedFilters?.status, setSelectedFilters],
  );
  const deadlineItems: MenuItem<string>[] = useMemo(
    () =>
      deadlineOptions.map(({ name, value }) => ({
        key: value,
        label: name,
        value,
        onSelect: () =>
          setSelectedFilters((prev) => ({
            ...prev,
            response_deadline: selectedFilters?.response_deadline?.includes(value) ? [] : [value],
          })),
        selected: selectedFilters?.response_deadline?.includes(value),
      })),
    [selectedFilters?.response_deadline, setSelectedFilters],
  );

  return {
    statusItems,
    deadlineItems,
  };
};
