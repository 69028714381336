/** @jsxImportSource @emotion/react */

import { PopoverClose } from "@radix-ui/react-popover";
import { Checkbox } from "components/atoms/checkbox";
import Icon from "components/atoms/icons/Icon";
import { Button } from "components/editor/components";
import { Avatar } from "components/molecules/avatar";
import { useMemo, useState } from "react";
import { useAppSelector } from "store/storeTypes";
import tw from "twin.macro";

type SingleSelectProps = {
  singleSelect: true;
  onUserSelect: (id: string | null) => void;
};
type MultiSelectProps = {
  singleSelect?: false;
  onUserSelect: (id: string) => void;
};

type ModeProps = SingleSelectProps | MultiSelectProps;

type Props = {
  selectedUsers: string[];
  onClearAll?: () => void;
} & ModeProps;

const AssigneesPopoverContent = ({ singleSelect, selectedUsers, onUserSelect, onClearAll, ...props }: Props) => {
  const [searchVal, setSearchVal] = useState("");
  const { workspaceMembers } = useAppSelector((store) => store.auth);

  const filteredUsers = useMemo(() => {
    return workspaceMembers?.filter((me) =>
      me?.username?.toLocaleLowerCase()?.includes(searchVal?.toLocaleLowerCase()),
    );
  }, [searchVal, workspaceMembers]);

  return (
    <div className="w-max max-w-[425px] overflow-hidden bg-white" {...props}>
      <div className="sticky top-0 left-0 bg-white z-[2]">
        <input
          autoFocus
          onChange={(e) => setSearchVal(e.target.value)}
          value={searchVal}
          type="text"
          placeholder="Search"
          className="w-[100%] h-[25px] py-5 px-2 text-sm rounded border !outline-0 border-gray-200"
        />
        {!singleSelect && (
          <Button onClick={() => onClearAll?.()} variant="link" size="sm">
            Clear All
          </Button>
        )}
      </div>
      <div className="py-2 flex flex-col max-h-[180px] overflow-y-auto" css={[!singleSelect && tw`pt-0`]}>
        {singleSelect && (
          <PopoverClose>
            <button
              onClick={() => onUserSelect(null)}
              className="flex items-center justify-between gap-3 w-full py-2 px-2 duration-100 bg-transparent hover:bg-slate-100"
            >
              <div className="flex items-center gap-2 line-clamp-1">
                <Avatar size={20} empty className="!text-[13px] ml-0.5 text-slate-500" />
                Unassign
              </div>
              {!selectedUsers.length && <Icon name="Check" className="w-5 h-5 min-w-[20px]" />}
            </button>
          </PopoverClose>
        )}
        {filteredUsers?.length > 0 ? (
          filteredUsers?.map(({ id, username }) =>
            singleSelect ? (
              <PopoverClose key={id}>
                <button
                  onClick={() => onUserSelect(id)}
                  className="flex items-center justify-between gap-3 w-full py-2 px-2 duration-100 bg-transparent hover:bg-slate-100"
                >
                  <div className="flex items-center gap-2 line-clamp-1">
                    <Avatar id={username} size={24} name={username} className="!text-[12px]" />
                    {username}
                  </div>
                  {selectedUsers.includes(id) && <Icon name="Check" className="w-5 h-5 min-w-[20px]" />}
                </button>
              </PopoverClose>
            ) : (
              <div key={id} className="flex items-center min-w-[120px] max-w-[425px] py-1.5 pr-2 pl-1">
                <label className="flex items-center gap-2 text-sm select-none cursor-pointer w-full text-gray-900 max-w-full  overflow-hidden line-clamp-1">
                  <Checkbox onCheck={() => onUserSelect(id)} checked={selectedUsers.includes(id)} />
                  <Avatar id={username} size={24} name={username} className="!text-[13px]" />
                  {username}
                </label>
              </div>
            ),
          )
        ) : (
          <div className="min-w-[160px] text-gray-400 text-center px-2 py-3 text-xs">No members found.</div>
        )}
      </div>
    </div>
  );
};

export default AssigneesPopoverContent;
