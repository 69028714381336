import Heading, { HeadingOptions, type Level } from "@tiptap/extension-heading";

import { LEVELS, MAX_LEVEL } from "../constants";
import { expandSelectionToFullNodes, indent, outdent, setNodeAttrDefaults } from "./utils";

function getLevelClasses(level: Level) {
  const commonClasses =
    "outline-none leading-tight text-gray-800 bg-transparent flex-grow whitespace-nowrap border-l-2";
  const topClasses = "font-semibold pl-[3px]";
  const nestedClasses = `font-medium pl-2 border-gray-300`;

  switch (level) {
    case 1:
      return [commonClasses, topClasses].join(" ");
    default:
      return [commonClasses, nestedClasses].join(" ");
  }
}

/**
 * Extends heading functionality to support only predefined levels (LEVELS).
 * Limits headings to specific levels and customizes rendering to apply
 * indentation and styling based on the heading level. Adds an `xid` attribute
 * for unique node identification, ensuring consistent structure.
 */
const CustomHeading = Heading.extend<HeadingOptions & { maxLevel: Level }>({
  addOptions() {
    return {
      ...this.parent?.(),
      LEVELS,
      maxLevel: MAX_LEVEL,
    };
  },
  renderHTML({ node, HTMLAttributes }) {
    return [
      `h${node.attrs.level}`,
      {
        ...HTMLAttributes,
        class: getLevelClasses(node.attrs.level),
      },
      0,
    ];
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      xid: {
        default: null,
        parseHTML: (element) => element.getAttribute("xid"),
        renderHTML: (attributes) => {
          if (!attributes.xid) {
            return {};
          }
          return { xid: attributes.xid };
        },
      },
    };
  },
  addKeyboardShortcuts() {
    return {
      /**
       * Custom behavior when we delete the first node. Since there's logic around ensuring the first node always exists
       * and is at level 1, we need to custom logic to allow clearing the first node entirely
       */
      Backspace: () => {
        // Proceed only if the selection is a cursor (not a range)
        if (!this.editor.state.selection.empty) {
          return false;
        }

        const parentNode = this.editor.state.selection.$from.parent;
        const parentPos = this.editor.state.selection.$from.before();

        const isInFirstNode = parentPos <= 1;
        const isNodeEmpty = !parentNode.content.firstChild?.text?.trim().length;

        if (isInFirstNode && isNodeEmpty) {
          this.editor
            .chain()
            .focus()
            .command(({ state, dispatch }) => {
              const [, tr] = expandSelectionToFullNodes(state);

              dispatch?.(tr.deleteSelection().setMeta("addToHistory", true));
              return true;
            })
            .run();
          return true;
        }
        return false;
      },
      /**
       * Custom Tab key behavior to increase the indentation level of the current
       * heading node. Increases the level only if it does not exceed MAX_LEVEL
       * or the parent level + 1, enforcing hierarchical consistency. Prevents
       * default Tab behavior to maintain outline structure.
       */
      Tab: () => {
        setNodeAttrDefaults(this.editor.state.selection.$anchor.node());
        indent(this.editor, this.options.maxLevel);

        return true; // Prevent default tab behavior
      },
      /**
       * Custom Shift-Tab key behavior to decrease the indentation level of the
       * current heading and all its child nodes. Reduces the level only if it
       * does not fall below MIN_LEVEL. Ensures that nested child headings maintain
       * relative indentation after unindenting.
       */
      "Shift-Tab": () => {
        setNodeAttrDefaults(this.editor.state.selection.$anchor.node());
        outdent(this.editor);

        return true;
      },
    };
  },
});

export const RestrictedHeading = CustomHeading.configure({
  levels: LEVELS,
});
