import { useState } from "react";
import DocumentTable from "../DocumentTable";
import UploadDocumentModal from "../UploadDocumentModal";

const Documents = ({ showUploadModal, setUploadModal, searchVal }) => {
  const [forceRefresh, setForceRefresh] = useState(false);
  return (
    <div className="w-full">
      <DocumentTable searchVal={searchVal} forceRefresh={forceRefresh} setForceRefresh={setForceRefresh} />
      <UploadDocumentModal
        showUploadModal={showUploadModal}
        setShowUploadModal={setUploadModal}
        btnText={"Upload"}
        loadingBtnText={"Uploading"}
        setForceRefresh={setForceRefresh}
      />
    </div>
  );
};

export default Documents;
