/** @jsxImportSource @emotion/react */

import { useMemo } from "react";
import { groupBy } from "lodash";
import tw from "twin.macro";
import { pluralizeWord } from "utils/string";
import { Empty } from "components/molecules/empty";

import type { ToImmutable } from "YJSProvider/LiveObjects";
import type { Extraction, Volume } from "components/copilot/CopilotSchemaTypes";
import type { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";

import RequirementList from "./RequirementList";
import RequirementsListHeader from "./RequirementsListHeader";
import { useCurrentFormattedExtraction } from "../hooks";
import { useSelection } from "../../../document-display/SelectionContext";
import { StepValue } from "../../../types";

type Props = {
  dragDrafts: (Omit<ToImmutable<Volume>, "sections"> & { sections: FormattedSection[] })[];
  isReadOnly: boolean;
};

export function RequirementsSection({ dragDrafts, isReadOnly }: Props) {
  const { selectedBlocks } = useSelection();
  const { extraction } = useCurrentFormattedExtraction();
  const extractionHasSections = useMemo(
    () => extraction?.framework?.volumes?.some((vol) => vol.sections?.length),
    [extraction?.framework?.volumes],
  );
  const isRequirementsStep = extraction?.step === StepValue.Requirements;
  const isImportStep = extraction?.step === StepValue.Review;

  const groupedComplianceMatrix: Record<string, ToImmutable<Extraction["compliance_matrix"]>> = useMemo(() => {
    if (!isRequirementsStep && !isImportStep) return {};
    return groupBy(
      (
        extraction?.compliance_matrix.filter(
          (row) => !row.requirement.soft_deleted && !!row.proposal_reference.section_id && !row.requirement.skipped,
        ) || []
      ).sort((a, b) => (a.requirement.section_order || 0) - (b.requirement.section_order || 0)),
      (row) => row.proposal_reference.section_id,
    );
  }, [extraction?.compliance_matrix, isRequirementsStep, isImportStep]);
  const flattenedSections = useMemo(
    () =>
      dragDrafts.map((draft) => ({
        ...draft,
        sections: draft.sections.flatMap((section) => [section, ...(section.subsections || [])]),
      })),
    [dragDrafts],
  );

  return (
    <>
      {!!selectedBlocks?.length && extractionHasSections && (
        <>
          <div className="bg-gray-darkest text-white text-[13px] p-1 py-2 flex items-center justify-center">
            Hover below to add selected {pluralizeWord(selectedBlocks.length, "requirement")}
          </div>

          <div className="absolute inset-0 border-4 border-dashed border-gray-darkest pointer-events-none z-10" />
        </>
      )}
      <div
        className="overflow-y-auto relative"
        id="template-manager-section-scroll"
        css={[selectedBlocks?.length ? tw`h-[calc(100%-28px)]` : tw`h-full`, { zIndex: 5 }]}
      >
        {!flattenedSections.length && (
          <div className="px-8 h-full">
            <Empty
              heading="No Requirements Assigned"
              title="Begin by creating an outline and assigning requirements from the document"
            />
          </div>
        )}
        {flattenedSections.map(({ sections, id: volumeId, title: volumeTitle }) => (
          <div key={volumeId} className="flex flex-col relative" id={`template-manager-volume-${volumeId}`}>
            <RequirementsListHeader
              volumeId={volumeId}
              extractionId={extraction?.id}
              title={volumeTitle}
              isReadOnly={isReadOnly}
            />
            {sections.map(({ id: sectionId, title, parent_id }) => (
              <div
                key={sectionId}
                className="flex flex-col relative group"
                id={`template-manager-section-${sectionId}`}
              >
                <RequirementsListHeader
                  sectionId={sectionId}
                  volumeId={volumeId}
                  extractionId={extraction?.id}
                  title={title}
                  parentId={parent_id}
                  isReadOnly={isReadOnly}
                />
                <RequirementList requirements={groupedComplianceMatrix[sectionId] || []} sectionId={sectionId} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
}
