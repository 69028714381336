import { ReactComponent as ResponseGeneration } from "Assets/svgs/response-generation.svg";
import { LucideArrowRight } from "lucide-react";
import { useMemo, useEffect, useState } from "react";
import { useAppSelector } from "store/storeTypes";
import { THREAD_COUNT, ONSITE_THREAD_COUNT } from "./constants";
import { useFlags } from "hook/useFlags";
import { getInstantDraftQueuedTask } from "api/api";
import { pluralizeWord } from "utils/string";

const InstantDraftInstructions = () => {
  const instantDraftConfigSections = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.sections,
  );
  const extractionMatrix = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.compliance_matrix || [],
  );
  const flags = useFlags();
  const multiple = flags.estimatedTimeMultiplicationFactor || 1;
  const estimatedTimeMultiple = multiple < 1 ? 1 : multiple;
  const threadCount = flags.enableOnsiteFeatures ? ONSITE_THREAD_COUNT : THREAD_COUNT;
  const [instantDraftTasksInProgress, setInstantDraftTasksInProgress] = useState(0);

  useEffect(() => {
    if (!flags.enableOnsiteFeatures) {
      setInstantDraftTasksInProgress(0);
      return;
    }

    getInstantDraftQueuedTask()
      .then((response) => {
        setInstantDraftTasksInProgress(response.data.total_tasks_in_queue);
      })
      .catch(() => {
        setInstantDraftTasksInProgress(0);
      });
  }, [flags.enableOnsiteFeatures]);

  const requirementsToSubmitLength = useMemo(
    () =>
      extractionMatrix.filter(
        ({ requirement, proposal_reference }) =>
          !requirement.soft_deleted &&
          !!requirement.content?.trim() &&
          !!proposal_reference.section_id &&
          !requirement.skipped &&
          instantDraftConfigSections?.includes(proposal_reference.section_id),
      ).length,
    [extractionMatrix, instantDraftConfigSections],
  );
  const estimate = !requirementsToSubmitLength
    ? 0
    : requirementsToSubmitLength < 15
      ? 5 * estimatedTimeMultiple
      : Math.ceil(requirementsToSubmitLength / threadCount + (extractionMatrix.length % 8)) * estimatedTimeMultiple;

  return (
    <div className="bg-gray-lightest border border-gray-light rounded-md gap-4 flex flex-row justify-between items-center">
      <div className="gap-8 flex flex-col p-8">
        <div className="flex flex-col gap-2">
          <div className="text-gray-darkest text-2xl font-medium">What is Instant Draft?</div>
          <div className="text-gray-500 text-sm">
            Vultron combines your content library, capture insights, and proposal outline to generate a review-ready
            draft.
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-2 items-center">
            <LucideArrowRight size={18} /> <span className="text-sm text-gray-600">Generates initial responses</span>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <LucideArrowRight size={18} /> <span className="text-sm text-gray-600">Ranks content relevancy</span>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <LucideArrowRight size={18} />
            <span className="text-sm text-gray-600">Incorporates capture intelligence</span>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <LucideArrowRight size={18} /> <span className="text-sm text-gray-600">Scores generated responses</span>
          </div>
        </div>
        <div className="text-gray-600 text-sm font-medium">
          Estimated Time: {!estimate && "0 minutes"}
          {!!estimate && `${estimate} minutes`}
        </div>
        {flags.enableOnsiteFeatures && instantDraftTasksInProgress != 0 && (
          <div className="text-gray-500 text-sm -mt-4">
            There {instantDraftTasksInProgress == 1 ? "is" : "are"} {instantDraftTasksInProgress}{" "}
            {pluralizeWord(instantDraftTasksInProgress, "Instant Draft")} in progress. This Instant Draft will be
            processed once the existing drafts are complete.
          </div>
        )}
      </div>
      <div className="px-4 h-full bg-white border border-gray-lightest rounded-md m-4">
        <ResponseGeneration className="w-96 h-72" />
      </div>
    </div>
  );
};

export default InstantDraftInstructions;
