/** @jsxImportSource @emotion/react */

import { ButtonHTMLAttributes, ReactNode } from "react";
import SpinnerCircle from "utils/Spinner/SpinnerCircle";
import "twin.macro";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  icon: ReactNode;
}

const IconActionButton = ({ isLoading, icon, ...props }: Props) => {
  return (
    <button
      className="bg-action relative text-sm cursor-pointer flex items-center justify-center text-white w-5 min-w-[24px] h-6 min-h-[24px] duration-150 rounded-full hover:bg-action-hover disabled:bg-gray-200 disabled:text-slate-400 disabled:cursor-default"
      {...props}
    >
      {isLoading ? <SpinnerCircle className="h-[70%] w-[70%]" /> : icon}
    </button>
  );
};

export default IconActionButton;
