import type { Level } from "@tiptap/extension-heading";

export const MAX_LEVEL = 3;
export const MIN_LEVEL = 1;
export const LEVELS = new Array(MAX_LEVEL - MIN_LEVEL + 1).fill(0).map((_, i) => i + MIN_LEVEL) as Level[];
export const LEVEL_LABELS: Partial<{ [key in Level]: string }> = {
  1: "Volume",
  2: "Section",
  3: "Subsection",
};
export const NON_BREAKING_SPACE = "\u00A0";
export const UPDATE_VERSION_KEY = "volumesVersion";
