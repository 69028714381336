import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Editor } from "@tiptap/react";

type State = {
  fullscreen: boolean;
  sidebarVisible: boolean;
  activeCommentEditor?: Editor | null;
  activeEditorId?: string;
};

const initialState: State = {
  fullscreen: true,
  sidebarVisible: false,
  activeCommentEditor: undefined,
  activeEditorId: undefined,
};

const copilotReducer = createSlice({
  name: "copilotReducer",
  initialState,
  reducers: {
    setFullscreen: (state, action: PayloadAction<boolean>) => {
      state.fullscreen = action.payload;
    },
    setSidebarVisible: (state, action: PayloadAction<boolean>) => {
      state.sidebarVisible = action.payload;
    },
    setActiveCommentEditor: (
      state,
      action: PayloadAction<{ editor: Editor | undefined | null; editorId?: string }>,
    ) => {
      return { ...state, activeCommentEditor: action.payload.editor, activeEditorId: action.payload.editorId };
    },
  },
});

export const { setFullscreen, setSidebarVisible, setActiveCommentEditor } = copilotReducer.actions;

export default copilotReducer.reducer;
