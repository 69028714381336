import { useMemo, useState } from "react";
import { downloadProposal, cancelReviewEngine } from "api/api";
import { Spinner } from "utils/icons";
import { useDispatch } from "react-redux";
import { EditorToggler } from "../EditorToggler";
import { CopilotPresencePage } from "types/Presence";
import { useSearchParams } from "react-router-dom";
import Icon from "components/atoms/icons/Icon";
import { Upload } from "lucide-react";
import { Avatars } from "components/editor/components/Avatars";
import { useFlags } from "hook/useFlags";
import Popover from "components/atoms/popover/Popover";
import ReviewEnginePopover from "components/molecules/review-engine-popover/ReviewEnginePopover";
import { useAppSelector } from "store/storeTypes";
import { ReactComponent as Sparkles } from "Assets/svgs/sparkles.svg";
import tw from "twin.macro";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";
import { addDocIdToCanceledReviews, removeDocIdFromReviews } from "store/reducers/proposal/proposalReducer";

type Props = {
  fullscreen: boolean;
  setForceRefresh: (newValue: boolean) => unknown;
  setDocId: (docId: string) => unknown;
  proposalDocId: string;
};

const Proposal = ({ fullscreen, setForceRefresh, setDocId, proposalDocId }: Props) => {
  const flags = useFlags();
  const [searchParams] = useSearchParams();
  const nestedTab = searchParams.get("tab")?.toLocaleLowerCase();
  const referenceId = searchParams.get("docId")?.toLocaleLowerCase();
  const fullScreen = useAppSelector((state) => state.aiReducer.isExpanded);
  const [exporting, setExporting] = useState(false);
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const proposal_review_tasks = useAppSelector((state) => state.autopilotHealthCheck.proposal_review_tasks);
  const reviewsInProgress = useAppSelector((state) => state.proposal.reviewsInProgress);
  const dispatch = useDispatch();

  const reviewInProgressButtonText = flags.enableCancelReviewEngine ? "Cancel Review" : "Reviewing...";

  const isProposalReviewTaskInProgress = useMemo(() => {
    if (!referenceId) return false;

    const isInProgressOrQueued = proposal_review_tasks?.some(
      (task) => task.reference_id === referenceId && !task.failed,
    );

    return isInProgressOrQueued || reviewsInProgress.includes(referenceId);
  }, [proposal_review_tasks, referenceId, reviewsInProgress]);

  const handleReviewEngineButtonClick = () => {
    if (isProposalReviewTaskInProgress) {
      triggerConfirm({
        header: "Are you sure you want to cancel this review?",
        body: "Canceling will stop the review process and no comments will be added for this review.",
      }).then((proceed) => {
        if (proceed) {
          cancelReviewEngine(referenceId);
          dispatch(removeDocIdFromReviews(referenceId!));
          dispatch(addDocIdToCanceledReviews(referenceId!));
          setPopoverIsOpen(false);
        }
      });
    } else {
      setPopoverIsOpen(true);
    }
  };

  return (
    <div className="pt-3 px-5 flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
      <div className="flex items-center pb-2 mb-1">
        <div className="flex items-center justify-between w-full gap-2 ml-auto">
          <button
            onClick={() => {
              setDocId("");
              setForceRefresh(true);
            }}
            className="flex items-center justify-center gap-1.5 h-8 px-2.5 rounded border border-[rgb(218,220,224)] text-[rgb(95,99,104)] text-xs hover:bg-[rgb(218,220,224,0.2)] active:text-[rgb(60,64,67)]"
          >
            <Icon name="ArrowLeft" className="h-4 w-4" />
            All Proposals
          </button>
          <div className="flex items-center gap-1">
            <Avatars />
            {flags.reviewEngine && (
              <Popover
                content={<ReviewEnginePopover proposalDocId={proposalDocId} />}
                contentProps={{
                  align: "end",
                  alignOffset: -8,
                  css: tw`border-gray-900`,
                }}
                open={popoverIsOpen && !isProposalReviewTaskInProgress}
                onOpenChange={setPopoverIsOpen}
              >
                <button
                  {...(!flags.enableCancelReviewEngine && { disabled: isProposalReviewTaskInProgress })}
                  {...(flags.enableCancelReviewEngine && { onClick: handleReviewEngineButtonClick })}
                  className="bg-[radial-gradient(50%_50%_at_50%_50%,_#646464_0%,_#1E1E1E_100%)] cursor-pointer h-8 px-3.5 flex items-center justify-center gap-2.5 rounded text-white text-xs enabled:hover:bg-[radial-gradient(50%_50%_at_50%_50%,_#5e5d5d_0%,_#333333_100%)]"
                >
                  {isProposalReviewTaskInProgress ? reviewInProgressButtonText : "AI Review"}
                  {isProposalReviewTaskInProgress ? (
                    <Spinner classes="!text-black items-center" width={12} height={12} />
                  ) : (
                    <Sparkles className="white" size={12} />
                  )}
                </button>
              </Popover>
            )}
            <button
              className="h-8 px-2.5 flex items-center justify-center rounded border border-[rgb(218,220,224)] text-[rgb(95,99,104)] hover:bg-[rgb(218,220,224,0.2)] active:text-[rgb(60,64,67)]"
              onClick={() => {
                setExporting(true);
                const dataFormat = "yjs";
                downloadProposal(proposalDocId, dataFormat)
                  .then((response) => {
                    console.log({ Resdata: response.data });
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    console.log({ url });
                    const link = document.createElement("a");
                    const filename = `proposal_${new Date().toISOString().split("T")[0]}.docx`;
                    link.href = url;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                  })
                  .catch((error) => console.error(error))
                  .finally(() => setExporting(false));
              }}
            >
              {exporting ? <Spinner classes="!text-black items-center" width={12} height={12} /> : <Upload size={12} />}
            </button>
          </div>
        </div>
      </div>
      <div
        className="border bg-white px-3 pb-2 overflow-hidden flex flex-col flex-1"
        style={{
          position: fullScreen ? "fixed" : undefined,
          top: fullScreen ? "64px" : "",
          left: fullScreen ? "233px" : "",
          width: fullScreen ? "calc(100% - 233px)" : "",
          bottom: 0,
          overflow: "hidden",
          zIndex: fullScreen ? "20" : "",
        }}
      >
        <EditorToggler fullscreen={fullscreen} liveCursor={CopilotPresencePage.Proposal === nestedTab} />
      </div>
    </div>
  );
};

export default Proposal;
