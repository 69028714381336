import { useEffect } from "react";

export const useObserveSseController = (abortConnection: () => void, onAbortSuccess?: () => void) => {
  useEffect(() => {
    const unloadCallback = () => {
      abortConnection();
      onAbortSuccess?.();
    };
    window.addEventListener("unload", unloadCallback);
    return () => {
      window.removeEventListener("unload", unloadCallback);
      abortConnection();
      onAbortSuccess?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { abortConnection };
};
