import { DocumentInputContent } from "components/molecules/documents-mention-input/DocumentsMentionInput";

const INITIAL_LOADING_MSG = "Searching for relevant content";
const SECOND_LOADING_MSG = "Synthesizing";
const THIRD_LOADING_MSG = "Generating ideas";
export const GENERATED_CONTENT_DROPPABLE = "GENERATED_CONTENT";
export const SELECTED_CONTENT_DROPPABLE = "SELECTED_CONTENT";
export const INITIAL_QUERY_STATE: DocumentInputContent = {
  raw: "",
  plainText: "",
  mentions: [],
};
export const LOADING_MSGS = [INITIAL_LOADING_MSG, SECOND_LOADING_MSG, THIRD_LOADING_MSG];
