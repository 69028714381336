/** @jsxImportSource @emotion/react */
import { Interpolation, Theme } from "@emotion/react";
import { HTMLProps, PropsWithChildren } from "react";
import tw, { TwStyle } from "twin.macro";

interface Props extends PropsWithChildren<HTMLProps<HTMLDivElement>> {
  clickable?: boolean;
  round?: boolean;
  variant?: Variant;
  colors?: {
    primaryColor: string;
    secondaryColor: string;
  };
  styles?: Interpolation<Theme>;
}

type Variant = "primary" | "secondary";

const variantStyle = (variant: Variant, clickable?: boolean, colors?: Props["colors"]) => {
  const variantStyles: Record<Variant, TwStyle> = {
    primary: {
      ...tw`bg-zinc-100 border-gray-500 text-gray-500`,
      ...(clickable && tw`duration-150 cursor-pointer hover:[filter: brightness(0.9)]`),
      ...(colors && {
        color: colors.primaryColor,
        borderColor: colors.primaryColor,
        background: colors.secondaryColor,
      }),
    },
    secondary: {
      ...tw`text-zinc-100 bg-gray-500 border-transparent`,
      ...(clickable && tw`duration-150 cursor-pointer hover:[filter: brightness(0.9)]`),
      ...(colors && {
        color: colors.secondaryColor,
        background: colors.primaryColor,
      }),
    },
  };

  return variantStyles[variant];
};

const Chip = ({ children, clickable, round, variant = "primary", colors, styles, ...props }: Props) => {
  return (
    <div
      tw="font-medium border w-fit px-2 py-0.5 rounded flex items-center justify-center text-xs"
      css={[
        clickable && tw`duration-150 cursor-pointer`,
        variantStyle(variant, clickable, colors),
        round && tw`rounded-full`,
        styles,
      ]}
      {...props}
    >
      {children}
    </div>
  );
};

export default Chip;
