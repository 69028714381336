/** @jsxImportSource @emotion/react */

import { MouseEvent, useState } from "react";
import pp from "Assets/letter-profile.svg";
import tw from "twin.macro";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { ReactComponent as EmptyAvatar } from "Assets/empty-avatar.svg";

// Custom Avatar component
const colorList = [
  { color: "#4F00B2", bg: "#D9BBFF" },
  { color: "#339E00", bg: "#B2DC9E" },
  { color: "#00809D", bg: "#90C8D4" },
  { color: "#E8AB7E", bg: "#CF5800" },
  { color: "#AA0000", bg: "#FFBBBB" },
  { color: "#339E00", bg: "#B2DC9E" },
  { color: "#AA0000", bg: "#FFBBBB" },
  { color: "#E8AB7E", bg: "#CF5800" },
  { color: "#00809D", bg: "#90C8D4" },
  { color: "#4F00B2", bg: "#D9BBFF" },
  { color: "#E8AB7E", bg: "#CF5800" },
];

// choose random color for our Avatar component
const randomColor = () => {
  const luckyNum = Math.floor(Math.random() * colorList.length);
  return colorList[luckyNum];
};

const hashCode = function (s: string) {
  let h = 0,
    l = s.length,
    i = 0;
  if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
  return h;
};

export interface Props {
  src?: string;
  size: number;
  bgColor?: string;
  textColor?: string;
  colorCode?: number;
  className?: string;
  name?: string;
  onClick?: (e?: MouseEvent<HTMLDivElement>) => void;
  id?: string;
  tooltip?: boolean;
  empty?: boolean;
}

// Custom colors can be passed, or a random color can be selected.
// Alternatively, a color index from the predefined list can be used.
// If an ID is provided, the selected color code will be replaced with a randomly chosen option.
export const Avatar = ({
  src,
  size,
  bgColor,
  empty,
  textColor,
  name,
  colorCode,
  onClick,
  id,
  tooltip,
  ...props
}: Props) => {
  let colorSet = colorCode;
  if (id && id.length > 0) colorSet = Math.abs(hashCode(id)) % colorList.length;
  const [currentState] = useState(randomColor());
  const sizeStyles = {
    width: size + "px",
    minWidth: size + "px",
    maxWidth: size + "px",
    height: size + "px",
    minHeight: size + "px",
    maxHeight: size + "px",
    fontSize: size * 0.6 + "px",
  };

  if (empty) return <EmptyAvatar css={sizeStyles} {...props} />;

  const background = bgColor || (typeof colorSet === "number" && colorList[colorSet]?.bg) || currentState?.bg;
  const color = textColor || (typeof colorSet === "number" && colorList[colorSet]?.color) || currentState?.color;
  return (
    <Tooltip disabled={!tooltip} content={name}>
      <div
        onClick={(e) => onClick && onClick(e)}
        tw="rounded-full bg-slate-50 flex items-center justify-center select-none overflow-hidden font-medium"
        css={[sizeStyles, onClick && tw`cursor-pointer`]}
        {...props}
      >
        {src ? (
          <img className="max-w-full object-cover" src={src || pp || ""} alt={name} />
        ) : (
          <p
            className="rounded-full h-full flex-grow flex justify-center items-center"
            style={{
              background,
              color,
            }}
          >
            <span className="">{name?.slice(0, 1)?.toUpperCase()}</span>
          </p>
        )}
      </div>
    </Tooltip>
  );
};

export default Avatar;
