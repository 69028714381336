import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import { Provider } from "react-redux";
import { store } from "./store";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import "./index.css";
import "./styles/text-editor.css";
import "./styles/prose.css";
import "./styles/global.css";
import "./styles/themes/dark-theme.css";
import "./styles/themes/light-theme.css";
import { asyncWithLDProvider, basicLogger } from "launchdarkly-react-client-sdk";
import * as Sentry from "@sentry/browser";
import { datadogRum } from "@datadog/browser-rum";
import ErrorBoundaryWrapper from "components/error-boundary";
import { apiUrl } from "config/vultronConfig";

TimeAgo.addDefaultLocale(en);

axios.defaults.baseURL = apiUrl;
axios.defaults.headers.post["Content-Type"] = "application/json";

datadogRum.init({
  applicationId: process.env.REACT_APP_DD_APPLICATION_ID,
  clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: "vultron",
  env: "prod",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask",
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  ignoreErrors: [/ResizeObserver loop (limit exceeded|completed with undelivered notifications)/i],
  tracePropagationTargets: [],
  tracesSampleRate: 0.05, // only used for performance monitoring so reduce it to 5% to reduce costs
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur
});

const root = ReactDOM.createRoot(document.getElementById("root"));
(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_ID,
    context: {
      kind: "user",
      key: "anonymous_user",
      anonymous: true,
    },
    options: {
      logger: basicLogger({ level: "error" }),
    },
  });
  root.render(
    <ErrorBoundaryWrapper>
      <LDProvider>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </LDProvider>
    </ErrorBoundaryWrapper>,
  );
})();
