import { Button } from "components/editor/components";
import { Modal } from "components/organisms/modal";
import { ComponentProps, useEffect, useState } from "react";
import { Subdirectory } from "types/FileStorage";
import { useMediaOperations } from "../media/useMediaOperations";

interface Props extends Partial<ComponentProps<typeof Modal>> {
  folder: Subdirectory | null;
  onFolderSelected?: (folder: Subdirectory | null) => void;
}

const UpdateMediaFolderModal = ({ folder, ...props }: Props) => {
  const [name, setName] = useState("");
  const { updateFolder, isLoading } = useMediaOperations();

  useEffect(() => {
    if (props.open) {
      setName(folder?.name || "");
    }
  }, [props.open]);

  return (
    <Modal
      contentProps={{ onClick: (e) => e.stopPropagation() }}
      title="Update Folder"
      header="Update Folder"
      body={
        <div className="flex flex-col overflow-y-auto px-5">
          <label className="mb-2 block text-sm text-[#5B6B79]">Name</label>
          <input
            type="text"
            className="w-full rounded-md py-2 px-3 h-10 border border-gray-light outline-none"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoFocus
            required
            placeholder="New Folder name..."
          />
        </div>
      }
      footer={
        <div className="flex justify-end gap-4">
          <button
            type="button"
            className="border border-[#DBE0E5] rounded-lg py-[9px] px-4 text-sm font-medium text-[#1D2630]"
            onClick={() => props.onOpenChange?.(false)}
            disabled={isLoading}
          >
            Cancel
          </button>
          <Button
            variant="primaryBlack"
            size="md"
            loading={isLoading}
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              if (!folder?.id || !name) return;
              await updateFolder({
                id: folder.id,
                name,
              });
              props.onOpenChange?.(false);
              props.onFolderSelected?.(null);
            }}
            className="border-0 bg-gray-darkest rounded-lg py-[9px] px-4 text-sm font-medium text-[#ffffff] flex items-center gap-2"
            disabled={isLoading || name === folder?.name || !name}
          >
            Confirm
          </Button>
        </div>
      }
      {...props}
    />
  );
};

export default UpdateMediaFolderModal;
