import uniqBy from "lodash/uniqBy";
import isEqual from "lodash/isEqual";
import { DragEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GroupedBlock, MergedRequirement } from "../types";
import { Extraction, ExtractionFramework, InstantDraftStatus } from "components/copilot/CopilotSchemaTypes";
import { SelectionEvent } from "@viselect/vanilla";
import { LiveList, LiveObject, ToImmutable } from "YJSProvider/LiveObjects";
import axios from "axios";
import { useParams, useSearchParams } from "react-router-dom";
import { useNotification } from "context/notificationContext";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import {
  getFilteredRequirements,
  getOrderKeys,
  getRequirementGroups,
  getTemplateRequirements,
  setActiveDragOverId,
} from "store/reducers/extract/CurrentExtractionReducer";
import useExtractionOperations from "hook/useExtractionOperations";
import { createComplianceMatrixRowRequirement } from "utils/complianceMatrix";
import { useSelection } from "./SelectionContext";
import { getSortedRequirementsBySectionOrder } from "utils/extraction";
import { YJS_OPERATIONS } from "const-values/yjs";
import { useProxyRef } from "hook/useProxyRef";
import throttle from "lodash/throttle";
import keyBy from "lodash/keyBy";

export const useDragSelectOperation = (allFilteredBlocks: GroupedBlock[], extraction?: ToImmutable<Extraction>) => {
  const { setSelectedBlocks, clearSelection } = useSelection();

  useEffect(() => {
    clearSelection?.();
  }, [clearSelection, extraction?.step]);

  const onMove = useCallback(
    ({
      store: {
        changed: { added, removed },
      },
    }: SelectionEvent) => {
      added.forEach((node) => {
        if (node instanceof HTMLElement) {
          document
            .querySelectorAll(`[data-element='${node.dataset.element}']`)
            .forEach((node) => node.classList.add("highlighted-dragged-selected-requirement"));
        }
      });
      removed.forEach((node) => {
        if (node instanceof HTMLElement) {
          document
            .querySelectorAll(`[data-element='${node.dataset.element}']`)
            .forEach((node) => node.classList.remove("highlighted-dragged-selected-requirement"));
        }
      });
    },
    [],
  );

  const onStop = useCallback(
    ({ event, store: { stored } }: SelectionEvent) => {
      if (!event) return;
      document.body.style.userSelect = "unset";
      const dragSelectedBlocks = uniqBy(allFilteredBlocks, "requirement.requirement.element_id").filter((block) =>
        Array.from(stored).some((item) => {
          if (item instanceof HTMLElement) {
            return item.dataset.element && item.dataset.element === block.id;
          }
          return false;
        }),
      );

      setSelectedBlocks?.(dragSelectedBlocks);
    },
    [allFilteredBlocks, setSelectedBlocks],
  );

  const onBeforeStart = useCallback(({ event }: SelectionEvent) => {
    const target = event?.target as HTMLElement;
    document.body.style.userSelect = "none";
    return !target?.closest(".ds-selectable") && !event?.shiftKey;
  }, []);

  return { clearSelection, onMove, onStop, onBeforeStart };
};

type MergeRequirementsVariables = {
  requirement_ids: string[];
};

export const useMergeRequirements = () => {
  const { extractionId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id");
  const { setToast } = useNotification();
  const dispatch = useAppDispatch();
  const { setSelectedBlocks, clearSelection } = useSelection();
  const { addAttribution, setBulkExtractionRequirementsMerged, createAndInsertRequirement } = useExtractionOperations();
  const extractionComplianceMatrix = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.compliance_matrix,
  );
  const allFilteredBlocks = useAppSelector((store) => store.currentExtractionState.groupedBlocks.allFilteredBlocks);
  const allFilteredBlocksRef = useProxyRef(allFilteredBlocks);

  const mergeRequirements = useCallback(
    async (body: MergeRequirementsVariables) => {
      if (isLoading || !extractionId) return;
      setIsLoading(true);

      try {
        const { data } = await axios.post<MergedRequirement>(`/autopilot/${projectId}/requirements/groups`, body);
        addAttribution(YJS_OPERATIONS.EXTRACTION.MERGE_REQUIREMENTS);

        const sectionSortedRequirements = getSortedRequirementsBySectionOrder(extractionComplianceMatrix || []);

        const childRequirements =
          sectionSortedRequirements?.filter(
            (row) =>
              row.requirement.element_id &&
              data.child_requirements.some((childRequirement) => childRequirement.id === row.requirement.id),
          ) || [];
        const hasMultipleAssignedSections =
          uniqBy(
            childRequirements?.filter((req) => !!req.proposal_reference.section_id),
            "proposal_reference.section_id",
          )?.length > 1;
        const firstRequirementWithAssignment = childRequirements.find((row) => !!row.proposal_reference.section_id);
        const volumeAssignment = hasMultipleAssignedSections
          ? ""
          : firstRequirementWithAssignment?.proposal_reference?.volume_id || "";
        const sectionAssignment = hasMultipleAssignedSections
          ? ""
          : firstRequirementWithAssignment?.proposal_reference?.section_id || "";

        const firstYjsRequirementIdxWithinSection =
          sectionSortedRequirements
            .filter(({ proposal_reference }) => proposal_reference.section_id === sectionAssignment)
            ?.findIndex((row) => firstRequirementWithAssignment?.requirement?.id === row.requirement.id) || 0;

        const elementId = childRequirements[0]?.requirement?.element_id;
        const createdRequirement = createComplianceMatrixRowRequirement({
          content: data.content,
          id: data.id,
          skipped: firstRequirementWithAssignment ? false : childRequirements[0].requirement?.skipped,
          element_id: elementId,
        });
        const rowProperties = {
          document: new LiveObject({
            id: data.document_id,
            name: firstRequirementWithAssignment?.document?.name || "",
          }),
          requirement: createdRequirement,
          written_content: data.response || "",
          proposal_reference: new LiveObject({
            volume_id: volumeAssignment,
            section_id: sectionAssignment,
          }),
        };
        if (projectId) {
          await dispatch(getRequirementGroups({ projectId }));
          await dispatch(getFilteredRequirements({ projectId }));
          await dispatch(getOrderKeys({ projectId }));
        }

        if (extractionId) {
          setBulkExtractionRequirementsMerged(extractionId, body.requirement_ids);
          createAndInsertRequirement(
            extractionId,
            firstYjsRequirementIdxWithinSection < 0 ? 0 : firstYjsRequirementIdxWithinSection,
            rowProperties,
          );

          await dispatch(getTemplateRequirements(extractionId));
          const mergedNode = document.querySelector(`div[data-element='${elementId}']`);
          const dragSelectedBlocks = uniqBy(
            allFilteredBlocksRef.current.filter((block) => block.id === elementId),
            "id",
          );

          if (mergedNode && dragSelectedBlocks) {
            setSelectedBlocks?.(dragSelectedBlocks);
          } else {
            clearSelection?.();
          }
        }
      } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
          setToast.error({ msg: e.response.data.error_msg });
        }
        clearSelection?.();
        await dispatch(getTemplateRequirements(extractionId));
      } finally {
        setIsLoading(false);
      }
    },
    [
      isLoading,
      extractionId,
      projectId,
      addAttribution,
      extractionComplianceMatrix,
      dispatch,
      setBulkExtractionRequirementsMerged,
      createAndInsertRequirement,
      allFilteredBlocksRef,
      setSelectedBlocks,
      clearSelection,
      setToast,
    ],
  );

  return { mergeRequirements, isLoading };
};

export const useUnmergeRequirement = () => {
  const { extractionId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id");
  const { setToast } = useNotification();
  const dispatch = useAppDispatch();
  const { clearSelection } = useSelection();
  const { unmergeRequirement, addAttribution } = useExtractionOperations();
  const mergedRequirements = useAppSelector((store) => store.currentExtractionState.mergedRequirements);

  const handleUnmergeRequirement = useCallback(
    async (requirementId: string) => {
      if (isLoading || !extractionId) return;
      setIsLoading(true);

      try {
        await axios.delete<MergedRequirement>(`/autopilot/${projectId}/requirements/groups/${requirementId}`);
        addAttribution(YJS_OPERATIONS.EXTRACTION.MERGE_REQUIREMENTS);

        const mergedRequirement = mergedRequirements.find(
          (mergedRequirement) => mergedRequirement.id === requirementId,
        );

        if (extractionId && mergedRequirement)
          unmergeRequirement(
            extractionId,
            mergedRequirement.id,
            mergedRequirement.child_requirements.map(({ id }) => id),
          );
        if (projectId) {
          await dispatch(getRequirementGroups({ projectId }));
          await dispatch(getFilteredRequirements({ projectId }));
          await dispatch(getOrderKeys({ projectId }));
        }

        if (extractionId) await dispatch(getTemplateRequirements(extractionId));

        clearSelection?.();
      } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
          setToast.error({ msg: e.response.data.error_msg });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      addAttribution,
      clearSelection,
      dispatch,
      extractionId,
      isLoading,
      mergedRequirements,
      projectId,
      setToast,
      unmergeRequirement,
    ],
  );

  return { handleUnmergeRequirement, isLoading };
};

export const useSyncDraftConfig = ({
  extractionId,
  outlineVolumes,
}: {
  extractionId?: string;
  outlineVolumes?: ToImmutable<ExtractionFramework["volumes"]>;
}) => {
  const { updateInstantDraftConfig, addAttribution } = useExtractionOperations();
  const instantDraftStatus = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.status,
  );
  const volumes = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.volumes || [],
  );
  const sections = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.sections || [],
  );

  const instantDraftSectionsAndVolumes = useMemo(
    () => ({ volumes: volumes || [], sections: sections || [] }),
    [sections, volumes],
  );

  useEffect(() => {
    if (!extractionId || instantDraftStatus === InstantDraftStatus.Done) return;

    const { sections, volumes } = instantDraftSectionsAndVolumes;

    if (!volumes.length && !sections.length) return;

    const newCheckedVolumes = outlineVolumes
      ?.filter((volume) => {
        const isAllSectionsChecked = volume.sections.every(({ id }) => sections.includes(id));
        return isAllSectionsChecked;
      })
      .map(({ id }) => id);

    if (!isEqual(newCheckedVolumes, volumes)) {
      updateInstantDraftConfig(extractionId, { volumes: new LiveList(newCheckedVolumes) });
      addAttribution(YJS_OPERATIONS.EXTRACTION.SYNC_INSTANT_DRAFT_VOLUMES);
    }
  }, [
    addAttribution,
    extractionId,
    instantDraftSectionsAndVolumes,
    instantDraftStatus,
    outlineVolumes,
    updateInstantDraftConfig,
  ]);
};

export const useRequirementDragOverOperations = (block: GroupedBlock) => {
  const [isDragging, setIsDragging] = useState(false);
  const [dragPosition, setDragPosition] = useState<{ x: number; y: number } | undefined>();
  const throttledSetDragPosition = useRef(
    throttle((x, y) => setDragPosition((prev) => (prev?.x === x && prev?.y === y ? prev : { x, y })), 80),
  ).current;
  const requirementListScrollContainer = useRef(document.getElementById("template-manager-section-scroll"));
  const { selectedBlocks } = useSelection();
  const dispatch = useAppDispatch();
  const activeDragOverId = useAppSelector((store) => (isDragging ? store.currentExtractionState.activeDragOverId : ""));
  const activeDragOverIdRef = useProxyRef(activeDragOverId);

  const onDragStart = useCallback(
    (e: DragEvent) => {
      const editorContentNode = document.getElementById("generation-outline-editor-content");
      if (editorContentNode) editorContentNode.style.pointerEvents = "none";
      requirementListScrollContainer.current = document.getElementById("template-manager-section-scroll");
      const requirementListById = selectedBlocks?.length
        ? selectedBlocks?.map(({ requirement }) => ({ id: requirement.requirement.id }))
        : [{ id: block.requirement.requirement.id }];
      const draggedRequirements = keyBy(requirementListById, "id");

      const dragImage = document.createElement("div");
      dragImage.style.top = "-1000px";
      dragImage.style.position = "absolute";
      dragImage.style.padding = "10px";

      document.body.appendChild(dragImage);
      e.dataTransfer.setDragImage(dragImage, 0, 0);

      setTimeout(() => {
        document.body.removeChild(dragImage);
      }, 0);

      e.dataTransfer.setData("application/json", JSON.stringify(draggedRequirements));
      e.dataTransfer.effectAllowed = "move";
      setIsDragging(true);
    },
    [block, selectedBlocks],
  );
  const onDragEnd = useCallback(() => {
    setIsDragging(false);
    const editorContentNode = document.getElementById("generation-outline-editor-content");
    if (editorContentNode) editorContentNode.style.pointerEvents = "unset";
  }, []);
  const onDrag = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      const { clientX, clientY } = e;
      const requirementListScrollContainerNode = requirementListScrollContainer.current;
      if (!requirementListScrollContainerNode) return;
      throttledSetDragPosition(clientX, clientY);
      const containerRect = requirementListScrollContainerNode.getBoundingClientRect();
      const { top, bottom, height } = requirementListScrollContainerNode.getBoundingClientRect();
      const threshold = 45;
      const scrollSpeed = 3;

      if (
        clientY > bottom - threshold &&
        requirementListScrollContainerNode.scrollTop < requirementListScrollContainerNode.scrollHeight - height
      ) {
        requirementListScrollContainerNode.scrollTop += scrollSpeed;
      } else if (clientY < top + threshold && requirementListScrollContainerNode.scrollTop > 0) {
        requirementListScrollContainerNode.scrollTop -= scrollSpeed;
      }

      const outside =
        clientX < containerRect.left ||
        clientX > containerRect.right ||
        clientY < containerRect.top ||
        clientY > containerRect.bottom;

      if (outside && activeDragOverIdRef.current) {
        dispatch(setActiveDragOverId(""));
      }
    },
    [dispatch, throttledSetDragPosition],
  );

  return useMemo(
    () => ({
      onDragStart,
      onDragEnd,
      onDrag,
      isDragging,
      dragPosition,
    }),
    [dragPosition, isDragging, onDrag, onDragEnd, onDragStart],
  );
};
