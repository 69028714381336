import { FormattedVolume } from "types/Volume";
import { useEditorState } from "./hooks/useEditorState";

import { useBulkVolumesUpdate } from "hook/useExtractionOperation/bulkUpdate";
import { useCurrentVersion } from "./hooks/useCurrentVersion";
import { SharedOutlineProps } from "./types";
import { InternalOutline } from "./InternalOutline";
import { MAX_LEVEL, MIN_LEVEL } from "./constants";
import { useMemo } from "react";
import { flattenSections } from "./hooks/utils";

export function Outline({
  className,
  drafts,
  extractionId,
  isReadOnly,
}: SharedOutlineProps & {
  drafts: FormattedVolume[];
  extractionId: string;
}) {
  const onChange = useBulkVolumesUpdate(extractionId);
  const version = useCurrentVersion(extractionId);
  const maxLevel = MAX_LEVEL;
  const flattenedDrafts = useMemo(() => flattenSections(drafts, MIN_LEVEL, maxLevel), [drafts, maxLevel]);
  const { editor, active } = useEditorState({
    content: flattenedDrafts,
    onChange,
    version,
    isReadOnly,
    maxLevel,
  });

  return <InternalOutline {...{ editor, isReadOnly, maxLevel, className, active }} />;
}
