import { FlexibleTextarea } from "components/molecules/flexible-textarea";

interface NotesSectionProps {
  notes: string;
  handleNotesChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleNotesBlur: () => void;
  isSaving: boolean;
  isOpen: boolean;
}

export const NotesSection = ({ notes, handleNotesChange, handleNotesBlur, isSaving, isOpen }: NotesSectionProps) => (
  <div className="ml-6 mr-6">
    <div className="flex items-center justify-between mb-4">
      <div className="text-sm font-medium">Notes</div>
    </div>
    <FlexibleTextarea
      flexible
      value={notes}
      onChange={handleNotesChange}
      onBlur={handleNotesBlur}
      placeholder="Add notes..."
      className="w-full min-h-[120px] p-3 text-sm border rounded-md focus:outline-none focus:ring-1 focus:ring-gray-400 bg-white"
      forceResetProps={[isOpen]}
      forceResetDelay={100}
      readOnly={isSaving}
    />
  </div>
);
