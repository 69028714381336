export enum ResponseTolerance {
  Strict = "strict",
  Moderate = "moderate",
  Flexible = "flexible",
}

export enum GenerationStatus {
  InProgress = "in_progress",
  Complete = "complete",
  Failed = "failed",
  FailedNoContent = "failed_no_content",
}

export enum TotalScore {
  Adequate = "Adequate",
  NeedsImprovement = "Needs Improvement",
}

export enum RelevanceScore {
  NoSources,
  Low,
  Medium,
  High,
}

// TODO: add None to RelevanceScore (no sources used)
export const RELEVANCE_SCORE_TO_RESPONSE_TOLERANCE = {
  [RelevanceScore.Low]: ResponseTolerance.Flexible,
  [RelevanceScore.Medium]: ResponseTolerance.Moderate,
  [RelevanceScore.High]: ResponseTolerance.Strict,
};

export const RESPONSE_TOLERANCE_TO_RELEVANCE_SCORE = {
  [ResponseTolerance.Flexible]: RelevanceScore.Low,
  [ResponseTolerance.Moderate]: RelevanceScore.Medium,
  [ResponseTolerance.Strict]: RelevanceScore.High,
};

type RelevanceCategory = {
  category: "Responsiveness" | "Comprehensiveness" | "Quality";
  feedback: string[];
  score: TotalScore;
};

type RequirementResponseSource = {
  citations: {
    citation: string;
    content: string;
    file_content_id: string;
    partition_order_key: number;
  }[];
  file: {
    created_at: string;
    id: string;
    name: string;
    type: string;
  };
};

type RequirementResponse = {
  categories: RelevanceCategory[];
  content: string | null;
  id: string | null;
  relevance_score: RelevanceScore;
  sources: RequirementResponseSource[];
  total_score: TotalScore;
  section_header: string | null;
  section_id: string | null;
  skipped: boolean;
  volume_id: string | null;
  generation_status: GenerationStatus | null;
};

export type Requirement = {
  analysis_id: string | null;
  content: string;
  document_id: string | null;
  element_id: string | null;
  group_id: string | null;
  id: string;
  order_key: number | null;
  page: number | null;
  recommended: boolean;
  response: RequirementResponse | null;
  section_id: string | null;
  skipped: boolean;
  volume_id: string | null;
  section_header: string | null;
};
