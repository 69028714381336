/** @jsxImportSource @emotion/react */

import React, { forwardRef } from "react";
import type { LucideProps } from "lucide-react";
import "twin.macro";

type Props = Pick<React.ComponentProps<"button">, "disabled" | "onClick"> & {
  Component: React.ForwardRefExoticComponent<Omit<LucideProps, "ref"> & React.RefAttributes<SVGSVGElement>>;
  className: string;
  iconProps: { className: string };
};

export const LucideIconButton = forwardRef<HTMLButtonElement, Props>(({ Component, iconProps, ...props }, ref) => {
  return (
    <button ref={ref} tw="flex items-center justify-center" {...props}>
      <Component {...iconProps} />
    </button>
  );
});
