/** @jsxImportSource @emotion/react */

import { useMemo } from "react";
import { DropdownMenu } from "../dropdown-menu";
import tw from "twin.macro";
import { ResponseSpeed } from "./types";
import { SPEED_TO_META } from "./constants";

type Props = {
  disabled: boolean;
  selectedSpeed: ResponseSpeed;
  onSpeedSelect: (value: ResponseSpeed) => void;
};

const ResponseQualityDropdown = ({ disabled, selectedSpeed, onSpeedSelect }: Props) => {
  const responseSpeedMeta = SPEED_TO_META[selectedSpeed];
  const ResponseSpeedIcon = responseSpeedMeta.icon;
  const items = useMemo(
    () =>
      Object.values(SPEED_TO_META).map((option) => {
        const Icon = option.icon;
        return {
          key: option.label,
          label: (
            <div>
              <div className="flex items-center gap-1.5">
                <Icon size={14} />
                <div className="text-sm pb-1">{option.label}</div>
              </div>
              <div className="text-[11.5px] text-gray-text">{option.description}</div>
            </div>
          ),
          selected: selectedSpeed === option.value,
          value: option.value,
          onSelect: () => onSpeedSelect(option.value),
        };
      }),
    [onSpeedSelect, selectedSpeed],
  );

  return (
    <DropdownMenu disabled={disabled} items={items}>
      <div
        className="flex items-center gap-1 bg-slate-200 text-slate-600 rounded shadow-sm text-xs px-2 py-1 duration-100 hover:text-slate-900 hover:bg-slate-300 disabled:opacity-50 disabled:hover:text-slate-600 disabled:hover:bg-slate-200"
        css={[disabled && tw`text-zinc-300 pointer-events-none`]}
      >
        <ResponseSpeedIcon size={14} />
        {responseSpeedMeta.label}
      </div>
    </DropdownMenu>
  );
};

export default ResponseQualityDropdown;
