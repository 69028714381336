import { Editor, EditorContent } from "@tiptap/react";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import Toolbar from "./toolbar/Toolbar";

import type { Level } from "@tiptap/extension-heading";
import type { EditorActive } from "./types";

import "./Outline.css";
import { getEditorFontSize } from "./hooks/useLocalFontSize";
import LiveTemplateExtractionTemplate from "../live-template-extraction-template";
import { useFlags } from "hook/useFlags";

type Props = {
  active: EditorActive;
  canLockEdits?: boolean;
  className?: string;
  editor?: Editor | null;
  isReadOnly: boolean;
  maxLevel: Level;
};

export function InternalOutline({ editor, isReadOnly, maxLevel, className, active, canLockEdits = false }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const flags = useFlags();
  const [isLocked, setIsLocked] = useState(isReadOnly);
  useEffect(() => {
    const { current } = ref;
    function eventListener() {
      if (!editor?.isFocused) {
        editor?.commands.focus();
      }
    }

    current?.addEventListener("click", eventListener);
    return () => {
      current?.removeEventListener("click", eventListener);
    };
  }, [editor]);

  if (!editor) {
    return <></>;
  }

  function handleSetIsLocked(newIsLocked: boolean) {
    editor?.setOptions({ editable: !isReadOnly && newIsLocked });
    setIsLocked(newIsLocked);
  }

  return (
    <div
      className={classNames("outline-editor relative h-full w-full flex flex-col", className)}
      data-font-size={getEditorFontSize(editor)}
    >
      {isReadOnly ? null : (
        <Toolbar
          {...{
            canLockEdits,
            editor,
            isLocked,
            isReadOnly,
            maxLevel,
            active,
          }}
          editorRef={ref}
          className="z-[1]"
          setIsLocked={handleSetIsLocked}
        />
      )}
      <div className="h-full overflow-y-auto flex-1 flex flex-col z-0" id="generation-outline-editor-scroll-container">
        <EditorContent
          editor={editor}
          ref={ref}
          id="generation-outline-editor-content"
          className="outline-content pr-6 pt-4 w-full"
        />
        {flags.liveTemplateGeneration && !isReadOnly && <LiveTemplateExtractionTemplate />}
      </div>
    </div>
  );
}
