import { UniqueIdentifier } from "@dnd-kit/core";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Section } from "components/copilot/CopilotSchemaImmutableTypes";
import { uniq } from "lodash";

export interface ProposalInProgressSection extends Section {
  full_requirement_title: boolean;
}

type State = {
  sectionEditable: string;
  requirementsState: {
    requirementRouted: string;
    requirementHighlighted: string;
    requirementEditable: string;
    activeDragRequirementId: UniqueIdentifier | null;
    expandedRequirementIds: string[];
    responseErrorRequirementIds: string[];
    autoScroll: boolean;
  };
  proposalState: {
    sectionInProgress: ProposalInProgressSection | null;
  };
  sidebarSize: number;
};

const initialState: State = {
  sectionEditable: "",
  requirementsState: {
    requirementRouted: "",
    requirementHighlighted: "",
    requirementEditable: "",
    activeDragRequirementId: null,
    expandedRequirementIds: [],
    responseErrorRequirementIds: [],
    autoScroll: false,
  },
  proposalState: {
    sectionInProgress: null,
  },
  sidebarSize: 30,
};

const sectionReducer = createSlice({
  name: "section",
  initialState,
  reducers: {
    resetSectionState: () => {
      return initialState;
    },
    setSectionState: (state: State, action: PayloadAction<Partial<State>>) => {
      return { ...state, ...action.payload };
    },
    setRequirementsState: (state: State, action: PayloadAction<Partial<State["requirementsState"]>>) => {
      state.requirementsState = { ...state.requirementsState, ...action.payload };
    },
    appendExpandedRequirementIds: (
      state: State,
      action: PayloadAction<State["requirementsState"]["expandedRequirementIds"]>,
    ) => {
      state.requirementsState.expandedRequirementIds = [
        ...state.requirementsState.expandedRequirementIds,
        ...action.payload,
      ];
    },
    setResponseErrorRequirementIds: (
      state: State,
      action: PayloadAction<{ add: boolean; data: State["requirementsState"]["responseErrorRequirementIds"] }>,
    ) => {
      const { add, data } = action.payload;
      if (add) {
        state.requirementsState.responseErrorRequirementIds = uniq([
          ...state.requirementsState.responseErrorRequirementIds,
          ...data,
        ]);
      } else {
        state.requirementsState.responseErrorRequirementIds =
          state.requirementsState.responseErrorRequirementIds.filter((id) => !data.includes(id));
      }
    },
    setProposalState: (state: State, action: PayloadAction<Partial<State["proposalState"]>>) => {
      state.proposalState = { ...state.proposalState, ...action.payload };
    },
  },
});

export const {
  setResponseErrorRequirementIds,
  resetSectionState,
  setSectionState,
  setRequirementsState,
  setProposalState,
  appendExpandedRequirementIds,
} = sectionReducer.actions;

export default sectionReducer.reducer;
