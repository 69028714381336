import { useState, useCallback } from "react";
import { useNotification } from "context/notificationContext";
import { formatBytes } from "utils/helpers";
import { MAX_FILE_SIZE } from "./constants";
import { useAppDispatch } from "store/storeTypes";
import { setSelectedDocuments } from "store/reducers/extract/ExtractReducerV2";

export const useUploadDocuments = () => {
  const { setToast } = useNotification();
  const [files, setFiles] = useState<File[]>([]);
  const dispatch = useAppDispatch();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFiles((prevFiles) => [...acceptedFiles, ...prevFiles]);
      dispatch(setSelectedDocuments(acceptedFiles));
    },
    [dispatch],
  );

  const dropValidator = useCallback(
    (file: File) => {
      if (file?.size > MAX_FILE_SIZE) {
        setToast.error({
          title: "Document size not supported",
          msg:
            file?.name +
            `size is too large. We do not support document sizes larger than ${formatBytes(
              MAX_FILE_SIZE,
            )}. Please try again with a smaller document or contact support@vultron.ai for assistance.`,
        });
        return {
          code: "file-too-large",
          message: `Document is larger than ${formatBytes(MAX_FILE_SIZE)}`,
        };
      }

      return null;
    },
    [setToast],
  );

  return {
    files,
    onDrop,
    dropValidator,
  };
};
