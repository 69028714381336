/** @jsxImportSource @emotion/react */

import { useState } from "react";
import logo from "Assets/svgs/help-messenger.svg";
import tw from "twin.macro";

const IntercomHelpMessenger = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="fixed right-0 top-[calc(68%)] h-16 bg-gray-darkest rounded-tl-md rounded-bl-md flex items-center justify-center z-50 duration-300 translate-x-0"
      css={[!isOpen && tw`translate-x-[48px]`]}
    >
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="group flex h-16 w-8 items-center justify-center -ml-1 mr-0.5"
      >
        <div className="flex h-6 w-6 flex-col items-center">
          <div
            className="h-3 w-1 rounded-full duration-200 bg-slate-400 translate-y-0.5 group-hover:bg-slate-100"
            css={[isOpen && tw`-rotate-[15deg]`, !isOpen && tw`rotate-[15deg]`]}
          />
          <div
            className="h-3 w-1 rounded-full duration-200 bg-slate-400 -translate-y-0.5 group-hover:bg-slate-100"
            css={[isOpen && tw`rotate-[15deg]`, !isOpen && tw`-rotate-[15deg]`]}
          />
        </div>
      </button>
      <div className="sidebarContent flex items-center justify-center mr-3 transform transition-all duration-300 ease-in-out">
        <img src={logo} id="launch-intercom" className="cursor-pointer" alt="Vultron Logo" />
      </div>
    </div>
  );
};

export default IntercomHelpMessenger;
