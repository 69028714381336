import moment from "moment";
import { getFileIcon } from "utils/getFileIcon";
import { useAppDispatch } from "store/storeTypes";
import { toggleSourceModal } from "store/reducers/modalsSlice";
import { VultronBlock } from "utils/yjs-configs/ai-assistant/schema";
import Icon from "components/atoms/icons/Icon";
import { ToImmutable } from "YJSProvider/LiveObjects";

const SourceWrapper = ({ sources }: { sources: ToImmutable<VultronBlock>["sources"] }) => {
  const dispatch = useAppDispatch();

  return (
    <div className="relative -mx-2">
      <div className="w-8 absolute right-0 top-0 bottom-0 bg-gradient-to-l to-transparent from-layout-gray-lightest" />
      <div className="flex items-center gap-2 pt-0.5 pb-1.5 pl-0.5 pr-[22px] overflow-x-auto">
        {sources.map((source) =>
          source.extension_type === "internet_search" ? (
            <button
              key={source.reference_id}
              onClick={() => {
                window.open(source.reference_id, "_blank");
              }}
              className="flex gap-2 items-center rounded-lg shadow border border-gray-light bg-white pl-2 py-1.5 pr-3 max-w-[220px] duration-100 hover:bg-gray-100"
            >
              <Icon name="Internet" className="h-[24px] w-[24px] shrink-0 text-slate-600" />
              <div className="flex flex-col items-start min-w-0">
                <div className="text-xs text-gray-darkest max-w-full truncate">
                  {source.name ? source.name : source.reference_id}
                </div>
                {source.name && (
                  <div className="flex items-center max-w-full">
                    <span className="text-gray-mid text-xs">{source.citation_reference}</span>
                    <div className="text-xxs text-gray-mid truncate ml-1.5">{source.reference_id}</div>
                  </div>
                )}
              </div>
            </button>
          ) : (
            <button
              key={source.reference_id}
              onClick={() => {
                dispatch(
                  toggleSourceModal({
                    open: true,
                    initialProps: {
                      id: source.reference_id,
                      sourceContent: source.content,
                      extension: source.extension_type,
                      name: source.name,
                    },
                  }),
                );
              }}
              className="flex gap-2 items-center rounded-lg shadow border border-gray-light bg-white pl-2 py-1.5 pr-3 max-w-[220px] duration-100 hover:bg-gray-100"
            >
              <img src={getFileIcon(source.extension_type)} alt="" className="h-[24px]" />
              <div className="flex flex-col items-start min-w-0">
                <div className="text-xs text-gray-darkest max-w-full truncate">{source.name}</div>
                <div className="text-xxs text-gray-mid whitespace-nowrap">
                  {moment(source?.date).format("MMMM DD, YYYY")}
                </div>
              </div>
            </button>
          ),
        )}
      </div>
    </div>
  );
};

export default SourceWrapper;
