import { OutlineContent } from "../types";
import { FormattedSection, FormattedVolume } from "types/Volume";
import { MIN_LEVEL, NON_BREAKING_SPACE } from "../constants";
import { v4 } from "uuid";
import type { Level } from "@tiptap/extension-heading";
import { ComplianceMatrixRow, Requirement } from "components/copilot/CopilotSchemaImmutableTypes";
import { ProposalReference } from "components/copilot/CopilotSchemaTypes";

export function flattenSections(
  sections: Array<FormattedVolume | FormattedSection>,
  level: Level,
  maxLevel: Level,
): OutlineContent[] {
  const seenIds = new Set<string>();
  return sections.flatMap((section) => {
    const children =
      (section as any as FormattedSection).subsections || (section as any as FormattedVolume).sections || [];
    const item = createNode(level, section);
    // Only add items with unique ids
    const hasBeenSeen = seenIds.has(section.id);
    seenIds.add(section.id);

    return [
      ...(hasBeenSeen ? [] : [item]),
      ...flattenSections(children, Math.min(maxLevel, level + 1) as Level, maxLevel),
    ] as OutlineContent[];
  });
}

export function createNode(
  level: Level,
  { title, id, sections, subsections, ...data }: Partial<FormattedSection & FormattedVolume> = {},
): OutlineContent {
  return {
    type: "heading",
    attrs: {
      level,
      xid: id || v4(),
      data,
    },
    content: [
      {
        type: "text",
        text: title || NON_BREAKING_SPACE, // Use non-breaking space if empty
      },
    ],
  };
}

type DuplicateResult = {
  originalIndex: number;
  duplicates: number[];
};

const toString = ({ attrs, title }: OutlineContent): string => `${attrs.xid}-${attrs.level}-${title}`;

/**
 * Identifies duplicate entries in an `updated` list of `OutlineContent` items, comparing them to a `reference` list.
 * Each duplicate entry is compared using the `toString` method for content matching.
 * If no exact match is found in `updated` for a duplicate, the first occurrence is considered the original.
 *
 * @param {OutlineContent[]} reference - The list of original `OutlineContent` items.
 * @param {OutlineContent[]} updated - The list of potentially modified `OutlineContent` items to check for duplicates.
 * @returns {Record<string, DuplicateResult>} An object with duplicate `xid`s as keys, each containing the index of the original item
 *   and a list of indexes for duplicates.
 */
export function findDuplicates(
  reference: OutlineContent[],
  updated: OutlineContent[],
): Record<string, DuplicateResult> {
  // Step 1: Map reference items by `xid`
  const referenceMap = new Map<string, string>();
  reference.forEach((item) => referenceMap.set(item.attrs.xid, toString(item)));

  // Step 2: Identify all duplicate `xid`s in `updated`
  const duplicateIds = new Map<string, number[]>();
  updated.forEach(({ attrs }, index) => {
    const arr = duplicateIds.get(attrs.xid) || [];
    duplicateIds.set(attrs.xid, [...arr, index]);
  });

  // Step 3: Process each set of duplicates
  const duplicates: Record<string, DuplicateResult> = {};

  Array.from(duplicateIds.entries()).forEach(([xid, indexes]) => {
    if (indexes.length === 1) {
      return;
    }

    const refItem = referenceMap.get(xid);
    // Find the original by comparing the `toString` output, or use the first occurrence
    const originalIndex = indexes.find((index) => toString(updated[index]) === refItem) ?? indexes[0];

    // Store the duplicates, excluding the originalIndex
    duplicates[xid] = {
      originalIndex,
      duplicates: indexes.filter((index) => index !== originalIndex),
    };
  });

  return duplicates;
}

type LevelError = {
  index: number;
  correctLevel: Level;
};

export function findLevelErrors(items: OutlineContent[], maxLevel: Level): [LevelError[], OutlineContent[]] {
  const errors: LevelError[] = [];
  const corrected: OutlineContent[] = JSON.parse(JSON.stringify(items));

  corrected.forEach((item, index) => {
    const level = item.attrs.level;

    // Check the first item for MIN_LEVEL
    if (index === 0) {
      if (level !== MIN_LEVEL) {
        const correctLevel = MIN_LEVEL;
        errors.push({
          index,
          correctLevel,
        });
        corrected[0].attrs.level = correctLevel;
      }
      return;
    }

    const prevLevel = corrected[index - 1].attrs.level;

    // Ensure current level does not exceed preceding level + 1
    if (level > prevLevel + 1) {
      const correctLevel = Math.min(maxLevel, prevLevel + 1) as Level;
      errors.push({
        index,
        correctLevel,
      });
      corrected[index].attrs.level = correctLevel;
      return;
    }

    // Ensure current level is within MIN_LEVEL and maxLevel
    if (level < MIN_LEVEL || level > maxLevel) {
      const correctLevel = Math.max(MIN_LEVEL, Math.min(level, maxLevel)) as Level;
      errors.push({
        index,
        correctLevel,
      });
      corrected[index].attrs.level = correctLevel;
    }
  });

  return [errors, corrected];
}

function removeDeletedVolumeDescendants(reference: OutlineContent[], updated: OutlineContent[]): OutlineContent[] {
  const referenceTree = buildTree(reference);

  const referenceXidMap = new Map<string, TreeNode>();
  function mapXids(node: TreeNode) {
    referenceXidMap.set(node.attrs.xid, node);
    if (node.children) {
      node.children.forEach(mapXids);
    }
  }
  referenceTree.forEach(mapXids);

  const referenceVolumeXids = new Set(reference.filter((node) => node.attrs.level === 1).map((node) => node.attrs.xid));
  const updatedVolumeXids = new Set(updated.filter((node) => node.attrs.level === 1).map((node) => node.attrs.xid));

  const deletedVolumeXids = Array.from(referenceVolumeXids).filter((xid) => !updatedVolumeXids.has(xid));

  const xidsToRemove = new Set<string>();
  function collectDescendants(node: TreeNode) {
    xidsToRemove.add(node.attrs.xid);
    if (node.children) {
      node.children.forEach(collectDescendants);
    }
  }
  for (const volumeXid of deletedVolumeXids) {
    const volumeNode = referenceXidMap.get(volumeXid);
    if (volumeNode) {
      collectDescendants(volumeNode);
    }
  }

  return updated.filter((node) => !xidsToRemove.has(node.attrs.xid));
}

/**
 * Fixes issues in the `updated` list of `OutlineContent` items by identifying duplicates and adjusting their `xid`s,
 * as well as correcting level errors. Uses both `findDuplicates` and `findLevelErrors` functions to make adjustments.
 *
 * @param {OutlineContent[]} [reference=[]] - The reference list of original `OutlineContent` items.
 * @param {OutlineContent[]} [updated=[]] - The list of items to fix, potentially containing duplicates and level errors.
 * @returns {[boolean, OutlineContent[]]} A tuple where the first element is a boolean indicating whether any fixes were applied,
 *   and the second element is the updated list with fixes, or the original list if no changes were necessary.
 */
export function fixTree(
  reference: OutlineContent[] = [],
  updated: OutlineContent[] = [],
  maxLevel: Level,
): [boolean, OutlineContent[]] {
  let hasFixes = false;
  // In any case we aren't adding or removing nodes, we don't need to worry about the tree becoming invalid
  // this way we can usually avoid the relatively expensive tree repair
  if (reference.length === updated.length) {
    return [false, updated];
  }

  const cleanedUpdated = removeDeletedVolumeDescendants(reference, updated);

  if (cleanedUpdated.length !== updated.length) {
    hasFixes = true;
  }

  const duplicates = findDuplicates(reference, cleanedUpdated);
  const fixed: OutlineContent[] = JSON.parse(JSON.stringify(cleanedUpdated));

  Object.values(duplicates).forEach(({ duplicates }) => {
    hasFixes = true;
    duplicates.forEach((index) => {
      fixed[index].attrs = createNode(fixed[index].attrs.level).attrs;
    });
  });

  const [errors, fixedAndLeveled] = findLevelErrors(fixed, maxLevel);

  hasFixes = hasFixes || !!errors.length;

  // If no fixes have been applied we return the original object so that we can preserve its identity
  return hasFixes ? [true, fixedAndLeveled] : [false, updated];
}

export type TreeNode = OutlineContent & {
  parentXid?: string;
  children?: TreeNode[];
  requirements?: Requirement[];
};

export function buildTree(outlineContents: OutlineContent[]): TreeNode[] {
  const rootNodes: TreeNode[] = [];
  const stack: TreeNode[] = [];

  for (const item of outlineContents) {
    // Create a TreeNode from OutlineContent
    const treeNode: TreeNode = { ...item, children: [] };

    // Remove nodes from the stack until we find a parent
    while (stack.length > 0 && stack[stack.length - 1].attrs.level >= treeNode.attrs.level) {
      stack.pop();
    }

    if (stack.length === 0) {
      // No parent found, this is a root node
      rootNodes.push(treeNode);
    } else {
      // Parent is the last item on the stack
      const parent = stack[stack.length - 1];
      parent.children = parent.children || [];
      parent.children.push(treeNode);
      treeNode.parentXid = parent.attrs.xid;
    }

    // Add the current node to the stack
    stack.push(treeNode);
  }

  return rootNodes;
}

function buildXidMap(treeNodes: TreeNode[]): Map<string, TreeNode> {
  const xidMap = new Map<string, TreeNode>();

  function traverse(node: TreeNode) {
    xidMap.set(node.attrs.xid, node);
    if (node.children) {
      for (const child of node.children) {
        traverse(child);
      }
    }
  }

  for (const node of treeNodes) {
    traverse(node);
  }

  return xidMap;
}

function deleteVolumeAndUnassignRequirements(
  volumeXid: string,
  xidMap: Map<string, TreeNode>,
  complianceMatrixState: ComplianceMatrixRow[],
): ComplianceMatrixRow[] {
  const updatedRequirements: ComplianceMatrixRow[] = [];
  const volumeNode = xidMap.get(volumeXid);

  if (!volumeNode) {
    throw new Error("Volume not found.");
  }

  // Collect all xids of the volume and its descendant nodes
  const xidsToUnassign = new Set<string>();

  function collectXids(node: TreeNode) {
    xidsToUnassign.add(node.attrs.xid);
    if (node.children) {
      for (const child of node.children) {
        collectXids(child);
      }
    }
  }

  collectXids(volumeNode);

  // Update proposal_reference in complianceMatrixState
  complianceMatrixState.forEach((row) => {
    const proposal_reference = { ...row.proposal_reference };
    if (
      (proposal_reference.volume_id && xidsToUnassign.has(proposal_reference.volume_id)) ||
      (proposal_reference.section_id && xidsToUnassign.has(proposal_reference.section_id))
    ) {
      // Unassign proposal_reference
      proposal_reference.volume_id = undefined;
      proposal_reference.section_id = undefined;
      proposal_reference.subsection_id = undefined;
      updatedRequirements.push({
        ...row,
        proposal_reference,
      });
    }
  });

  // Remove the volume and its descendants from the xidMap
  xidsToUnassign.forEach((xid) => xidMap.delete(xid));

  return updatedRequirements;
}

function mapRequirementsToTree(treeNodes: TreeNode[], complianceMatrixState: ComplianceMatrixRow[]) {
  const xidMap = buildXidMap(treeNodes);

  // Iterate over each ComplianceMatrixRow
  complianceMatrixState.forEach(({ requirement, proposal_reference: proposalRef }) => {
    let node: TreeNode | undefined;

    if (proposalRef.section_id) {
      node = xidMap.get(proposalRef.section_id);
    } else if (proposalRef.volume_id) {
      node = xidMap.get(proposalRef.volume_id);
    }

    if (node) {
      if (!node.requirements) {
        node.requirements = [];
      }
      node.requirements.push(requirement);
    }
  });
}

function deleteNodeAndMoveRequirements(
  nodeXid: string,
  nodeLevel: Level,
  xidMap: Map<string, TreeNode>,
  complianceMatrixState: ComplianceMatrixRow[],
): ComplianceMatrixRow[] {
  const updatedRequirements: ComplianceMatrixRow[] = [];
  const node = xidMap.get(nodeXid);

  if (!node) {
    // Node has already been handled or does not exist
    return updatedRequirements;
  }

  if (!node.parentXid) {
    // Cannot proceed without a parent
    return updatedRequirements;
  }

  const parentNode = xidMap.get(node.parentXid);

  if (!parentNode) {
    // Parent node has been deleted; unassign requirements
    complianceMatrixState.forEach(({ proposal_reference, ...row }) => {
      if (nodeLevel > 1 && proposal_reference.section_id === nodeXid) {
        proposal_reference.section_id = undefined;
        proposal_reference.volume_id = undefined;
        proposal_reference.subsection_id = undefined;

        updatedRequirements.push({
          ...row,
          proposal_reference,
        });
      }
    });

    // Remove the node from the xidMap
    xidMap.delete(nodeXid);

    return updatedRequirements;
  }

  // Move requirements from node to parent
  if (node.requirements) {
    parentNode.requirements = parentNode.requirements || [];
    parentNode.requirements.push(...node.requirements);
  }

  // Update proposal_reference in complianceMatrixState
  complianceMatrixState.forEach(({ proposal_reference, ...row }) => {
    if (nodeLevel > 1 && proposal_reference.section_id === nodeXid) {
      proposal_reference.section_id = undefined;
      proposal_reference.subsection_id = undefined;
      proposal_reference.volume_id = undefined;

      if (nodeLevel === 2) {
        proposal_reference.volume_id = parentNode.attrs.xid; // Set parent volume ID
      } else if (nodeLevel === 3) {
        proposal_reference.section_id = parentNode.attrs.xid; // Keep parent at section ID
      }
      updatedRequirements.push({
        ...row,
        proposal_reference,
      });
    }
  });

  // Remove the node from the xidMap
  xidMap.delete(nodeXid);

  return updatedRequirements;
}

const getNodeText = (node: TreeNode): string => node?.content?.[0]?.text || node?.text || "";

function fixRemovedRequirements(
  reference: OutlineContent[] = [],
  updated: OutlineContent[] = [],
  complianceMatrixState: ComplianceMatrixRow[],
): ComplianceMatrixRow[] {
  // Build trees for reference and updated outlines
  const referenceTree = buildTree(reference);
  const updatedTree = buildTree(updated);

  // Build xid maps for easy lookup
  const referenceXidMap = buildXidMap(referenceTree);
  const updatedXidMap = buildXidMap(updatedTree);

  const updatedRequirements: ComplianceMatrixRow[] = [];

  // Map requirements to the reference tree
  mapRequirementsToTree(referenceTree, complianceMatrixState);

  // Build a map from xid to text for reference and updated
  const referenceTextMap = new Map<string, string>();
  Array.from(referenceXidMap.entries()).forEach(([xid, node]) => {
    referenceTextMap.set(xid, getNodeText(node));
  });

  const updatedTextMap = new Map<string, string>();
  Array.from(updatedXidMap.entries()).forEach(([xid, node]) => {
    updatedTextMap.set(xid, getNodeText(node));
  });

  // Identify deleted xids
  const deletedXids = Array.from(referenceXidMap.keys()).filter((xid) => !updatedXidMap.has(xid));

  // Collect the texts of deleted nodes
  const deletedTexts = new Map<string, string>(); // xid to text
  for (const xid of deletedXids) {
    const node = referenceXidMap.get(xid);
    if (node) {
      deletedTexts.set(xid, getNodeText(node));
    }
  }

  // Process potential merges
  const mergedNodes: Map<string, string[]> = new Map(); // updatedXid to array of deletedXids

  Array.from(updatedXidMap.entries()).forEach(([xid, updatedNode]) => {
    const referenceNode = referenceXidMap.get(xid);
    if (referenceNode && getNodeText(updatedNode) !== getNodeText(referenceNode)) {
      // Node exists in both, but text has changed
      const updatedText = getNodeText(updatedNode);
      const referenceText = getNodeText(referenceNode);

      let differenceText = "";

      if (updatedText.startsWith(referenceText)) {
        differenceText = updatedText.substring(referenceText.length);
      } else if (updatedText.endsWith(referenceText)) {
        differenceText = updatedText.substring(0, updatedText.length - referenceText.length);
      }

      if (differenceText) {
        // Check if differenceText matches any of the deleted node texts
        const appendedTexts: string[] = [];
        Array.from(deletedTexts.entries()).forEach(([deletedXid, deletedText]) => {
          if (differenceText === deletedText) {
            // We have a merge
            if (!mergedNodes.has(xid)) {
              mergedNodes.set(xid, []);
            }
            mergedNodes.get(xid)?.push(deletedXid);
            appendedTexts.push(deletedXid);
          }
        });

        // Remove the merged deleted xids from deletedTexts and deletedXids
        for (const mergedXid of appendedTexts) {
          deletedTexts.delete(mergedXid);
          const index = deletedXids.indexOf(mergedXid);
          if (index !== -1) {
            deletedXids.splice(index, 1);
          }
        }
      }
    }
  });

  // Now, process the merges
  Array.from(mergedNodes.entries()).forEach(([updatedXid, mergedDeletedXids]) => {
    const updatedNode = updatedXidMap.get(updatedXid);
    if (!updatedNode) return;

    const updatedLevel = updatedNode.attrs.level;

    for (const deletedXid of mergedDeletedXids) {
      const deletedNode = referenceXidMap.get(deletedXid);
      if (!deletedNode) return;

      // Move requirements from deletedNode to updatedNode
      if (deletedNode.requirements) {
        updatedNode.requirements = updatedNode.requirements || [];
        updatedNode.requirements.push(...deletedNode.requirements);

        // Update proposal_reference in complianceMatrixState
        for (const row of complianceMatrixState) {
          const proposal_reference = { ...row.proposal_reference };

          if (
            (proposal_reference.volume_id === deletedXid && deletedNode.attrs.level === 1) ||
            (proposal_reference.section_id === deletedXid && deletedNode.attrs.level > 1)
          ) {
            proposal_reference.volume_id = undefined;
            proposal_reference.section_id = undefined;
            proposal_reference.subsection_id = undefined;

            if (updatedLevel === 1) {
              proposal_reference.volume_id = updatedXid;
            } else {
              proposal_reference.section_id = updatedXid;
            }

            updatedRequirements.push({
              ...row,
              proposal_reference,
            });
          }
        }
      }
    }
  });

  // Process deletions (remaining deleted nodes)
  // Handle volumes first
  for (const xid of deletedXids) {
    const deletedNode = referenceXidMap.get(xid);

    if (!deletedNode) continue;

    if (deletedNode.attrs.level === 1) {
      // Volume deleted
      updatedRequirements.push(...deleteVolumeAndUnassignRequirements(xid, referenceXidMap, complianceMatrixState));
    }
  }

  // Then handle sections and subsections
  for (const xid of deletedXids) {
    const deletedNode = referenceXidMap.get(xid);

    if (!deletedNode) continue;

    const nodeLevel = deletedNode.attrs.level;

    if (nodeLevel > 1) {
      const parentExists = updatedXidMap.has(deletedNode.parentXid!);

      if (parentExists) {
        updatedRequirements.push(
          ...deleteNodeAndMoveRequirements(xid, nodeLevel, referenceXidMap, complianceMatrixState),
        );
      } else {
        // Parent node has been deleted; requirements have already been unassigned
        continue;
      }
    }
  }

  // Return the updated outline
  return updatedRequirements;
}

function fixAddedRequirements(
  reference: OutlineContent[] = [],
  updated: OutlineContent[] = [],
  complianceMatrixState: ComplianceMatrixRow[],
): ComplianceMatrixRow[] {
  const updatedRequirements: ComplianceMatrixRow[] = [];

  const refXidMap = new Map<string, OutlineContent>();
  reference.forEach((node) => refXidMap.set(node.attrs.xid, node));

  const updatedXidMap = new Map<string, OutlineContent>();
  updated.forEach((node) => updatedXidMap.set(node.attrs.xid, node));

  // For each node in the reference outline
  for (let refIndex = 0; refIndex < reference.length; refIndex++) {
    const refNode = reference[refIndex];
    const refXid = refNode.attrs.xid;
    const refText = getNodeText(refNode);
    const refLevel = refNode.attrs.level;

    // If the node exists in the updated outline
    if (updatedXidMap.has(refXid)) {
      const updatedNode = updatedXidMap.get(refXid)!;
      const updatedText = getNodeText(updatedNode);

      // If texts are different, the node might have been split
      if (refText !== updatedText) {
        let combinedText = updatedText;
        const updatedIndex = updated.findIndex((node) => node.attrs.xid === refXid);

        if (updatedIndex >= 0) {
          let i = updatedIndex + 1;
          const newNodes: OutlineContent[] = [];

          while (combinedText.length < refText.length && i < updated.length) {
            const nextNode = updated[i];
            combinedText += getNodeText(nextNode);
            newNodes.push(nextNode);
            i++;
          }

          // Check if the combined text matches the reference text
          if (combinedText === refText && newNodes.length > 0) {
            const targetNode = newNodes[newNodes.length - 1];

            // Move requirements from refNode to targetNode
            complianceMatrixState.forEach((row) => {
              const proposal_reference: ProposalReference = {
                volume_id: undefined,
                section_id: undefined,
                subsection_id: undefined,
              };

              if (refLevel === 1 && row.proposal_reference.volume_id === refXid) {
                proposal_reference.volume_id = targetNode.attrs.xid;
              } else if (refLevel > 1 && row.proposal_reference.section_id === refXid) {
                proposal_reference.section_id = targetNode.attrs.xid;
              }

              if (proposal_reference.volume_id || proposal_reference.section_id) {
                updatedRequirements.push({
                  ...row,
                  proposal_reference,
                });
              }
            });
          }
        }
      }
    }
  }

  return updatedRequirements;
}

export function fixRequirements(
  reference: OutlineContent[] = [],
  updated: OutlineContent[] = [],
  complianceMatrixState: ComplianceMatrixRow[] | undefined,
): ComplianceMatrixRow[] {
  if (!complianceMatrixState?.length) {
    return [];
  }
  if (reference.length > updated.length) {
    return fixRemovedRequirements(reference, updated, complianceMatrixState);
  }
  if (reference.length < updated.length) {
    return fixAddedRequirements(reference, updated, complianceMatrixState);
  }
  return [];
}
