import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Section } from "components/copilot/CopilotSchemaImmutableTypes";
import { SourceType } from "components/copilot/CopilotSchemaTypes";
import { insert, move } from "utils/array";

export type SectionIdeaContentBody = {
  content: string;
  reference_id?: string | null;
  source_name: string;
  source_type: SourceType;
  source_extension_type?: string;
  source_content: string;
};

type State = {
  section: Section | null;
  isIdeationLoading: boolean;
  isSubmitting: boolean;
  selectedContent: SectionIdeaContentBody[];
  generatedContent: SectionIdeaContentBody[];
};

const initialState: State = {
  section: null,
  isIdeationLoading: false,
  isSubmitting: false,
  selectedContent: [],
  generatedContent: [],
};

const sectionIdeationReducer = createSlice({
  name: "sectionIdeationReducer",
  initialState,
  reducers: {
    deleteGeneratedContent: (state: State, action: PayloadAction<{ at: number }>) => {
      state.generatedContent.splice(action.payload.at, 1);
    },
    moveSelectedContentToGenerated: (state: State, action: PayloadAction<{ from: number; to: number }>) => {
      const [removedItem] = state.selectedContent.splice(action.payload.from, 1);
      state.generatedContent = insert(
        state.generatedContent,
        removedItem,
        action.payload.to === -1 ? state.generatedContent.length : action.payload.to,
      );
    },
    moveGenratedContentToSelected: (state: State, action: PayloadAction<{ from: number; to: number }>) => {
      const [removedItem] = state.generatedContent.splice(action.payload.from, 1);
      state.selectedContent = insert(
        state.selectedContent,
        removedItem,
        action.payload.to === -1 ? state.selectedContent.length : action.payload.to,
      );
    },
    reorderSectionIdeationSelectedContent: (state: State, action: PayloadAction<{ from: number; to: number }>) => {
      state.selectedContent = move(state.selectedContent, action.payload.from, action.payload.to);
    },
    appendSectionIdeationSelectedContent: (state: State, action: PayloadAction<SectionIdeaContentBody>) => {
      state.selectedContent = [...state.selectedContent, action.payload];
    },
    reorderSectionIdeationGeneratedContent: (state: State, action: PayloadAction<{ from: number; to: number }>) => {
      state.generatedContent = move(state.generatedContent, action.payload.from, action.payload.to);
    },
    appendSectionIdeationGeneratedContent: (state: State, action: PayloadAction<SectionIdeaContentBody>) => {
      state.generatedContent = [...state.generatedContent, action.payload];
    },
    setSectionIdeationState: (state: State, action: PayloadAction<Partial<State>>) => {
      return { ...state, ...action.payload };
    },
    openSectionIdeation: (state: State, action: PayloadAction<State["section"]>) => {
      const section = action.payload;
      return { ...state, section };
    },
    closeSectionIdeation: () => {
      return initialState;
    },
  },
});

export const {
  deleteGeneratedContent,
  moveGenratedContentToSelected,
  moveSelectedContentToGenerated,
  reorderSectionIdeationGeneratedContent,
  reorderSectionIdeationSelectedContent,
  appendSectionIdeationSelectedContent,
  appendSectionIdeationGeneratedContent,
  openSectionIdeation,
  closeSectionIdeation,
  setSectionIdeationState,
} = sectionIdeationReducer.actions;

export default sectionIdeationReducer.reducer;
