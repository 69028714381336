import { CoreBlock as ImmutableCoreBlock } from "../CopilotSchemaImmutableTypes";
import { Avatar } from "components/molecules/avatar";
import { useAppSelector } from "store/storeTypes";
import { useDeleteAssistantBlock } from "./hooks";
import IconButton from "components/atoms/icon-button";
import { Trash2 } from "lucide-react";
import copyText from "utils/copyText";
import { useNotification } from "context/notificationContext";
import LiveDate from "components/molecules/live-date";

const CoreBlock = ({ block }: { block: ImmutableCoreBlock }) => {
  const { setToast } = useNotification();
  const { currentUser } = useAppSelector((root) => root.auth);
  const deleteBlock = useDeleteAssistantBlock();

  return (
    <div className="flex flex-row gap-2 w-full">
      <Avatar size={28} id={currentUser?.username} name={currentUser?.username} className="text-base" />
      <div className="flex flex-col gap-3 pt-1 flex-1 min-w-0">
        <div className="text-gray-darkest text-sm whitespace-pre-wrap break-words">{block.body}</div>
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row gap-2 items-center text-gray-400">
            <IconButton
              name="Copy"
              className="w-4 h-4 duration-150 hover:text-slate-600"
              onClick={() =>
                copyText(block.body, () =>
                  setToast.success({
                    msg: "Copied",
                  }),
                )
              }
            />
            <div className="h-4 w-px bg-gray-400" />
            <button onClick={() => deleteBlock(block.id)} className="duration-150 hover:text-slate-600">
              <Trash2 size={14} className="stroke-[1.6]" />
            </button>
          </div>
          <LiveDate date={block.updated_at} duration={30000} />
        </div>
      </div>
    </div>
  );
};

export default CoreBlock;
