import { getAllProposalAssistantChatSessionsAPI } from "api/api";
import { formatDate } from "helpers/helper";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ChatHistory from "./ChatHistory";
import ChatPanel from "./ChatPanel";
import Documents from "./Documents";
import plusCircle from "../../Assets/plus-circle.svg";
import { BackArrow } from "../../utils/icons";
import search from "../../Assets/search-normal.png";
import { useNotification } from "context/notificationContext";

const ProposalAssistant = ({ internalContractId }) => {
  const { setToast } = useNotification();

  const [loadingSessions, setLoadingSessions] = useState(true);
  const [chatSessions, setChatSessions] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [creatingSession, setCreatingSession] = useState(false);
  const [uploadDocumentMode, setUploadDocumentMode] = useState(false);
  const [showUploadModal, setUploadModal] = useState(false);
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    getAllSessions();
  }, []);

  const handleInputChange = (e) => {
    setSearchVal(e.target.value);
  };

  const getAllSessions = () => {
    getAllProposalAssistantChatSessionsAPI(internalContractId)
      .then((res) => {
        const data = res.data.map((s) => ({
          ...s,
          name: s.name || `Chat Session ${formatDate(s.created_at)}`,
        }));
        setChatSessions([...data]);
        if (data?.length !== 0) {
          setSearchParameters({ chatId: data[0].id });
        }
      })
      .catch((err) => {
        setToast.error({
          title: "Oops!",
          msg: "There was an error fetching chat sessions. Please refresh and try again.",
        });
      })
      .finally(() => {
        setLoadingSessions(false);
        setCreatingSession(false);
      });
  };

  const setSearchParameters = (newParams) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    Object.keys(newParams).forEach((key) => {
      if (newParams[key]) {
        newSearchParams.set(key, newParams[key]);
      }
    });

    setSearchParams(newSearchParams);
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="p-4 my-3 mb-1 shadow-sm flex items-center gap-9 bg-white">
        {!uploadDocumentMode ? (
          <>
            <div className="flex items-center gap-3">
              <h4 className="font-bold text-xl">Proposal Assistant</h4>
            </div>
            <div className="ml-auto flex gap-3">
              <button
                className="bg-action flex text-white gap-2 py-2 px-3 text-sm items-center rounded-lg"
                onClick={() => setUploadDocumentMode(true)}
              >
                <img src={plusCircle} alt="" />
                <span>Documents</span>
              </button>
            </div>
          </>
        ) : (
          <div className="flex w-full gap-3 items-center select-none cursor-pointer">
            <span onClick={() => setUploadDocumentMode(false)}>
              <BackArrow />
            </span>
            <>
              <div className="flex items-center gap-3 ml-2">
                <h4 className="font-bold text-xl">Documents</h4>
              </div>
              <div className="flex-grow ml-4 max-w-[580px] 2xl:max-w-[850px] flex items-center gap-2 px-3 py-[0.32rem] rounded-md border bg-[#f7f7f7]">
                <img src={search} alt="" className="mr-1 w-4 h-4" />
                <input
                  type="text"
                  className="bg-[#f7f7f7] focus:outline-none  focus:ring-0 focus:ring-gray-200 text-sm py-1 text-gray-500 w-full placeholder:text-sm"
                  placeholder=""
                  value={searchVal}
                  onChange={handleInputChange}
                />
              </div>
              <div className="ml-auto flex gap-3">
                <button
                  className="bg-action flex text-white gap-2 py-2 px-3 text-sm items-center rounded-lg"
                  onClick={() => setUploadModal(true)}
                >
                  <img src={plusCircle} alt="" /> <span>New</span>
                </button>
              </div>
            </>
          </div>
        )}
      </div>
      <div className="flex flex-grow mt-4 px-3 pb-3 overflow-auto relative">
        {uploadDocumentMode ? (
          <Documents showUploadModal={showUploadModal} setUploadModal={setUploadModal} searchVal={searchVal} />
        ) : (
          <>
            {loadingSessions ? (
              <div
                className="absolute   top-0 left-0 w-full  flex items-center justify-center overflow-hidden rounded-md box-border"
                style={styles.loadingOverlay}
              >
                <span className="loaderr"></span>
              </div>
            ) : (
              <div className="flex flex-grow pb-3 overflow-auto">
                <div className="border overflow-y-scroll min-w-[343px] w-[343px] min-h-full border-gray-300 shadow-lg rounded-lg bg-white sessions">
                  <ChatHistory
                    internalContractId={internalContractId}
                    chatSessions={chatSessions}
                    setChatSessions={setChatSessions}
                    searchParams={searchParams}
                    getAllSessions={getAllSessions}
                    creatingSession={creatingSession}
                    setCreatingSession={setCreatingSession}
                  />
                </div>
                <div className="flex-grow flex">
                  <ChatPanel />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  loadingOverlay: {
    background: "transparent",
    height: "100%",
    zIndex: 5,
  },
};

export default ProposalAssistant;
