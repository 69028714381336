import { Extraction, Section, Storage, Volume } from "components/copilot/CopilotSchemaTypes";
import useExtractionOperations from "hook/useExtractionOperations";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Pencil, Trash2 } from "lucide-react";
import { setEditableTemplateRowState, setSectionToMove } from "store/reducers/extract/CurrentExtractionReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import {
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { move } from "utils/array";
import { find, findIndex, LiveList, LiveObject, moveItem, ToImmutable } from "YJSProvider/LiveObjects";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";
import { useMutation } from "YJSProvider/createYJSContext";
import Icon from "components/atoms/icons/Icon";
import { YJS_OPERATIONS } from "const-values/yjs";

export const useDrag = (
  formattedDrafts: (ToImmutable<Volume> & { sections: FormattedSection[] })[],
  extractionId?: string,
) => {
  const { addAttribution } = useExtractionOperations();
  const [activeDragId, setActiveDragId] = useState<UniqueIdentifier | null>(null);
  const [dragDrafts, setDragDrafts] = useState<
    (Omit<ToImmutable<Volume>, "sections"> & { sections: FormattedSection[] })[]
  >([]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    setDragDrafts(formattedDrafts);
  }, [formattedDrafts]);

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      setDragDrafts(formattedDrafts);
      setActiveDragId(event.active.id);
    },
    [formattedDrafts],
  );

  const handleDragCancel = useCallback(() => {
    setDragDrafts(formattedDrafts);
    setActiveDragId(null);
  }, [formattedDrafts]);

  const handleDragEnd = useMutation(
    ({ storage }, event: DragEndEvent) => {
      const { active, over } = event;
      addAttribution(YJS_OPERATIONS.EXTRACTION.REORDER_VOLUME);
      if (over?.id && active.id !== over.id) {
        const extractions = storage.get("extractions") as Storage["extractions"] | undefined;
        let liveExtraction: LiveObject<Extraction> | undefined;
        if (extractions) {
          liveExtraction = find(extractions, (extraction) => extraction.get("id") === extractionId);
        }

        const liveVolumes = liveExtraction?.get("framework")?.get("volumes") as
          | LiveList<LiveObject<Volume>>
          | undefined;

        if (!liveVolumes?.length) return;

        let sourceIndex: number | undefined;
        let destinationIndex: number | undefined;

        if (liveVolumes) {
          sourceIndex = findIndex(liveVolumes, (volume) => volume.get("id") === active.id);
          destinationIndex = findIndex(liveVolumes, (volume) => volume.get("id") === over.id);
        }

        if (
          sourceIndex === -1 ||
          destinationIndex === -1 ||
          typeof sourceIndex !== "number" ||
          typeof destinationIndex !== "number"
        )
          return;

        const moved = move(dragDrafts, sourceIndex, destinationIndex);
        setDragDrafts(moved);
        moveItem(liveVolumes, sourceIndex, destinationIndex);
      } else {
        setDragDrafts(formattedDrafts);
      }

      setActiveDragId(null);
    },
    [extractionId, dragDrafts, addAttribution],
  );

  return { dragDrafts, sensors, handleDragStart, handleDragEnd, handleDragCancel, activeDragId };
};

export const useDraftDropdownItems = (
  draft: ToImmutable<Omit<Volume, "sections">> & { sections: FormattedSection[] },
  extractionId?: string,
) => {
  const dispatch = useAppDispatch();
  const { deleteDraft, addAttribution } = useExtractionOperations();

  const items = useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex items-center gap-2 text-xs">
            <Pencil size={16} /> Edit
          </div>
        ),
        onSelect: () =>
          dispatch(
            setEditableTemplateRowState({
              localValue: draft.title,
              id: draft.id,
            }),
          ),
      },

      {
        key: 2,
        label: (
          <div className="flex gap-2 text-xs items-center text-red-500">
            <Trash2 size={16} /> Remove
          </div>
        ),
        onSelect: () => {
          triggerConfirm({
            proceedLabel: "Remove",
            header: `Are you sure you want to remove "${draft?.title}"?`,
            body: "This action is irreversible and will delete all progress associated with the draft.",
          }).then((proceed) => {
            if (proceed && extractionId) {
              deleteDraft(extractionId, draft.id);
              addAttribution(YJS_OPERATIONS.EXTRACTION.DELETE_VOLUME);
            }
          });
        },
      },
    ],
    [addAttribution, deleteDraft, dispatch, draft.id, draft.title, extractionId],
  );

  return { items };
};

export const useSectionDropdownItems = (section: ToImmutable<Section>, volumeId: string, extractionId?: string) => {
  const dispatch = useAppDispatch();
  const { deleteSection, addAttribution } = useExtractionOperations();
  const isSubsection = section.parent_id;

  const items = useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex items-center gap-2 text-xs">
            <Pencil size={16} /> Edit
          </div>
        ),
        onSelect: () =>
          dispatch(
            setEditableTemplateRowState({
              localValue: section.title,
              id: section.id,
            }),
          ),
      },
      {
        key: 2,
        label: (
          <div className="flex items-center gap-2 text-xs">
            <Icon name="Swap" className="text-sm w-4" />
            Move
          </div>
        ),
        onSelect: () => dispatch(setSectionToMove(section)),
      },
      {
        key: 3,
        label: (
          <div className="flex gap-2 text-xs items-center text-red-500">
            <Trash2 size={16} /> Remove
          </div>
        ),
        onSelect: () => {
          triggerConfirm({
            proceedLabel: "Remove",
            header: `Are you sure you want to remove this ${isSubsection ? "subsection" : "section"}?`,
            body: (
              <div>
                This action is irreversible and will delete all progress associated with the{" "}
                {isSubsection ? "subsection" : "section"}.
                <br />
                <br />
                Please confirm this action.
              </div>
            ),
          }).then((proceed) => {
            if (proceed && extractionId) {
              deleteSection(extractionId, volumeId, section.id);
              addAttribution(
                isSubsection ? YJS_OPERATIONS.EXTRACTION.DELETE_SUBSECTION : YJS_OPERATIONS.EXTRACTION.DELETE_SECTION,
              );
            }
          });
        },
      },
    ],
    [addAttribution, deleteSection, dispatch, extractionId, isSubsection, section, volumeId],
  );

  return { items };
};

export const useTemplateRowOperations = (extractionId?: string) => {
  const { addNewVolume, addNewSection, addAttribution } = useExtractionOperations();
  const dispatch = useAppDispatch();

  const handleAddVolume = useCallback(
    (position: number) => {
      if (extractionId) {
        const createdDraft = addNewVolume(extractionId, undefined, position);
        addAttribution(YJS_OPERATIONS.EXTRACTION.ADD_VOLUME);
        setTimeout(
          () =>
            dispatch(
              setEditableTemplateRowState({
                localValue: createdDraft.title,
                id: createdDraft.id,
              }),
            ),
          80,
        );
      }
    },
    [extractionId, addNewVolume, dispatch, addAttribution],
  );

  const handleAddSection = useCallback(
    (draftId: string, position?: number) => {
      if (extractionId) {
        const createdSection = addNewSection(extractionId, draftId, undefined, position);
        addAttribution(YJS_OPERATIONS.EXTRACTION.ADD_SECTION);
        setTimeout(
          () =>
            dispatch(
              setEditableTemplateRowState({
                localValue: createdSection.title,
                id: createdSection.id,
              }),
            ),
          80,
        );
      }
    },
    [addNewSection, dispatch, extractionId, addAttribution],
  );

  const handleAddSubsection = useCallback(
    (draftId: string, parentId: string) => {
      if (extractionId) {
        const createdSection = addNewSection(extractionId, draftId, {
          parent_id: parentId,
        });
        addAttribution(YJS_OPERATIONS.EXTRACTION.ADD_SUBSECTION);
        setTimeout(
          () =>
            dispatch(
              setEditableTemplateRowState({
                localValue: createdSection.title,
                id: createdSection.id,
              }),
            ),
          80,
        );
      }
    },
    [addNewSection, dispatch, extractionId, addAttribution],
  );

  return { handleAddVolume, handleAddSection, handleAddSubsection };
};

export function useCurrentFormattedExtraction() {
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const formattedDrafts = useMemo(() => {
    const drafts = extraction?.framework?.volumes || [];

    return drafts.map((vol) => ({
      ...vol,
      sections:
        vol?.sections?.reduce<FormattedSection[]>((acc, section) => {
          if (!section.parent_id) {
            const subsections = vol?.sections?.filter(({ parent_id }) => parent_id === section.id) || [];
            return [...acc, { ...section, subsections }];
          }

          return acc;
        }, []) || [],
    }));
  }, [extraction?.framework?.volumes]);

  return { extraction, formattedDrafts };
}
