import { expandFileContent } from "api/api";
import { useNotification } from "context/notificationContext";
import { useCallback, useState } from "react";

type ContentState = {
  sourceContent: string;
  north: {
    id: string | null;
    loading: boolean;
  };
  south: {
    id: string | null;
    loading: boolean;
  };
};

export const usePullSourceContent = (source_content: string, content_id: string) => {
  const { setToast } = useNotification();
  const [content, setContent] = useState<ContentState>({
    sourceContent: source_content,
    north: { id: content_id, loading: false },
    south: { id: content_id, loading: false },
  });
  const { north, south } = content;

  const pullContentNorth = useCallback(async () => {
    if (north.loading) return;

    setContent((prev) => ({
      ...prev,
      north: {
        ...prev.north,
        loading: true,
      },
    }));
    return expandFileContent(north.id, true)
      .then((resp) => {
        const hasContent = !!resp.data?.content;
        setContent((prev) => ({
          ...prev,
          ...(hasContent && { sourceContent: `${resp.data?.content}\n\n${content.sourceContent}` }),
          north: {
            ...prev.north,
            id: hasContent ? resp.data.file_content_id : null,
          },
        }));

        return hasContent;
      })
      .catch(() => {
        setToast.error({
          title: "Unable to search additional content",
          msg: "We were unable to search additional content due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        });
      })
      .finally(() => {
        setContent((prev) => ({
          ...prev,
          north: {
            ...prev.north,
            loading: false,
          },
        }));
      });
  }, [content.sourceContent, north.id, north.loading, setToast]);

  const pullContentSouth = useCallback(async () => {
    if (south.loading) return;
    setContent((prev) => ({
      ...prev,
      south: {
        ...prev.south,
        loading: true,
      },
    }));

    return expandFileContent(south.id, false)
      .then((resp) => {
        const hasContent = !!resp.data?.content;
        setContent((prev) => ({
          ...prev,
          ...(hasContent && { sourceContent: `${content.sourceContent}\n\n${resp.data?.content}` }),
          south: {
            ...prev.south,
            id: hasContent ? resp.data.file_content_id : null,
          },
        }));

        return hasContent;
      })
      .catch(() => {
        setToast.error({
          title: "Unable to search additional content",
          msg: "We were unable to search additional content due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        });
      })
      .finally(() => {
        setContent((prev) => ({
          ...prev,
          south: {
            ...prev.south,
            loading: false,
          },
        }));
      });
  }, [content.sourceContent, setToast, south.id, south.loading]);

  return { pullContentNorth, pullContentSouth, content };
};
