import { getFileIcon } from "utils/getFileIcon";
import { ResponseSource } from "components/copilot/CopilotSchemaImmutableTypes";
import { toggleSourceModal } from "store/reducers/modalsSlice";
import { useAppDispatch } from "store/storeTypes";
import { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";

import moment from "moment";

type Props = {
  responseSources?: ResponseSource[];
};

const ResponseSources = ({ responseSources }: Props) => {
  const dispatch = useAppDispatch();
  const [showSources, setShowSources] = useState(true);

  return (
    <div className="flex flex-col py-2 gap-3 px-4 border border-gray-75 rounded-b-md">
      <div className="flex w-full text-xs text-gray-500 items-center">
        <button
          className="flex justify-center items-center gap-1 rounded-md hover:bg-[#F0F0F0] p-1"
          onClick={() => setShowSources(!showSources)}
        >
          <div className="font-normal">Sources Used ({responseSources?.length ? responseSources.length : "0"})</div>
          {showSources ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
        </button>
      </div>
      {showSources && (
        <>
          {responseSources?.length ? (
            <span className="flex gap-2 overflow-x-auto">
              {responseSources?.map((source, idx) => (
                <button
                  key={`${source.file_id}_${idx}`}
                  onClick={() => {
                    dispatch(
                      toggleSourceModal({
                        open: true,
                        initialProps: {
                          id: source.file_id,
                          extension: source.extension_type,
                          name: source.name,
                          sourceContent: source.used_file_contents
                            .map((uc) => `${uc.requirement_source_citations}\n\n${uc.content}\n`)
                            .join("\n"),
                        },
                      }),
                    );
                  }}
                  className="flex gap-2 items-center rounded border-[1.5px] border-gray-mid pl-2 pr-5 py-1.5 mb-1 duration-100 bg-white hover:bg-gray-100 max-w-[200px]"
                >
                  <img src={getFileIcon(source.extension_type)} alt="" className="h-[22px]" />
                  <div className="flex flex-col items-start mr-2">
                    <p className="text-xs text-gray-darkest max-w-[160px] truncate">{source.name}</p>
                    <div className="text-xxs text-gray-mid whitespace-nowrap">
                      {moment(source?.date).format("MMMM DD, YYYY")}
                    </div>
                  </div>
                </button>
              ))}
            </span>
          ) : (
            <div className="text-gray-400 mb-1 text-xs font-normal">
              No sources were used in this response generation.
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ResponseSources;
