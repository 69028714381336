/** @jsxImportSource @emotion/react */

import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import Icon from "components/atoms/icons/Icon";
import { ComplianceMatrixRow, RequirementStatus } from "components/copilot/CopilotSchemaImmutableTypes";
import { HTMLAttributes, forwardRef, useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import tw from "twin.macro";
import WritingPrompts from "./writing-prompts";
import UserInstructions from "./user-instructions";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import * as Collapsible from "@radix-ui/react-collapsible";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import RequirementOptionsDropdown from "components/molecules/requirement-options-dropdown/RequirementOptionsDropdown";
import Popover from "components/atoms/popover";
import AssigneesPopoverContent from "components/organisms/assignees-popover-content";
import xor from "lodash/xor";
import useRequirementOperations from "hook/useRequirementOperations";
import { AvatarGroup } from "components/molecules/avatar-group";
import useGetAvatarGroup from "hook/draft/useAvatarGroup";
import { Avatar } from "components/molecules/avatar";
import RequirementStatusPopoverContent from "components/organisms/requirement-status-popover-content/RequirementStatusPopoverContent";
import { REQUIREMENT_STATUS_TO_META } from "const-values/Draft";
import { setRequirementsState } from "store/reducers/draft/sectionReducer";
import RequirementResponse from "components/organisms/requirement-response";
import Tooltip from "components/atoms/tooltip";
import EditableContent from "components/molecules/editable-content";
import { updateCheckedState } from "store/reducers/copilot/requirementsReducer";
import { Checkbox } from "components/atoms/checkbox";
import { updateDraftExtractionState } from "store/reducers/draft/DraftsExtractionReducer";
import { useParams, useSearchParams } from "react-router-dom";
import { ChevronDown, ChevronUp, EllipsisVertical, Maximize2, WandSparkles } from "lucide-react";
import GenerateResponseActions from "./GenerateResponseActions";

interface Props extends HTMLAttributes<HTMLDivElement> {
  row: ComplianceMatrixRow;
  withOpacity?: boolean;
  isDragging?: boolean;
  dragProps?: { attributes?: DraggableAttributes; listeners?: SyntheticListenerMap };
  sortedSections?: FormattedSection[];
}

const SectionRequirement = forwardRef<HTMLDivElement, Props>(
  ({ row, style, withOpacity, isDragging, dragProps, sortedSections }, ref) => {
    const { workspaceMembers } = useAppSelector((store) => store.auth);
    const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
    const { volumeId } = useParams();
    const [scrollHeight, setScrollHeight] = useState(0);
    const [textCollapsed, setTextCollapse] = useState(false);
    const {
      requirement,
      assigned_user_ids,
      requirement_status,
      locked,
      is_response_generating,
      auto_response_actor,
      is_response_in_queue,
    } = row;

    const { requirementEditable, expandedRequirementIds, activeDragRequirementId, requirementHighlighted } =
      useAppSelector((store) => store.sectionState.requirementsState);
    const sidebarSize = useAppSelector((store) => store.sectionState.sidebarSize);
    const checkedState = useAppSelector((root) => root.requirements.checkedState);
    const actionsPanelOpen = useAppSelector((store) => store.copilotDrawer.open);
    const { setAssignees, setRequirementStatus, setRequirementContent } = useRequirementOperations();
    const avatars = useGetAvatarGroup(assigned_user_ids, { size: 18, className: "!text-xs" });
    const dispatch = useAppDispatch();
    const editorContainerRef = useRef<HTMLDivElement | null>(null);
    const editorWrapperRef = useRef<HTMLDivElement | null>(null);
    const open = useMemo(
      () => expandedRequirementIds.includes(requirement.id),
      [expandedRequirementIds, requirement.id],
    );
    const content = requirement?.content || requirement?.summarized_content || "";
    const isEditable = requirement.id === requirementEditable;
    const checked = checkedState[requirement.id];
    const [searchParams] = useSearchParams();
    const internalContractId = searchParams.get("id");
    const requirementContent = requirement?.content || requirement?.summarized_content || "";
    const canGenerate = !!internalContractId && !!requirementContent && !locked;

    const handleToggleRowSelection = useCallback(
      (checked: boolean) => {
        const reqId = requirement?.id;
        dispatch(updateCheckedState({ [reqId]: checked }));
        if (volumeId) dispatch(updateDraftExtractionState({ [volumeId]: { id: volumeId, isOpen: false } }));
      },
      [dispatch, requirement?.id, volumeId],
    );

    const generatedOrQueuedBy = useMemo(
      () => workspaceMembers.find((member) => member.id === auto_response_actor)?.username,
      [auto_response_actor, workspaceMembers],
    );

    useLayoutEffect(() => {
      const node = document.getElementById(`section-requirement-response-${requirement.id}`);
      const editorWrapperRefNode = editorWrapperRef.current;
      if (editorWrapperRefNode && node?.clientHeight) {
        editorWrapperRefNode.style.height = `${node.clientHeight + 12}px`;
      }
    }, [requirement.id, textCollapsed, scrollHeight]);

    return (
      <div
        id={`section-requirement-${requirement.id}`}
        className="flex flex-row items-baseline gap-1.5 cursor-default"
        ref={ref}
        css={[
          {
            zIndex: isDragging ? "2" : "auto",
            opacity: withOpacity ? "0.3" : "1",
            pointerEvents: isEditable ? "none" : "auto",
            ...style,
          },
        ]}
        {...dragProps?.attributes}
      >
        <div className="flex flex-col items-center gap-2">
          <div
            className="flex z-[1] bg-transparent rounded p-1 text-slate-500 hover:text-slate-900 hover:bg-[#F0F0F0] group-hover:opacity-100"
            css={[{ cursor: isDragging ? "grabbing" : "grab" }]}
            {...dragProps?.listeners}
          >
            <Icon name="Draggable" className="w-3.5 h-3.5" />
          </div>
          <Checkbox
            size="sm"
            id={requirement?.id}
            checked={checked || false}
            onCheck={(checked) => handleToggleRowSelection(checked)}
          />
        </div>
        <Collapsible.Root
          open={open && !activeDragRequirementId}
          onOpenChange={(o) => dispatch(setRequirementsState({ expandedRequirementIds: o ? [requirement.id] : [] }))}
          className="flex-1 max-w-[calc(100%-24px)]"
        >
          <div
            className="flex shadow-sharp-thin flex-col gap-2 p-2 pb-3 bg-white border border-gray-light rounded-md flex-1"
            css={[
              isDragging && tw`shadow-expanded`,
              !open && tw`hover:bg-hover-defaultLight`,
              open && !activeDragRequirementId && tw`border-slate-500`,
              requirementHighlighted === requirement.id && tw`bg-[#F6F6F6] border-action`,
            ]}
            onClick={() => {
              !open && dispatch(setRequirementsState({ expandedRequirementIds: [requirement.id] }));
            }}
          >
            <div className="flex items-center justify-between">
              <div className="flex gap-1 items-center justify-between">
                <div className="text-xs pl-1 font-medium text-gray-mid text-start mr-1">Requirement</div>
                <Collapsible.Trigger>
                  <div
                    className="text-xxs rounded bg-transparent p-1 font-medium text-gray-400 duration-100 transition-colors hover:text-gray-500 hover:bg-[#f0f0f0]"
                    css={[open && tw`bg-[#f0f0f0] text-gray-500`]}
                  >
                    {open ? (
                      <div className="flex justify-center items-center gap-1">
                        Close Response
                        <ChevronUp size={14} />
                      </div>
                    ) : (
                      <div className="flex justify-center items-center gap-1">
                        Open Response
                        <ChevronDown size={14} />
                      </div>
                    )}
                  </div>
                </Collapsible.Trigger>
                {(is_response_generating || is_response_in_queue) && (
                  <Tooltip
                    disabled={!generatedOrQueuedBy}
                    disableHoverableContent
                    content={
                      <div>
                        {is_response_generating ? "Generated by " : "Queued by "}{" "}
                        <span className="font-semibold">{generatedOrQueuedBy}</span>
                      </div>
                    }
                  >
                    <Icon
                      name={is_response_generating ? "Generate" : "NoteQueue"}
                      className="px-1 min-w-5 min-h-4 h-4 w-5"
                      css={[
                        is_response_generating && {
                          animation: "rotateAnimation 0.5s infinite linear",
                        },
                        !is_response_generating && tw`text-amber-400 px-0 min-h-[18px] min-w-[22px]`,
                      ]}
                    />
                  </Tooltip>
                )}
              </div>
              <div className="flex items-center gap-1.5" onClick={(e) => e.stopPropagation()}>
                <Popover
                  contentProps={{ align: "end", css: tw`mx-0` }}
                  content={
                    <RequirementStatusPopoverContent
                      selectedStatus={requirement_status || RequirementStatus.Todo}
                      onStatusSelect={(newStatus) => {
                        setRequirementStatus(requirement.id, newStatus);
                      }}
                      tw="p-1"
                    />
                  }
                >
                  <button className="z-[1] h-7 flex items-center max-w-full text-gray-700 py-1 px-1.5 rounded bg-[#F6F6F6] duration-150 hover:bg-[#F0F0F0]">
                    <div className="flex text-xs items-center gap-1.5 text-gray-500 truncate">
                      {REQUIREMENT_STATUS_TO_META[requirement_status || RequirementStatus.Todo].smallerIcon}
                      {REQUIREMENT_STATUS_TO_META[requirement_status || RequirementStatus.Todo].label}
                    </div>
                  </button>
                </Popover>
                <Popover
                  contentProps={{ align: "end", css: tw`mx-0` }}
                  content={
                    <AssigneesPopoverContent
                      selectedUsers={assigned_user_ids || []}
                      onUserSelect={(userId) => {
                        const newAssignees = xor(assigned_user_ids || [], [userId]);
                        setAssignees(requirement.id, newAssignees);
                      }}
                      onClearAll={() => setAssignees(requirement.id, [])}
                      tw="p-1"
                    />
                  }
                >
                  <button className="z-[1] h-7 max-w-full flex items-center text-gray-700 py-1 px-1.5 rounded bg-[#F6F6F6] duration-150 hover:bg-[#F0F0F0]">
                    {avatars.length ? (
                      <div className="flex text-xs items-center gap-2 text-gray-500">
                        <AvatarGroup maxCount={3} size={18} avatars={avatars} />
                        <span className="max-w-[125px] truncate">{`${avatars[0].id} ${
                          avatars.length > 1 ? `(+${avatars.length - 1})` : ""
                        }`}</span>
                      </div>
                    ) : (
                      <span className="text-xs text-slate-500">
                        <Avatar size={18} empty />
                      </span>
                    )}
                  </button>
                </Popover>
                <button
                  className="text-lg rounded bg-transparent p-1 font-medium text-gray-mid text-start hover:bg-[#F0F0F0]"
                  onClick={() => dispatch(setRequirementsState({ requirementRouted: requirement.id }))}
                >
                  <Maximize2 size={18} />
                </button>
                <RequirementOptionsDropdown complianceMatrixRow={row} sections={sortedSections || []}>
                  <div className="p-1 pl-0 text-base text-slate-500 duration-100 z-[1] hover:text-black">
                    <EllipsisVertical size={14} />
                  </div>
                </RequirementOptionsDropdown>
              </div>
            </div>
            <div ref={editorWrapperRef}>
              <EditableContent
                ref={editorContainerRef}
                content={content || ""}
                onMouseDown={() => {
                  setTextCollapse(false);
                }}
                onContentUpdate={(val) => setRequirementContent(requirement?.id, val)}
                heightProps={[textCollapsed && tw`line-clamp-3 max-h-[60px] overflow-hidden`]}
                onHeightSet={(height) => {
                  setScrollHeight(height);
                }}
                css={tw`text-sm p-1.5 flex-1`}
                textareaProps={{
                  id: `section-requirement-response-${requirement.id}`,
                  placeholder: "Add requirement content...",
                  ...(sidebarSize && {
                    forceResetProps: [sidebarSize, actionsPanelOpen],
                    forceResetDelay: 150,
                  }),
                }}
              />
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setTextCollapse((prev) => !prev);
              }}
              className="text-xs ml-1.5 flex self-start text-slate-500 duration-100 hover:text-slate-900"
            >
              {scrollHeight > 60 && (textCollapsed ? "Show more..." : "Show less...")}
            </button>
            <Collapsible.Content className="collapsibleContent">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-3 border border-light rounded-md bg-slate-50 px-4 py-3 mx-1.5">
                  <div className="flex items-center justify-between">
                    <div className="text-xs font-semibold"> Generate Response</div>
                    <div className="flex items-center justify-end">
                      <GenerateResponseActions
                        showSelectFileButton
                        row={row}
                        disabled={!canGenerate || showAdvancedSettings}
                      />
                      <div className="bg-slate-300 w-[1px] mx-2 h-6" />
                      <button
                        className="flex items-center gap-1 bg-slate-200 text-gray-mid rounded border-[1px] border-transparent shadow-sm text-xs px-2 py-1 duration-100 hover:text-slate-900 hover:bg-slate-300 disabled:opacity-50 disabled:hover:text-gray-mid disabled:hover:bg-[#F0F0F0]"
                        onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
                        css={[showAdvancedSettings && tw`border-slate-500`]}
                      >
                        <WandSparkles size={12} />
                        Advanced
                      </button>
                    </div>
                  </div>
                  {showAdvancedSettings && (
                    <>
                      <WritingPrompts row={row} />
                      <UserInstructions row={row} />
                      <GenerateResponseActions
                        showStrictnessDropdown
                        showSpeedDropdown
                        row={row}
                        disabled={!canGenerate}
                      />
                    </>
                  )}
                </div>
                <RequirementResponse complianceMatrixRow={row} />
              </div>
            </Collapsible.Content>
          </div>
        </Collapsible.Root>
      </div>
    );
  },
);

export default SectionRequirement;
