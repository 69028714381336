import { useProxyRef } from "hook/useProxyRef";
import { useEffect, useRef } from "react";
import Selecto from "selecto";

interface DragSelectProps {
  onSelect: (id: string, isSelected: boolean) => void;
  selectedDocuments: string[];
  containerRef: React.RefObject<HTMLElement>;
}

const selectionStyle = {
  border: "1px solid #1E1E1E",
  background: "rgba(30, 30, 30, 0.15)",
  borderRadius: "2px",
} as const;

export const useDragSelect = ({ onSelect, selectedDocuments, containerRef }: DragSelectProps): void => {
  const selectoRef = useRef<Selecto | null>(null);
  const documentsRef = useProxyRef(selectedDocuments);
  const styleRef = useRef<HTMLStyleElement | null>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    if (!styleRef.current) {
      styleRef.current = document.createElement("style");
      styleRef.current.textContent = `
        .selecto-selection { 
          border: ${selectionStyle.border} !important; 
          background: ${selectionStyle.background} !important; 
          border-radius: ${selectionStyle.borderRadius} !important;
        }
      `;
      document.head.appendChild(styleRef.current);
    }

    selectoRef.current = new Selecto({
      container: containerRef.current,
      selectableTargets: [".card"],
      hitRate: 20,
      selectByClick: false,
      selectFromInside: false,
      preventDragFromInside: true,
      dragContainer: containerRef.current,
      boundContainer: false,
    }).on("select", (e) => {
      e.added.forEach((el) => {
        const docId = el.getAttribute("data-doc-id");
        if (docId && !documentsRef.current.includes(docId)) {
          onSelect(docId, true);
        }
      });
      e.removed.forEach((el) => {
        const docId = el.getAttribute("data-doc-id");
        if (docId && documentsRef.current.includes(docId)) {
          onSelect(docId, false);
        }
      });
    });

    return () => {
      if (styleRef.current) {
        document.head.removeChild(styleRef.current);
        styleRef.current = null;
      }
      if (selectoRef.current) {
        selectoRef.current.destroy();
        selectoRef.current = null;
      }
    };
  }, [onSelect, documentsRef, containerRef]);
};
