import { useEffect } from "react";
import { Storage, WritingAssistant } from "../CopilotSchemaTypes";
import { WritingAssistant as ImmutableWritingAssistant } from "../CopilotSchemaImmutableTypes";
import { createWritingAssistant } from "utils/Liveblocks/WritingAssistant";
import { useAppSelector } from "store/storeTypes";
import { useMutation } from "YJSProvider/createYJSContext";
import { findIndex, LiveObject, update } from "YJSProvider/LiveObjects";

export const useDeleteAssistantBlock = () => {
  const { currentUser } = useAppSelector((root) => root.auth);

  return useMutation(
    ({ storage }, blockId: string) => {
      if (!currentUser?.id) return;
      const myConversation = (storage.get("writing_assistant") as Storage["writing_assistant"])
        ?.get(currentUser?.id || "")
        ?.get("conversation") as WritingAssistant["conversation"];

      if (!myConversation) return;
      const msgIdx = findIndex(myConversation, (block) => block.get("id") === blockId);
      if (msgIdx === -1) return;

      myConversation?.delete(msgIdx);
    },
    [currentUser],
  );
};

export const useInitWritingAssistant = (
  userId?: string,
  myConversation?: ImmutableWritingAssistant["conversation"],
) => {
  const initWritingAssistant = useMutation(
    ({ storage }) => {
      if (!userId) return;
      const writingAssistant = storage.get("writing_assistant") as Storage["writing_assistant"];
      if (!writingAssistant) {
        storage.set("writing_assistant", new LiveObject({ [userId]: createWritingAssistant(userId) }));
      } else if (!writingAssistant?.get(userId)) update(writingAssistant, { [userId]: createWritingAssistant(userId) });
    },
    [userId],
  );

  useEffect(() => {
    if (!myConversation) {
      initWritingAssistant();
    }
  }, [initWritingAssistant, myConversation]);
};
