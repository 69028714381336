import { Link } from "react-router-dom";
import axios from "axios";
import { getWorkSpaces, setAuth } from "store/reducers/authReducerSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom/dist";
// eslint-disable-next-line no-undef
import { useNotification } from "context/notificationContext";
import { useEffect } from "react";

//
const smsIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M17 21.25H7C3.35 21.25 1.25 19.15 1.25 15.5V8.5C1.25 4.85 3.35 2.75 7 2.75H17C20.65 2.75 22.75 4.85 22.75 8.5V15.5C22.75 19.15 20.65 21.25 17 21.25ZM7 4.25C4.14 4.25 2.75 5.64 2.75 8.5V15.5C2.75 18.36 4.14 19.75 7 19.75H17C19.86 19.75 21.25 18.36 21.25 15.5V8.5C21.25 5.64 19.86 4.25 17 4.25H7Z"
      fill="white"
    />
    <path
      d="M11.9998 12.87C11.1598 12.87 10.3098 12.61 9.65978 12.08L6.52978 9.57997C6.20978 9.31997 6.14978 8.84997 6.40978 8.52997C6.66978 8.20997 7.13978 8.14997 7.45978 8.40997L10.5898 10.91C11.3498 11.52 12.6398 11.52 13.3998 10.91L16.5298 8.40997C16.8498 8.14997 17.3298 8.19997 17.5798 8.52997C17.8398 8.84997 17.7898 9.32997 17.4598 9.57997L14.3298 12.08C13.6898 12.61 12.8398 12.87 11.9998 12.87Z"
      fill="white"
    />
  </svg>
);

// ---------------- Auth 1st screen -------------------
const ContinueWithEmail = () => {
  // eslint-disable-next-line no-undef
  const { setToast } = useNotification();
  const dispatch = useDispatch();
  const navigate = useNavigate("");

  const params = new URLSearchParams(window.location.search);
  const auth0OperationSucessful = params.get("success");
  const message = params.get("message");

  useEffect(() => {
    if (auth0OperationSucessful !== null) {
      if (auth0OperationSucessful === "true") {
        const successMessage = message || "Login using your email and password to continue.";
        setToast.success({ title: "Success", msg: successMessage });
      } else {
        const errMsg =
          message ||
          "An error occurred. Please retry or if the issue persists contact support@vultron.ai".setToast.error({
            title: "Error",
            msg: errMsg,
          });
      }
    }
  }, [auth0OperationSucessful, message]);

  const handleLogin = async () => {
    const synthetic_test_secret = localStorage.getItem("synthetic_test_secret");
    if (synthetic_test_secret) {
      axios.defaults.headers["X-Synthetic-Test-User-Token"] = synthetic_test_secret;
    }
    axios
      .post("/users/auth0/login", {}, { withCredentials: true })
      .then((response) => {
        const maybeSyntheticToken = response.data.user_token;
        if (maybeSyntheticToken) {
          localStorage.setItem("vultron_user_token", JSON.stringify(maybeSyntheticToken));
          localStorage.setItem("vultron_user_sso", JSON.stringify(false));
          axios.defaults.headers.common["Authorization"] = `Bearer ${maybeSyntheticToken}`;
          axios.defaults.headers["X-Authorization-SSO"] = false;
          dispatch(getWorkSpaces(maybeSyntheticToken));
          dispatch(
            setAuth({
              isAuthenticated: true,
              isLoading: false,
            }),
          );
          // TODO: redirect to workspace selection page /select-workspace
          navigate("/select-workspace");
          return;
        }
        const redirectUrl = response.data.url;
        window.location.href = redirectUrl;
      })
      .catch((error) => {
        console.error("Login error:", error);
      });
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center ">
        <h1 className="text-4xl font-medium py-1">Welcome to Vultron</h1>
        <p className="text-gray-600 py-2">Sign up or login with your work email.</p>
      </div>
      <Link
        className="bg-midnight-900 duration-150 hover:bg-midnight-700 text-white flex my-2 w-[450px] flex-col rounded-md mt-6"
        onClick={handleLogin}
      >
        <span className="w-full flex p-4 items-center gap-3 justify-center">
          {" "}
          <span>{smsIcon}</span> <span>Login</span>{" "}
        </span>
      </Link>
    </>
  );
};

export default ContinueWithEmail;
