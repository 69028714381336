/** @jsxImportSource @emotion/react */

import Icon from "components/atoms/icons/Icon";
import Tooltip from "components/atoms/tooltip/Tooltip";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import DatePicker from "react-multi-date-picker";
import Input from "react-input-mask";
import { editForm, streamEnhance, toggleEnhanceLoading } from "store/reducers/newProjectReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { getWordCount, sliceByWordCount } from "utils/getWordCount";
import { CalenderIcon } from "utils/icons";
import { Button } from "components/editor/components";
import { useObserveSseController } from "hook/useObserveSseController";
import { useEnhanceDescription } from "./hooks";
import { EventStreamContentType } from "@microsoft/fetch-event-source";
import { useNotification } from "context/notificationContext";
import { useAnimateLoadingMsg } from "hook/useAnimateLoadingMsg";

const MAX_PROJECT_DESCRIPTION = 500;
const ENHANCE_LOADING_MSGS = ["Analyzing", "Enhancing"];

const EssentialDetails = () => {
  const [titleIsBlurredNoContent, setTitleIsBlurredNoContent] = useState(false);
  const [clientNameIsBlurredNoContent, setClientNameIsBlurredNoContent] = useState(false);
  const { setToast } = useNotification();
  const [enhanceLoadingMsg, setEnhanceLoadingMsg] = useState(ENHANCE_LOADING_MSGS[0]);
  const {
    form: { title, description, response_date, client_name },
    isEnhanceLoading,
  } = useAppSelector((root) => root.newProject);
  const attachments = useAppSelector((root) => root.newProject.attachments);
  const dispatch = useAppDispatch();
  useAnimateLoadingMsg(isEnhanceLoading, 3500, ENHANCE_LOADING_MSGS, (msg) => setEnhanceLoadingMsg(msg));

  const { enhanceDescription, abortConnection } = useEnhanceDescription({
    onmessage(msg) {
      if (msg.event === "FatalError") {
      }

      if (msg.data?.length) {
        dispatch(streamEnhance(msg.data));
      }
    },
    async onopen(response) {
      dispatch(editForm({ description: "" }));
      if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
        return; // everything's good
      } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
        setToast.error({
          title: "Unable to format description",
          msg: "We were unable to format the description due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        });
        dispatch(toggleEnhanceLoading(false));
        // client-side errors are usually non-retriable:
        // throw new FatalError();
      } else {
        // throw new RetriableError();
      }
    },
    onclose() {
      dispatch(toggleEnhanceLoading(false));
      // if the server closes the connection unexpectedly, retry:
      // throw new RetriableError();
    },
    onerror(err) {
      setToast.error({
        title: "Unable to format description",
        msg: "We were unable to format the description due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
      });
      dispatch(toggleEnhanceLoading(false));
      if (err instanceof Error) {
        throw err; // rethrow to stop the operation
      } else {
        // do nothing to automatically retry. You can also
        // return a specific retry interval here.
      }
    },
  });

  useObserveSseController(abortConnection, () => dispatch(toggleEnhanceLoading(false)));

  const words = useMemo(() => getWordCount(description), [description]);

  useEffect(() => {
    if (attachments.length) {
      setTitleIsBlurredNoContent(!title.trim());
      setClientNameIsBlurredNoContent(!client_name.trim());
    }
  }, [attachments, client_name, setClientNameIsBlurredNoContent, title]);

  return (
    <div className="pl-6">
      <div className="flex flex-col gap-6 pr-4 pt-5 pb-6">
        <div className="flex flex-col gap-2">
          <div className="font-semibold text-sm">Project Title</div>
          <input
            onBlur={() => {
              if (!title.trim()) {
                setTitleIsBlurredNoContent(true);
              }
              dispatch(editForm({ title: title.trim() }));
            }}
            value={title}
            onChange={(e) => {
              if (e.target.value.trim()) {
                setTitleIsBlurredNoContent(false);
              }
              dispatch(editForm({ title: e.target.value }));
            }}
            className="text-sm border border-gray-light rounded-lg px-3 py-2 outline-none"
            css={[titleIsBlurredNoContent && tw`border-red-500`]}
            placeholder="Joint Targeting System"
            required
          />
        </div>
        <div className="flex gap-4">
          <div className="flex flex-col gap-6 flex-1">
            <div className="flex flex-col gap-2 pr-2">
              <div className="flex">
                <div className="font-semibold text-sm">Client Name</div>
              </div>
              <input
                value={client_name}
                onBlur={() => {
                  if (!client_name.trim()) {
                    setClientNameIsBlurredNoContent(true);
                  }
                }}
                onChange={(e) => {
                  if (e.target.value.trim()) {
                    setClientNameIsBlurredNoContent(false);
                  }
                  dispatch(editForm({ client_name: e.target.value }));
                }}
                className="text-sm border border-gray-light rounded-lg px-3 py-2 outline-none"
                css={[clientNameIsBlurredNoContent && tw`border-red-500`]}
                placeholder="Department of Defense"
                required
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <div className="font-semibold text-sm">Deadline</div>
            <DatePicker
              arrow={false}
              calendarPosition="top-end"
              format="MM/DD/YYYY"
              value={response_date || new Date()}
              onChange={(date) => {
                const dateValue = date?.valueOf();
                if (
                  typeof dateValue === "number" &&
                  moment(dateValue, true).isValid() &&
                  moment(dateValue).year() > 0
                ) {
                  dispatch(editForm({ response_date: new Date(dateValue)?.toISOString() }));
                } else dispatch(editForm({ response_date: undefined }));
              }}
              containerClassName="!w-full !max-w-full"
              render={(value, openCalendar, handleValueChange) => {
                return (
                  <div
                    className="relative text-sm px-3 py-2 pb-2.5"
                    onClick={openCalendar}
                    css={[!value && tw`text-gray-400`]}
                  >
                    <div className="absolute z-[1] inset-y-0 right-3 flex items-center cursor-pointer select-none">
                      <CalenderIcon />
                    </div>
                    <Input
                      placeholder={moment().format("MM/DD/YYYY")}
                      className="absolute pl-2 right-0 top-0 bottom-0 left-0 border outline-none border-gray-light rounded-lg"
                      mask="99/99/9999"
                      maskChar="-"
                      onFocus={openCalendar}
                      onChange={handleValueChange}
                      value={value}
                    />
                    {response_date ? moment(response_date).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY")}
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex justify-between items-end gap-1">
            <div className="flex items-center gap-2">
              <div className="font-semibold text-sm">Project Description</div>{" "}
              <div className="font-normal text-gray-lightest text-xs ml-0.5 mt-0.5">(Optional)</div>
            </div>
            <div className="flex gap-1.5 items-center justify-center">
              <div className="text-xs flex text-gray-400" css={[words >= MAX_PROJECT_DESCRIPTION && tw`text-red-500`]}>
                {words}/{MAX_PROJECT_DESCRIPTION} words
              </div>
              <div className="w-px h-3 bg-gray-400" />
              <div className="text-action text-xs flex gap-1 items-center">
                <Button
                  onClick={() => {
                    if (!isEnhanceLoading) {
                      setEnhanceLoadingMsg(ENHANCE_LOADING_MSGS[0]);
                      dispatch(toggleEnhanceLoading(true));
                      enhanceDescription({ text: description });
                    }
                  }}
                  className="peer"
                  disabled={!description.trim()}
                  variant="link"
                  css={[isEnhanceLoading && tw`pointer-events-none`]}
                >
                  {isEnhanceLoading && ((!description.trim() && enhanceLoadingMsg) || "Formatting")}
                  {!isEnhanceLoading && "Format"}
                  {isEnhanceLoading && <span className="loading-ellipsis" />}
                </Button>
                <Tooltip
                  contentProps={{ alignOffset: -10, align: "end" }}
                  delayDuration={200}
                  content="Vultron will reformat the description for optimal results"
                >
                  <Icon name="InfoCircleBold" className="w-3.5 h-3.5 peer-disabled:text-gray-disabled" />
                </Tooltip>
              </div>
            </div>
          </div>
          <textarea
            value={description}
            readOnly={isEnhanceLoading}
            onBlur={() => {
              dispatch(editForm({ description: description.trim() }));
            }}
            onChange={(e) => {
              if (isEnhanceLoading) return;
              const count = getWordCount(e.target.value);
              if (count > MAX_PROJECT_DESCRIPTION) {
                // @ts-expect-error
                if (e.nativeEvent.inputType === "insertFromPaste" && words < 500)
                  dispatch(
                    editForm({
                      description: sliceByWordCount(e.target.value, MAX_PROJECT_DESCRIPTION),
                    }),
                  );
                return;
              }
              dispatch(editForm({ description: e.target.value }));
            }}
            rows={5}
            className="text-sm border border-gray-light rounded-lg px-3 py-2 outline-none resize-none"
            placeholder="The Department of Defense is seeking industry input and collaboration for the testing of next generation joint targeting capability. This initiative aims to augment the joint targeting system, offering partners insight into NATO's capability..."
          />
        </div>
      </div>
    </div>
  );
};

export default EssentialDetails;
