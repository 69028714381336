import { useAnimateLoadingMsg } from "hook/useAnimateLoadingMsg";
import { useAppSelector } from "store/storeTypes";
import { useState } from "react";
import GenerationLottie from "Assets/lotties/generation-lottie.json";
import Lottie from "lottie-react";
import { pluralizeWord } from "utils/string";
import classNames from "classnames";

type Props = {
  className?: string;
  bottomText?: string;
  estimateText?: string;
  missingTimeEstimateAltText?: string;
  rotatingLoadingMessages?: string[];
  topText?: string;
};

export const EXTRACTING_LOADING_MSGS = ["Processing", "Analyzing", "Extracting"];

export function LoadingDisplay({
  className,
  bottomText = "You can leave the page and come back later.",
  estimateText = "Preparing this document will take approximately",
  missingTimeEstimateAltText = "This document is queued for processing and will begin preparing shortly.",
  rotatingLoadingMessages = EXTRACTING_LOADING_MSGS,
  topText = "Grab some water and take a moment to unwind.",
}: Props) {
  const [loadingStep, setLoadingStep] = useState(rotatingLoadingMessages[0]);
  const isLoading = useAppSelector((state) => state.currentExtractionState.isExtractingDocument);
  const timeRemaining = useAppSelector((state) => state.currentExtractionState.timeRemaining);

  useAnimateLoadingMsg(isLoading, 15000, rotatingLoadingMessages, setLoadingStep);

  return (
    <>
      <div className={classNames(className, "h-full w-full flex flex-col justify-center items-center px-8")}>
        <Lottie className="h-[400px] w-[400px]" animationData={GenerationLottie} loop />
        <div className="text-2xl font-semibold mb-4 mr-4">
          {loadingStep}
          <span className="loading-ellipsis" />
        </div>
        {topText ? <div className="text-sm text-gray-500">{topText}</div> : null}
        {timeRemaining ? (
          <div className="text-sm text-gray-500">
            {estimateText} {timeRemaining} {pluralizeWord(timeRemaining, "minute")}.
          </div>
        ) : (
          <div className="text-sm text-gray-500">{missingTimeEstimateAltText}</div>
        )}
        <br />
        <div className="text-sm text-gray-500">{bottomText}</div>
      </div>
    </>
  );
}

export default LoadingDisplay;
