const MEDIA_BASE = "image_search";
const FILE_STORAGE_BASE = "file_storage";

export const DRIVE_ROUTES = {
  media: {
    default: `${MEDIA_BASE}/directory`,
    search: `${MEDIA_BASE}/search`,
    directory: {
      create: `${MEDIA_BASE}/directory/create`,
      getRoot: `${MEDIA_BASE}/directory`,
      get: (id: string) => `${MEDIA_BASE}/directory/${id}`,
      update: (id: string) => `${MEDIA_BASE}/directory/${id}`,
      delete: (id: string) => `${MEDIA_BASE}/directory/${id}`,
      move: (id: string) => `${MEDIA_BASE}/directory/move/${id}`,
    },
    file: {
      create: `${MEDIA_BASE}/file/create`,
      get: (id: string) => `${MEDIA_BASE}/file/${id}`,
      update: (id: string) => `${MEDIA_BASE}/file/${id}`,
      delete: (id: string) => `${MEDIA_BASE}/file/${id}`,
      move: (id: string) => `${MEDIA_BASE}/file/move/${id}`,
      moveMultiple: `${MEDIA_BASE}/files/move`,
      deleteMultiple: `${MEDIA_BASE}/files/delete`,
    },
  },
  document: {
    search: `${FILE_STORAGE_BASE}/directory/view/search`,
  },
} as const;
