import { deleteADoc } from "api/api";
import { Upload } from "lucide-react";
import { getProject } from "store/reducers/projectReducer";
import { InternalDocument } from "types/Project";
import { pluralizeWord } from "utils/helpers";
import { useLocalStorage } from "hook/useLocalStorage";
import { useNotification } from "context/notificationContext";
import { useOutletContext } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useCallback, useEffect, useRef, useState } from "react";
import { useFlags } from "hook/useFlags";

import axios from "axios";
import ProjectSpecificDocumentsTableRow from "./ProjectSpecificDocumentsTableRow";

const ProjectSpecific = () => {
  const { internalContractId } = useOutletContext<{
    internalContractId: string;
  }>();
  const internalContractDetails = useAppSelector((store) => store.project.activeProject);

  const dispatch = useAppDispatch();
  const flags = useFlags();

  const inputFile = useRef<HTMLInputElement | null>(null);
  const [allDocs, setAllDocs] = useState<InternalDocument[]>([]);
  const [deletingDocs, setDeletingDocs] = useState<string[]>([]);

  const { setToast } = useNotification();

  const onButtonClick = () => {
    if (inputFile && inputFile.current) inputFile.current.click();
  };

  const { localValue } = useLocalStorage("vultron_user_token", "");
  const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
  const { localValue: use_auth0 } = useLocalStorage("vultron_user_use_auth0");
  const useAuth0Header = use_auth0 === true;

  const acceptDocTypes = flags.enableOnsiteFeatures ? ".pdf" : ".pdf,.docx,.txt,.xlsx,.pptx";

  const autoUpload = (files: any) => {
    Promise.allSettled(
      files.map((it: any, i: number) => {
        const formData = new FormData();
        formData.append("file", it);
        formData.append("internal_upload_type", "0");

        return axios({
          method: "POST",
          url: "/contracts/internal/" + internalContractId + "/document/",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localValue}`,
            Workspace: `Workspace ${workspace_id}`,
            "X-Authorization-Auth0": JSON.stringify(useAuth0Header),
          },
        });
      }),
    )
      .then(() => {
        setTimeout(() => {
          setToast.success({
            msg: `Uploaded ${files.length} ${pluralizeWord("document", "documents", files.length)}`,
          });
        }, 500);
        dispatch(getProject({ internalContractId, triggerDocRefresh: true }));
      })
      .catch(() => {
        setToast.error({
          title: `Unable to upload ${pluralizeWord("document", "documents", files.length)}`,
          msg: `We were unable to upload the ${pluralizeWord(
            "document",
            "documents",
            files.length,
          )} due to a technical issue on our end. Please try uploading again. If the issue persists, contact support@vultron.ai for assistance.`,
        });
      });
  };

  const uploadFiles = (fileList: any) => {
    const files = [...fileList];
    const newFiles: any[] = [];
    files.forEach((file: any) => {
      if (allDocs.find((doc) => doc.name === file.name)) {
        return;
      } else {
        newFiles.push(file);
      }
    });
    autoUpload(newFiles);
  };

  useEffect(() => {
    if (internalContractDetails) {
      const government_sources = internalContractDetails?.government_source || [];
      const internal_documents = internalContractDetails?.internal_documents || [];
      const combined_sources = [...government_sources, ...internal_documents];
      combined_sources.sort((a, b) => {
        return new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf();
      });
      setAllDocs(combined_sources);
    }
  }, [
    internalContractDetails,
    internalContractDetails?.government_source,
    internalContractDetails?.internal_documents,
  ]);

  useEffect(() => {
    dispatch(getProject({ internalContractId, triggerDocRefresh: true }));
  }, [dispatch, internalContractId]);

  const deleteInternalDoc = useCallback(
    (id: string) => {
      if (deletingDocs.includes(id)) return;
      deleteADoc(id)
        .then(() => {
          dispatch(getProject({ internalContractId, triggerDocRefresh: true }));
          setTimeout(() => {
            setToast.success({
              msg: `Your document was deleted successfully`,
            });
            setDeletingDocs((prev) => prev?.filter((b) => b !== id));
          }, 500);
        })
        .catch((err) => {
          setToast.error({
            title: "Unable to delete document",
            msg: "We were unable to delete the document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
          });
          setDeletingDocs((prev) => prev?.filter((b) => b !== id));
        });
    },
    [deletingDocs, setToast, setDeletingDocs, dispatch, internalContractId],
  );

  return (
    <div className="p-6">
      <div className="flex">
        <div className="font-semibold text-lg mb-2 pl-1">Project Specific Documents</div>
      </div>
      <div className="text-gray-text text-sm pb-2 flex items-center gap-[0.2rem]">
        <span className="mr-2.5 pl-1">
          Upload documents such as the solicitation, market intelligence, meeting notes, and briefings to use in this
          project.
        </span>
      </div>
      <div className="flex flex-col ml-2 mt-4">
        {!!allDocs.length && (
          <>
            <button
              className="flex mb-6 gap-2 items-center justify-center rounded-md shadow-sm text-sm px-3 py-2 border border-gray-light w-[180px]"
              onClick={onButtonClick}
            >
              <Upload size={14} />
              Upload Documents
            </button>
            <input
              type="file"
              id="input"
              multiple
              ref={inputFile}
              className="hidden"
              accept={acceptDocTypes}
              onChange={(event) => uploadFiles(event.target.files)}
            />
          </>
        )}
        <div className="max-w-full overflow-auto">
          <table className="table-auto w-full">
            <thead className="text-sm bg-[#F0F4F8]">
              <tr className="text-left text-gray-500">
                <th className="px-6 py-3 font-light">File</th>
                <th className="px-4 py-3 font-light">Date Added</th>
                <th className="px-4 py-3 w-52 min-w-52 font-light">Type</th>
                <th className="px-4 py-3 font-light">Size</th>
                <th className="px-4 py-3 font-light">Status</th>
              </tr>
            </thead>
            {!!allDocs.length && (
              <tbody>
                {allDocs.map((doc) => {
                  return (
                    <ProjectSpecificDocumentsTableRow
                      key={doc.id}
                      doc={doc}
                      deletingDocs={deletingDocs}
                      deleteInternalDoc={deleteInternalDoc}
                      setDeletingDocs={setDeletingDocs}
                    />
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        {!allDocs.length && (
          <div className="min-h-[65vh] bg-white w-full flex justify-center items-center flex-col py-7 px-2">
            <div className="font-bold text-xl mb-1"> No Documents</div>
            <span className="text-gray-text text-sm mt-3">Add a project specific document</span>
            <button
              className="bg-gray-200 flex my-5  text-gray-600 py-1.5 px-2 text-sm items-center justify-center self-center rounded-md gap-2 w-[180px]"
              onClick={onButtonClick}
            >
              <Upload size={14} />
              Upload Documents
            </button>
            <input
              type="file"
              id="input"
              multiple
              ref={inputFile}
              className="hidden"
              onChange={(event) => uploadFiles(event.target.files)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectSpecific;
