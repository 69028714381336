import { Volume } from "types/Templates";
import {
  Section as ImmutableSection,
  Volume as ImmutableVolume,
  ComplianceMatrixRow as ImmutableComplianceMatrixRow,
} from "../CopilotSchemaImmutableTypes";
import {
  ComplianceMatrixRow,
  Storage,
  Framework,
  Section as LiveSection,
  Volume as LiveVolume,
} from "../CopilotSchemaTypes";
import { insert } from "utils/array";
import { REQUIREMENT_TABS_META } from "./constants";
import { TabSlug } from "./types";
import { filter, find, LiveList, LiveObject, some, update } from "YJSProvider/LiveObjects";
import { createSection, createVolume } from "utils/framework";

export const transformImmutableVolumesToYJS = (volumes: Volume[]): LiveObject<LiveVolume>[] => {
  const transformSections = (volume: Volume) =>
    volume.sections?.reduce<LiveObject<LiveSection>[]>((acc, sec) => {
      const { id, item: createdSection } = createSection({ title: sec.name });

      const createdSubsections = sec.subsections.map(
        (subsection) => createSection({ title: subsection.name, parent_id: id }).item,
      );
      return [...acc, createdSection, ...createdSubsections];
    }, []) || [];

  const liveVolumes = volumes.map((volume) => {
    const createdVolume = createVolume({ title: volume.name, sections: new LiveList(transformSections(volume)) });
    return createdVolume;
  });

  return liveVolumes;
};

export const transformFrameworkToSavePayload = (volumes: ImmutableVolume[]) => {
  const transformSections = (volume: ImmutableVolume) => {
    return (
      volume?.sections?.reduce<
        {
          name: string;
          subsections: { name: string }[];
        }[]
      >((acc, section) => {
        if (!section.parent_id) {
          const subsections =
            volume?.sections
              ?.filter(({ parent_id }) => parent_id === section.id)
              ?.map((sec) => ({ name: sec.title })) || [];
          return [...acc, { name: section.title, subsections }];
        }

        return acc;
      }, []) || []
    );
  };

  return volumes.map((volume) => ({
    name: volume.title,
    sections: transformSections(volume),
  }));
};

export const insertRequirementsIntoDestinationSection = (
  rowsInSection: LiveObject<ComplianceMatrixRow>[],
  activeRow: LiveObject<ComplianceMatrixRow>,
  destinationIndex: number,
) => {
  // check if ordering is set
  const doesNotHaveFullOrdering = rowsInSection.some(
    (row) => typeof row.get("requirement")?.get("section_order") !== "number",
  );

  // set order
  if (doesNotHaveFullOrdering) {
    rowsInSection.forEach((row, idx) => {
      const requirement = row?.get("requirement");
      requirement?.set("section_order", idx);
    });
  }

  rowsInSection.sort(
    (a, b) => (a.get("requirement")?.get("section_order") || 0) - (b.get("requirement")?.get("section_order") || 0),
  );

  return insert(rowsInSection, activeRow, destinationIndex);
};

export const resetSourceRequirementsOrder = (
  complianceMatrix: Storage["compliance_matrix"],
  activeRow: LiveObject<ComplianceMatrixRow>,
) => {
  const proposalReference = activeRow.get("proposal_reference") as ComplianceMatrixRow["proposal_reference"];
  const requirement = activeRow.get("requirement");
  const rowsInSourceSection = filter(
    complianceMatrix,
    (row) =>
      row?.get("proposal_reference").get("section_id") === proposalReference?.get("section_id") &&
      row?.get("requirement")?.get("id") !== requirement?.get("id"),
  );

  rowsInSourceSection.sort(
    (a, b) => (a.get("requirement")?.get("section_order") || 0) - (b.get("requirement")?.get("section_order") || 0),
  );
  rowsInSourceSection.forEach((row, idx) => {
    const requirement = row?.get("requirement");
    requirement?.set("section_order", idx);
  });

  return rowsInSourceSection;
};

type AddRequirementToSectionVariables = {
  complianceMatrix: Storage["compliance_matrix"];
  activeRow: LiveObject<ComplianceMatrixRow>;
  volumeList: Framework["volumes"];
  destinationSectionId: string;
  setSelectedSection?: (section: ImmutableSection) => void;
};

export const addRequirementToSection = ({
  complianceMatrix,
  activeRow,
  volumeList,
  destinationSectionId,
  setSelectedSection,
}: AddRequirementToSectionVariables) => {
  some(volumeList, (volume) => {
    const sections = volume.get("sections") as LiveVolume["sections"];
    if (!sections) return false;

    const foundDestinationSection = find(sections, (section) => section.get("id") === destinationSectionId);

    if (foundDestinationSection) {
      setSelectedSection?.(foundDestinationSection.toJSON() as ImmutableSection);

      const isSubsection = !!foundDestinationSection.get("parent_id");
      const rowsInSection = filter(
        complianceMatrix,
        (row) => row?.get("proposal_reference").get("section_id") === destinationSectionId,
      );
      const inserted = insertRequirementsIntoDestinationSection(rowsInSection, activeRow, rowsInSection?.length || 0);

      const proposalReference = activeRow.get("proposal_reference");
      const requirement = activeRow.get("requirement");
      if (proposalReference?.get("volume_id") && proposalReference?.get("section_id")) {
        resetSourceRequirementsOrder(complianceMatrix, activeRow);
      }

      if (proposalReference) {
        update(proposalReference, {
          volume_id: volume.get("id"),
          section_id: foundDestinationSection.get("id"),
          subsection_id: isSubsection ? foundDestinationSection.get("id") : "",
        });
      }

      requirement.set("disregarded", false);
      inserted.forEach((row, idx) => {
        row.get("requirement")?.set("section_order", idx);
      });

      return true;
    }

    return false;
  });
};

export const getTabWithResultCount = (activeTab: TabSlug, numResults: number) => {
  return {
    ...REQUIREMENT_TABS_META[activeTab],
    name: REQUIREMENT_TABS_META[activeTab].name + ` (${numResults})`,
  };
};

export const getTabResultCount = (tab: TabSlug, sheetRequirements: ImmutableComplianceMatrixRow[]) => {
  switch (tab) {
    case TabSlug.Assigned:
      return sheetRequirements.filter((row) => !row?.requirement?.disregarded && !!row.proposal_reference.section_id)
        ?.length;
    case TabSlug.Unassigned:
      return sheetRequirements.filter((row) => !row?.requirement?.disregarded && !row.proposal_reference.section_id)
        ?.length;
    default:
      return sheetRequirements.filter((row) => !!row?.requirement?.disregarded)?.length;
  }
};
