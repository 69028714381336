import { AvatarGroup } from "components/molecules/avatar-group";
import { uniqBy } from "lodash";
import { memo, useMemo } from "react";
import { useOthers } from "YJSProvider/createYJSContext";

const LiveOthers = ({ tab, avatarSize = 32 }: { tab: string; avatarSize?: number }) => {
  const others = useOthers();
  const avatars = useMemo(
    () =>
      uniqBy(
        others
          ?.filter(({ presence }) => presence.activePage === tab)
          ?.map(({ presence }) => ({
            id: presence.name || "",
            name: presence.name || "",
            src: "",
            alt: presence.name || "",
            size: avatarSize,
            tooltip: true,
          })),
        "id",
      ),
    [avatarSize, others, tab],
  );
  return <AvatarGroup maxCount={6} avatars={avatars || []} size={avatarSize} />;
};

export default memo(LiveOthers);
