import { debounce } from "lodash";
import { useMemo, useRef } from "react";

export const useDebouncedCallback = <T,>(callback: (val?: T) => void, delay: number) => {
  const callbackRef = useRef<(val?: T) => void>();
  callbackRef.current = callback;
  return useMemo(
    () =>
      debounce((arg?: T) => {
        callbackRef.current?.(arg);
      }, delay),
    [delay],
  );
};
