const GeneralInformation = ({ contractDetails }) => {
  return (
    <div className="border border-[#D8DDE2] shadow-sm p-4 rounded-lg mb-4 bg-white">
      <h3 className="text-base font-medium pb-3">General Information</h3>
      <div className="flex flex-col gap-4">
        <div className="flex gap-2 pt-2 w-full">
          <div className="flex flex-col gap-3 w-[50%]">
            <span className="text-[#5B6B79]">Published Date</span>
            <span className="flex gap-2 text-sm">{contractDetails?.publish_date || "-"}</span>
          </div>
          <div className="flex flex-col gap-3 w-[50%]">
            <span className="text-[#5B6B79]">Deadline</span>
            <span className="flex gap-2 text-sm">{contractDetails?.response_date || "-"}</span>
          </div>
        </div>
        <div className="flex flex-col gap-3 pt-2">
          <span className="text-[#5B6B79]">Product Code</span>
          <span className="text-sm">{contractDetails?.product_service_codes || "-"}</span>
        </div>
        <div className="flex flex-col gap-3 pt-2">
          <span className="text-[#5B6B79]">NAICS Code</span>
          {contractDetails?.naics_codes && contractDetails?.naics_codes.length > 0
            ? contractDetails?.naics_codes?.map((v, i) => (
                <span key={i} className="text-sm">
                  {v}
                </span>
              ))
            : "-"}
        </div>
        <div className="flex flex-col gap-3 pt-2">
          <span className="text-[#5B6B79]">Stage</span>
          <span className="text-sm">
            {contractDetails?.opportunity_stage === undefined || contractDetails?.opportunity_stage.length === 0
              ? "-"
              : contractDetails?.opportunity_stage}
          </span>
        </div>
        <div className="flex flex-col gap-3 pt-2">
          <span className="text-[#5B6B79]">Set Aside</span>
          <span className="text-sm">
            {contractDetails?.set_aside && contractDetails?.set_aside?.length > 0 ? contractDetails?.set_aside : "-"}
          </span>
        </div>
        <div className="flex flex-col gap-3 pt-2">
          <span className="text-[#5B6B79]">Place of Performance</span>
          <span className="text-sm">
            {contractDetails?.place_of_performance === undefined || contractDetails?.place_of_performance.length === 0
              ? "-"
              : contractDetails?.place_of_performance}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GeneralInformation;
