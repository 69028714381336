export const scrollToTemplateManager = (id: string, isVolume?: boolean, sectionId?: string) => {
  const scrollContainer = document.getElementById("template-manager-section-scroll");
  let requirementScrollOffset = 0;
  const yOffset = isVolume ? -60 : -102;

  const element = document.getElementById(id);

  if (!element || !scrollContainer) return;

  if (sectionId) {
    const sectionHeader = document.querySelector(`.section-header-${sectionId}`);
    if (sectionHeader) {
      requirementScrollOffset = sectionHeader.getBoundingClientRect().height;
    }
  }

  const y = element.getBoundingClientRect().top + scrollContainer.scrollTop + yOffset - requirementScrollOffset;
  scrollContainer.scrollTo({ top: y, behavior: "smooth" });
};
