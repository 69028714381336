import { PropsWithChildren, ReactNode, createContext, useCallback, useContext, useMemo, useState } from "react";
import * as Toast from "@radix-ui/react-toast";
import { NotificationToast } from "components/atoms/notification-toast";

interface NotificationContextState {
  setToast: {
    success: (state: Omit<NotificationToastState, "variant">) => void;
    warning: (state: Omit<NotificationToastState, "variant">) => void;
    error: (state: Omit<NotificationToastState, "variant">) => void;
    dark: (state: Omit<NotificationToastState, "variant">) => void;
  };
  clearToast: () => void;
  notification?: Partial<NotificationToastState>;
}

const NotificationContext = createContext<NotificationContextState>({} as NotificationContextState);

export enum NotificationType {
  Success = 1,
  Error,
  Warning,
  Dark,
}

type NotificationToastState = {
  variant: NotificationType;
  title?: ReactNode;
  msg?: ReactNode;
  duration?: number;
};

const NotificationsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [notification, setNotification] = useState<NotificationContextState["notification"]>();

  const handleAddToast = useCallback((state: NotificationToastState) => {
    setNotification(state);
  }, []);

  const setToast: NotificationContextState["setToast"] = useMemo(
    () => ({
      success: (state) => handleAddToast({ ...state, variant: NotificationType.Success }),
      warning: (state) => handleAddToast({ ...state, variant: NotificationType.Warning }),
      error: (state) => handleAddToast({ ...state, variant: NotificationType.Error }),
      dark: (state) => handleAddToast({ ...state, variant: NotificationType.Dark }),
    }),
    [handleAddToast],
  );

  const clearToast = useCallback(() => setNotification(undefined), []);

  return (
    <NotificationContext.Provider value={{ setToast, notification, clearToast }}>
      <Toast.Provider swipeDirection="up">
        <NotificationToast />
        {children}
      </Toast.Provider>
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);

export default NotificationsProvider;
