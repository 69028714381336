/** @jsxImportSource @emotion/react */

import {
  Root,
  Trigger,
  Portal,
  Content,
  TooltipProps,
  TooltipContentProps,
  PortalProps,
} from "@radix-ui/react-tooltip";
import { PropsWithChildren, ReactNode, forwardRef } from "react";
import "twin.macro";

interface Props extends TooltipProps {
  content: ReactNode;
  contentProps?: TooltipContentProps;
  portalProps?: PortalProps;
  disabled?: boolean;
}
const Tooltip = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children, content, contentProps, portalProps, disabled, ...props }, ref) => (
    <Root delayDuration={350} {...props}>
      <Trigger asChild>{children}</Trigger>
      {!disabled && (
        <Portal {...portalProps}>
          <Content
            ref={ref}
            tw="z-[999] text-xs break-words max-w-[300px] px-3 py-2 mx-2 shadow rounded-md bg-white border border-zinc-200"
            sideOffset={5}
            {...contentProps}
          >
            {content}
          </Content>
        </Portal>
      )}
    </Root>
  ),
);

export default Tooltip;
