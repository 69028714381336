import classNames from "classnames";

type Props = {
  children: React.ReactNode;
  noGap?: boolean;
};

export function ToolbarGroup({ children, noGap }: Props) {
  return <div className={classNames("flex flex-wrap", !noGap && "gap-2")}>{children}</div>;
}
