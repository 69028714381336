/** @jsxImportSource @emotion/react */

import { FormItem } from "types/Capture";
import tw from "twin.macro";
import { useCallback, useEffect, useState } from "react";
import {
  insertAssistantFiles,
  removeAllAssistantFiles,
  setAssistantPrompt,
  setHideBlock,
} from "store/reducers/writing-assistant/writingAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useAssistant } from "components/copilot/writing-assistant-drawer/writing-assistant-input/hooks";
import { toggleAssistant } from "store/reducers/copilot/copilotDrawerReducer";
import AttachmentsPopover from "components/molecules/attachments-popover";
import { BaseFile } from "types/FileStorage";
import { uniq } from "lodash";
import VoiceTranslateItem from "components/voice-assist/VoiceTranslateItem";
import { Paperclip, RefreshCw } from "lucide-react";
import { SUGGESTED_ANSWER_PROMPT, SUGGESTED_ANSWER_ONSITE_PROMPT, CAPTURE_CUSTOMER_ONSITE_PROMPT } from "./constants";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { useFlags } from "hook/useFlags";
import { useParams } from "react-router-dom";
import { CaptureSchemaType } from "types/Capture";

type Props = {
  formItem: FormItem;
  localResponse: string;
  setLocalResponse: (response: string) => void;
};

const FormItemRowActions = ({ formItem, localResponse, setLocalResponse }: Props) => {
  const dispatch = useAppDispatch();
  const { submitMessage } = useAssistant();
  const { streamState } = useAppSelector((store) => store.writingAssistant);
  const { question } = formItem;
  const [dispatchComplete, setDispatchComplete] = useState(false);
  const [localFiles, setLocalFiles] = useState<BaseFile[]>([]);
  const flags = useFlags();
  const { schemaType } = useParams<{ schemaType: string }>();

  const handleRequestSuggestedAnswer = (question: string) => {
    // customer capture questions require a different prompt (since we cant summarize LLMS documents)
    const capturePrompt = flags.enableOnsiteFeatures
      ? CaptureSchemaType.Customer.valueOf().includes(schemaType || "")
        ? CAPTURE_CUSTOMER_ONSITE_PROMPT
        : SUGGESTED_ANSWER_ONSITE_PROMPT
      : SUGGESTED_ANSWER_PROMPT;
    const assistantPrompt = `${capturePrompt} ${question}`;
    dispatch(setAssistantPrompt(assistantPrompt));
    dispatch(removeAllAssistantFiles());
    dispatch(
      insertAssistantFiles(
        localFiles.map(({ id, name, file_extension_type }) => ({ id, name, fileExtensionType: file_extension_type })),
      ),
    );
    dispatch(setHideBlock(true));
    setDispatchComplete(true);
  };

  useEffect(() => {
    if (dispatchComplete) {
      submitMessage();
      dispatch(toggleAssistant(true));
      setDispatchComplete(false);
      setLocalFiles([]);
    }
  }, [dispatch, dispatchComplete, submitMessage]);

  const handleClear = useCallback(
    (files: BaseFile[]) =>
      setLocalFiles((prev) => prev.filter((prevFile) => !files.find((file) => file.id === prevFile.id))),
    [],
  );

  const handleSelectedFiles = useCallback(
    (checked: boolean, files: BaseFile[]) => {
      if (checked) {
        setLocalFiles((prev) => uniq([...prev, ...files]));
      } else {
        setLocalFiles((prev) => prev.filter((file) => !files.find((selectedFile) => selectedFile.id === file.id)));
      }
    },
    [setLocalFiles],
  );

  const handleTranslation = (message: string) => {
    setLocalResponse(localResponse + message);
  };

  const disableGenerate = localFiles.length === 0 || streamState.isStreamingInProgress;
  const tooltipContent = streamState.isStreamingInProgress
    ? "Please wait for the active response generation in the assistant to complete."
    : "Select one or more documents to generate an answer.";

  return (
    <div className=" bg-white border-t border-gray-200 bottom-0 left-0 right-0 flex items-center justify-end gap-2 px-2 py-2">
      <VoiceTranslateItem
        hoverBg="gray-200"
        rounded="rounded"
        styles={tw`text-gray-900 duration-150 h-[26px] border border-gray-200 bg-white! hover:bg-hover-default!`}
        onComplete={handleTranslation}
      />
      <AttachmentsPopover
        handleSelectedFiles={handleSelectedFiles}
        onClear={handleClear}
        selectedFiles={localFiles.map(({ id }) => id)}
      >
        <div className="relative border border-gray-200 px-2.5 py-1 text-xs rounded z-10 gap-1.5 flex items-center justify-center cursor-pointer bg-white hover:bg-hover-default">
          {!!localFiles.length && (
            <div
              className="bg-gray-900 text-white font-medium shadow-sharp-full rounded-full flex justify-center items-center absolute -top-2 -right-1.5 z-1"
              css={[String(localFiles?.length || 0).length > 2 ? tw`w-4 h-4 text-[8px]` : tw`w-3.5 h-3.5 text-[9px]`]}
            >
              {localFiles.length}
            </div>
          )}
          <Paperclip size={12} />
          Documents
        </div>
      </AttachmentsPopover>
      <Tooltip disabled={!disableGenerate} content={tooltipContent}>
        <button
          onClick={() => handleRequestSuggestedAnswer(question)}
          className="border border-gray-200 px-2.5 py-1 text-xs rounded z-10 bg-gray-100  gap-1.5 flex items-center justify-center hover:bg-gray-200 disabled:opacity-50 disabled:hover:bg-gray-100"
          disabled={disableGenerate}
        >
          <RefreshCw size={12} />
          Generate Answer
        </button>
      </Tooltip>
    </div>
  );
};

export default FormItemRowActions;
