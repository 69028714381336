import { Editor } from "@tiptap/react";
import { useState, useEffect } from "react";
import { findMarkInRange } from "../utils";
import { useOnEditorTransaction } from "hook/useOnEditorTransaction";
import { commentPluginHighlightKey } from "../CommentsPluginHighlight";

interface CommentThreadEditorDetails {
  isActive: boolean;
  domNode: HTMLElement;
}
export const useThread = (editor: Editor | undefined | null, threadId: string) => {
  const [thread, setThread] = useState<CommentThreadEditorDetails | null>(null);

  useEffect(() => {
    if (!editor) return;
    const mark = findMarkInRange(
      editor.state.doc,
      0,
      editor.state.doc.nodeSize - 2,
      (m) => m.type.name === "comment" && m.attrs.id === threadId,
    );
    if (!mark) return setThread(null);

    try {
      const threadDom = editor.view.domAtPos(mark?.pos);

      if (!(threadDom.node instanceof HTMLElement)) return setThread(null);

      setThread({
        isActive: commentPluginHighlightKey.getState(editor.state)?.activeCommentId === threadId,
        domNode: threadDom.node,
      });
    } catch (e) {
      console.error("Could not get thread dom node");
    }
  }, [editor?.state, threadId]);

  return thread;
};

export const useActiveThread = (editor: Editor | undefined | null) => {
  const [activeThread, setActiveThread] = useState<string | undefined | null>(
    editor ? commentPluginHighlightKey.getState(editor.state)?.activeCommentId : undefined,
  );

  useOnEditorTransaction(editor, (_tr, editor) => {
    const activeCommentId = commentPluginHighlightKey.getState(editor.state)?.activeCommentId;
    setActiveThread(activeCommentId || undefined);
  });

  return activeThread;
};
