export function getOS(): "mac" | "windows" | "linux" | "unknown" {
  const platform = navigator.platform.toLowerCase();
  const userAgent = navigator.userAgent.toLowerCase();

  if (platform.includes("mac") || userAgent.includes("mac")) return "mac";
  if (platform.includes("win") || userAgent.includes("win")) return "windows";
  if (platform.includes("linux")) return "linux";

  return "unknown";
}

export function getShortcutKey(): string {
  const os = getOS();
  return os === "mac" ? "⌘" : "Ctrl";
}
