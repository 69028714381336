import { Editor } from "@tiptap/react";
import { useCallback } from "react";
import { Select } from "../primitives/Select";

const FONT_FAMILY_OPTIONS = [
  { value: "Arial, sans-serif", title: "Arial" },
  { value: "Times New Roman, serif", title: "Times New Roman" },
  { value: "Verdana, sans-serif", title: "Verdana" },
  { value: "Georgia, serif", title: "Georgia" },
  { value: "Tahoma, sans-serif", title: "Tahoma" },
  { value: "Courier New, monospace", title: "Courier New" },
];

const DEFAULT_FONT_FAMILY = "Arial, sans-serif";

type Props = {
  editor: Editor;
};

export function ToolbarFontFamily({ editor }: Props) {
  const onFontFamilyChange = useCallback(
    (value: string) => {
      if (!editor) {
        return;
      }
      editor.chain().focus().setFontFamily(value).run();
    },
    [editor],
  );

  return (
    <Select
      variant="subtle"
      value={getCurrentFontSize(editor)}
      initialValue={DEFAULT_FONT_FAMILY}
      items={FONT_FAMILY_OPTIONS}
      onChange={onFontFamilyChange}
      className="w-28"
    />
  );
}

function getCurrentFontSize(editor: Editor) {
  return editor.getAttributes("textStyle").fontFamily || DEFAULT_FONT_FAMILY;
}
