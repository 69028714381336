/** @jsxImportSource @emotion/react */

import { removeAllAssistantFiles, setAssistantPrompt } from "store/reducers/ai-assistant/aiAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import AIAssistantInputActions from "../AIAssistantInputActions";
import { useLayoutEffect, useRef, useState } from "react";
import tw from "twin.macro";
import TextareaAutosize from "react-textarea-autosize";
import Popover from "components/atoms/popover";
import { ArrowUp, Files, Square, X } from "lucide-react";
import FileChip from "./FileChip";
import InternetChip from "./InternetChip";
import { useAssistant } from "./hooks";

const AIAssistantInput = () => {
  const { prompt, selectedFiles, streamState, enableInternet, activeSession } = useAppSelector(
    (root) => root.aiAssistantState,
  );
  const { isStreamingInProgress } = streamState;
  const promptRef = useRef<HTMLTextAreaElement | null>(null);
  const dispatch = useAppDispatch();
  const { submitMessage, abortConnection } = useAssistant();
  const canSubmit = !!prompt.trim() && !isStreamingInProgress;

  useLayoutEffect(() => {
    promptRef.current?.focus();
  }, [activeSession?.id]);

  return (
    <div className="relative flex flex-col pb-6 gap-2 mx-4 w-full lg:px-2 lg:mx-auto lg:max-w-2xl xl:max-w-3xl 2xl:max-w-[900px] 3xl:max-w-[1100px]">
      <div className="flex bg-white flex-col rounded-lg border border-gray-light shadow-sm overflow-hidden">
        {(!!selectedFiles.length || enableInternet) && (
          <div className="flex items-center gap-2 pr-3 pb-1 pl-4 pt-3.5">
            {!!selectedFiles.length && <FileWrap />}
            {enableInternet && <InternetChip />}
          </div>
        )}
        <div className="relative flex">
          <TextareaAutosize
            ref={promptRef}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                submitMessage();
              }
            }}
            readOnly={isStreamingInProgress}
            autoFocus
            minRows={1}
            maxRows={12}
            value={prompt}
            className="text-slate-700 text-base pr-12 py-3.5 pl-4 flex-1 outline-none resize-none w-full"
            placeholder="Chat with Vultron..."
            onChange={(e) => dispatch(setAssistantPrompt(e.target.value))}
          />
          <button
            className="bg-black absolute text-sm bottom-2.5 right-3 flex items-center justify-center text-white w-[32px] min-w-[32px] h-[32px] min-h-[32px] duration-150 rounded-lg disabled:bg-gray-200 disabled:text-slate-400 disabled:cursor-default"
            onClick={() => {
              if (isStreamingInProgress) {
                abortConnection();
              } else {
                submitMessage();
              }
            }}
            disabled={!isStreamingInProgress ? !canSubmit : undefined}
          >
            {isStreamingInProgress ? <Square size={16} className="fill-current" /> : <ArrowUp size={20} />}
          </button>
        </div>
      </div>
      <AIAssistantInputActions />
    </div>
  );
};

export default AIAssistantInput;

const FileWrap = () => {
  const [fileMenuOpen, setFileMenuOpen] = useState(false);
  const ref = useRef(null);
  const { selectedFiles } = useAppSelector((root) => root.aiAssistantState);
  const dispatch = useAppDispatch();

  return (
    <div ref={ref} className="relative flex flex-row flex-nowrap duration-200 max-w-[61px]">
      {selectedFiles.length === 1 ? (
        <div className="fade-in-container flex flex-row gap-1">
          {selectedFiles.map((file, idx) => (
            <FileChip key={file.id} fileMenuOpen={fileMenuOpen} idx={idx} file={file} />
          ))}
        </div>
      ) : (
        <Popover
          portalProps={{ container: ref.current }}
          open={fileMenuOpen}
          onOpenChange={setFileMenuOpen}
          contentProps={{
            side: "top",
            align: "start",
            alignOffset: -8,
            sideOffset: 8,
            css: [tw`bg-white shadow-md border border-gray-200 m-0`],
          }}
          content={
            <div className="relative px-3 py-3 max-h-[60vh] overflow-y-auto flex flex-col-reverse gap-2">
              {selectedFiles.map((file, idx) => (
                <FileChip key={file.id} fileMenuOpen={fileMenuOpen} isMultiple idx={idx} file={file} />
              ))}
            </div>
          }
        >
          <button className="relative duration-100 flex gap-1 items-center px-2 py-[3px] min-w-fit h-[28px] text-xs rounded bg-white border border-light shadow-sm text-gray-600 hover:bg-neutral-50 hover:border-gray-silver">
            <Files size={14} className="text-sm text-action" />
            <div className="max-w-[120px] truncate">{selectedFiles.length} Files</div>
            <button
              onClick={() => dispatch(removeAllAssistantFiles())}
              className="absolute flex items-center justify-center w-5 h-5 -right-2 -top-2 rounded-full bg-[rgba(225,225,225,0.8)] duration-150 text-lg hover:bg-[rgba(215,215,215,0.8)] active:bg-[rgba(190,190,190,0.8)] [backdrop-filter:blur(1px)]"
            >
              <X size={14} />
            </button>
          </button>
        </Popover>
      )}
    </div>
  );
};
