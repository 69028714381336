/** @jsxImportSource @emotion/react */

import { CommentThread, CommentsFilterState, ThreadContext } from "../types";
import { CommentView } from "./CommentView";
import { CommentEditor } from "./CommentEditor/CommentEditor";
import { useCommentOperations } from "../../../api/comments/useCommentOperations";
import { useThread } from "./useThread";
import { CircleCheck, RotateCcw, Trash2 } from "lucide-react";
import { v4 } from "uuid";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import Tooltip from "components/atoms/tooltip/Tooltip";
import moment from "moment";
import { Editor } from "@tiptap/react";
import { setRequirementsState } from "store/reducers/draft/sectionReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ControlItemSlug } from "pages/draft-section/hooks";
import Avatar from "components/molecules/avatar";
import tw from "twin.macro";
import CommentOptions from "./CommentOptions";
import copyText from "utils/copyText";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";

interface ThreadProps {
  editorId: string | undefined;
  thread: CommentThread;
  internalContractId: string;
  context: ThreadContext;
  editor?: Editor | null;
  isActiveThread?: boolean;
  referenceId?: string;
  activeCommentId?: string | null;
  filterState?: CommentsFilterState;
  setDisableScroll: React.Dispatch<React.SetStateAction<boolean>>;
  initialActiveThreadIdRef: MutableRefObject<string | null>;
}

export const Thread = ({
  thread,
  context,
  internalContractId,
  referenceId,
  isActiveThread,
  editor,
  editorId,
  activeCommentId,
  filterState,
  setDisableScroll,
  initialActiveThreadIdRef,
}: ThreadProps) => {
  const [searchParams] = useSearchParams();
  const editorThread = useThread(editor, thread.id);
  const threadRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((store) => store.auth.currentUser);
  const isCommenter = currentUser?.id === thread.commenter_id;
  const navigate = useNavigate();
  const activeFromFocusRef = useRef(false);
  const isFocused = document.activeElement === threadRef.current;
  const { createCommentMutation, resolveThreadMutation, deleteThreadMutation } = useCommentOperations(
    internalContractId,
    referenceId,
  );
  const unread = !thread.is_read;
  const [commentIsEditable, setCommentIsEditable] = useState(false);
  const authorIsVultron = thread.comments[0].commenter.is_vultron;

  useEffect(() => {
    // scroll to active thread
    if (isActiveThread && threadRef.current) {
      if (activeFromFocusRef.current) return;
      setTimeout(
        () =>
          threadRef.current?.scrollIntoView({
            behavior: "smooth",
          }),
        100,
      );
    }
    activeFromFocusRef.current = false;
  }, [isActiveThread]);

  const sectionId = editorId?.split("$")[0];
  const requirementId = editorId?.split("$")[1];
  const handleFocus = () => {
    if (context === ThreadContext.DRAFT) {
      navigate(`sections/${sectionId}/${ControlItemSlug.requirements}?${searchParams.toString()}`);
      if (requirementId) dispatch(setRequirementsState({ expandedRequirementIds: [requirementId] }));
    }
    editorThread?.domNode.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    if (filterState === CommentsFilterState.Open) {
      editor?.commands.setActiveComment(thread.id);
      activeFromFocusRef.current = true;
    }
  };

  const handleResolve = () => {
    resolveThreadMutation.mutate({
      thread_id: thread.id,
      editor,
      resolved: !thread.resolved,
    });
  };

  const formattedDate = moment(thread.created_at).isSame(new Date(), "day")
    ? "Today"
    : moment(thread.created_at).format("MMMM D, YYYY");

  useEffect(() => {
    // set the active comment in the editor when the thread is focused
    if (!editor || !isFocused) return;
    if (filterState === CommentsFilterState.Open) {
      editor.commands.setActiveComment(thread.id);
    }
  }, [editor, thread.id, isFocused, filterState]);

  useEffect(() => {
    if (!initialActiveThreadIdRef.current) return;
    if (thread.id === initialActiveThreadIdRef.current) {
      threadRef.current?.focus();
      initialActiveThreadIdRef.current = null;
    }
  }, []);

  const handleBlur = () => {
    editor?.commands.setActiveComment(undefined);
  };

  const [threadComment, ...otherComments] = thread.comments;

  return (
    <div
      className="flex flex-col w-full h-fit p-4 border-gray-300 border-b shadow-sm gap-2 transition-background group focus:outline-none hover:bg-gray-50 focus:bg-gray-50 focus-within:bg-gray-50"
      css={[isActiveThread ? tw`bg-gray-50` : tw`bg-white`]}
      tabIndex={-1}
      onFocus={handleFocus}
      onBlur={handleBlur}
      ref={threadRef}
    >
      <div className="flex flex-1 flex-row justify-between items-start gap-5 mb-0.5">
        <div className="flex flex-row items-center gap-2">
          {unread && <div className="w-2 h-2 bg-[#E8C969] rounded-full" />}
          <span className="text-sm font-medium text-gray-light">{formattedDate}</span>
        </div>
        <div
          className="flex flex-row gap-0.5 items-center text-gray-light opacity-0 group-hover:opacity-100"
          onFocus={(e) => e.stopPropagation()}
        >
          <Tooltip content={`${thread.resolved ? "Reopen" : "Resolve"} thread`}>
            <button
              onClick={handleResolve}
              onMouseDown={(e) => e.preventDefault()}
              className="duration-100 p-1 rounded-md hover:bg-gray-200 hover:text-gray-darkest"
              // added to prevent a click when the editor is undefined
              disabled={!editor}
            >
              {thread.resolved ? <RotateCcw size={18} /> : <CircleCheck size={18} />}
            </button>
          </Tooltip>
          {(isCommenter || authorIsVultron) && (
            <Tooltip content="Delete thread">
              <button
                onClick={() => {
                  triggerConfirm({
                    header: `Are you sure you want to delete this thread?`,
                    body: "This action is irreversible and will delete all comments within this thread.",
                  }).then((proceed) => {
                    if (proceed) {
                      deleteThreadMutation.mutate({ editor, thread_id: thread.id });
                    }
                  });
                }}
                onMouseDown={(e) => e.preventDefault()}
                className="duration-100 p-1 rounded-md hover:bg-gray-200 hover:text-gray-darkest"
                // added to prevent a click when the editor is undefined
                disabled={!editor}
              >
                <Trash2 size={18} className="stroke-[1.6]" />
              </button>
            </Tooltip>
          )}
          <CommentOptions
            isThread
            authorIsVultron={authorIsVultron}
            isCommenter={isCommenter}
            onEdit={() => {
              setCommentIsEditable(true);
            }}
            onDelete={() => {
              triggerConfirm({
                header: `Are you sure you want to delete this thread?`,
                body: "This action is irreversible and will delete all comments within this thread.",
              }).then((proceed) => {
                if (proceed) {
                  deleteThreadMutation.mutate({ editor, thread_id: thread.id });
                }
              });
            }}
            onCopyLink={() => {
              const url = new URL(window.location.href);
              url.searchParams.set("threadId", thread.id);
              copyText(url.href);
            }}
          />
        </div>
      </div>
      <CommentView
        comment={threadComment}
        commentIsEditable={commentIsEditable}
        setCommentIsEditable={setCommentIsEditable}
        threadId={thread.id}
        resolved={thread.resolved}
        key={threadComment.id}
        internalContractId={internalContractId}
        referenceId={referenceId}
        quote_text={thread.quote_text}
        isActive={activeCommentId === threadComment.id}
        isLastComment={!otherComments.length}
        setDisableScroll={setDisableScroll}
      />

      {otherComments?.map((comment, idx) => (
        <CommentView
          comment={comment}
          threadId={thread.id}
          resolved={thread.resolved}
          key={comment.id}
          internalContractId={internalContractId}
          referenceId={referenceId}
          isActive={activeCommentId === comment.id}
          setDisableScroll={setDisableScroll}
          isLastComment={idx === otherComments.length - 1}
          showCommentOptions
        />
      ))}
      {!thread.resolved && (
        <div className="flex items-center gap-2 max-w-full">
          <Avatar size={22} textColor="#FFFFFF" id={currentUser?.id} name={currentUser?.username} />
          <div className="flex-grow min-w-0">
            <CommentEditor
              readonly={false}
              onAddComment={(content) => {
                createCommentMutation.mutate({ content, thread_id: thread.id, new_comment_id: v4(), context });
                return true;
              }}
              placeholder="Reply..."
            />
          </div>
        </div>
      )}
    </div>
  );
};
