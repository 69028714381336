import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonProps } from "react-loading-skeleton";
import { HTMLProps, PropsWithChildren } from "react";

const Wrapper = ({
  children,
  height,
  width,
  ...props
}: PropsWithChildren<{ height?: string | number; width?: string | number }>) => {
  return (
    <div style={{ height, width, lineHeight: 0 }} {...props}>
      {children}
    </div>
  );
};

interface Props extends SkeletonProps {
  wrapperProps?: HTMLProps<HTMLDivElement>;
}

const LoadingSkeleton = ({ wrapperProps, ...rest }: Props) => {
  return (
    <Skeleton
      wrapper={(props) => <Wrapper {...props} {...wrapperProps} height={rest.height} width={rest.width} />}
      {...rest}
    />
  );
};

export default LoadingSkeleton;
