import { useLayoutEffect } from "react";

export const useScrollToLoader = (isLoading: boolean) => {
  useLayoutEffect(() => {
    if (isLoading) {
      const outlineScrollContainerNode = document.getElementById("generation-outline-editor-scroll-container");
      if (outlineScrollContainerNode)
        outlineScrollContainerNode.scrollTo({ top: outlineScrollContainerNode.scrollHeight, behavior: "smooth" });
    }
  }, [isLoading]);
};
