import { ComponentProps } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import VolumeCard from "./VolumeCard";
import { useAppSelector } from "store/storeTypes";

const SortableItem = (props: ComponentProps<typeof VolumeCard>) => {
  const isDisabled = useAppSelector((store) => store.volumeState.volumeEditable) === props.volume.id;

  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.volume.id || "",
    disabled: isDisabled,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return (
    <VolumeCard ref={setNodeRef} style={style} withOpacity={isDragging} {...props} {...attributes} {...listeners} />
  );
};

export default SortableItem;
