import { Editor } from "@tiptap/react";
import { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "../Toolbar.module.css";
import { changeToneToggleAction, resetEditorResponse, selectedTextAction } from "store/reducers/aiReducerSlice";
import {
  isTextAreaHaveValueAction,
  toggleAskAiInputWthDrpDownAction,
  typewrtierDivFinishesTypingAction,
} from "store/reducers/yjs-editor-reducer";
import { TextSelection } from "@tiptap/pm/state";

type AskAiPortalProps = {
  editor: Editor;
  top: number;
  left: number;
  typewriterDiv?: HTMLElement | null;
  scrollDiv?: HTMLElement | null;
  proseElement?: HTMLElement | null;
  children: ReactNode;
};

const AskAiPortal = ({ editor, proseElement, scrollDiv, typewriterDiv, top, left, children }: AskAiPortalProps) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement | null>(null);
  const [initialPosition, setInitialPosition] = useState<{ top: number; left: number } | null>(null);
  const [selectedArea, setSelectedArea] = useState<DOMRect | null>(null);
  const { selectedText, editorPrevSelection, typingFinished, prevDomSelection, loader } = useSelector(
    (state: any) => state.yjsEditor,
  );

  const handleOpnDrpDwon = () => {
    const el = ref.current;
    const currentSelection = editor.state.selection;

    if (!el) {
      return;
    }
    const editorRect = proseElement?.getBoundingClientRect() || prevDomSelection.proseElement?.getBoundingClientRect();
    if (
      !currentSelection ||
      currentSelection.empty || // If the selection is empty
      currentSelection.from === currentSelection.to // If the selection is collapsed
    ) {
      el.removeAttribute("style");
      dispatch(toggleAskAiInputWthDrpDownAction(false));
      dispatch(typewrtierDivFinishesTypingAction(false));
      dispatch(isTextAreaHaveValueAction(false));
      dispatch(changeToneToggleAction(false));
      dispatch(selectedTextAction(""));
      dispatch(resetEditorResponse());
      setSelectedArea(null);
      if (!initialPosition) {
        const domRange = window.getSelection()?.getRangeAt(0);
        const selectedParagraph = domRange?.startContainer.parentElement;
        if (selectedParagraph && editorRect && scrollDiv) {
          const rect = selectedParagraph.getBoundingClientRect();
          setInitialPosition({
            top: rect.bottom - editorRect.top - top,
            left: rect.left + scrollDiv.scrollLeft - left,
          });
        }
      }
      const startPos = editorPrevSelection.from;
      const endPos = editorPrevSelection.to;

      try {
        const tr = editor.state.tr.removeMark(startPos, endPos, editor.schema.marks.highlight);
        editor.view.dispatch(tr);
        const newSelection = TextSelection.create(editor.state.doc, startPos, endPos);
        const transaction = editor.state.tr.setSelection(newSelection);
        editor.view.dispatch(transaction);
      } catch (e) {
        if (e instanceof TypeError) {
          // Just ignore it because removeMark may throw a TypeError brought by 3rd party library
        } else {
          throw e;
        }
      }
      localStorage.removeItem("selectedAreaData");
      return;
    } else {
      const selectedElements: HTMLElement[] = [];
      const selection = window.getSelection() || prevDomSelection.domSelection;

      // testing starts here
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const container = range.commonAncestorContainer;

        if (container instanceof HTMLElement) {
          // Iterate through child nodes to find selected elements
          const walker = document.createTreeWalker(container, NodeFilter.SHOW_ELEMENT, {
            acceptNode: (node) => {
              const element = node as HTMLElement;
              if (range.intersectsNode(element)) {
                return NodeFilter.FILTER_ACCEPT;
              }
              return NodeFilter.FILTER_SKIP;
            },
          });

          while (walker.nextNode()) {
            selectedElements.push(walker.currentNode as HTMLElement);
          }
        }
      }

      //    selectedElements;

      // Check if the current node at the selection point is one of the supported block types
      const domSelection = window.getSelection() || prevDomSelection.domSelection;
      const domRange = domSelection && domSelection.getRangeAt(0);
      let selectedParagraph: HTMLElement | null = null;
      let isInsideList = false;
      const paragraphTags = ["p", "li", "ul", "ol"];

      for (const tag of paragraphTags) {
        const elements = Array.from(document.querySelectorAll(tag));
        const matchingElements = elements.filter((element) => {
          const range = document.createRange();
          range.selectNode(element);
          return selection.containsNode(element, true) || range.intersectsNode(element);
        });

        if (matchingElements.length > 0) {
          selectedParagraph = matchingElements[matchingElements.length - 1] as HTMLElement;
          if (tag === "ul" || tag === "ol") {
            isInsideList = true;
            break;
          } else if (tag === "p") {
            const closestList = selectedParagraph.closest("ul, ol");
            if (closestList) {
              isInsideList = true;
            }
            break;
          }
        }
      }

      if (isInsideList) {
        // Handle the case when <p> is inside <ul> or <ol>
        // console.log("Inside <ul> or <ol>");
        // console.log("Selected Paragraph:", selectedParagraph);
      } else {
        const paragraphTags = ["h1", "h2", "h3", "h4", "h5", "h6", "p", "li"];

        if (selectedElements.length !== 0) {
          const closestTag = selectedElements[selectedElements.length - 1].closest("h1, h2, h3, h4, h5, h6, ul, ol, p");
          selectedParagraph = closestTag as HTMLElement;
        } else {
          for (const tag of paragraphTags) {
            selectedParagraph = domRange.startContainer.parentElement?.closest(tag) as HTMLElement;
            if (selectedParagraph) {
              break; // Found a matching tag, no need to continue searching
            }
          }
        }
      }

      if (!initialPosition && scrollDiv) {
        // Calculate the initial position based on the selected paragraph
        const rect = selectedParagraph?.getBoundingClientRect();
        if (rect) {
          setInitialPosition({
            top: rect.bottom - editorRect.top - top,
            left: rect.left + scrollDiv.scrollLeft - left,
          });
        }
      }

      if (selectedParagraph && editorPrevSelection) {
        const rect = selectedParagraph.getBoundingClientRect();
        if (rect.bottom > 0 && !selectedArea) {
          if (!rect) {
            return;
          }
          if (scrollDiv) {
            setSelectedArea({
              bottom: rect.bottom,
              height: rect.height,
              left: rect.left + scrollDiv.scrollLeft,
              right: rect.right,
              top: rect.top + scrollDiv.scrollTop,
              width: rect.width,
              x: rect.x,
              y: rect.y,
            } as DOMRect);
          }
        }
      }
      if (typewriterDiv) {
        const marginTop = typewriterDiv.offsetHeight + 24;
        const currentMarginTop = parseInt(el.style.marginTop) || 0;

        if (marginTop !== currentMarginTop) {
          el.style.marginTop = `${marginTop}px`;
        }
      }
    }
  };

  useEffect(() => {
    handleOpnDrpDwon();

    return () => {};
  }, [selectedText, editor.state.selection, editor, dispatch, typingFinished]);

  return createPortal(
    <div
      ref={ref}
      className={styles.replaceText}
      style={{
        position: "absolute",
        opacity: "1",
        top: initialPosition?.top && `${initialPosition.top}px`,
        left: initialPosition?.left && `${initialPosition.left}px`,
        // left: initialPosition?.left && `${initialPosition.left}px`,
        maxHeight: "100vh",
      }}
      onMouseDown={(e) => {
        const target = e.target as HTMLElement;
        if (!target.classList.contains("textarea")) {
          e.preventDefault();
        }
      }}
    >
      {children}
    </div>,
    scrollDiv ? scrollDiv : document.body,
  );
};

export default AskAiPortal;
