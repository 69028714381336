import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocalStorage } from "hook/useLocalStorage";
import { getWorkSpaces, resetAuth, setAuth } from "store/reducers/authReducerSlice";
import axios from "axios";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useAppSelector } from "store/storeTypes";
import { Workspace } from "types/Workspace";
import { useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import * as amplitude from "@amplitude/analytics-browser";
import { useValidateUserSession } from "hook/useValidateUserSession";
import { feedbackIntegration } from "@sentry/react";
import { apiUrl } from "config/vultronConfig";

export const useAccount = () => {
  const { localValue } = useLocalStorage("vultron_user_token", "");
  const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
  const { localValue: user_sso } = useLocalStorage("vultron_user_sso", false);
  // redux dispatcher
  const dispatch = useDispatch();
  const { currentUser, myWorkSpaces } = useAppSelector((store) => store.auth);
  const ldClient = useLDClient();
  useValidateUserSession();

  const currentWorkspace: Partial<Workspace> = useMemo(() => {
    return myWorkSpaces?.workspaces?.find((workspace) => workspace?.id === workspace_id) || {};
  }, [myWorkSpaces?.workspaces, workspace_id]);

  useEffect(() => {
    // get user token from local storage
    if (!localValue) {
      localStorage.removeItem("vultron_user_token");
      localStorage.removeItem("vultron_user_sso");
      localStorage.removeItem("vultron_workspace_id");
      dispatch(resetAuth());
      return;
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${localValue}`;
    if (user_sso) {
      axios.defaults.headers["X-Authorization-SSO"] = user_sso;
    } else {
      axios.defaults.headers["X-Authorization-Auth0"] = true;
    }
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = apiUrl;
    if (workspace_id) {
      axios.defaults.headers.common["Workspace"] = `Workspace ${workspace_id}`;
    }
    dispatch(
      setAuth({
        isAuthenticated: true,
        isLoading: false,
      }),
    );

    dispatch(getWorkSpaces());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentUser?.id) {
      const { email, id, username } = currentUser;

      ldClient?.identify({
        key: id,
        name: username,
        email,
        workspace_name: currentWorkspace.name || "",
        company_name: currentWorkspace.company_name || "",
      });
      amplitude.setUserId(currentUser.id);

      Sentry.setUser({
        fullName: username,
        email,
        workspace_name: currentWorkspace.name || "",
        company_name: currentWorkspace.company_name || "",
      });

      feedbackIntegration({
        useSentryUser: {
          name: username || "",
          email: email || "",
        },
      });
    }
  }, [currentUser, currentWorkspace, currentWorkspace.company_name, currentWorkspace.name, ldClient]);

  // THIRD-PARTY INTEGRATIONS
  useIntercom();
};

const useIntercom = () => {
  const { pathname } = useLocation();
  const currentUser = useAppSelector((store) => store.auth.currentUser);
  const isProduction = process.env["NODE_ENV"] === "production";

  useEffect(() => {
    if (!isProduction) return;
    if (currentUser?.id) {
      const { email, id, username } = currentUser;

      // @ts-expect-error
      window.Intercom("boot", {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        email,
        name: username,
        user_id: id,
        custom_launcher_selector: "#launch-intercom",
      });
    }
  }, [currentUser, isProduction]);

  useEffect(() => {
    if (!isProduction) return;

    // @ts-expect-error
    window.Intercom("update", { last_request_at: new Date().toISOString() });
  }, [isProduction, pathname]);
};
