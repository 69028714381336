import { useEffect, useState } from "react";
import CustomModal from "components/CustomModal";
import { CloseIconBlack, FolderIconBlue, FolderIconYellow, Spinner } from "utils/icons";
import { useSelector } from "react-redux";

const MoveModal = ({ selectedItems, handleCloseMoveModal, onMove, isMain, currentFiles }) => {
  const [loading, setLoading] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const allDirectories = useSelector((state) => state.drive.fileStorage.allDirectories);

  // --- move function ------
  const handleMove = () => {
    if (loading || !selectedDestination) return;
    setLoading(true);
    onMove(selectedDestination).finally(() => {
      setLoading(false);
      setSelectedDestination(null);
    });
  };

  useEffect(() => {
    if (!selectedItems) {
      setSelectedDestination(null);
      return;
    }

    // Set modal title based on selection
    if (selectedItems.length === 1) {
      // Find the document object from the documents array
      const document = currentFiles?.find((doc) => doc.id === selectedItems[0]);
      const folder = allDirectories?.find((folder) => folder.id === selectedItems[0]);
      setModalTitle(`Move "${document?.name || folder?.name || "Item"}"`);
    } else {
      setModalTitle(`Move ${selectedItems.length} items`);
    }
  }, [selectedItems, currentFiles]);

  const availableFolders = allDirectories.filter((folder) => {
    const movingId = Array.isArray(selectedItems) ? selectedItems[0] : selectedItems;

    // Check if this is a file or folder
    const isFile = currentFiles?.some((file) => file.id === movingId);

    if (isFile) {
      // For files: exclude current parent
      const selectedFile = currentFiles?.find((file) => file.id === movingId);
      return folder.id !== selectedFile?.parent_directory;
    } else {
      // Get all children folders recursively
      const getChildrenFolders = (parentId) => {
        const children = allDirectories.filter((f) => f.parent_directory === parentId);
        return children.reduce((acc, child) => [...acc, child.id, ...getChildrenFolders(child.id)], []);
      };

      const movingFolder = allDirectories.find((f) => f.id === movingId);
      const folderChildrens = getChildrenFolders(movingId);

      return (
        folder.id !== movingId && // Not itself
        !folderChildrens.includes(folder.id) && // Not a child
        folder.id !== movingFolder?.parent_directory // Not current parent
      );
    }
  });

  const hasNoFolders = isMain && (!availableFolders || availableFolders.length === 0);
  const showBorder = !isMain && availableFolders?.length > 0;

  return (
    <CustomModal
      isOpen={!!selectedItems}
      onClose={() => (handleCloseMoveModal ? (loading ? null : handleCloseMoveModal()) : null)}
    >
      <div className="p-6 w-[595px]">
        <div className="flex justify-between items-start">
          <h3 className="font-semibold text-xl break-words line-clamp-3 pr-4">{modalTitle}</h3>
          <div className="w-[15.56px] h-[15.56px] flex items-center justify-center mt-1">
            <span className="cursor-pointer">
              <CloseIconBlack
                onClick={() => (handleCloseMoveModal ? (loading ? null : handleCloseMoveModal()) : null)}
                className="h-3 w-3"
              />
            </span>
          </div>
        </div>

        <div className="my-6 max-h-[350px] flex flex-col border rounded overflow-auto">
          {!isMain && (
            <div
              role="button"
              className={`flex gap-3 p-4 cursor-pointer select-none ${
                selectedDestination === "drive" ? "bg-[#f7f7f7]" : "hover:bg-[#f8f8f8]"
              } ${showBorder ? "border-b" : ""}`}
              onClick={() => {
                setSelectedDestination("drive");
              }}
            >
              <div className="min-w-[38px] flex items-center justify-center">
                <FolderIconBlue />
              </div>
              <p className="font-semibold flex-1">Main Library</p>
            </div>
          )}

          {hasNoFolders ? (
            <div className="p-8 text-center">
              <h5 className="text-base font-semibold text-[#1D2630]">No Folders</h5>
              <p className="text-[#353535] pt-[5px]">No folders have been created yet.</p>
            </div>
          ) : (
            availableFolders?.length > 0 &&
            availableFolders.map((v, i) => (
              <div
                role="button"
                className={`flex gap-3 p-4 cursor-pointer select-none ${
                  selectedDestination === v?.id ? "bg-[#f7f7f7]" : "hover:bg-[#f8f8f8]"
                } ${i < availableFolders.length - 1 ? "border-b" : ""}`}
                key={i}
                onClick={() => {
                  setSelectedDestination((prev) => (prev === v?.id ? null : v?.id));
                }}
              >
                <div className="min-w-[38px] flex items-center justify-center">
                  <FolderIconYellow />
                </div>
                <p className="font-[500] flex-1">{v?.name}</p>
              </div>
            ))
          )}
        </div>
        <div className="flex justify-end gap-4 mt-2">
          <button
            className="border-[1px] border-[#DBE0E5] rounded-lg py-[9px] px-4 text-sm font-medium text-[#1D2630]"
            disabled={loading}
            onClick={() => (handleCloseMoveModal ? (loading ? null : handleCloseMoveModal()) : null)}
          >
            Cancel
          </button>
          <button
            className="border-0 disabled:opacity-50 bg-gray-darkest rounded-lg py-[9px] px-4 text-sm font-medium text-[#ffffff] flex items-center gap-2"
            onClick={handleMove}
            disabled={loading || selectedDestination === null}
          >
            {loading ? <Spinner width="18" height="18" /> : null}
            {loading ? "Moving" : "Move"}
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default MoveModal;
