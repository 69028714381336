import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getNotificationFeedsApi } from "api/api";

export const getFeedItemsData = createAsyncThunk("feedSlice/getFeedItems", async (payload, data) => {
  const { setLoading } = payload;
  return getNotificationFeedsApi()
    .then((res) => (data.getState().feed.isLoadingFeed ? res.data || [] : data.getState().feed.data))
    .finally(() => setLoading && setLoading(false));
});

const initialState = {
  data: [],
  isLoadingFeed: true,
};

export const feedReducerSlice = createSlice({
  name: "feedSlice",
  initialState,
  reducers: {
    setFeed: (state, action) => {
      state.data = action.payload.data;
      state.isLoadingFeed = action.payload.loading;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeedItemsData.pending, (state) => {
        state.isLoadingFeed = true;
      })
      .addCase(getFeedItemsData.fulfilled, (state, action) => {
        state.data = action.payload || [];
        state.isLoadingFeed = false;
      })
      .addCase(getFeedItemsData.rejected, (state) => {
        state.isLoadingFeed = false;
      });
  },
});

export const { setFeed } = feedReducerSlice.actions;

export default feedReducerSlice.reducer;
