import { Section, Volume } from "components/copilot/CopilotSchemaTypes";
import { GeneratedDraft } from "store/reducers/extract/CurrentExtractionReducer";
import { createSection, createVolume } from "utils/framework";
import { LiveList, LiveObject } from "YJSProvider/LiveObjects";

export const transformGeneratedDraftToLiveblocks = (draft: GeneratedDraft): LiveObject<Volume> => {
  const liveSections =
    draft.sections?.reduce<LiveObject<Section>[]>((acc, sec) => {
      const { id, item: createdSection } = createSection({ id: sec.id, title: sec.title });
      const createdSubsections = sec.subsections.map(
        (subsection) => createSection({ id: subsection.id, title: subsection.title, parent_id: id }).item,
      );
      return [...acc, createdSection, ...createdSubsections];
    }, []) || [];

  const createdDraft = createVolume({ id: draft.id, title: draft.title, sections: new LiveList(liveSections) });
  return createdDraft;
};
