import { MutableRefObject, memo, useEffect } from "react";
import { useBoundingClientRectRef } from "./utils";
import { CopilotPresencePage } from "types/Presence";
import { NameCursor } from "../name-cursor";
import isEqual from "lodash/isEqual";
import { useOthersMapped, useUpdateMyPresence } from "YJSProvider/createYJSContext";

type Props = {
  // The element that's used for pointer events and scroll position
  cursorPanel: MutableRefObject<HTMLElement | null>;
  activePage: CopilotPresencePage;
  activeRoomId?: string;
};

/**
 * This file shows you how to create a reusable live cursors component for your product.
 * The component takes a reference to another element ref `cursorPanel` and renders
 * cursors according to the location and scroll position of this panel.
 * Make sure that cursorPanel has a CSS position set, and that LiveCursors is placed inside
 */
export const LiveNameCursors = memo(({ cursorPanel, activePage, activeRoomId }: Props) => {
  /**
   * useMyPresence returns a function to update  the current user's presence.
   * updateMyPresence is different to the setState function returned by the useState hook from React.
   * You don't need to pass the full presence object to update it.
   */
  const updateMyPresence = useUpdateMyPresence();

  /**
   * Return all the other users in the room and their presence (a cursor position in this case)
   */
  const others = useOthersMapped(
    (other: any) => ({
      activePage: other.presence?.activePage,
      proposalRoomId: other.presence?.proposalRoomId,
      cursor: other.presence?.cursor,
      name: other.presence?.name,
      info: other.info,
    }),
    isEqual,
  );

  const rectRef = useBoundingClientRectRef(cursorPanel);

  useEffect(() => {
    if (!(cursorPanel?.current instanceof HTMLElement)) {
      console.warn('Pass `ref` containing HTMLElement to `<LiveCursors scrollRef=""`.');
      return;
    }

    // If cursorPanel, add live cursor listeners
    const updateCursor = (event: PointerEvent) => {
      if (!cursorPanel?.current) {
        return;
      }

      // (Viewport position) - (element position) + (element scroll amount)
      const percentX = (event.clientX - rectRef.current.x + cursorPanel.current.scrollLeft) / rectRef.current.width;
      const percentY = (event.clientY - rectRef.current.y + cursorPanel.current.scrollTop) / rectRef.current.height;

      updateMyPresence({
        cursor: {
          percentX,
          percentY,
        },
      });
    };

    const removeCursor = () => {
      updateMyPresence({
        cursor: null,
      });
    };

    cursorPanel.current.addEventListener("pointermove", updateCursor);
    cursorPanel.current.addEventListener("pointerleave", removeCursor);

    // Clean up event listeners
    const oldRef = cursorPanel.current;
    return () => {
      if (!oldRef) {
        return;
      }

      oldRef.removeEventListener("pointermove", updateCursor);
      oldRef.removeEventListener("pointerleave", removeCursor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMyPresence, cursorPanel]);

  return (
    <>
      {
        /**
         * Iterate over other users and display a cursor based on their presence
         */
        others
          .filter(([, other]) => {
            return activePage === CopilotPresencePage.Proposal
              ? other.activePage === activePage && activeRoomId === other.proposalRoomId
              : other.activePage === activePage;
          })
          .map(([id, other]) => {
            if (other.cursor == null) {
              return null;
            }

            return (
              <NameCursor
                name={other.name}
                key={id}
                // connectionId is an integer that is incremented at every new connections
                // Assigning a color with a modulo makes sure that a specific user has the same colors on every clients
                // color={other.info.color}
                x={other.cursor.percentX * rectRef.current.width}
                y={other.cursor.percentY * rectRef.current.height}
              />
            );
          })
      }
    </>
  );
});
