import * as Y from "yjs";
import * as awarenessProtocol from "y-protocols/awareness";
import { Room } from "./createYJSContext";
import { WebsocketProvider } from "y-websocket";
import uniq from "lodash/uniq";
import debounce from "lodash/debounce";
import { apiUrl } from "config/vultronConfig";

export interface AwarenessUpdate {
  added: number[];
  updated: number[];
  removed: number[];
}

export type OthersMap = Map<
  number,
  {
    [x: string]: any;
  }
>;

const base_url = new URL(apiUrl || "localhost:1234");
const wsServer = `wss://${base_url.host}/yjs`;
const updateThrottle = 0;
export class YJSProvider extends WebsocketProvider {
  private destroyed = false;
  public room: Room<any, any, any>;

  constructor(
    room: Room<any, any, any>,
    doc: Y.Doc,
    options?: {
      awareness?: awarenessProtocol.Awareness;
      disableAutoConnect?: boolean;
    },
  ) {
    super(wsServer, room.id, doc, {
      awareness: options?.awareness || new awarenessProtocol.Awareness(doc),
      connect: false,
      resyncInterval: 10000,
    });
    this.room = room;
    this.awareness.on("change", (changes: AwarenessUpdate) => {
      // Listen to remote and local state changes on the awareness instance.
      const allChanges = uniq([...changes.added, ...changes.updated, ...changes.removed]);
      if (allChanges.includes(this.awareness.clientID)) {
        this.throttleEmitSelf();
      }
      this.emitOthers();
    });
    this.on("connection-close", (e: { code: number }) => {
      if (e.code === 1008) {
        console.log("Token expired, reconnecting...");
        this.disconnect();
        setTimeout(() => {
          // Token is old, so override it
          this.authenticate(true);
        }, 1000);
      }
    });
    if (!options?.disableAutoConnect) this.authenticate();
  }

  public async authenticate(override = false) {
    try {
      if (this.destroyed) return;
      const token = await this.room.authenticate(override);
      // Update params to include token
      const params = new URLSearchParams({
        auth: `Bearer ${token}`,
        clientID: this.awareness.clientID.toString(),
      });
      this.url = `${wsServer}/${this.room.id}?${encodeURIComponent(params.toString())}`;
      this.connect();
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        this.authenticate(true);
      }, 1000);
    }
  }

  private throttleEmitSelf = debounce(
    () => this.awareness.emit("self", [this.awareness.getLocalState()]),
    updateThrottle,
    {
      leading: true,
      trailing: true,
    },
  );

  private emitOthers = debounce(() => this.awareness.emit("others", [this.awareness.getStates()]), updateThrottle, {
    leading: true,
    trailing: true,
  });

  destroy(): void {
    this.destroyed = true;
    super.destroy();
  }

  connect(): void {
    if (this.destroyed) return;
    super.connect();
  }
}
