import { useCallback } from "react";
import { useAppSelector } from "store/storeTypes";

export const useGetUsernameById = () => {
  const { workspaceMembers } = useAppSelector((store) => store.auth);

  return useCallback(
    (userId: string) => {
      const result = workspaceMembers?.find((member) => member?.id === userId);
      return result?.username;
    },
    [workspaceMembers],
  );
};
