/** @jsxImportSource @emotion/react */

import ToastBanner from "components/atoms/toast-banner";
import { Button } from "components/editor/components";
import { HTMLProps, useMemo } from "react";
import { setBannerState } from "store/reducers/copilot/copilotBannerReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { ExtractionTask } from "types/Autopilot/HealthCheck";

const ExtractingRequirementsBanner = (props: HTMLProps<HTMLDivElement>) => {
  const { requirement_document_tasks, requirement_text_tasks } = useAppSelector((state) => state.autopilotHealthCheck);
  const dispatch = useAppDispatch();

  const groupedTasks = useMemo(
    () =>
      [...requirement_document_tasks, ...requirement_text_tasks].reduce<{
        isStarted: ExtractionTask[];
        isQueued: ExtractionTask[];
      }>(
        (acc, task) => ({
          ...acc,
          ...(task.is_started && { isStarted: [...acc.isStarted, task] }),
          ...(!task.is_started && { isQueued: [...acc.isQueued, task] }),
        }),
        { isStarted: [], isQueued: [] },
      ),
    [requirement_document_tasks, requirement_text_tasks],
  );

  const totalMinutes = useMemo(
    () => groupedTasks.isStarted.reduce<number>((acc, task) => (acc += task.minutes_time_remaining || 0), 0),
    [groupedTasks.isStarted],
  );

  return (
    <ToastBanner {...props}>
      <div className="w-full flex-1">
        <div className="flex flex-col gap-1">
          <div className="text-sm text-white font-semibold">
            Extracting requirements
            <span className="loading-ellipsis" />
          </div>
          <div className="flex items-center text-xs text-gray-400">
            {groupedTasks.isStarted.length
              ? `This may take up to ${totalMinutes} minutes.`
              : `There ${groupedTasks.isQueued.length === 1 ? "is" : "are"} currently ${
                  groupedTasks.isQueued.length
                } extraction${groupedTasks.isQueued.length === 1 ? "" : "s"} in the queue.`}
          </div>
        </div>
      </div>
      <div className="w-full flex gap-1 justify-end">
        <Button
          className="!text-white hover:!bg-[rgba(200,200,200,0.1)]"
          onClick={() => dispatch(setBannerState({ requirementExtraction: { open: false, forceClose: true } }))}
          variant="ghost"
          size="sm"
        >
          Close
        </Button>
      </div>
    </ToastBanner>
  );
};

export default ExtractingRequirementsBanner;
