/** @jsxImportSource @emotion/react */

import { ExtractionStatus, InstantDraftStatus } from "components/copilot/CopilotSchemaTypes";
import VolumesAndSectionsTable from "components/organisms/volumes-and-sections-table";
import useExtractionOperations from "hook/useExtractionOperations";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";
import InstantDraftLoading from "./InstantDraftLoading";
import InstantDraftConfigForm from "./InstantDraftConfigForm";
import InstantDraftInstructions from "./InstantDraftInstructions";
import { isInstantDraftStarted } from "../utils";
import { YJS_OPERATIONS } from "const-values/yjs";

const InstantDraftGenerationForm = () => {
  const { extractionId } = useParams();
  const { addAttribution, toggleInstantDraftOutline, setSectionPageCount } = useExtractionOperations();
  const extractionStatus = useAppSelector((store) => store.currentExtractionState.currentExtraction?.status);
  const extractionVolumes = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.framework?.volumes || [],
  );
  const instantDraftConfig = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig,
  );
  const isReadOnly =
    extractionStatus === ExtractionStatus.Completed || isInstantDraftStarted(instantDraftConfig?.status);

  if (instantDraftConfig?.status === InstantDraftStatus.InProgress) return <InstantDraftLoading />;

  return (
    <div className="h-full overflow-y-auto p-6">
      <div className="flex flex-col gap-3.5">
        <InstantDraftInstructions />
        <div className="min-h-0 bg-gray-lightest border border-gray-light rounded-md p-8 gap-4 flex flex-col">
          <div className="flex flex-col gap-2">
            <div className="text-gray-darkest text-xl font-medium">1. Select Volumes & Sections</div>
            <div className="text-gray-500 text-sm">
              Choose one or more volumes or sections to generate a draft. Page limits are only applicable to sections.
            </div>
          </div>
          <VolumesAndSectionsTable
            isReadOnly={isReadOnly}
            volumes={extractionVolumes}
            onCheck={(checked, reference) => {
              if (!extractionId) return;
              toggleInstantDraftOutline(extractionId, checked ? "insert" : "remove", reference);
              addAttribution(YJS_OPERATIONS.EXTRACTION.SET_INSTANT_DRAFT_VOLUMES_SECTIONS_SELECTION);
            }}
            onPageCountSelect={(sectionId, pageCount) => {
              if (extractionId) {
                setSectionPageCount(extractionId, sectionId, pageCount);
                addAttribution(YJS_OPERATIONS.EXTRACTION.SET_INSTANT_DRAFT_PAGE_LIMIT);
              }
            }}
          />
        </div>
        <InstantDraftConfigForm isReadOnly={isReadOnly} instantDraftConfig={instantDraftConfig} />
      </div>
    </div>
  );
};

export default InstantDraftGenerationForm;
