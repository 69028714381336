import { useEffect, useMemo, useState } from "react";
import {
  CheckMarkGreen,
  CloseIconBlack,
  InfoIconWarn,
  MenuVerticalBars,
  SortingActiveBottomIcon,
  SortingActiveIcon,
  SortingActiveTopIcon,
} from "utils/icons";
import { useSortable } from "hook/useSortable";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Spinner } from "utils/icons";
import loadingImg from "../../Assets/generating.gif";
import Avatar from "components/Avatar";
import { createProposalDocument, deleteProposal, updateProposalName, getProposalStatus } from "api/api";
import CustomModal from "components/CustomModal";
import { downloadProposal } from "api/api";
import { createdNewPropsalDocumentAction } from "../../store/reducers/yjs-editor-reducer";
import { useNotification } from "context/notificationContext";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { Download, Pencil, Trash2 } from "lucide-react";
import { useTrackUserMetric } from "utils/metrics";
import tw from "twin.macro";

const CONFIRM_DELETE_TEXT = "confirm";

const ProposalTable = ({ proposals, setProposals, internalContractId, setDocId, setForceRefresh }) => {
  const { setToast } = useNotification();
  const trackUserEvent = useTrackUserMetric();
  const { workspaceMembers } = useSelector((store) => store.auth);
  const [deleting, setDeleting] = useState([]);
  const [creatingProposalDoc, setCreatingProposalDoc] = useState(false);
  const [deleteModal, setDeleteModal] = useState({ open: false, id: null });
  const [confirmDeleteText, setConfirmDeleteText] = useState("");
  // tracks the displayed generation loader on the screen
  const [displayProposalGenerationLoader, setDisplayProposalGenerationLoader] = useState(false);
  // tracks if the proposal is in active generation state
  const [proposalGenerating, setProposalGenerating] = useState(false);

  const [cancelModel, setCancelModel] = useState(false);

  // Load initial proposal generation status
  useEffect(() => {
    getProposalStatus(internalContractId)
      .then((resp) => {
        setDisplayProposalGenerationLoader(resp?.data?.in_progress === true);
        setProposalGenerating(resp?.data?.in_progress === true);
      })
      .catch((err) => {
        console.log("Error fetching proposal generation status: ", err);
      });
  }, []);

  // Poll proposal generation status
  useEffect(() => {
    const updateGenerateProposalState = () => {
      getProposalStatus(internalContractId)
        .then((response) => {
          if (response?.data?.in_progress !== true && proposalGenerating) {
            setForceRefresh(true);
            setDisplayProposalGenerationLoader(false);
            setProposalGenerating(false);
          }
        })
        .catch((err) => {
          console.log("Error fetching proposal generation status: ", err);
        });
    };
    const intervalId = setInterval(() => updateGenerateProposalState(), 15000);
    return () => clearInterval(intervalId);
  }, [proposalGenerating]);

  // find a user
  const getUser = (userId) => {
    return workspaceMembers?.find((v) => v?.id === userId) || {};
  };

  // sorted data
  const { items, sortConfig, handleSorting } = useSortable(
    proposals?.map((v) => {
      return {
        ...v,
        creator: getUser(v?.created_by)?.username || "",
      };
    }),
  );

  // handle sort click event on table headers
  const handleSortEvent = (key, by_date) => {
    if (sortConfig?.key === key) {
      if (sortConfig?.direction === "ascending") {
        handleSorting(key, "descending", by_date);
      } else {
        handleSorting("", "", false);
      }
    } else {
      handleSorting(key, "ascending", by_date);
    }
  };

  // render sort icon
  const renderSortIcon = (key, by_date) => {
    if (sortConfig?.key !== key) {
      return (
        <SortingActiveIcon
          onclick1={() => handleSorting(key, "ascending", by_date)}
          onclick2={() => handleSorting(key, "descending", by_date)}
        />
      );
    }
    switch (sortConfig?.direction) {
      case "ascending":
        return (
          <SortingActiveTopIcon
            onclick1={() => handleSorting(key, "ascending", by_date)}
            onclick2={() => handleSorting(key, "descending", by_date)}
          />
        );
      case "descending":
        return (
          <SortingActiveBottomIcon
            onclick1={() => handleSorting(key, "ascending", by_date)}
            onclick2={() => handleSorting(key, "descending", by_date)}
          />
        );
      default:
        return (
          <SortingActiveIcon
            onclick1={() => handleSorting(key, "ascending", by_date)}
            onclick2={() => handleSorting(key, "descending", by_date)}
          />
        );
    }
  };

  // Check if already deleting or not
  const isAlreadyDeleting = (id) => (deleting?.find((v) => v === id) ? true : false);

  // Delete a proposal
  const deleteAProposal = () => {
    if (isAlreadyDeleting(deleteModal?.id)) return;
    if (confirmDeleteText !== CONFIRM_DELETE_TEXT) return;
    setDeleting((prev) => [...prev, deleteModal?.id]);
    deleteProposal(deleteModal?.id)
      .then(() => {
        setDeleting((prev) => prev.filter((p) => p !== deleteModal.id));
        setProposals(proposals?.filter((pr) => pr?.id !== deleteModal?.id));
        setForceRefresh(true);
        setDeleteModal({ open: false, id: null });
        setConfirmDeleteText("");
        setToast.success({
          msg: "Proposal deleted",
        });
      })
      .catch((err) => {
        setDeleting((prev) => prev.filter((p) => p?.id !== deleteModal.id));
        setToast.error({
          title: "Unable to delete proposal",
          msg: "We were unable to delete the proposal due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        });
      });
  };
  // on create new doc tiptap editor should open

  const dispatch = useDispatch();
  // create new proposal doc
  const createNewProposalDoc = () => {
    if (creatingProposalDoc) return;
    setCreatingProposalDoc(true);
    createProposalDocument(internalContractId, { proposal_name: "" })
      .then((res) => {
        setProposals([...proposals, res.data]);
        dispatch(createdNewPropsalDocumentAction(true));
        setForceRefresh(true);
      })
      .catch((err) => {
        console.log("Error while creating a new proposal doc", err);
      })
      .finally(() => {
        setCreatingProposalDoc(false);
      });
  };

  return (
    <div className="pt-3 flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
      <div className="px-5 h-full">
        <div className="flex items-top">
          <div className="ml-auto relative group flex gap-2 z-30">
            {proposalGenerating ? (
              <div className="absolute font-inter font-semibold w-max max-w-[200px] top-[120%] right-[0px] hidden  cursor-pointer rounded border-[2px] border-[#F5F5F5]  bg-white shadow-xl  group-hover:block z-20">
                <div className="menu-arrow left !bg-gray-400"></div>
                <div className="bg-white p-2">
                  <p className="text-xs text-gray-500 text-gray-primary pt-1 font-normal pr-1">
                    There is an <b>active import in progress</b>. Please wait until the import is complete before{" "}
                    <b>starting a new one</b>.
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {/* proposal table */}
        <div
          className="max-w-full overflow-auto  border bg-white rounded-lg mt-3 scrollbar-0"
          style={{ maxHeight: `calc(100% - 35px)` }}
        >
          <table className="overflow-auto">
            <thead className="border-b text-sm sticky top-0 left-0 bg-[#f5f5f5] rounded-tl-lg rounded-tr-lg z-[10]">
              <tr className="text-left font-thin">
                <th className="px-4 pl-7 py-3 w-[58%] min-w-[350px] font-medium" style={{ height: "54px" }}>
                  <p className="flex w-max max-w-full items-center select-none cursor-pointer">
                    <span onClick={() => handleSortEvent("name")} className="block pr-1">
                      Name
                    </span>
                    <span className="p-[2px] block">{renderSortIcon("name")}</span>
                  </p>
                </th>
                <th className="px-4 py-3 w-[26%] font-medium text-xs" style={{ height: "54px" }}>
                  <p className="flex w-max max-w-full items-center select-none cursor-pointer">
                    <span onClick={() => handleSortEvent("created_at", true)} className="block pr-1">
                      Created
                    </span>
                    <span className="p-[2px] block">{renderSortIcon("created_at", true)}</span>
                  </p>
                </th>
                <th className="px-4 py-3 font-medium text-xs w-[35%]" style={{ height: "54px" }}>
                  <p className="flex w-max max-w-full items-center select-none cursor-pointer">
                    <span onClick={() => handleSortEvent("creator")} className="block pr-1">
                      Creator
                    </span>
                    <span className="p-[2px] block">{renderSortIcon("creator")}</span>
                  </p>
                </th>
                <th className="px-4 py-3 font-medium text-xs min-w-[60px] max-w-[60px]" style={{ height: "54px" }}></th>
              </tr>
              <tr>
                <th className="h-[1px] bg-gray-200" colSpan="8"></th>
              </tr>
            </thead>
            <tbody>
              {items?.map((data, i) => {
                return (
                  <MyTableRow
                    key={i}
                    getUser={getUser}
                    data={data}
                    mainData={proposals}
                    setData={setProposals}
                    isAlreadyDeleting={isAlreadyDeleting}
                    setDeleteModal={setDeleteModal}
                    setConfirmDeleteText={setConfirmDeleteText}
                    setForceRefresh={setForceRefresh}
                    setDocId={setDocId}
                    internalContractId={internalContractId}
                    trackUserEvent={trackUserEvent}
                  />
                );
              })}
              <tr>
                <td colSpan="4" className="">
                  <p
                    role="button"
                    tabIndex="1"
                    onClick={createNewProposalDoc}
                    className={`${
                      creatingProposalDoc ? "opacity-50 cursor-wait" : " cursor-pointer"
                    }  select-none w-max max-w-full pl-7 pr-2 py-5 text-sm"`}
                  >
                    <span className="text-sm text-action font-medium">+ Create Blank Proposal</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {displayProposalGenerationLoader && (
          <div className="fixed bg-white z-[999] top-0 left-0 w-full h-full flex justify-center items-center flex-col p-5">
            <img
              width="420"
              height="420"
              className="mx-auto max-w-full object-contain"
              style={{ maxWidth: "calc(100vh - 15%)" }}
              src={loadingImg}
              alt="Loading..."
            />
            <div className="p-2 text-center">
              <h3 className="text-2xl font-bold text-black">Proposal is generating...</h3>
              <p className="my-4 text-gray-500">
                Grab some water, pick out a snack, and take a moment to unwind.
                <br />
                This could take between 10 to 30 minutes depending on the proposal draft length.
              </p>
              <button
                onClick={() => {
                  setCancelModel(true);
                }}
                className="flex items-center mx-auto gap-2 text-sm bg-action rounded-lg py-2 px-6 text-white"
              >
                Leave Proposal Generation
              </button>
            </div>
          </div>
        )}
        {/* ************ Cancel Modal ************* */}
        <CustomModal
          isOpen={cancelModel}
          onClose={() => {
            setCancelModel(false);
          }}
          className={"w-[100%] max-w-[477px]"}
        >
          <div className="px-5 py-6">
            <div className="flex items-start">
              <span className="mr-2">
                <InfoIconWarn />{" "}
              </span>
              <p className="text-[16px] font-semibold">Are you sure you want to leave?</p>
              <span
                className="ml-auto my-auto p-1 cursor-pointer select-none"
                role="button"
                onClick={() => {
                  setCancelModel(false);
                }}
              >
                <CloseIconBlack />
              </span>
            </div>
            <div className="flex items-start mt-5 flex-col">
              <div className="pl-8">
                <p className="text-[15px]">Leaving will not cancel the proposal generation.</p>
              </div>
            </div>
            <div className="flex items-center justify-end w-full gap-3 mt-7">
              <button
                className="border-gray-300 border py-2 px-4 rounded-lg font-[500]"
                onClick={() => {
                  setCancelModel(false);
                }}
              >
                Stay
              </button>
              <button
                className="py-2 px-4 rounded-lg font-[500] border-action bg-action text-white active:bg-[#304aa5de]"
                onClick={() => {
                  setCancelModel(false);
                  setDisplayProposalGenerationLoader(false);
                }}
              >
                Leave
              </button>
            </div>
          </div>
        </CustomModal>
        {/* Ask delete modal */}
        <CustomModal
          isOpen={deleteModal?.open}
          onClose={() => (deleting?.length > 0 ? null : setDeleteModal({ open: false, id: null }))}
          className={"w-[100%] max-w-[477px]"}
        >
          <div className="px-5 py-6">
            <div className="flex items-start">
              <span className="mr-2">
                <InfoIconWarn />{" "}
              </span>
              <p className="text-[16px] font-semibold">Are you sure you want to delete this proposal?</p>
              <span
                className="p-2 ml-auto cursor-pointer"
                role="button"
                onClick={() => (deleting?.length > 0 ? null : setDeleteModal({ open: false, id: null }))}
              >
                <CloseIconBlack width="13" height="13" />
              </span>
            </div>
            <div className="flex pl-8 pr-2 items-start mt-5 flex-col">
              <p className="text-[15px]">
                This is an irreversible operation. You will <b>NOT</b> be able to recover this proposal. Please type
                <span className="font-semibold"> "{CONFIRM_DELETE_TEXT}"</span> below to confirm.
              </p>
              <input
                type="text"
                onChange={(e) => setConfirmDeleteText(e.target.value)}
                value={confirmDeleteText}
                className="bg-white mt-5 focus:outline-none border px-3 py-1 rounded-md focus:ring-0 focus:ring-action text-md text-black w-full placeholder:text-md"
                placeholder='Enter "confirm" to delete.'
              />
            </div>
            <div className="flex items-center justify-end w-full gap-3 mt-8">
              <button
                disabled={deleting?.length > 0}
                className="border-gray-300 border py-2 px-4 rounded-lg font-normal"
                onClick={() => {
                  setDeleteModal({ open: false, id: null });
                }}
              >
                Cancel
              </button>
              <button
                className="py-2 px-4 rounded-lg font-normal bg-action text-white active:bg-[#304aa5de] disabled:opacity-60"
                onClick={deleteAProposal}
                disabled={deleting?.length > 0 || confirmDeleteText !== CONFIRM_DELETE_TEXT}
              >
                {deleting?.length > 0 ? (
                  <>
                    {" "}
                    <span className="mr-1">
                      <Spinner width={18} height={18} />
                    </span>{" "}
                    Deleting
                  </>
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
};

// Table row
const MyTableRow = ({
  getUser,
  mainData,
  data,
  setData,
  isAlreadyDeleting,
  setDeleteModal,
  setConfirmDeleteText,
  setForceRefresh,
  setDocId,
  trackUserEvent,
}) => {
  const [inputValue, setInputValue] = useState(data?.name || "");
  const [editing, setEditing] = useState(false);
  const [downloading, setDownloading] = useState(false);

  // save name
  const saveName = (e) => {
    if (e) e.preventDefault();
    updateProposalName({ proposal_name: inputValue }, data?.id)
      .then(() => {
        setForceRefresh(true);
      })
      .catch((err) => {
        console.log("error while updating the proposal name: ", err);
      });
    //
    setTimeout(() => {
      const prev = [...mainData];
      const index = mainData?.findIndex((v) => v?.id === data?.id);
      if (index >= 0) {
        prev[index] = {
          ...prev[index],
          name: inputValue,
          updated_at: new Date().toISOString(),
        };
        setData(prev);
      }
      setEditing(false);
    }, 750);
  };

  useEffect(() => {
    setInputValue(data?.name || "");
  }, [data]);

  const menuItems = useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex items-center gap-2">
            <Pencil size={14} /> Edit
          </div>
        ),
        onSelect: () => {
          setEditing(true);
        },
      },
      {
        key: 2,
        label: (
          <div className="flex items-center gap-2">
            {downloading ? <Spinner width={14} height={14} /> : <Download size={14} />} Download
          </div>
        ),
        disabled: downloading,
        onSelect: () => {
          setDownloading(true);
          trackUserEvent("User Downloaded Proposal");
          downloadProposal(data?.id, "yjs")
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              const filename = `${
                data?.name?.length === 0 ? `proposal_${new Date().toISOString().split("T")[0]}` : data?.name
              }.docx`;
              link.href = url;
              link.setAttribute("download", filename);
              document.body.appendChild(link);
              link.click();
            })
            .catch((error) => console.error(error))
            .finally(() => setDownloading(false));
        },
      },
      {
        key: 3,
        label: (
          <div className="flex items-center gap-2 text-red-500">
            {isAlreadyDeleting(data?.id) ? <Spinner width={14} height={14} /> : <Trash2 size={14} />}Delete
          </div>
        ),
        disabled: !!isAlreadyDeleting(data?.id),
        onSelect: () => {
          setDeleteModal({ open: true, id: data.id });
          setConfirmDeleteText("");
        },
      },
    ],
    [data.id, data?.name, downloading, isAlreadyDeleting, setConfirmDeleteText, setDeleteModal, trackUserEvent],
  );

  return (
    <tr className="hover:bg-[#f5f5f5] border-b text-sm cursor-pointer">
      <td
        className="px-4 pl-7 py-5 w-[58%] min-w-[350px]"
        onClick={() => {
          if (!editing) setDocId(data?.id);
        }}
      >
        {!editing ? (
          <div className="py-2">{data?.name || "-"}</div>
        ) : (
          <form onSubmit={saveName}>
            <input
              type="text"
              className="font-medium text-sm outline-none rounded-lg ring-[1px] ring-action w-full overflow-hidden break-word whitespace-pre-line text-[#1E1E1E] px-2 py-2 editable placeholder:text-gray-400"
              autoFocus
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onBlur={saveName}
            />
          </form>
        )}
      </td>
      <td
        className="px-4 py-5 w-[26%]"
        onClick={() => {
          if (!editing) setDocId(data?.id);
        }}
      >
        {moment(data?.created_at).format("MMMM DD, YYYY")}
      </td>
      <td
        className="px-4 py-5 w-[35%] items-center"
        onClick={() => {
          if (!editing) setDocId(data?.id);
        }}
      >
        <div className="flex items-center">
          {!getUser(data?.created_by)?.username && !getUser(data?.created_by)?.email ? null : (
            <Avatar
              src=""
              alt={getUser(data?.created_by)?.username || getUser(data?.created_by)?.email || "-"}
              id={data?.created_by}
              width={28}
              height={28}
              className={"text-[15px]"}
            />
          )}
          <p className="pl-2 my-auto">{getUser(data?.created_by)?.username || "-"}</p>
        </div>
      </td>
      <td className="pr-4 min-w-[40px] max-w-[40px] cursor-default">
        {editing ? (
          <div className="py-1 flex items-center justify-end">
            <CheckMarkGreen />
          </div>
        ) : (
          <DropdownMenu triggerProps={{ css: tw`w-full flex items-center justify-end` }} modal items={menuItems}>
            <div className="bg-transparent border-0 cursor-pointer duration-100 rounded-md py-1 px-0.5 hover:bg-gray-200">
              <MenuVerticalBars />
            </div>
          </DropdownMenu>
        )}
      </td>
    </tr>
  );
};

export default ProposalTable;
