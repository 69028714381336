import { Storage } from "components/copilot/CopilotSchemaImmutableTypes";
import { Outlet, useParams } from "react-router-dom";
import DraftVolumeSidebar from "./draft-volume-sidebar";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { GripVertical } from "lucide-react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { resetSectionState, setSectionState } from "store/reducers/draft/sectionReducer";
import { useEffect } from "react";
import FloatingRequirement from "components/organisms/floating-requirement/FloatingRequirement";
import DraftExtractionBar from "./draft-extraction-bar";
import { useStorage } from "YJSProvider/createYJSContext";
import { isEqual } from "lodash";

const DraftVolume = () => {
  const { volumeId } = useParams();
  const volume = useStorage(
    (storage) => (storage.framework as Storage["framework"]).volumes.find((vol) => vol.id === volumeId),
    isEqual,
  );
  const requirementRouted = useAppSelector((store) => store.sectionState.requirementsState.requirementRouted);
  const actionsMenuOpen = useAppSelector((store) => store.copilotDrawer.open);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetSectionState());
    };
  }, [dispatch]);

  return (
    <div className="relative flex flex-1 flex-row h-full">
      {!!requirementRouted && <FloatingRequirement requirementId={requirementRouted} />}
      <PanelGroup direction="horizontal">
        <Panel
          defaultSize={30}
          minSize={actionsMenuOpen ? 20 : 14}
          maxSize={44}
          onResize={(size) => dispatch(setSectionState({ sidebarSize: size }))}
        >
          <DraftVolumeSidebar volume={volume} />
        </Panel>
        <PanelResizeHandle className="z-[1] relative group flex justify-center">
          <div className="z-[1] absolute top-14 py-0.5 -left-[6.5px] rounded bg-slate-800 hover:bg-gray-darkest">
            <GripVertical size={14} className="text-gray-50" />
          </div>
          <div className="w-px h-full bg-gray-200 delay-300 duration-150 group-hover:bg-slate-800 group-hover:scale-x-[2.5]" />
        </PanelResizeHandle>
        <Panel defaultSize={70} className="flex flex-col relative">
          <Outlet context={{ volume }} />
          <DraftExtractionBar />
        </Panel>
      </PanelGroup>
    </div>
  );
};

export default DraftVolume;
