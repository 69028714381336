interface TagComponentProps {
  handleTagSelect: (tag: string) => void;
  tag: string;
  addTag?: boolean;
  removeTag?: boolean;
}

export default function TagComponent({ handleTagSelect, tag, addTag, removeTag }: TagComponentProps) {
  return (
    <button
      className="flex items-center gap-1 border border-action bg-[#E9F0FF] rounded h-[24px] px-1.5 py-0.5"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (addTag) handleTagSelect(tag);
      }}
    >
      <span className="text-[12px] font-medium text-action leading-1">{tag}</span>
      {removeTag && (
        <span className="text-action" onClick={() => removeTag && handleTagSelect(tag)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-4 h-4"
          >
            <path d="M18 6L6 18M6 6l12 12"></path>
          </svg>
        </span>
      )}
    </button>
  );
}
