/** @jsxImportSource @emotion/react */

import moment from "moment";
import { memo, useCallback, useMemo, useState } from "react";
import AddMilestoneModal from "./AddMilestoneModal";
import edit from "../../../Assets/edit-2.png";
import EditDateModal from "./EditDateModal";
import Popover from "components/atoms/popover/Popover";
import tw from "twin.macro";
import { updateInternalContractDetails } from "api/api";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { getProject } from "store/reducers/projectReducer";
import DatePicker from "components/molecules/date-picker";

const Timeline = ({ internalContractDetails, internalContractId, setInternalContractDetails }) => {
  const [addMilestoneModalOpen, setAddMilestoneModalOpen] = useState(false);
  const [selectedMilestone, setSelectedMilestone] = useState();
  const [openedMilestone, setOpenedMilestone] = useState();
  const [editDateModalOpen, setEditDateModalOpen] = useState(false);
  const [milestoneType, setMilestoneType] = useState("");
  const { activeProject } = useAppSelector((state) => state.project);
  const dispatch = useAppDispatch();
  const handleAddMilestoneModalOpen = () => setAddMilestoneModalOpen(true);

  const openModal = () => setEditDateModalOpen(true);

  const milestones =
    internalContractDetails?.milestones?.sort((a, b) => new Date(a.deadline) - new Date(b.deadline)) || [];

  const onStartDateSelect = useCallback(
    async (data) => {
      try {
        await updateInternalContractDetails(internalContractId, {
          start_date: new Date(data)?.toISOString(),
        });
        dispatch(getProject({ internalContractId }));
      } catch {}
    },
    [dispatch, internalContractId],
  );
  const onEndDateSelect = useCallback(
    async (data) => {
      try {
        await updateInternalContractDetails(internalContractId, {
          response_date: new Date(data)?.toISOString(),
        });
        dispatch(getProject({ internalContractId }));
      } catch {}
    },
    [dispatch, internalContractId],
  );

  return (
    <div className="border border-[#D8DDE2] shadow-sm p-4 rounded-lg mb-4 bg-white pb-5">
      <div className="w-full flex justify-between items-center">
        <h1 className="text-base font-medium">Timeline</h1>
        <div className="flex gap-3 items-center">
          {selectedMilestone && (
            <img src={edit} alt="" id="editBtn" onClick={openModal} className="cursor-pointer w-[14px] h-[14px]" />
          )}
          <h6 className="text-[12px] font-medium text-action cursor-pointer" onClick={handleAddMilestoneModalOpen}>
            Add Milestone
          </h6>
        </div>
      </div>

      <div className="w-full mt-10 relative">
        <div className="w-full  bg-[#D8E0E8] h-[7px] rounded absolute"></div>
        <div className="w-full translate-y-[-4px] flex justify-between">
          {activeProject?.internal_contract?.start_date && (
            <TimelineCircle
              name="Start Date"
              date={activeProject?.internal_contract?.start_date}
              selectedMilestone={selectedMilestone}
              id="start"
              circleType="start"
              excludingItem="editBtn"
              setMilestoneType={setMilestoneType}
              editDateModalOpen={editDateModalOpen}
              setSelectedMilestone={setSelectedMilestone}
            />
          )}
          {milestones?.map((milestone, index) => (
            <TimelineCircle
              key={index}
              id={milestone.id}
              name={milestone?.name}
              date={milestone.deadline}
              milestone={milestone}
              setSelectedMilestone={setSelectedMilestone}
              selectedMilestone={selectedMilestone}
              setOpenedMilestone={setOpenedMilestone}
              openedMilestone={openedMilestone}
              editDateModalOpen={editDateModalOpen}
              excludingItem="editBtn"
              circleType="milestone"
              setMilestoneType={setMilestoneType}
            />
          ))}
          {activeProject?.internal_contract?.response_date && (
            <TimelineCircle
              name="Deadline"
              date={activeProject?.internal_contract?.response_date}
              selectedMilestone={selectedMilestone}
              editDateModalOpen={editDateModalOpen}
              id="response"
              excludingItem="editBtn"
              circleType="response"
              setMilestoneType={setMilestoneType}
              setSelectedMilestone={setSelectedMilestone}
            />
          )}
        </div>
      </div>
      <div className="w-full flex justify-between mt-5">
        <div>
          <div className="text-[#939EA7] pl-1 text-[12px]">Start Date</div>
          <DateComp dateString={activeProject?.internal_contract?.start_date} onDateSelect={onStartDateSelect} />
        </div>
        <div className="flex flex-col items-end">
          <div className="text-[#939EA7] text-[12px] pr-1">Deadline</div>
          <DateComp dateString={activeProject?.internal_contract?.response_date} onDateSelect={onEndDateSelect} />
        </div>
      </div>
      <AddMilestoneModal
        isOpen={addMilestoneModalOpen}
        setIsOpen={setAddMilestoneModalOpen}
        internalContractId={internalContractId}
        setInternalContractDetails={setInternalContractDetails}
      />
      <EditDateModal
        isOpen={editDateModalOpen}
        setIsOpen={(open) => {
          setEditDateModalOpen(open);
          setSelectedMilestone();
          setOpenedMilestone();
        }}
        selectedMilestone={selectedMilestone}
        milestoneType={milestoneType}
        openedMilestone={openedMilestone}
        setSelectedMilestone={setSelectedMilestone}
        setOpenedMilestone={setOpenedMilestone}
        internalContractDetails={internalContractDetails}
        setInternalContractDetails={setInternalContractDetails}
        setMilestoneType={setMilestoneType}
      />
    </div>
  );
};

const TimelineCircle = ({
  date,
  name,
  id,
  milestone,
  setSelectedMilestone,
  setMilestoneType,
  editDateModalOpen,
  selectedMilestone,
  setOpenedMilestone,
  openedMilestone,
  excludingItem,
  circleType,
}) => {
  return (
    <Popover
      arrow
      arrowProps={{ css: [tw`fill-action`] }}
      contentProps={{
        onPointerDownOutside: (e) => {
          if (e.target.id === excludingItem || editDateModalOpen) e.preventDefault();
        },
        css: [tw`bg-action px-3 py-1 border-0`],
      }}
      open={openedMilestone ? openedMilestone?.id && openedMilestone.id === milestone?.id : undefined}
      onOpenChange={
        openedMilestone
          ? () => {
              if (selectedMilestone) {
                setSelectedMilestone();
                setOpenedMilestone();
              }
              if (!selectedMilestone) {
                if (circleType === "milestone") setOpenedMilestone(JSON.parse(JSON.stringify(milestone)));
                setMilestoneType(circleType);
                setSelectedMilestone(id);
              }
            }
          : undefined
      }
      content={
        <div className="text-[#fff] text-gray-primary font-normal text-[12px] overflow-auto overflow-wrap break-words">
          <p className="my-1">{name}</p>
          <p className="my-1">{moment(date).format("MM/DD/YYYY")}</p>
        </div>
      }
    >
      <div
        onMouseEnter={() => {
          if (circleType === "milestone" && !selectedMilestone)
            setOpenedMilestone(JSON.parse(JSON.stringify(milestone)));
        }}
        onMouseLeave={() => {
          if (openedMilestone && !selectedMilestone) setOpenedMilestone();
        }}
        className="w-[18px] h-[18px] bg-[#D9DEE8] rounded-lg shadow-lg flex justify-center items-center relative group cursor-pointer z-[999]"
      >
        <div className="w-[13px] h-[13.05px] bg-action rounded-lg"> </div>
      </div>
    </Popover>
  );
};

export default memo(Timeline);

const DateComp = ({ dateString, onDateSelect, ...props }) => {
  const date = useMemo(() => (dateString ? new Date(dateString) : undefined), [dateString]);

  return (
    <DatePicker
      value={date}
      onChange={(date) => {
        const dateValue = date?.valueOf();
        if (!dateValue) return;
        onDateSelect(new Date(dateValue)?.toISOString());
      }}
      render={(value, openCalendar, handleValueChange) => {
        return (
          <button
            onClick={() => openCalendar()}
            className="text-sm p-1 rounded-md bg-gray-100 duration-100 hover:bg-gray-200"
            css={[!dateString && tw`w-[54px]`]}
          >
            {dateString ? moment(dateString).format("MM/DD/YYYY") : "-"}
          </button>
        );
      }}
      {...props}
    />
  );
};
