import { theme } from "twin.macro";

export const blueScrollStyles = {
  "&::-webkit-scrollbar": {
    width: "8px",
    height: "8px",
    borderRadius: "50px",
    overflow: "hidden",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme`colors.action.DEFAULT`,
  },
};
