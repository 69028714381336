import { Extension } from "@tiptap/core";
import { Plugin, PluginKey, TextSelection } from "prosemirror-state";
import { addAttributes } from "./utils";
import { createNode } from "../hooks/utils";

export const EnterHandler = Extension.create({
  name: "enterHandler",
  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        const { state, view } = editor;
        const { selection } = state;
        const { $from } = selection;
        const { tr } = state;

        const currentNode = $from.parent;
        const currentAttrs = currentNode.attrs;

        // Split the block at the current position
        const splitPos = tr.selection.to;
        tr.split(splitPos);

        // Calculate the position of the new node after the split
        const newNodePos = splitPos + 1;

        // Create new attributes for the new node
        const newAttrs = {
          ...currentAttrs,
          ...createNode(currentAttrs.level).attrs,
        };

        tr.setNodeMarkup(newNodePos, undefined, newAttrs);
        // Move the cursor to the start of the new node
        tr.setSelection(TextSelection.create(tr.doc, newNodePos + 1));

        view.dispatch(tr);

        // Prevent the default behavior
        return true;
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("enterHandler"),
        props: {
          handleDOMEvents: {
            /**
             * Initializes attributes for empty nodes on click in an empty editor.
             * Ensures each node has required attributes by updating any uninitialized nodes
             * upon the first click focus.
             */
            click: (view) => {
              const { state, dispatch } = view;
              const { doc, selection } = state;
              const { from, to } = selection;

              // If the selection is a NodeSelection (selects an entire node)
              if (from === to) {
                doc.descendants((node, pos) => {
                  if (node.isText) return;

                  const [newAttrs, needsUpdate] = addAttributes(node.attrs);

                  // If any attribute needs to be updated, create a transaction
                  if (needsUpdate) {
                    const tr = state.tr.setNodeMarkup(pos, undefined, newAttrs).setMeta("addToHistory", false);
                    dispatch(tr);
                  }
                });
              }
              return false; // Allow other handlers to process the event
            },
          },
        },
      }),
    ];
  },
});
