import { Sheet } from "components/copilot/CopilotSchemaImmutableTypes";
import { Storage } from "components/copilot/CopilotSchemaTypes";
import { EMPTY_SHEET } from "const-values/Sheets";
import { useMutation } from "YJSProvider/createYJSContext";
import { updateCheckedState } from "store/reducers/copilot/requirementsReducer";
import { useAppDispatch } from "store/storeTypes";
import { createSheet } from "utils/sheet";
import { LiveList, LiveObject, filter, find, findIndex, some, update } from "YJSProvider/LiveObjects";

const useSheetOperations = () => {
  const dispatch = useAppDispatch();

  const insertDefaultSheet = useMutation(
    ({ storage }, createdSheetProperties: Partial<Sheet> & Pick<Sheet, "name">, destinationIdx: number): Sheet => {
      let liveSheets = storage.get("sheets") as Storage["sheets"];
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];

      if (!liveSheets?.length) {
        storage.set("sheets", new LiveList([]));
        liveSheets = storage.get("sheets") as Storage["sheets"];
      }

      const createdSheet = createSheet(createdSheetProperties);

      complianceMatrix?.forEach((row) => {
        const extractionId = row.get("requirement")?.get("extraction_id");
        if (!extractionId) {
          row.get("requirement")?.set("extraction_id", createdSheet.get("id"));
        }
      });

      liveSheets?.insert(destinationIdx, [createdSheet]);

      // TODO: avoid force casting for toJSON()
      return createdSheet.toJSON() as Sheet;
    },
    [],
  );

  const validateDefaultSheet = useMutation(({ storage }) => {
    const liveRequirements = storage.get("compliance_matrix") as Storage["compliance_matrix"];
    const liveSheets = (storage.get("sheets") as Storage["sheets"]) || new LiveList();
    const requirementsWithoutExtraction_id = filter(
      liveRequirements,
      (row) => !row.get("requirement")?.get("extraction_id"),
    );
    const defaultSheetExists = some(liveSheets, (sheet) => sheet.get("name") === EMPTY_SHEET.name);
    const needsDefaultSheet = !defaultSheetExists && !!requirementsWithoutExtraction_id?.length;

    if (needsDefaultSheet) {
      const createdSheet = insertDefaultSheet({ name: EMPTY_SHEET.name }, 0);
      requirementsWithoutExtraction_id?.forEach((row) => {
        row.get("requirement")?.set("extraction_id", createdSheet.id);
      });
    }
  }, []);

  const updateSheet = useMutation(({ storage }, sheetId: Sheet["id"], sheetProperties: Omit<Partial<Sheet>, "id">) => {
    validateDefaultSheet();

    const liveSheets = storage.get("sheets") as Storage["sheets"];
    if (liveSheets) {
      const foundSheet = find(liveSheets, (sheet) => sheet.get("id") === sheetId);
      foundSheet && update(foundSheet, sheetProperties);
    }
  }, []);

  const appendNewSheets = useMutation(({ storage }, newSheets: LiveObject<Sheet>[]) => {
    validateDefaultSheet();

    let liveSheets = storage.get("sheets") as Storage["sheets"];
    if (!liveSheets?.length) {
      storage.set("sheets", new LiveList([]));
      liveSheets = storage.get("sheets") as Storage["sheets"];
    }

    newSheets?.forEach((sheet) => {
      liveSheets?.push([sheet]);
    });
  }, []);

  const deleteSheet = useMutation(
    ({ storage }, sheetId: Sheet["id"]) => {
      const liveSheets = storage.get("sheets") as Storage["sheets"];
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];

      if (sheetId === EMPTY_SHEET.id) {
        complianceMatrix?.forEach((row) => {
          const extractionId = row.get("requirement")?.get("extraction_id");
          if (!extractionId) {
            const rowIndex = findIndex(
              complianceMatrix,
              (deleteRow) => deleteRow.get("requirement")?.get("id") === row.get("requirement")?.get("id"),
            );

            dispatch(updateCheckedState({ [row.toJSON().requirement.id]: false }));
            complianceMatrix.delete(rowIndex);
          }
        });

        return;
      }

      if (!liveSheets?.length) return;
      const sheetIdx = findIndex(liveSheets, (sheet) => sheet.get("id") === sheetId);

      if (sheetIdx < 0) return;

      complianceMatrix?.forEach((row) => {
        const extractionId = row.get("requirement")?.get("extraction_id");
        if (extractionId === sheetId) {
          const rowIndex = findIndex(
            complianceMatrix,
            (deleteRow) => deleteRow.get("requirement")?.get("id") === row.get("requirement")?.get("id"),
          );

          dispatch(updateCheckedState({ [row.toJSON().requirement.id]: false }));
          complianceMatrix.delete(rowIndex);
        }
      });

      liveSheets?.delete(sheetIdx);
    },
    [dispatch],
  );

  return {
    appendNewSheets,
    deleteSheet,
    insertDefaultSheet,
    validateDefaultSheet,
    updateSheet,
  };
};

export default useSheetOperations;
