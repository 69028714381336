import { EventStreamContentType, fetchEventSource } from "@microsoft/fetch-event-source";
import { useNotification } from "context/notificationContext";
import { useLocalStorage } from "hook/useLocalStorage";
import { useCallback, useEffect, useRef, useState } from "react";
import { StreamEvent } from "types/Streaming/streamConfig";
import * as Sentry from "@sentry/react";
import { apiUrl } from "config/vultronConfig";

type ReviseVariables = {
  previous_response: string;
  user_feedback: string;
  win_themes: string[];
};

export const useAIReviseStream = (onSuccess: (text: string) => void, internalContractId?: string) => {
  const { localValue } = useLocalStorage("vultron_user_token", "");
  const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
  const { localValue: use_auth0 } = useLocalStorage("vultron_user_use_auth0");
  const useAuth0Header = use_auth0 === true;
  const controllerRef = useRef(new AbortController());
  const { current: controller } = controllerRef;
  const { setToast } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [revisedText, setRevisedText] = useState("");

  useEffect(() => {
    if (!isLoading && !!revisedText) {
      onSuccess(revisedText);
      setRevisedText("");
    }
  }, [isLoading, onSuccess, revisedText]);

  const reviseText = (body: ReviseVariables) => {
    setIsLoading(true);
    fetchEventSource(`${apiUrl}/proposal_generation/${internalContractId}/feedback/generate/stream`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Workspace: `Workspace ${workspace_id}`,
        Authorization: `Bearer ${localValue}`,
        "X-Authorization-Auth0": JSON.stringify(useAuth0Header),
        Accept: "application/json",
      },
      body: JSON.stringify(body),
      signal: controller.signal,
      openWhenHidden: true,
      onmessage(msg) {
        if (msg.event === "FatalError") {
        }

        if (msg.event === StreamEvent.StreamRestart) {
          setRevisedText("");
          return;
        }

        if (msg.data?.length) {
          setRevisedText((prev) => `${prev}${msg.data}`);
        } else if (typeof msg.data === "string") {
          setRevisedText((prev) => `${prev}\n`);
        }
      },
      async onopen(response) {
        setRevisedText("");
        if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
          return; // everything's good
        } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
          setToast.error({
            title: "Unable to revise content",
            msg: "We were unable to revise the content due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
          });
          setIsLoading(false);
          Sentry.captureException(new Error("Ai revise failed"), {
            extra: { response },
          });
        } else {
        }
      },
      onclose() {
        setIsLoading(false);
      },
      onerror(err) {
        setToast.error({
          title: "Unable to revise content",
          msg: "We were unable to revise the content due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        });
        setIsLoading(false);
        if (err instanceof Error) {
          Sentry.captureException(err);
          throw err; // rethrow to stop the operation
        } else {
        }
      },
    });
  };

  const abortConnection = useCallback(() => {
    controllerRef.current.abort();
    controllerRef.current = new AbortController();
    setRevisedText("");
    setIsLoading(false);
  }, []);

  return { isLoading, setIsLoading, reviseText, abortConnection };
};
