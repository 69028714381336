import { formatMargins } from "./utils";
import { DefaultMarginOptions, margins } from "../extensions/document-margin/constants";

export const DEFAULT_MARGIN_OPTIONS = [
  {
    value: DefaultMarginOptions.Normal,
    title: "Normal",
    description: formatMargins(margins[DefaultMarginOptions.Normal]),
  },
  {
    value: DefaultMarginOptions.Narrow,
    title: "Narrow",
    description: formatMargins(margins[DefaultMarginOptions.Narrow]),
  },
  {
    value: DefaultMarginOptions.Moderate,
    title: "Moderate",
    description: formatMargins(margins[DefaultMarginOptions.Moderate]),
  },
  { value: DefaultMarginOptions.Wide, title: "Wide", description: formatMargins(margins[DefaultMarginOptions.Wide]) },
];
