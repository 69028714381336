import { PlusIcon, X } from "lucide-react";
import ClickAwayListener from "helpers/ClickAwayListener";
import { useRef, useEffect, useMemo } from "react";
import { useAppSelector } from "store/storeTypes";

interface TagsSectionProps {
  tags: string[];
  isAddingTag: boolean;
  setIsAddingTag: (value: boolean) => void;
  newTag: string;
  setNewTag: (value: string) => void;
  handleAddTag: (e: React.FormEvent | string) => void;
  handleRemoveTag: (tag: string) => void;
  isMediaFile?: boolean;
}

export const TagsSection = ({
  tags,
  isAddingTag,
  setIsAddingTag,
  newTag,
  setNewTag,
  handleAddTag,
  handleRemoveTag,
  isMediaFile,
}: TagsSectionProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { userTags } = useAppSelector((state) => state.drive[isMediaFile ? "media" : "fileStorage"]);

  useEffect(() => {
    if (isAddingTag && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAddingTag]);

  const suggestedTags = useMemo(() => {
    return userTags.filter((tag) => tag.toLowerCase().includes(newTag.toLowerCase()) && !tags.includes(tag));
  }, [userTags, newTag, tags]);

  const handleSelectSuggestedTag = (tag: string) => {
    handleAddTag(tag);
    setNewTag("");
    inputRef.current?.focus();
  };

  return (
    <div className="ml-6 mr-6">
      <div className="flex items-center justify-between mb-4">
        <div className="text-sm font-medium">Tags</div>
        <button className="hover:bg-gray-100 rounded-full" onClick={() => setIsAddingTag(true)}>
          <PlusIcon color="#7A7F84" size={16} />
        </button>
      </div>
      <div className="flex flex-wrap gap-2 relative">
        {tags.length > 0 ? (
          tags.map((tag, index) => (
            <div
              key={index}
              className="relative inline-flex items-center rounded bg-[#F4F5F6] px-2 py-[4.5px] text-sm group"
            >
              {tag}
              <button
                onClick={() => handleRemoveTag(tag)}
                className="absolute -top-1 -right-1 rounded-full bg-gray-400 hover:bg-gray-500 w-4 h-4 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity"
              >
                <X size={12} className="text-white" />
              </button>
            </div>
          ))
        ) : (
          <div className="text-sm text-gray-400 cursor-pointer py-[4.5px]" onClick={() => setIsAddingTag(true)}>
            + Add Tag
          </div>
        )}

        {isAddingTag && (
          <ClickAwayListener
            onClickAway={() => {
              setIsAddingTag(false);
              setNewTag("");
            }}
          >
            <div className="absolute top-full left-0 mt-1 bg-white rounded-lg shadow-lg border p-3 z-10 w-full">
              <form onSubmit={handleAddTag}>
                <input
                  ref={inputRef}
                  type="text"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  onKeyDown={(e) => e.key === "Escape" && setIsAddingTag(false)}
                  placeholder="Add tag..."
                  className="w-full px-2 py-1.5 text-sm border rounded focus:outline-none focus:ring-gray-darkest focus:border-gray-darkest"
                />
              </form>

              {suggestedTags.length > 0 && (
                <div className="mt-1">
                  <span className="text-xs text-gray-500">Suggested tags</span>
                  <div className="flex flex-wrap gap-1.5 mt-2 max-h-[60px] overflow-y-auto">
                    {suggestedTags.map((tag, index) => (
                      <button
                        key={index}
                        onClick={() => handleSelectSuggestedTag(tag)}
                        className="px-2 py-1 text-xs bg-gray-50 hover:bg-gray-100 rounded transition-colors duration-150"
                      >
                        {tag}
                      </button>
                    ))}
                  </div>
                </div>
              )}
              <div className="mt-2 text-xs text-gray-500">Press Enter to add, Esc to cancel</div>
            </div>
          </ClickAwayListener>
        )}
      </div>
    </div>
  );
};
