/** @jsxImportSource @emotion/react */

import { Extraction, ExtractionStatus } from "components/copilot/CopilotSchemaTypes";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { StepValue } from "../../../types";
import { ToImmutable } from "YJSProvider/LiveObjects";
import useExtractionOperations from "hook/useExtractionOperations";
import "styles/markdown-styles.css";
import { isInstantDraftStarted } from "../../../utils";
import { useSelection } from "../../../document-display/SelectionContext";
import { useEffect, useRef, useState } from "react";
import compact from "lodash/compact";
import omit from "lodash/omit";
import InsertHereLine from "components/atoms/insert-here-line";
import RequirementItem from "./RequirementItem";
import { useDocViewNotification } from "components/copilot/extract-v2/context/doc-view-notification-context/docViewNotificationContext";
import { setActiveDragOverId } from "store/reducers/extract/CurrentExtractionReducer";
import tw from "twin.macro";
import DropItemsContainer from "components/atoms/drop-items-container";

type Props = {
  sectionId: string;
  requirements: ToImmutable<Extraction["compliance_matrix"]>;
};

const RequirementList = ({ requirements, sectionId }: Props) => {
  const isDraggingOver = useAppSelector((store) => store.currentExtractionState.activeDragOverId === sectionId);
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const { selectedBlocks, clearSelection } = useSelection();
  const dispatch = useAppDispatch();
  const isComplete = extraction?.status === ExtractionStatus.Completed;
  const isImportStep = extraction?.step === StepValue.Review;
  const isReadOnly = isComplete || isImportStep || isInstantDraftStarted(extraction?.instantDraftConfig?.status);
  const { bulkAssignExtractionRequirements } = useExtractionOperations();
  const previousRequirementsRef = useRef(requirements);
  const [newRequirementsMap, setNewRequirementsMap] = useState<Record<string, boolean>>({});
  const { showRequirementSuccessToast } = useDocViewNotification();
  const canDrag = !isReadOnly && !!selectedBlocks?.length;
  const onInsertRequirement = () => {
    if (!extraction?.id || !selectedBlocks?.length) return;
    const selectedReqIds = selectedBlocks.map((block) => block.requirement.requirement.id);

    bulkAssignExtractionRequirements(extraction.id, selectedReqIds, sectionId, 0);
    showRequirementSuccessToast("assigned to Proposal Outline", selectedReqIds.length);
    clearSelection?.();
  };

  useEffect(() => {
    previousRequirementsRef.current = requirements;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionId]);

  useEffect(() => {
    if (requirements.length < previousRequirementsRef.current.length) {
      previousRequirementsRef.current = requirements;
      return;
    }

    const addedRequirements = requirements.filter(
      (requirement) =>
        !previousRequirementsRef.current?.some(
          (oldRequirement) => oldRequirement.requirement.id === requirement.requirement.id,
        ),
    );

    setNewRequirementsMap(
      addedRequirements.reduce<Record<string, boolean>>(
        (acc, requirement) => ({ ...acc, [requirement.requirement.id]: true }),
        {},
      ),
    );

    setTimeout(
      () =>
        setNewRequirementsMap((prev) =>
          omit(
            prev,
            addedRequirements.map(({ requirement }) => requirement.id),
          ),
        ),
      12000,
    );

    previousRequirementsRef.current = requirements;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requirements?.length]);

  return (
    <div className="flex flex-col border-r border-gray-light markdown-body">
      {!requirements.length && (
        <div
          className="relative"
          onDragEnter={(e) => {
            e.preventDefault();

            dispatch(setActiveDragOverId(sectionId));
          }}
          {...(canDrag
            ? {
                onClick: onInsertRequirement,
                tabIndex: 0,
                role: "button",
              }
            : {})}
          css={[!!selectedBlocks?.length && !isReadOnly && tw`cursor-pointer hover:bg-gray-100`]}
        >
          {isDraggingOver && (
            <DropItemsContainer
              onDragLeave={() => {
                dispatch(setActiveDragOverId(""));
              }}
              onDrop={(e) => {
                if (!extraction?.id) return;
                const data = e.dataTransfer.getData("application/json");
                if (!data) return;

                try {
                  const reqIdMap = JSON.parse(data);
                  const reqIds = compact(Object.keys(reqIdMap));
                  bulkAssignExtractionRequirements(extraction?.id, reqIds, sectionId, 0);
                  clearSelection?.();
                } catch {
                } finally {
                  dispatch(setActiveDragOverId(""));
                }
              }}
              dropLabel="Add selected requirements here"
            />
          )}
          <div className="relative peer">
            <div
              className="relative border-b border-gray-light text-center text-gray-500 text-sm py-4"
              css={[isDraggingOver && tw`opacity-0`]}
            >
              No requirements
            </div>
          </div>
          {canDrag && !isDraggingOver && <InsertHereLine onHoverOnly onClick={onInsertRequirement} />}
        </div>
      )}
      {requirements.map((row, i) => {
        const isNewlyAdded = !!newRequirementsMap[row.requirement.id];
        return (
          <RequirementItem
            key={row.requirement.id}
            row={row}
            isReadOnly={isReadOnly}
            extractionId={extraction?.id}
            sectionId={sectionId}
            isNewlyAdded={isNewlyAdded}
            idx={i}
          />
        );
      })}
    </div>
  );
};

export default RequirementList;
