/** @jsxImportSource @emotion/react */

import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { useFlags } from "hook/useFlags";
import {
  toggleAssistant,
  toggleComments,
  toggleContentSearch,
  toggleRequirements,
} from "store/reducers/copilot/copilotDrawerReducer";

const CopilotActionsOptions = () => {
  const flags = useFlags();
  const dispatch = useAppDispatch();

  const params = useParams();
  const { assistantOpen, contentSearchOpen, requirementsOpen, requirementDetailsOpen, commentsOpen } = useAppSelector(
    (root) => root.copilotDrawer,
  );
  const [searchParams] = useSearchParams();
  const internalContractId = searchParams.get("id")?.toLocaleLowerCase();
  const referenceId = searchParams.get("docId")?.toLocaleLowerCase() || params.volumeId;
  const { comments: shouldEnableComments } = flags;

  return (
    <>
      <button
        className="h-full m-2 border-b border-white px-0.5 hover:border-gray-darkest"
        css={[assistantOpen ? tw`border-gray-darkest font-semibold` : tw`font-medium`]}
        onClick={() => {
          dispatch(toggleAssistant(true));
        }}
      >
        <span className="text-sm text-gray-darkest">Assistant</span>
      </button>
      <button
        className="h-full m-2 border-b border-white px-0.5 hover:border-gray-darkest"
        css={[requirementsOpen || requirementDetailsOpen ? tw`border-gray-darkest font-semibold` : tw`font-medium`]}
        onClick={() => {
          dispatch(toggleRequirements(true));
        }}
      >
        <span className="text-sm text-gray-darkest">Requirements</span>
      </button>
      <button
        className="h-full m-2 border-b border-white px-0.5 hover:border-gray-darkest"
        css={[contentSearchOpen ? tw`border-gray-darkest font-semibold` : tw`font-medium`]}
        onClick={() => {
          dispatch(toggleContentSearch(true));
        }}
      >
        <span className="text-sm text-gray-darkest">Search</span>
      </button>
      {shouldEnableComments && internalContractId && referenceId && (
        <button
          className="h-full m-2 border-b border-white px-0.5 hover:border-gray-darkest"
          css={[commentsOpen ? tw`border-gray-darkest font-semibold` : tw`font-medium`]}
          onClick={() => {
            dispatch(toggleComments(true));
          }}
        >
          <span className="text-sm text-gray-darkest">Comments</span>
        </button>
      )}
    </>
  );
};

export default CopilotActionsOptions;
