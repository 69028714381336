import { FC } from "react";
import { ReactComponent as Note } from "Assets/note-2.svg";

export enum Source {
  ContentDrive = 1,
  Internet,
}

export const SOURCE_TO_META: Record<Source, { copy: string; searchPlaceholder?: string; icon: FC; tooltip?: string }> =
  {
    [Source.Internet]: {
      copy: "Internet",
      icon: Note,
      tooltip:
        "Search the entire internet, a full website, or a specific link. Please indicate how to use the link, when provided, such as whether to use the full website or only the specific link.",
    },
    [Source.ContentDrive]: {
      searchPlaceholder: "Search content library documents...",
      copy: "Content Library",
      icon: Note,
    },
  };
