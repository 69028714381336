import { useEffect, useMemo, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { threadQueryKey, getThreads } from "./comments";
import { partition } from "lodash";
import { useUpdates } from "YJSProvider/createYJSContext";

export const useCommentsQuery = (internalContractId: string, referenceId?: string) => {
  const query = useQuery({
    queryKey: threadQueryKey(internalContractId, referenceId),
    queryFn: () => getThreads(internalContractId, referenceId),
    staleTime: 1000,
  });

  const [resolvedThreads, unresolvedThreads] = useMemo(
    () => partition(query.data, (thread) => thread.resolved) || [],
    [query.data],
  );

  return {
    ...query,
    unresolvedThreads,
    resolvedThreads,
  };
};

export const useComments = (internalContractId: string, referenceId?: string) => {
  const updates = useUpdates<number>(`threads_${referenceId}`, 0);
  const isFirstRender = useRef(true); //track initial render

  const query = useCommentsQuery(internalContractId || "", referenceId);

  useEffect(() => {
    // check if its the first render
    if (isFirstRender.current) {
      isFirstRender.current = false; // set isFirstRender to false after initial run
      return;
    }

    query.refetch();
  }, [updates]);

  return query;
};
