/** @jsxImportSource @emotion/react */

import moment from "moment";
import { useMemo } from "react";
import { SquarePen } from "lucide-react";
import { AIAssistant } from "utils/yjs-configs/ai-assistant/schema";
import { useAppSelector } from "store/storeTypes";
import AIAssistantSidebarRow from "./AIAssistantSidebarRow";
import { motion } from "framer-motion";
import ArchivedMenu from "./ArchivedMenu";
import { ToImmutable } from "YJSProvider/LiveObjects";
import { useStorage } from "utils/yjs-configs/ai-assistant/yjs.config";

import useAIAssistantOperations from "hook/useAIAssistantOperations";

type Props = {
  isInitial: boolean;
  sidebarOpen: boolean;
};

const AIAssistantSidebar = ({ isInitial, sidebarOpen }: Props) => {
  const activeSession = useAppSelector((store) => store.aiAssistantState.activeSession);
  const sessions = useStorage((root) => root.ai_assistant?.sessions || []);
  const { createSession } = useAIAssistantOperations();

  const sortedSessions = useMemo(
    () =>
      [...sessions].sort((a, b) => {
        if (a.updated_at && b.updated_at) {
          return -a.updated_at.localeCompare(b.updated_at);
        }
        return 0;
      }),
    [sessions],
  );

  const groupedSessions = useMemo(() => {
    return sortedSessions.reduce<{ [key: string]: ToImmutable<AIAssistant["sessions"]> }>((acc, session) => {
      const today = moment();
      const sessionDate = moment(session.updated_at);
      const daysDiff = today.diff(sessionDate, "days"); // 1

      // today
      if (sessionDate.isSame(today, "day")) {
        return { ...acc, Today: [...(acc.Today || []), session] };
      }

      // yesterday
      if (daysDiff <= 1) {
        return { ...acc, Yesterday: [...(acc.Yesterday || []), session] };
      }

      // 7 days
      if (daysDiff <= 7) {
        return { ...acc, "Last 7 days": [...(acc["Last 7 days"] || []), session] };
      }

      // 30 days
      if (daysDiff <= 30) {
        return { ...acc, "Last 30 days": [...(acc["Last 30 days"] || []), session] };
      }

      // 90 days
      if (daysDiff <= 90) {
        return { ...acc, "Last 90 days": [...(acc["Last 90 days"] || []), session] };
      }

      // by year (< 90 days)
      if (daysDiff > 90) {
        const year = sessionDate.year();
        return { ...acc, [year]: [...(acc[year] || []), session] };
      }

      return acc;
    }, {});
  }, [sortedSessions]);

  const entries = useMemo(() => Object.entries(groupedSessions), [groupedSessions]);

  return (
    <div
      data-state={isInitial ? undefined : sidebarOpen ? "open" : "closed"}
      className="flex-shrink-0 whitespace-nowrap border-r border-gray-light sidebarCollapsibleContent overflow-x-hidden"
    >
      <div className="h-full flex flex-col">
        <div className="flex-1 relative overflow-hidden">
          <div className="absolute -bottom-[1px] right-0 left-0 h-8 z-[1] from-layout-gray-light to-transparent" />
          <div className="h-full flex flex-col gap-6 px-1 overflow-y-auto">
            <div className="sticky left-0 right-0 top-0 z-20 pt-3.5 px-2">
              <button
                onClick={() => createSession("New Session")}
                className="flex text-sm font-medium shadow border border-gray w-full justify-between flex-row items-center rounded-md py-3 pl-2.5 pr-4 duration-150 bg-white hover:brightness-95"
              >
                New Session <SquarePen size={14} />
              </button>
            </div>
            <div className="flex flex-col text-sm pb-4 gap-10">
              {!entries.length && (
                <div className="flex flex-col gap-1.5 px-[18px]">
                  <div className="text-xs text-slate-500 font-medium">Today</div>
                  <div className="text-sm text-slate-400">New Session</div>
                </div>
              )}
              {entries.map(([dateRangeKey, sessions]) => (
                <div key={dateRangeKey} className="flex flex-col gap-1.5">
                  <motion.div
                    key={dateRangeKey}
                    className="text-xs pl-[18px] text-slate-500 font-medium"
                    layout
                    layoutId={dateRangeKey}
                    animate="visible"
                  >
                    {dateRangeKey}
                  </motion.div>
                  <div className="px-2">
                    {sessions.map((session) => (
                      <AIAssistantSidebarRow
                        key={session.id}
                        session={session}
                        isActive={activeSession?.id === session.id}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <ArchivedMenu />
      </div>
    </div>
  );
};

export default AIAssistantSidebar;
