import { useMemo } from "react";
import sortBy from "lodash/sortBy";
import { ReactComponent as Template } from "Assets/extract/template.svg";
import { ReactComponent as Assign } from "Assets/extract/assign.svg";
import { ReactComponent as Generate } from "Assets/generate-v2.svg";
import { ReactComponent as Review } from "Assets/extract/generate.svg";
import { Step } from "components/organisms/stepper";
import { StepValue, ValidStepValueType } from "../types";
import { useFlags } from "hook/useFlags";

export const useExtractionSteps = (
  instantDraftEnabled: boolean,
  currentStep?: StepValue,
  isExtractionCompleted?: boolean,
) => {
  const { disableTemplateTabInGenerate } = useFlags();
  const groupedSteps: Partial<{ [key in ValidStepValueType]: Step<key> }> = useMemo(() => {
    const tabTemplateOffset = disableTemplateTabInGenerate ? 0 : 1;
    return {
      ...(disableTemplateTabInGenerate
        ? {}
        : {
            [StepValue.Template]: {
              label: "Select templates",
              icon: Template,
              step: 1,
              value: StepValue.Template,
            },
          }),
      [StepValue.Requirements]: {
        label: "Manage requirements",
        icon: Assign,
        step: 1 + tabTemplateOffset,
        value: StepValue.Requirements,
      },
      [StepValue.Generate]: {
        label: "Generate draft",
        icon: Generate,
        step: 2 + tabTemplateOffset,
        value: StepValue.Generate,
      },
      [StepValue.Review]: {
        label: "Publish results",
        icon: Review,
        step: 3 + tabTemplateOffset,
        value: StepValue.Review,
        disabled: currentStep === StepValue.Generate && !isExtractionCompleted,
      },
    };
  }, [currentStep, isExtractionCompleted, disableTemplateTabInGenerate]);

  const extractSteps = useMemo(
    () =>
      sortBy(
        Object.values(groupedSteps).filter((step) =>
          step.value === StepValue.Generate && !instantDraftEnabled ? false : true,
        ),
        "step",
      ),
    [groupedSteps, instantDraftEnabled],
  );

  return { groupedSteps, extractSteps };
};

export const useExtractionStepsToMeta = (instantDraftEnabled: boolean) => {
  const STEP_TO_META: Record<ValidStepValueType, { prevStep: StepValue | null; nextStep: StepValue | null }> = {
    [StepValue.Template]: {
      prevStep: null,
      nextStep: StepValue.Requirements,
    },
    [StepValue.Requirements]: {
      prevStep: StepValue.Template,
      nextStep: instantDraftEnabled ? StepValue.Generate : StepValue.Review,
    },
    [StepValue.Generate]: {
      prevStep: StepValue.Requirements,
      nextStep: StepValue.Review,
    },
    [StepValue.Review]: {
      prevStep: instantDraftEnabled ? StepValue.Generate : StepValue.Requirements,
      nextStep: null,
    },
  };

  return STEP_TO_META;
};
