import { useEffect, useState } from "react";

type Props = {
  max?: number;
  min?: number;
  maxLength?: number;
  onChange: (newValue: number) => unknown;
  value: number;
};

export function SmallIntegerInput({ value, onChange, min, max, maxLength }: Props) {
  const [valueStr, setValueStr] = useState(`${value}`);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    setValueStr(inputValue);
  };

  useEffect(() => {
    setValueStr(`${value}`);
  }, [value]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete", "Home", "End", "Shift"];

    if (e.key === "Enter") {
      onChange(Number.parseInt(valueStr, 10));
    } else if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <input
      type="text"
      value={valueStr}
      className="border-gray-200 border rounded-md px-3 w-[40px] py-[5px] text-[#5b6b79] focus:border-sky-600 focus:outline-none text-[12.5px]"
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      inputMode="numeric"
      aria-label={min && max ? `Enter a font size between ${min} and ${max}` : undefined}
      maxLength={maxLength}
    />
  );
}
