/** @jsxImportSource @emotion/react */

import { ChangeEvent, FocusEvent, FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { DndContext } from "@dnd-kit/core";
import { PanelRight, PanelRightOpen } from "lucide-react";
import Tooltip from "components/atoms/tooltip";
import tw from "twin.macro";
import { useFlags } from "hook/useFlags";
import { useParams, useSearchParams } from "react-router-dom";

import Copilot from "components/copilot/Copilot";
import CopilotActionsPanel, { CopilotActionsOptions } from "components/copilot/copilot-actions-panel";
import LiveOthers from "components/copilot/LiveOthers";
import RequirementsBulkUpdate from "components/organisms/requirements-bulk-update/RequirementsBulkUpdate";
import { ProjectHeader } from "components/molecules/project-header";
import SideNavigation from "components/molecules/side-navigation/SideNavigation";
import { RoomProvider } from "YJSProvider/createYJSContext";
import { ScreenSpinner } from "utils/icons";
import usePersistedStorage from "hook/persisted-storage/usePersistedStorage";
import { useDrag as useBeautifulDrag } from "components/copilot/hooks";

import { clearProject, getProject } from "store/reducers/projectReducer";
import { fetchFileStorage } from "store/reducers/driveReducerSlice";
import { getProjectAttachments, resetProjectAttachmentsState } from "store/reducers/projectAttachmentsReducer";
import { resetAssistantState } from "store/reducers/writing-assistant/writingAssistantReducer";
import { resetBannerState } from "store/reducers/copilot/copilotBannerReducer";
import { resetContentSearchState } from "store/reducers/copilot/contentSearchReducer";
import { resetRequirementsDrawerState } from "store/reducers/copilot/requirementsDrawerReducer";
import { resetRequirementsState } from "store/reducers/copilot/requirementsReducer";
import { toggleActions, toggleAssistant } from "store/reducers/copilot/copilotDrawerReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";

import { getInternalContractDetails, updateInternalContractDetails } from "api/api";
import { useNotification } from "context/notificationContext";

import { CopilotPresencePage } from "types/Presence";
import { InternalContract } from "types/InternalContract";
import { TOP_BANNER } from "const-values/Banner";
import {
  useCloseInifiniteResponseGenerations,
  useCatchResizeObserver,
  useDrag,
  usePollActiveProject,
  usePrefetchCaptureForms,
  useInspectCorruptedObjects,
} from "./hooks";
import { verticalSortableListCollisionDetection } from "pages/draft-volume/draft-volume-sidebar/utils";

const ProjectDetails = () => {
  const [searchParams] = useSearchParams();
  const internalContractId = searchParams.get("id")?.toLocaleLowerCase() || "";
  const { fullscreen, sidebarVisible } = useAppSelector((root) => root.copilot);
  const flags = useFlags();

  return (
    <div
      className="bg-[#f8f9fa] z-[3] flex flex-row duration-150 absolute top-[65px] right-0 left-[234px] bottom-0 overflow-hidden"
      css={[
        fullscreen && tw`duration-200 top-0 left-0 right-0`,
        !!flags.topBannerV2?.bannerCopy && {
          top: fullscreen ? TOP_BANNER.HEIGHT : `calc(65px + ${TOP_BANNER.HEIGHT}px)`,
        },
        fullscreen && sidebarVisible && tw`left-[234px]`,
      ]}
    >
      <RoomProvider
        authParams={["copilot", internalContractId, internalContractId]}
        id={internalContractId}
        fallback={<LoadingText />}
      >
        <ProjectRoom />
      </RoomProvider>
    </div>
  );
};

export default ProjectDetails;

const ProjectRoom = () => {
  useCatchResizeObserver();
  const { setToast } = useNotification();
  const { volumeId } = useParams();
  const [searchParams] = useSearchParams();
  const tabName = searchParams.get("tab")?.toLocaleLowerCase() || CopilotPresencePage.Project;
  const internalContractId = searchParams.get("id")?.toLocaleLowerCase();
  const dispatch = useAppDispatch();
  const { open } = useAppSelector((root) => root.copilotDrawer);
  const fullscreen = useAppSelector((root) => root.copilot.fullscreen);
  const [internalContractDetails, setInternalContractDetails] = useState<InternalContract>({});
  const [contractDetails, setContractDetails] = useState({});
  const [title, setTitle] = useState("");
  const [titleEditable, setTitleEditable] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const { sensors, handleDragCancel, handleDragEnd, handleDragStart } = useDrag();
  const [isPinned, setIsPinned] = usePersistedStorage<boolean>("vultron_pin_navigation", false);
  usePollActiveProject(internalContractId);
  useCloseInifiniteResponseGenerations();
  usePrefetchCaptureForms();
  useInspectCorruptedObjects();

  useEffect(() => {
    if (!internalContractId) return;
    dispatch(resetBannerState());
    dispatch(getProject({ internalContractId, triggerDocRefresh: true }));
    dispatch(fetchFileStorage());
    dispatch(getProjectAttachments(internalContractId));
    return () => {
      dispatch(clearProject());
      dispatch(resetAssistantState());
      dispatch(resetRequirementsDrawerState());
      dispatch(resetContentSearchState());
      dispatch(resetRequirementsState());
      dispatch(resetProjectAttachmentsState());
    };
  }, [dispatch, internalContractId]);

  // Fetch contract information and mark as viewed
  useEffect(() => {
    if (!internalContractId) return;
    getInternalContractDetails(internalContractId)
      .then((res) => {
        setInternalContractDetails(res?.data || {});
        const contractDetails = { ...res?.data?.internal_contract?.contract, notes: res?.data?.notes };
        setContractDetails(contractDetails || {});
      })
      .catch((err) => {
        setToast.error({
          title: "Unable to load project",
          msg: "We were unable to load the project due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        });
      })
      .finally(() => {});
    if (forceRefresh) dispatch(getProject({ internalContractId, triggerDocRefresh: true }));

    setForceRefresh(false);
  }, [internalContractId, forceRefresh, dispatch, setToast]);

  useEffect(() => {
    const internalCDetails = JSON.parse(JSON.stringify(internalContractDetails));
    setTitle(internalCDetails?.internal_contract?.project_name);
  }, [internalContractDetails]);
  useEffect(() => {
    if (fullscreen) dispatch(toggleAssistant(true));
  }, [dispatch, fullscreen]);

  const titleChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTitle(value);
  }, []);

  const changeTitle = async (e: FormEvent<HTMLFormElement> | FocusEvent<HTMLInputElement>) => {
    e.preventDefault();

    setTitleEditable(false);
    await updateInternalContractDetails(internalContractId, { project_name: title });
  };

  const editTitle = () => {
    setTitleEditable(true);
  };

  const isProjectLoading = useMemo(
    () =>
      (internalContractDetails && Object.keys(internalContractDetails).length === 0) ||
      (contractDetails && Object.keys(contractDetails).length === 0),
    [contractDetails, internalContractDetails],
  );
  const dragEnd = useBeautifulDrag();

  const containerStyling = isPinned ? tw`w-[calc(100vw-230px)] left-[230px]` : tw`w-[calc(100vw-92px)] left-[92px]`;

  return (
    <DragDropContext onDragEnd={dragEnd}>
      <DndContext
        sensors={sensors}
        collisionDetection={verticalSortableListCollisionDetection}
        onDragEnd={(event) => handleDragEnd(event)}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
      >
        <div className="flex">
          {!volumeId && <SideNavigation isPinned={isPinned} setIsPinned={setIsPinned} />}
          <div className="flex flex-col relative duration-300" css={[!volumeId ? containerStyling : tw`w-[100vw]`]}>
            {!volumeId && (
              <ProjectHeader
                isLoading={isProjectLoading}
                back={
                  tabName !== CopilotPresencePage.AskAi
                    ? `/dashboard/contracts`
                    : `/dashboard/contracts/details/?tab=project&id=${internalContractId}&chatId=0ds`
                }
                actions={
                  fullscreen ? (
                    <div className="flex items-center justify-end gap-2 w-[80px]">
                      <LiveOthers tab={tabName} avatarSize={22} />
                      <Tooltip content={open ? "Close panel" : "Open panel"}>
                        <button
                          className="text-[22px] h-8 w-8 text-gray-darkest rounded-md flex justify-center items-center duration-150 bg-transparent hover:bg-action-lightest hover:brightness-95"
                          onClick={() => dispatch(toggleActions(!open))}
                        >
                          {open ? <PanelRight size={22} /> : <PanelRightOpen size={22} />}
                        </button>
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="flex gap-2">
                      <CopilotActionsOptions />
                    </div>
                  )
                }
                header={
                  <div className="flex items-center w-full text-gray-darkest">
                    <form onSubmit={changeTitle} className="w-full">
                      {titleEditable ? (
                        <input
                          type="text"
                          value={title}
                          className="border-none outline-none text-center w-[60vw]"
                          onChange={titleChangeHandler}
                          onBlur={changeTitle}
                          autoFocus
                        />
                      ) : (
                        <div className="contract-details-title flex items-center cursor-text">
                          <div
                            className="px-2 py-1 rounded-md truncate"
                            onClick={editTitle}
                            css={[
                              !titleEditable && tw`hover:bg-slate-100 pointer-events-auto`,
                              titleEditable && tw`!bg-transparent`,
                            ]}
                          >
                            {title}
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                }
              />
            )}

            <div className="flex h-[calc(100%-40px)] w-full" css={[volumeId ? tw`h-full` : tw`h-[calc(100%-40px)]`]}>
              <Copilot
                isProjectLoading={isProjectLoading}
                setContractDetails={setContractDetails}
                contractDetails={contractDetails}
                internalContractId={internalContractId}
                internalContractDetails={internalContractDetails}
                setForceRefreshContract={setForceRefresh}
                setInternalContractDetails={setInternalContractDetails}
              />
              {fullscreen && <CopilotActionsPanel />}
              <RequirementsBulkUpdate />
            </div>
          </div>
        </div>
      </DndContext>
    </DragDropContext>
  );
};

const LoadingText = () => {
  const [dots, setDots] = useState(".");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((currentDots) => {
        if (currentDots.length < 3) {
          return currentDots + ".";
        }
        return ".";
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="p-20 w-full my-auto flex flex-col items-center justify-center gap-3">
      <ScreenSpinner />
      <div className="relative text-gray-400">
        <span>Establishing secure connection</span>
        <span className="absolute left-full top-0">{dots}</span>
      </div>
    </div>
  );
};
